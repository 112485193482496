import React from 'react';
import ReactDOM from 'react-dom/client';
//import SignIn from './pages/SignIn/SignIn';
import App from './App';
//import LinkBankAccount from './pages/Account/LinkBankAccount';
//import Home from './pages/Home';
import './index.scss';

import reportWebVitals from './reportWebVitals';

import { AppContextProvider } from './utils/app-context';

/***SETUP AMPLIFY STYLES***/
import { configureAmplify } from 'utils/aws-config';
configureAmplify();
/***SETUP AMPLIFY STYLES***/

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
	<AppContextProvider>
		<App />
	</AppContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
