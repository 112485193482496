import {
	IonButton,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonRow,
	IonSpinner,
	IonText,
	useIonToast,
} from '@ionic/react';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useMobileDetector from 'hooks/use-mobile-detector';
import logoimg from '../../images/RP_Logo-01.svg';
import successimg from '../../images/LoginSuccess.svg';
import mailIcon from '../../images/light/mail.svg';
import { Routes } from 'utils/routes';
import { Toaster, toast } from 'react-hot-toast';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';

const ForgotPassword = () => {
	const [userNameType, setUserNameType] = useState('email');
	const [present] = useIonToast();
	const navigate = useNavigate();
	const [showPwd, setShowPwd] = useState(false);
	const isMobile = useMobileDetector() === 'mobile';
	const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const [resendCodeTimer, setResendCodeTimer] = useState(50);
	const [resendCodeDisabled, setResendCodeDisabled] = useState(false);

	const validationSchema = Yup.object({
		userName:
			userNameType === 'email'
				? Yup.string()
						.required('Email is required')
						.matches(
							/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							'Invalid Email address',
						)
				: Yup.number().required(),
		password: showPwd
			? Yup.string()
					.required('Enter your new password')
					.matches(
						/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
						'Password must be strong',
					)
			: Yup.string(),
		code: showPwd
			? Yup.string().length(6, 'Enter the 6 Digit OTP').required('OTP is required')
			: Yup.string(),
	});

	let formik = useFormik({
		initialValues: {
			userName: '',
			password: '',
			code: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			showPwd
				? forgotPwdSubmitHandler(values, setSubmitting)
				: initiatePwdChange(values, setSubmitting);
		},
	});

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	const initiatePwdChange = async (formState, setSubmitting) => {
		try {
			const forgotPwdInitiateRes = await Auth.forgotPassword(formState.userName);
			if (forgotPwdInitiateRes?.CodeDeliveryDetails) {
				setShowPwd(true);
				if (typeof setSubmitting === 'function') {
					setResendCodeTimer(50);
					startResendCodeTimer();
					setSubmitting(false);
				}
				notify({
					toastMessage: 'Verification code has been sent to e-mail',
					toastType: 'success',
				});
			}
		} catch (error: any) {
			console.log(error);
			console.log(error.code);
			console.log(error.message);
			setSubmitting(false);
			if (error.code === 'UserNotFoundException') {
				notify({ toastMessage: 'User not found', toastType: 'error' });
			}
			if (error.message === 'Network error') {
				notify({ toastMessage: error?.message, toastType: 'error' });
			}

			notify({ toastMessage: error?.message, toastType: 'error' });
		}
	};

	async function forgotPwdSubmitHandler(formState, setSubmitting) {
		try {
			const user = await Auth.forgotPasswordSubmit(
				formState.userName,
				formState.code,
				formState.password,
			);
			console.log(user);
			notify({
				toastMessage: 'Password changed successfully',
				toastType: 'success',
			});
			setIsPasswordResetSuccess(user === 'SUCCESS' && true);
			setTimeout(() => {
				navigate(Routes.SIGNIN, { replace: true });
			}, 5000);
		} catch (error: any) {
			console.log('error changing pwd:', error);
			setSubmitting(false);
			notify({ toastMessage: error?.message, toastType: 'error' });
		}
	}

	const isFormValid = () => {
		if (formik.values.code && formik.values.password) {
			return true;
		}
		return false;
	};

	const startResendCodeTimer = () => {
		setResendCodeTimer(30);
		setResendCodeDisabled(true);

		const timerInterval = setInterval(() => {
			setResendCodeTimer((prevTimer) => {
				if (prevTimer > 0) {
					return prevTimer - 1;
				} else {
					clearInterval(timerInterval);
					setResendCodeDisabled(false);
					return 0;
				}
			});
		}, 1000);
	};

	const toggleMailorPhone = () => {
		setUserNameType(userNameType === 'email' ? 'phone' : 'email');
	};

	const navigateToSignIn = async () => {
		navigate(Routes.SIGNIN);
	};

	return (
		<>
			<Toaster />
			{isPasswordResetSuccess ? (
				<div className="bg-primary-100 w-screen items-center justify-center">
					<div className=" flex justify-center items-center">
						<img
							className="mt-60"
							src={successimg}
							alt="Logo"
							style={{
								width: isMobile ? '30%' : '15%',
							}}
						/>
						<div className="absolute z-10 mt-96 pt-20 text-secondary-00 text-14px">
							Your Password has been reset.
						</div>
					</div>
				</div>
			) : (
				<form onSubmit={formik.handleSubmit}>
					<div className="justify-center w-full">
						{isMobile && (
							<div className="container mx-auto mt-16 flex items-center justify-center md:justify-start">
								{/* Replace text with SVG logo */}
								<img src={logoimg} alt="Logo" className=" h-10" />
							</div>
						)}

						{!showPwd && (
							<section className="px-4 mt-20">
								<div className="w-280px"></div>

								<div className="text-secondary-100 text-center text-22px font-bold mb-2">
									Forgot Password?
								</div>
								<div className="justify-center w-280px mb-10 text-center">
									Please enter your registered email ID to reset the password.
								</div>
								{/* <div className="text-16px font-normal text-secondary-100">
									Choose Email or Phone No
								</div>

								<div className="flex mt-2.5 justify-around mb-4 items-center">
									<IonButton
										className="w-280px capitalize h-36px font-normal bg-primary-100 text-16px rounded-md"
										onClick={toggleMailorPhone}
									>
										{userNameType === 'email' ? 'Phone' : 'Email'}
									</IonButton>
								</div> */}
								<div className="mt-5 lg:mt-8 text-16px font-normal text-secondary-100">
									{userNameType === 'email' ? 'Enter Your Email' : 'Enter Your Phone No'}
									<span className="text-failure text-xl">*</span>
								</div>

								<IonItem
									className={`mt-2.5 app-input-filed  bg-secondary-00 ${
										formik.touched.userName && formik.errors.userName ? 'app-input-has-error' : ''
									}`}
									lines="none"
								>
									{/* <IonLabel>@</IonLabel>} */}

									{userNameType === 'phone' && (
										<IonLabel className="signin-flag text-14px font-normal bg-no-repeat pl-7 ">
											+60
										</IonLabel>
									)}

									{userNameType === 'email' && (
										<img src={mailIcon} alt="Logo" className="text-secondary-75 mr-2 h-4" />
									)}

									<IonInput
										type="text"
										className="bg-transparent items-center text-secondary-100 text-14px font-medium"
										placeholder={userNameType === 'email' ? 'Email' : 'Phone No'}
										name="userName"
										value={formik.values.userName}
										onIonChange={(e) => {
											formik.handleChange(e);
										}}
									></IonInput>
								</IonItem>

								{userNameType === 'email' ? (
									<IonText className="text-failure">
										{formik.touched.userName && formik.errors.userName}
									</IonText>
								) : (
									<IonText className="text-failure">
										{formik.touched.userName && formik.errors.userName
											? 'Phone No field is required'
											: null}
									</IonText>
								)}

								{/* <IonRow className='mt-4'>
									
									{/* <IonLabel>@</IonLabel>} */}

								{/* <IonInput
										type="text"
										className={`app-input-filed h-10 bg-primary-500 w-280px ${
											formik.touched.userName && formik.errors.userName ? 'app-input-has-error' : ''
										} bg-transparent border-secondary-50 rounded-md border border-3 text-secondary-100 text-14px font-normal h-36px`}
								
										placeholder={userNameType === 'email' ? 'Email' : 'Phone No'}
										name="userName"
										value={formik.values.userName}
										onIonChange={formik.handleChange}
									>
									{userNameType === 'phone' && (
										<IonLabel className="signin-flag text-14px font-normal bg-no-repeat pl-7 ">
											+60
										</IonLabel>
									)}

									{userNameType === 'email' && (
										<img src={mailIcon} alt="Logo" className="text-secondary-75 mr-2 h-4" />
									)}</IonInput> */}
								{/* </IonRow> */}

								<IonButton
									expand="block"
									type="submit"
									className="mt-16 w-280px capitalize bg-primary-100 font-normal h-36px text-16px rounded-md "
									disabled={formik.isSubmitting}
								>
									{' '}
									{formik.isSubmitting ? <IonSpinner /> : 'Submit'}
								</IonButton>
								<div
									className="text-end text-primary-100 mr-1 cursor-pointer"
									onClick={navigateToSignIn}
								>
									<IonLabel className="text-14px font-normal">Back to login</IonLabel>
								</div>
							</section>
						)}

						{showPwd && (
							<section className="px-4 mt-20">
								<div className="w-280px"></div>
								<div className="text-center w-280px text-22px text-secondary-100 font-bold my-2">
									Reset Password
								</div>
								<div className="justify-center w-280px text-center">
									Verification code has been sent to your registered e-mail
								</div>
								<div className="mt-10"></div>
								<div className="text-16px font-normal text-secondary-100">
									Enter Your Code
									<span className="text-failure text-xl">*</span>
								</div>

								<IonItem
									className={`mt-2.5 app-input-filed ${
										formik.touched.code && formik.errors.code ? 'app-input-has-error' : ''
									}`}
									lines="none"
								>
									<IonInput
										type="password"
										className="bg-transparent text-14px text-secondary-75 font-normal"
										placeholder="Code"
										name="code"
										value={formik.values.code}
										onIonChange={formik.handleChange}
									/>
								</IonItem>
								<IonText className="text-failure">
									{formik.touched.code && formik.errors.code}
								</IonText>
								<div className="mt-8 "></div>
								<div className="text-16px font-normal text-secondary-100">
									Enter Your New Password
									<span className="text-failure text-xl">*</span>
								</div>

								<IonItem
									className={`mt-2.5 app-input-filed  ${
										formik.touched.password && formik.errors.password ? 'app-input-has-error' : ''
									}`}
									lines="none"
								>
									<IonInput
										type={showPassword ? 'text' : 'password'}
										className="bg-transparent text-14px  text-secondary-75 font-normal"
										placeholder="Password"
										name="password"
										value={formik.values.password}
										onIonChange={formik.handleChange}
									/>
									<IonIcon
										onClick={() => setShowPassword(!showPassword)}
										slot="end"
										icon={showPassword ? eyeOutline : eyeOffOutline}
										className="text-primary-500 cursor-pointer"
									/>
								</IonItem>
								<IonText className="text-failure">
									{formik.touched.password && formik.errors.password}
								</IonText>
								<IonButton
									expand="block"
									className="mt-12 capitalize h-36px text-16px bg-primary-100 text-secondary-00 font-normal rounded-md"
									type="submit"
									disabled={formik.isSubmitting || !isFormValid()}
								>
									{formik.isSubmitting ? <IonSpinner /> : 'Reset'}
								</IonButton>
								<IonButton
									expand="block"
									className="mt-8 capitalize h-36px text-16px bg-primary-100 text-secondary-00 font-normal rounded-md"
									onClick={() => {
										initiatePwdChange(formik.values, formik.setSubmitting);
										startResendCodeTimer();
									}}
									disabled={formik.isSubmitting || resendCodeDisabled}
								>
									{resendCodeDisabled ? `Resend Code (${resendCodeTimer}s)` : 'Resend Code'}
								</IonButton>

								<div
									className="text-end text-primary-100 mr-1 cursor-pointer"
									onClick={navigateToSignIn}
								>
									<IonLabel className="text-14px font-normal">Back to login</IonLabel>
								</div>
							</section>
						)}
					</div>
				</form>
			)}
		</>
	);
};
export default ForgotPassword;
