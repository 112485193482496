import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router-dom';

export const AccountGuard = ({ children }) => {
	const navigate = useNavigate();
	const appContext: any = useContext(AppContext);
	console.log('-----IS ADMIN:-----', appContext.isAdmin);
	if (appContext.selectedAccount && !appContext.isAdmin) {
		return children;
	} else {
		if (!appContext.isAdmin) {
			navigate(Routes.ACCOUNT_SELECTION);
		} else {
			navigate(Routes.ADMIN);
		}
		// return !appContext.isAdmin ? navigate(Routes.ACCOUNT_SELECTION) : navigate(Routes.ADMIN);
	}
};
