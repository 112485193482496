import React, { useContext } from 'react';
import { Routes } from 'utils/routes';
import { useNavigate } from 'react-router-dom';
import MerchantIcon from '../../images/MerchantIcon.svg';
import UserIcon from '../../images/UserIcon.svg';
import { IonIcon, IonRow,IonButton } from '@ionic/react';
import { Auth } from 'aws-amplify';
import * as clientStorage from 'utils/client-storage';
import AppContext from 'utils/app-context';

// interface SignOutMobileProps {
//   onSignOut: () => void;
// }


    export default React.memo(function SignOutMobile() {

      const navigate = useNavigate();
      const appContext: any = useContext(AppContext);

      function manageUserClick() {
        navigate(Routes.MANAGE_USERS);
      }
    
      function manageAccountClick() {
        navigate(Routes.MANAGE_ACCOUNTS);
      }

      const AccountsTableClickHandler = async () => {
          navigate(Routes.MANAGE_ACCOUNTS);
      };

      const signOutClickHandler = async () => {
        await Auth.signOut();
        clientStorage.clear();
        appContext.userDetails = null;
        navigate(Routes.SIGNIN, { replace: true });
      };

  return (
    <div>
      <div className='w-88px h-full text-secondary-100 '>
        <IonRow className='w-screen pt-10 outline outline-1 pb-10'>
          <div className='ml-10 justify-start' >
						<IonIcon className="w-20 h-16 pb-0.5 mt-6 mb-0 bg-secondary-90" icon={MerchantIcon} onClick={manageAccountClick}></IonIcon>
						<div className='text-16px pl-1 font-bold' onClick={manageAccountClick}>Merchant</div>
            <div className='text-16px pl-1 font-bold' onClick={manageAccountClick}> Accounts</div>
          </div>
          <div className='ml-10' >
						<IonIcon className="w-20 h-16 mt-6 pt-0.5 mb-0 bg-secondary-90" icon={UserIcon} onClick={manageUserClick}></IonIcon>
						<div className='text-16px ml-1.5 mt-0 mb-0 font-bold' onClick={manageUserClick}>BO User</div>	
          </div>
            </IonRow>
					</div>
          <IonButton className='text-secondary-00 font-bold mt-5 ml-10' onClick={signOutClickHandler}>
            Sign Out
          </IonButton>
        
    </div>
  );
});


