import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import AppContext from 'utils/app-context';

export const AuthGuard = ({ children }) => {
	const appContext = useContext(AppContext);

	if (appContext.isSignedIn()) {
		return children;
	}

	return <Navigate to="/" />;
};
