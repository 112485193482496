import React from 'react';
import { IonButton, IonCard, IonImg, IonRow } from '@ionic/react';
import PDFViewer from 'components/PDFViwer';

const ImageModal = ({ documentDetails, onClose }) => {
	const { documentType, url } = documentDetails;
	console.log(documentType, url);
	return (
		<>
			{documentType === 'image' && (
				<IonCard className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 full-screen-overlay z-50">
					<IonCard className="max-w-md p-4 bg-white rounded shadow-md animate-scale-in">
						<IonImg src={url} alt="Modal" className="w-full h-auto" />
						<IonButton
							onClick={onClose}
							className="mt-5 lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
						>
							Close
						</IonButton>
					</IonCard>
				</IonCard>
			)}

			{documentType === 'pdf' && (
				<IonCard className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 full-screen-overlay z-50">
					<IonCard className="max-w-md  p-4 bg-white rounded shadow-md animate-scale-in">
						<PDFViewer pdfFile={url} popup={true} />
						<IonButton
							onClick={onClose}
							className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
						>
							Close
						</IonButton>
					</IonCard>
				</IonCard>
			)}

			{/* <IonRow className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 full-screen-overlay z-50">
				<IonRow className="max-w-md p-4 bg-white rounded shadow-md animate-scale-in">
					{documentType === 'image' ? (
						<>
							<IonImg src={url} alt="Modal" className="w-full h-auto" />
							<IonButton
								onClick={onClose}
								className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
							>
								Close
							</IonButton>
						</>
					) : (
						<>
							<PDFViewer pdfFile={url} />
							<IonButton
								onClick={onClose}
								className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
							>
								Close
							</IonButton>
						</>
					)}
				</IonRow>
			</IonRow> */}
		</>
	);
};

export default ImageModal;
