import {
	CameraPreview,
	CameraPreviewOptions,
	CameraPreviewPictureOptions,
} from '@capacitor-community/camera-preview';
import {
	IonButton,
	IonCol,
	IonGrid,
	IonRow,
	useIonToast,
	IonIcon,
	IonCard,
	IonImg,
} from '@ionic/react';
import { alert } from 'ionicons/icons';
import { Camera } from '@mediapipe/camera_utils';
import FaceDetection from '@mediapipe/face_detection';
import { AccountType, DocCategory, DocType, VerificationStatus } from 'API';
import DocumentApi from 'api/document';
import useMobileDetector from 'hooks/use-mobile-detector';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CameraOptions, useFaceDetection } from 'react-use-face-detection';
import AppContext from 'utils/app-context';
import { base64toBlob } from 'utils/image-converter';
import { Routes } from 'utils/routes';
import './SelfieVerification.scss';
import IdCapture from 'components/IdCapture';
import { removeTypenameFromS3 } from 'utils/object-helper';
import verification from '../../images/verification.svg';
import RinngitPayLoader from 'components/RinngitPayLoader';
import AccountApi from 'api/account';

const SelfieVerification = () => {
	const navigate = useNavigate();
	const isMobile = useMobileDetector() === 'mobile';
	const [photo, setPhoto] = useState('');
	const [cameraActive, setCameraActive] = useState(false);
	const appContext = useContext(AppContext);
	const [showToast] = useIonToast();
	const [captureImage, setCapturImage] = useState<boolean>(false);
	const [capturedImage0, setCapturedImage0] = useState<File | null>(null);
	const [documentNumber, setDocumentNumber] = useState<number>(0);
	const [fileChange, setFileChange] = useState<boolean>(false);
	const [selfieImage, setSelfieImage] = useState<undefined | any>(undefined);
	const accountDetails = appContext.selectedAccount();
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		console.log('Selifie Verification Page');
		console.log(accountDetails);
		if (accountDetails !== null) {
			let selfie = accountDetails.referenceDocs?.items?.filter(
				(obj) => obj.docType === DocType.SELFIE,
			);
			console.log(selfie);
			if (selfie) {
				selfie[0]?.pages?.forEach((element, index) => {
					console.log('Element:', element, 'Index:', index);

					DocumentApi.getImage(element.key).then((res) => {
						console.log(res);
						setPhoto(res);
						setSelfieImage(res);
					});
				});
			}
		}
	}, []);

	async function nextHandler() {
		accountDetails.type === AccountType.INDIVIDUAL
			? navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BANK_VERIFICATION, {
					state: { id: accountDetails?.id, page: 3 },
			  })
			: navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_SSM_SUBMISSION_FOR_BUSINESS, {
					state: { id: accountDetails?.id, page: 3 },
			  });
	}

	async function verifyFace(selfiDocument) {
		try {
			//TODO: instead of find need to use filter to get the front side of the id to get the face side of the image
			const idDocument =
				appContext
					.selectedAccount()
					?.referenceDocs.items.find((doc) => doc.docType === DocType.MYKAD) ||
				appContext
					.selectedAccount()
					?.referenceDocs.items.find((doc) => doc.docType === DocType.PASSPORT);
			const verifyFace = DocumentApi.verifyFace(
				idDocument.id,
				selfiDocument.referenceDocs.items.find((doc) => doc.docType === DocType.SELFIE).id,
			);

			return verifyFace;
		} catch (error) {
			console.log(error);
		}
	}

	async function uploadSelfie(pages) {
		try {
			setIsLoading(true);
			const selfieDocument =
				appContext
					.selectedAccount()
					?.referenceDocs.items.find((doc) => doc.docType === DocType.SELFIE) || {};
			selfieDocument.docType = DocType.SELFIE;
			selfieDocument.docCategory = DocCategory.INDIVIDUAL;
			selfieDocument.accountId = appContext.selectedAccount()?.id;
			selfieDocument.updatedBy = appContext.userDetails.username;
			selfieDocument.owner = accountDetails.owner;
			selfieDocument.status = VerificationStatus.PENDING;
			const updatedPages = (selfieDocument.pages || []).map((page) => removeTypenameFromS3(page));
			selfieDocument.pages = updatedPages;
			const createSelfie = await DocumentApi.save(selfieDocument);
			await DocumentApi.upload(selfieDocument, pages, createSelfie.id, false);
			selfieDocument.id = createSelfie.id;
			const updateSelfie = await DocumentApi.save(selfieDocument);
			appContext.setSelectedAccount(createSelfie.account);
			await verifyFace(createSelfie.account);
			await getAccount();
			setIsLoading(false);
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			showToast({
				message: 'Error uploading selfie. Please try again',
				position: 'bottom',
				icon: alert,
				buttons: [
					{
						text: 'Ok',
						role: 'cancel',
					},
				],
			});
		}
	}

	async function getAccount() {
		const getAccountDetails = await AccountApi.getByUser(appContext.userDetails.username).then(
			(accounts) => {
				return accounts;
			},
		);
		const selectedAccount = getAccountDetails.find((account) => {
			return account.id === appContext.selectedAccount().id;
		});
		console.log('--------', selectedAccount);
		appContext.setSelectedAccount(selectedAccount);
	}

	const handleImageCapture = (image: File, documentNumber: number) => {
		console.log(image);
		setCapturImage(false);
		documentNumber === 0 && setCapturedImage0(image);
		setSelfieImage(image);
		uploadSelfie([image]);
	};

	function documentDetails() {
		return {
			documentType: DocType.SELFIE,
			documentNumber,
		};
	}

	//TODO: need to change to porit for selfi in idcapture
	return (
		<>
			{!isLoading ? (
				<section className="ml-0 md:ml-4 md:pl-0.5 selfie-verification mt-5">
					{!captureImage && (
						<>
							<IonRow>
								<h2 className="ml-5 mt-1 md:mt-0.5 mb-1 text-22px text-secondary-75 font-bold">
									Selfie (For live verification)
								</h2>
							</IonRow>
							<IonRow className="w-11/12">
								<div className="ml-5 mt-5 pt-0.5 mb-1 text-secondary-75 text-16px">
									Stay in clear environment with good lighting and keep your face in the highlighted
									box for face verification.
								</div>
							</IonRow>

							{!selfieImage && (
								<IonRow className="h-40 w-full items-center ml-3.5">
									<IonIcon icon={verification} className="icon h-32 w-32" />
								</IonRow>
							)}

							{selfieImage && (
								<IonRow className="w-96">
									<IonCard className="ml-5 w-64 h-40 border-2  border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
										<IonImg
											src={capturedImage0 ? URL.createObjectURL(capturedImage0) : selfieImage}
										></IonImg>
									</IonCard>
								</IonRow>
							)}

							<IonRow>
								<IonRow className="ml-5 mt-3">
									<IonButton
										className="h-36px capitalize text-16px font-normal rounded-md"
										fill="solid"
										onClick={() => {
											setCapturImage(true);
											setDocumentNumber(0);
											setFileChange(true);
										}}
									>
										{selfieImage ? 'Re-Take Selfie' : 'Take Selfie'}
									</IonButton>
								</IonRow>

								<IonRow className="ml-3 mt-3">
									<IonButton
										onClick={nextHandler}
										expand="block"
										className={`h-36px capitalize text-16px font-normal rounded-md ${
											!captureImage && selfieImage ? 'visible' : 'invisible'
										}`}
									>
										Continue
									</IonButton>
								</IonRow>
							</IonRow>
						</>
					)}

					{captureImage && (
						<div className="ml-1.5">
							<IdCapture onImageCapture={handleImageCapture} documentDetails={documentDetails()} />
						</div>
					)}
				</section>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</>
	);
};

export default React.memo(SelfieVerification);
