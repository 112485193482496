import { Account, AccountType, BankAccount, AccountStatus } from 'API';
import { API, graphqlOperation } from "aws-amplify";
import {
  createAccount, updateAccount, createUser, updateUser, toAdministratorGroup,
  createAccountUser
} from 'graphql/mutations';
import {
  checkKycReadiness, getAccount, getAccountsByOwner, listUsers, listAccounts, getAccountByShortName,
  getUserByUserId, getUsersByAccount, listGroupsForUser, listAllGroups, listUsersInGroup, getUserByEmail,
  getUserByEmailFilter
} from 'graphql/queries';
import {
  onUpdateAccount, onUpdateAccountUser, onUpdateBankAccount,
  onUpdateDocument
} from 'graphql/subscriptions';

import get from 'lodash.get';
import { Observable } from 'zen-observable-ts';
import { combineLatest } from 'zen-observable/extras';

type AccountModel = {
  id?: string | null,
  shortName: string,
  type: AccountType,
  linkedBankAccounts?: BankAccount | null,
  referenceDocs?: Document | null,
  attributes?: string | null,
  questionnaire?: string | null,
  owner: string,
  status?: AccountStatus | null,
  comments?: Array<Comment | null> | null,
  updatedBy: string | null,
  createdBy: string | null,
};

const AccountApi = {

  getByShortName: async function (shortName: string) {
    let response = await API.graphql({
      query: getAccountByShortName,
      variables: {
        shortName
      }
    });
    console.log('Account by short name: ' + shortName, response)
    return get(response, 'data.getAccountsByOwner.items', []) as Account[];
  },

  getByUser: async function (owner: string) {
    let response = await API.graphql({
      query: getAccountsByOwner,
      variables: {
        owner
      }
    });
    console.log('Accounts for owner: ' + owner, response)
    return get(response, 'data.getAccountsByOwner.items', []) as Account[];
  },

  getById: async function (id: string) {
    let response = await API.graphql({
      query: getAccount,
      variables: {
        id
      }
    });
    console.log('Account details for account id: ' + id, response)
    return get(response, 'data.getAccount', {}) as Account;
  },

  save: async function (account: AccountModel) {
    let response = await API.graphql({
      query: account.id ? updateAccount : createAccount,
      variables: {
        input: account
      }
    });
    console.log('Repsonse:', response)
    return get(response, account.id ? 'data.updateAccount' : 'data.createAccount', []) as Account;
  },

  onUpdate: function (accountId) {
    console.log('Subscription method calling......', accountId)
    return combineLatest(
      API.graphql(graphqlOperation(onUpdateAccount, {
        filter: {
          id: { eq: accountId }
        }
      })
      ) as Observable<any>,
      API.graphql(graphqlOperation(onUpdateDocument, {
        filter: {
          accountId: { eq: accountId }
        }
      })
      ) as Observable<any>,
      API.graphql(graphqlOperation(onUpdateBankAccount, {
        filter: {
          accountId: { eq: accountId }
        }
      })
      ) as Observable<any>,
      API.graphql(graphqlOperation(onUpdateAccountUser, {
        filter: {
          accountId: { eq: accountId }
        }
      })
      ) as Observable<any>
    )
  },

  checkKycReadiness: function (accountId) {
    return API.graphql({
      query: checkKycReadiness,
      variables: {
        accountId
      }
    }) as unknown as Promise<any>

  },

  getUsersByAccount: async function (accountId) {
    let response = await API.graphql({
      query: getUsersByAccount,
      variables: {
        accountId
      },
    });
    return response;
  },

  createAccountUser: async function (userDetails) {
    let response = await API.graphql({
      query: createAccountUser,
      variables: {
        input: userDetails
      },
    });
    return response;
  },

  //!Admin
  getAllUsers: async function (filter, paginationToken, limit) {
    const filterValue = filter ? `email = "${filter}"` : null
    console.log(filter, paginationToken, limit)
    console.log(filterValue)
    let response = await API.graphql({
      query: listUsers,
      variables: {
        input: { filter: filterValue, paginationToken: paginationToken, limit: limit }
      },
    });
    return response;
  },

  getUserByEmail: async function (email: string) {
    let response = await API.graphql({
      query: getUserByEmail,
      variables: {
        email
      }
    });
    // console.log('Account owner details for account  - ' + response)
    return response;
  },

  getUserByEmailFilter: async function (email: string) {
    let response = await API.graphql({
      query: getUserByEmailFilter,
      variables: {
        email
      }
    });
    // console.log('Account owner details for account  - ' + response)
    return response;
  },

  listAccounts: async function (id, filter, limit, nextToken, sortDirection) {
    // console.log(id, filter, paginationToken, limit, nextToken, sortDirection)
    let response = await API.graphql({
      query: listAccounts,
      variables: {
        id: id, filter: filter, limit: limit, nextToken: nextToken, sortDirection: sortDirection
      },
    });
    // console.log(response)
    return response;
  },

  createUser: async function (userDetails) {
    let response = await API.graphql({
      query: createUser,
      variables: {
        input: userDetails
      }
    });
    return response;
  },

  getUserByUserId: async function (userId: string) {
    // console.log(userId)
    let response = await API.graphql({
      query: getUserByUserId,
      variables: {
        userId
      }
    });
    // console.log('Account owner details for account  - ' + response)
    return response;
  },

  listGroupsForUser: async function (userId: string) {
    let response = await API.graphql({
      query: listGroupsForUser,
      variables: {
        userId
      }
    });
    // console.log('Account owner details for account  - ' + response)
    return response;
  },

  listAllGroups: async function () {
    let response = await API.graphql({
      query: listAllGroups,
    });
    // console.log('Account owner details for account  - ' + response)
    return response;
  },

  listUsersInGroup: async function (groupName: string) {
    console.log(groupName)
    let response = await API.graphql({
      query: listUsersInGroup,
      variables: {
        groupName: groupName
      }
    });
    // console.log('Account owner details for account  - ' + response)
    return response;
  },

  toAdministratorGroup: async function (userDetails) {
    // console.log(userDetails)
    let response = await API.graphql({
      query: toAdministratorGroup,
      variables: {
        input: userDetails
      }
    });
    console.log(response)
    return response;
  },

  updateUser: async function (userName, enabled) {
    console.log(userName, enabled)
    let response = await API.graphql({
      query: updateUser,
      variables: {
        input: {
          username: userName,
          enabled: enabled,
        }
      }
    });
    console.log(response)
    return response;
  },


};



export default AccountApi
