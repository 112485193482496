// import { GenerateCreditReportInput } from 'API';
import { generateCreditReport } from 'graphql/mutations';
import { API, Storage } from 'aws-amplify';

const CtosApi = {

    getCreditReport: async function (reportType: String, identityDocId: String) {
        console.log(reportType, identityDocId)
        return API.graphql({
            query: generateCreditReport,
            variables: {
                reportType: reportType,
                identityDocId: identityDocId,
            }
        })
    }
}

export default CtosApi