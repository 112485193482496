import React, { useContext, useState, useEffect } from 'react';
import {
	IonButton,
	IonCard,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonInput,
	IonItem,
	IonLabel,
	IonRow,
	IonSpinner,
	IonText,
	IonToggle,
	useIonToast,
} from '@ionic/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AccountApi from 'api/account';
import useMobileDetector from 'hooks/use-mobile-detector';
import { Account } from 'API';
import AppContext from 'utils/app-context';
import { eyeOutline, eyeOffOutline } from 'ionicons/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { Routes } from 'utils/routes';
import { Toaster, toast } from 'react-hot-toast';
import RinngitPayLoader from 'components/RinngitPayLoader';

export const AddUser = () => {
	const isMobile = useMobileDetector() === 'mobile';
	const navigate = useNavigate();
	const location = useLocation();
	const [present] = useIonToast();
	const appContext = useContext(AppContext);
	const userDetails = appContext.userDetails;
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [shortNameFilled, setShortNameFilled] = useState(false);
	const [usersList, setUsersList] = useState<undefined | any>(undefined);
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [checked, setChecked] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	useEffect(() => {
		console.log('Create Users for BO and Merchant user Page');
		console.log(location);
	}, []);

	const validationSchema = Yup.object({
		email: Yup.string()
			.required('Email is required')
			.matches(
				/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				'Invalid Email address',
			),
	});

	let formik = useFormik({
		initialValues: {
			email: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			onNext(values, setSubmitting);
		},
	});

	async function onNext(formState, setSubmitting) {
		setIsLoading(true);
		if (appContext.isAdmin && location.state.userType === 'BO_USER') {
			console.log('Creating BO User.....');
			AccountApi.createUser({
				username: formState.email,
			})
				.then(async (account) => {
					await AccountApi.toAdministratorGroup({
						username: account.data.createUser.Username,
						isAdmin: true,
					});

					navigate(Routes.MANAGE_USERS, {
						state: { toastMessage: 'BO User Created Successfully', toastType: 'success' },
					});
				})
				.catch((error) => {
					setIsLoading(false);
					setSubmitting(false);
					console.error('Failed to Create User', error);
					notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
				});
		}

		if (appContext.isAdmin && location.state.userType === 'MERCHANT_USER') {
			console.log('Creating MERCHANT USER User.....');
			AccountApi.createUser({
				username: formState.email,
			})
				.then(async (account) => {
					const getUserId = appContext.isAdmin && (await getUserByEmail(formState.email));
					console.log('To update owner details: ', getUserId);

					const existingUsers = await AccountApi.getUsersByAccount(location.state.accountId);

					console.log(existingUsers);

					const currentUserExists = existingUsers.data.getUsersByAccount.items.some(
						(user) => user.userId === getUserId,
					);

					if (!currentUserExists) {
						AccountApi.createAccountUser({
							accountId: location.state.accountId,
							userId: getUserId,
							updatedBy: userDetails.username,
						})
							.then((account) => {
								console.log(account);
								console.log('Account details added for account user table');
								setIsLoading(false);
								navigate(Routes.ACCOUNT_USERS, {
									state: {
										toastMessage: 'Account User Created Successfully',
										toastType: 'success',
										accountId: location.state.accountId,
									},
								});
							})
							.catch((error) => {
								setIsLoading(false);
								setSubmitting(false);
								console.error('Failed to Create User', error);
								notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
							});
					} else {
						setIsLoading(false);
						setSubmitting(false);
						notify({ toastMessage: 'User already exist', toastType: 'error' });
					}
				})
				.catch(async (error) => {
					setIsLoading(false);
					setSubmitting(false);
					console.error('Failed to Create User', error);
					notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
					const getUserId = appContext.isAdmin && (await getUserByEmail(formState.email));
					console.log('To update owner details: ', getUserId);

					const existingUsers = await AccountApi.getUsersByAccount(location.state.accountId);

					console.log(existingUsers);

					const currentUserExists = existingUsers.data.getUsersByAccount.items.some(
						(user) => user.userId === getUserId,
					);

					if (!currentUserExists) {
						AccountApi.createAccountUser({
							accountId: location.state.accountId,
							userId: getUserId,
							updatedBy: userDetails.username,
						})
							.then((account) => {
								console.log(account);
								console.log('Account details added for account user table');
								setIsLoading(false);
								navigate(Routes.ACCOUNT_USERS, {
									state: {
										toastMessage: 'Account User Created Successfully',
										toastType: 'success',
										accountId: location.state.accountId,
									},
								});
							})
							.catch((error) => {
								setIsLoading(false);
								setSubmitting(false);
								console.error('Failed to Create User', error);
								notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
							});
					} else {
						setIsLoading(false);
						setSubmitting(false);
						notify({ toastMessage: 'User already exist', toastType: 'error' });
					}
				});
		}
	}

	async function getUserByEmail(email) {
		return await AccountApi.getUserByEmail(email)
			.then((userDetailsByEmail) => {
				console.log(userDetailsByEmail.data.getUserByEmail.Username);
				return userDetailsByEmail.data.getUserByEmail.Username;
			})
			.catch(async (error) => {
				console.error('Failed to get user details: ', error);
				console.log(error.errors[0].message);
				notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
			});
	}

	const isFormValid = () => {
		if (appContext.isAdmin && formik.values.email && !formik.errors.email) {
			return true;
		}

		return false;
	};

	return (
		<form className=" w-screen h-screen" onSubmit={formik.handleSubmit}>
			<Toaster />
			{isLoading ? (
				<RinngitPayLoader props={{ overlay: true }} />
			) : (
				<IonRow>
					<>
						<div className="ml-4 md:ml-8 mt-10">
							<IonRow className="ml-1 w-3/4 mb-4">
								<IonText className="text-secondary-75 font-bold text-22px">
									{location.state.userType === 'MERCHANT_USER'
										? 'Create Merchant User'
										: 'Create BO User'}
								</IonText>
							</IonRow>

							<IonRow className="mb-5 w-80">
								<IonCol>
									<div className="mt-0.5">
										<IonLabel
											className="font-normal text-secondary-75 text-16px"
											position="floating"
										>
											Email
											<span className="text-failure text-xl">*</span>
										</IonLabel>
									</div>
									<IonItem
										className={`app-input-filed bg-secondary-00 mt-2.5 ${
											formik.touched.email && formik.errors.email ? 'app-input-has-error' : ''
										}`}
										lines="none"
									>
										<IonInput
											placeholder="Email"
											className=" text-16px text-secondary-75 font-medium bg-secondary-00"
											value={formik.values.email}
											name="email"
											onIonChange={formik.handleChange}
											onBlur={formik.handleBlur}
										></IonInput>
									</IonItem>
									<IonText className="text-failure">
										{formik.touched.email && formik.errors.email}
									</IonText>
								</IonCol>
							</IonRow>

							<IonRow>
								<IonButton
									type="submit"
									expand="block"
									className="h-36px capitalize font-normal text-16px rounded-sm cursor-pointer"
									disabled={!isFormValid() || formik.isSubmitting}
									// disabled={formik.isSubmitting}
									// onClick={handleButtonClick}
								>
									{formik.isSubmitting ? <IonSpinner /> : 'Create User'}
								</IonButton>

								<IonButton
									color={'failure'}
									className="w-32 lg:w-32 capitalize bg-failure rounded-md h-9 mt-1 ml-3 text-secondary-00 flex items-center justify-center cursor-pointer"
									onClick={() =>
										location.state.userType === 'BO_USER'
											? navigate(Routes.MANAGE_USERS)
											: navigate(Routes.ACCOUNT_USERS, {
													state: { accountId: location.state.accountId },
											  })
									}
								>
									Cancel
								</IonButton>
							</IonRow>
						</div>
					</>
				</IonRow>
			)}
		</form>
	);
};
