import { IonImg } from '@ionic/react';
import React from 'react';
import { useContext } from 'react';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';

const LoadImage = ({ props }) => {
	const appContext = useContext(AppContext);
	const img = require('images/' + appContext.appTheme + Routes.SIGNIN + props.imageName + '.svg');
	return <IonImg alt="ringgitpay-brand" src={img} className={props.className} />;
};
export default LoadImage;
