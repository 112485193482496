import React, { useEffect } from 'react';
import loadingLogo from '../images/loadingLogo.gif';

export default function RinngitPayLoader({ props }) {
	useEffect(() => {
		// console.log(props, '----------------');
	}, []);

	return (
		<div className={`spinner-container ${props.overlay ? 'full-screen-overlay' : ''}`}>
			<img src={loadingLogo} alt="Logo" />
		</div>
	);
}
