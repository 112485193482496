import { IonCol, IonIcon, IonLabel, IonRow, IonCard, IonText } from '@ionic/react';
import useMobileDetector from 'hooks/use-mobile-detector';
import React, { useContext } from 'react';
import AppContext from 'utils/app-context';
import {
	checkmarkCircleOutline, //success
	timeOutline, //progress
	stopwatchOutline, //pending
} from 'ionicons/icons';

const CustomerVerificationItem = ({ children, props, propsLength }) => {
	const isMobile = useMobileDetector() === 'mobile';

	const appContext = useContext(AppContext);
	const isClickable = props?.clickable;
	const textStatus =
		props?.navigationStatus === appContext.stepperCompletionStatus.OPEN
			? 'text-secondary-50  shadow-none'
			: props.navigationStatus === appContext.stepperCompletionStatus.INPROGRESS
			? 'text-primary-100 shadow-none'
			: 'text-secondary-100 shadow-none';
	const pointerStatus = props?.status === appContext.stepperCompletionStatus.OPEN ? false : true;
	const lineStatus =
		props?.navigationStatus === appContext.stepperCompletionStatus.OPEN
			? 'bg-secondary-50  shadow-none'
			: props.navigationStatus === appContext.stepperCompletionStatus.INPROGRESS
			? 'bg-primary-100 shadow-none'
			: !isMobile
			? 'bg-primary-100 shadow-none'
			: 'bg-secondary-75 shadow-none';

	const onClickHandler = () => {
		props.onClick(props.index);
	};
	// console.log(props);
	// console.log(propsLength);
	return (
		<>
			{isMobile && (
				<>
					<IonRow
						className={`px-1.5 ${isClickable ? 'cursor-pointer' : 'cursor-not-allowed'} mt-3`}
						onClick={onClickHandler}
					>
						<IonCol>
							{/* <IonCol className="text-center flex-col items-center justify-content p-0 ml-3"> */}
							{/* <IonCol size={String(12 / propsLength)} key={props.index}> */}
							{props.index !== propsLength && (
								<IonCard
									className={`${propsLength < 5 ? 'w-9' : 'w-3'} h-1 ${lineStatus} mx-auto my-2`}
								></IonCard>
							)}
						</IonCol>
					</IonRow>
				</>
			)}

			{!isMobile && (
				<IonRow
					className={`${isClickable ? 'cursor-pointer' : 'cursor-not-allowed'} mt-5`}
					onClick={onClickHandler}
				>
					<IonCol className="text-center flex-col items-center justify-content p-0 ml-3">
						<IonLabel className={`text-sm h-11 w-full ${textStatus}`}>{props?.title}</IonLabel>
						{props.index !== propsLength && (
							<IonCard className={`w-full h-1 ${lineStatus} mx-auto my-2`}></IonCard>
						)}
					</IonCol>
				</IonRow>
			)}
		</>
	);
};

export default CustomerVerificationItem;
