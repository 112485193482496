export function convert<T extends object>(original, expected: T) {
    console.log('original', original)
    expected = Object.keys(expected).reduce((result, key) => {
        const value = original[key];
        if (value && value != null) {
            result[key] = value
        }
        return result;
    }, expected)
    console.log('expected', expected)
    return expected;
}

export function removeTypenameFromS3(obj: any): any {
    if (Array.isArray(obj)) {
        return obj.map((item) => removeTypenameFromS3(item));
    } else if (typeof obj === 'object' && obj !== null) {
        const newObj: any = {};
        for (const key in obj) {
            if (key !== '__typename') {
                newObj[key] = removeTypenameFromS3(obj[key]);
            }
        }
        return newObj;
    }
    return obj;
}