import { IonCol } from '@ionic/core/components';
import { IonCard, IonGrid, IonRow } from '@ionic/react';
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'utils/routes';

export default function Admin() {
	const navigate = useNavigate();

	useEffect(() => {}, []);

	function manageUserClick() {
		navigate(Routes.MANAGE_USERS);
	}

	function manageAccountClick() {
		navigate(Routes.MANAGE_ACCOUNTS);
	}

	return (
		<section className="centerbox flex items-center flex-row">
			<IonGrid className="full-page-height">
				<IonRow className="justify-center md:justify-start ">
					<IonCard className="w-32 h-32 flex items-center justify-center shadow-none" onClick={manageUserClick}>
						<span className="text-lg">User</span>
					</IonCard>
					

					<IonCard
						className="w-32 h-32 flex items-center justify-center shadow-none"
						onClick={manageAccountClick}
					>
						<span className="text-lg">Accounts</span>
					</IonCard>
					
				</IonRow>
			</IonGrid>
		</section>
	);
}
