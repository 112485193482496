import { IonList, IonRow, IonButton, IonIcon, IonGrid, IonCol, IonCard } from '@ionic/react';
import {
	checkmarkCircleOutline,
	stopwatchOutline,
	timeOutline,
	arrowForwardCircle,
	arrowBackCircle,
	caretForwardCircleSharp,
	caretBackCircleSharp,
} from 'ionicons/icons';
import React, { useContext, useState, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Account, AccountType, DocCategory, DocType } from 'API';
import CustomerVerificationItem from 'components/CustomerVerificationItem';
import useMobileDetector from 'hooks/use-mobile-detector';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';
import { Toaster, toast } from 'react-hot-toast';
import chalk from 'chalk';

const CustomerVerification = () => {
	const log = console.log;
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	let location = useLocation();
	const device = useMobileDetector();
	const [customerVerificationLinks, setCustomerVerificationLinks] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);

	useEffect(() => {
		log(chalk.cyan('Account ID: ', location.state.id ? location.state.id : 'New Account.....'));
		log(chalk.cyan('Page ID: ', location.state.page));
		setCurrentPage(location.state.page);
		setCustomerVerificationLinks(newMenu(linksObj));

		setShowLeftArrow(location.state.page > 0);
		setShowRightArrow(location.state.page < totalPages - 1);
	}, [location.state.page, customerVerificationLinks.length]);

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	const adminRouteNames = [
		'basic-details',
		'ssm-submission-business',
		'id-submission-business',
		'link-bank-account',
		'authorization-letter-business',
	];

	const individualRouteNames = [
		'basic-details',
		'id-submission-individual',
		'selfie-verification',
		'link-bank-account',
	];

	const businessRouteNames = [
		'basic-details',
		'id-submission-business-auth',
		'selfie-verification',
		'ssm-submission-business',
		'id-submission-business',
		'link-bank-account',
		'authorization-letter-business',
	];

	const navigateToChild = (index, totalPages) => {
		if (appContext.isAdmin) {
			if (
				index === 0 ||
				(index > 0 && linksObj[index - 1].status === appContext.stepperStatus.COMPLETED)
			) {
				navigate(adminRouteNames[index], { state: { id: location.state.id, page: index } });
			} else {
				console.log(
					`Cannot navigate to page ${index + 1} because the current step is not completed.`,
				);
				// if (index - 1 === 0) {
				// 	console.log('Your Basic Details is incomplete');
				// 	notify({ toastMessage: 'Your Basic Details is incomplete', toastType: 'error' });
				// } else if (index - 1 === 1) {
				// 	console.log('Your SSM Document submission is incomplete');
				// 	notify({
				// 		toastMessage: 'Your SSM Document submission is incomplete',
				// 		toastType: 'error',
				// 	});
				// } else if (index - 1 === 2) {
				// 	console.log('Upload Atleast One Director Proof');
				// 	notify({ toastMessage: 'Upload Atleast One Director Proof', toastType: 'error' });
				// } else if (index - 1 === 3) {
				// 	console.log('Your Bank Account submission is incomplete');
				// 	notify({
				// 		toastMessage: 'Your Bank Account submission is incomplete',
				// 		toastType: 'error',
				// 	});
				// }
			}
		}

		if (!appContext.isAdmin) {
			const accountType = getAccountType();
			if (accountType === AccountType.INDIVIDUAL) {
				if (
					index === 0 ||
					(index > 0 && linksObj[index - 1].status === appContext.stepperStatus.COMPLETED)
				) {
					navigate(individualRouteNames[index], { state: { id: location.state.id, page: index } });
				} else {
					console.log(
						`Cannot navigate to page ${index + 1} because the current step is not completed.`,
					);
					// if (index - 1 === 0) {
					// 	console.log('Your Basic Details is incomplete');
					// 	notify({ toastMessage: 'Your Basic Details is incomplete', toastType: 'error' });
					// } else if (index - 1 === 1) {
					// 	console.log('Your ID submission is incomplete');
					// 	notify({ toastMessage: 'Your ID submission is incomplete', toastType: 'error' });
					// } else if (index - 1 === 2) {
					// 	console.log('Your Selfie submission is incomplete');
					// 	notify({ toastMessage: 'Your Selfie submission is incomplete', toastType: 'error' });
					// }
				}
			} else if (accountType === AccountType.BUSINESS) {
				if (
					index === 0 ||
					(index > 0 && linksObj[index - 1].status === appContext.stepperStatus.COMPLETED)
				) {
					navigate(businessRouteNames[index], { state: { id: location.state.id, page: index } });
				} else {
					console.log(
						`Cannot navigate to page ${index + 1} because the current step is not completed.`,
					);
					// if (index - 1 === 0) {
					// 	console.log('Your Basic Details is incomplete');
					// 	notify({ toastMessage: 'Your Basic Details is incomplete', toastType: 'error' });
					// } else if (index - 1 === 1) {
					// 	console.log('Your ID submission is incomplete');
					// 	notify({ toastMessage: 'Your ID submission is incomplete', toastType: 'error' });
					// } else if (index - 1 === 2) {
					// 	console.log('Your Selfie submission is incomplete');
					// 	notify({ toastMessage: 'Your Selfie submission is incomplete', toastType: 'error' });
					// } else if (index - 1 === 3) {
					// 	console.log('Your SSM Document submission is incomplete');
					// 	notify({
					// 		toastMessage: 'Your SSM Document submission is incomplete',
					// 		toastType: 'error',
					// 	});
					// } else if (index - 1 === 4) {
					// 	console.log('Upload Atleast One Director Proof');
					// 	notify({
					// 		toastMessage: 'Upload Atleast One Director Proof',
					// 		toastType: 'error',
					// 	});
					// } else if (index - 1 === 5) {
					// 	console.log('Your Bank Account submission is incomplete');
					// 	notify({
					// 		toastMessage: 'Your Bank Account submission is incomplete',
					// 		toastType: 'error',
					// 	});
					// }
				}
			}
		}

		// if (appContext.isAdmin) {
		// 	if (
		// 		index === 0 ||
		// 		(index > 0 && linksObj[index - 1].status === appContext.stepperStatus.COMPLETED)
		// 	) {
		// 		navigate(adminRouteNames[index], { state: { id: location.state.id, page: index } });
		// 	} else {
		// 		console.log(
		// 			`Cannot navigate to page ${index + 1} because the current step is not completed.`,
		// 		);
		// 		if (index - 1 === 0) {
		// 			console.log('Your Basic Details is incomplete');
		// 			notify({ toastMessage: 'Your Basic Details is incomplete', toastType: 'error' });
		// 		} else if (index - 1 === 1) {
		// 			console.log('Your ID submission is incomplete');
		// 			notify({ toastMessage: 'Your ID submission is incomplete', toastType: 'error' });
		// 		} else if (index - 1 === 2) {
		// 			console.log('Your Selfie submission is incomplete');
		// 			notify({ toastMessage: 'Your Selfie submission is incomplete', toastType: 'error' });
		// 		} else if (index - 1 === 3) {
		// 			console.log('Your SSM Document submission is incomplete');
		// 			notify({
		// 				toastMessage: 'Your SSM Document submission is incomplete',
		// 				toastType: 'error',
		// 			});
		// 		} else if (index - 1 === 4) {
		// 			console.log('Upload Atleast One Director Proof');
		// 			notify({
		// 				toastMessage: 'Upload Atleast One Director Proof',
		// 				toastType: 'error',
		// 			});
		// 		} else if (index - 1 === 4) {
		// 			console.log('Your Bank Account submission is incomplete');
		// 			notify({
		// 				toastMessage: 'Your Bank Account submission is incomplete',
		// 				toastType: 'error',
		// 			});
		// 		}
		// 	}
		// } else {
		// 	const accountType = getAccountType();
		// 	if (accountType === AccountType.INDIVIDUAL) {
		// 		if (
		// 			index === 0 ||
		// 			(index > 0 && linksObj[index - 1].status === appContext.stepperStatus.COMPLETED)
		// 		) {
		// 			navigate(individualRouteNames[index], { state: { id: location.state.id, page: index } });
		// 		} else {
		// 			console.log(
		// 				`Cannot navigate to page ${index + 1} because the current step is not completed.`,
		// 			);
		// 			if (index - 1 === 0) {
		// 				console.log('Your Basic Details is incomplete');
		// 				notify({ toastMessage: 'Your Basic Details is incomplete', toastType: 'error' });
		// 			} else if (index - 1 === 1) {
		// 				console.log('Your ID submission is incomplete');
		// 				notify({ toastMessage: 'Your ID submission is incomplete', toastType: 'error' });
		// 			} else if (index - 1 === 2) {
		// 				console.log('Your Selfie submission is incomplete');
		// 				notify({ toastMessage: 'Your Selfie submission is incomplete', toastType: 'error' });
		// 			}
		// 		}
		// 	} else if (accountType === AccountType.BUSINESS) {
		// 		if (
		// 			index === 0 ||
		// 			(index > 0 && linksObj[index - 1].status === appContext.stepperStatus.COMPLETED)
		// 		) {
		// 			navigate(businessRouteNames[index], { state: { id: location.state.id, page: index } });
		// 		} else {
		// 			console.log(
		// 				`Cannot navigate to page ${index + 1} because the current step is not completed.`,
		// 			);
		// 			if (index - 1 === 0) {
		// 				console.log('Your Basic Details is incomplete');
		// 				notify({ toastMessage: 'Your Basic Details is incomplete', toastType: 'error' });
		// 			} else if (index - 1 === 1) {
		// 				console.log('Your ID submission is incomplete');
		// 				notify({ toastMessage: 'Your ID submission is incomplete', toastType: 'error' });
		// 			} else if (index - 1 === 2) {
		// 				console.log('Your Selfie submission is incomplete');
		// 				notify({ toastMessage: 'Your Selfie submission is incomplete', toastType: 'error' });
		// 			} else if (index - 1 === 3) {
		// 				console.log('Your SSM Document submission is incomplete');
		// 				notify({
		// 					toastMessage: 'Your SSM Document submission is incomplete',
		// 					toastType: 'error',
		// 				});
		// 			} else if (index - 1 === 4) {
		// 				console.log('Upload Atleast One Director Proof');
		// 				notify({
		// 					toastMessage: 'Upload Atleast One Director Proof',
		// 					toastType: 'error',
		// 				});
		// 			} else if (index - 1 === 4) {
		// 				console.log('Your Bank Account submission is incomplete');
		// 				notify({
		// 					toastMessage: 'Your Bank Account submission is incomplete',
		// 					toastType: 'error',
		// 				});
		// 			}
		// 		}
		// 	}
		// }
	};

	function getAccountType() {
		if (!appContext.selectedAccount()) {
			return appContext.registrationDetails.accountType;
		}
		return appContext.selectedAccount().type;
	}

	const totalPages = customerVerificationLinks.length;

	const linkClickHandler = (index) => {
		navigateToChild(index, totalPages);
		setCurrentPage(index);
	};

	function progressStatus(index, location, isAdmin) {
		if (index === 0) {
			//!Basic Details
			// console.log(location === index);
			return location === index;
		} else if (index === 1) {
			//!Proof Submission
			// console.log(location === index && !isAdmin ? true : false);
			return location === index && !isAdmin ? true : false;
		} else if (index === 2) {
			//!Face Verification
			// console.log(location === index && !isAdmin ? true : false);
			return location === index && !isAdmin ? true : false;
		} else if (index === 3) {
			//!SSM Submission
			// console.log(
			// 	(location === 1 && isAdmin) ||
			// 		(location === 3 && getAccountType() === AccountType.BUSINESS),
			// );
			return (
				(location === 1 && isAdmin) || (location === 3 && getAccountType() === AccountType.BUSINESS)
			);
		} else if (index === 4) {
			//!Director's Proof Submission
			// console.log(
			// 	(location === 2 && isAdmin) ||
			// 		(location === 4 && getAccountType() === AccountType.BUSINESS),
			// );
			return (
				(location === 2 && isAdmin) || (location === 4 && getAccountType() === AccountType.BUSINESS)
			);
		} else if (index === 5) {
			//!Link Bank Account
			// console.log(
			// 	(location === 3 && isAdmin) ||
			// 		(location === 3 && getAccountType() === AccountType.INDIVIDUAL) ||
			// 		(location === 5 && getAccountType() === AccountType.BUSINESS),
			// );
			return (
				(location === 3 && isAdmin) ||
				(location === 3 && getAccountType() === AccountType.INDIVIDUAL) ||
				(location === 5 && getAccountType() === AccountType.BUSINESS)
			);
		} else if (index === 6) {
			//!Authorization Letter Submission
			// console.log(
			// 	(location === 4 && isAdmin) ||
			// 		(location === 6 && getAccountType() === AccountType.BUSINESS),
			// );
			return (
				(location === 4 && isAdmin) || (location === 6 && getAccountType() === AccountType.BUSINESS)
			);
		}
	}

	function updateStepperStatus(index) {
		// console.log('index to check', index);
		// const isCurrentStep = location.state.page === index;
		// console.log(
		// 	'******',
		// 	progressStatus(index, location.state.page, appContext.isAdmin),
		// 	isCurrentStep,
		// 	'******',
		// );

		if (index === 0) {
			if (appContext.selectedAccount() && appContext.selectedAccount().type) {
				return appContext.stepperStatus.COMPLETED;
			}
			if (progressStatus(index, location.state.page, appContext.isAdmin)) {
				return appContext.stepperStatus.INPROGRESS;
			}
			if (
				!appContext.selectedAccount() &&
				progressStatus(index, location.state.page, appContext.isAdmin)
			) {
				return appContext.stepperStatus.OPEN;
			}
			// return appContext.selectedAccount() && appContext.selectedAccount().type
			// 	? appContext.stepperStatus.COMPLETED
			// 	: progressStatus(index, location.state.page, appContext.isAdmin)
			// 	? appContext.stepperStatus.INPROGRESS
			// 	: appContext.stepperStatus.OPEN;
		} else if (index === 1) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter(
						(obj) =>
							(obj.docType === DocType.MYKAD || obj.docType === DocType.PASSPORT) &&
							obj.docCategory === DocCategory.INDIVIDUAL,
					).length > 0
				? appContext.stepperStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperStatus.INPROGRESS
				: appContext.stepperStatus.OPEN;
		} else if (index === 2) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter((obj) => obj.docType === DocType.SELFIE).length > 0
				? appContext.stepperStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperStatus.INPROGRESS
				: appContext.stepperStatus.OPEN;
		} else if (index === 3) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter((obj) => obj.docType === DocType.SSM).length > 0
				? appContext.stepperStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperStatus.INPROGRESS
				: appContext.stepperStatus.OPEN;
		} else if (index === 4) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter(
						(obj) =>
							(obj.docType === DocType.MYKAD || obj.docType === DocType.PASSPORT) &&
							obj.docCategory === DocCategory.BUSINESS,
					).length > 0
				? appContext.stepperStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperStatus.INPROGRESS
				: appContext.stepperStatus.OPEN;
		} else if (index === 5) {
			return appContext.selectedAccount() &&
				appContext.selectedAccount().linkedBankAccounts?.items?.length > 0
				? appContext.stepperStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperStatus.INPROGRESS
				: appContext.stepperStatus.OPEN;
		} else if (index === 6) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter((obj) => obj.docType === DocType.AUTHLETTER).length > 0
				? appContext.stepperStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperStatus.INPROGRESS
				: appContext.stepperStatus.OPEN;
		}
	}

	function updateStepperCompletionStatus(index) {
		if (index === 0) {
			return appContext.selectedAccount() &&
				appContext.selectedAccount().type &&
				location.state.page !== index
				? appContext.stepperCompletionStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperCompletionStatus.INPROGRESS
				: appContext.stepperCompletionStatus.OPEN;
		} else if (index === 1) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter(
						(obj) =>
							(obj.docType === DocType.MYKAD || obj.docType === DocType.PASSPORT) &&
							obj.docCategory === DocCategory.INDIVIDUAL,
					).length > 0 &&
				location.state.page !== index
				? appContext.stepperCompletionStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperCompletionStatus.INPROGRESS
				: appContext.stepperCompletionStatus.OPEN;
		} else if (index === 2) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter((obj) => obj.docType === DocType.SELFIE).length > 0 &&
				location.state.page !== index
				? appContext.stepperCompletionStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperCompletionStatus.INPROGRESS
				: appContext.stepperCompletionStatus.OPEN;
		} else if (index === 3) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter((obj) => obj.docType === DocType.SSM).length > 0 &&
				location.state.page !== index
				? appContext.stepperCompletionStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperCompletionStatus.INPROGRESS
				: appContext.stepperCompletionStatus.OPEN;
		} else if (index === 4) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter(
						(obj) =>
							(obj.docType === DocType.MYKAD || obj.docType === DocType.PASSPORT) &&
							obj.docCategory === DocCategory.BUSINESS,
					).length > 0 &&
				location.state.page !== index
				? appContext.stepperCompletionStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperCompletionStatus.INPROGRESS
				: appContext.stepperCompletionStatus.OPEN;
		} else if (index === 5) {
			return appContext.selectedAccount() &&
				appContext.selectedAccount().linkedBankAccounts?.items?.length > 0 &&
				location.state.page !== index
				? appContext.stepperCompletionStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperCompletionStatus.INPROGRESS
				: appContext.stepperCompletionStatus.OPEN;
		} else if (index === 6) {
			return appContext.selectedAccount() &&
				appContext
					.selectedAccount()
					.referenceDocs?.items?.filter((obj) => obj.docType === DocType.AUTHLETTER).length > 0 &&
				location.state.page !== index
				? appContext.stepperCompletionStatus.COMPLETED
				: progressStatus(index, location.state.page, appContext.isAdmin)
				? appContext.stepperCompletionStatus.INPROGRESS
				: appContext.stepperCompletionStatus.OPEN;
		}
	}

	const linksObj = [
		{
			title: 'Basic Details',
			desc: 'Some basic details about account type',
			leftIconClass: '',
			leftIcon: 'form',
			rightIcon: checkmarkCircleOutline,
			rightIconClass: 'text-success',
			clickable: true,
			onClick: linkClickHandler,
			index: 0,
			status: updateStepperStatus(0),
			navigationStatus: updateStepperCompletionStatus(0),
		},
		{
			title: 'Proof Submission',
			desc: 'Some proof for id verification',
			leftIconClass: '',
			leftIcon: 'mykad',
			rightIcon: timeOutline,
			rightIconClass: 'text-primary-50',
			clickable: true,
			onClick: linkClickHandler,
			index: !appContext.isAdmin && 1,
			status: updateStepperStatus(!appContext.isAdmin && 1),
			navigationStatus: updateStepperCompletionStatus(!appContext.isAdmin && 1),
		},
		{
			title: 'Face Verification',
			desc: 'Photo Verification',
			leftIconClass: '',
			leftIcon: 'selfie',
			rightIcon: stopwatchOutline,
			rightIconClass: '',
			clickable: true,
			onClick: linkClickHandler,
			index: !appContext.isAdmin && 2,
			status: updateStepperStatus(!appContext.isAdmin && 2),
			navigationStatus: updateStepperCompletionStatus(!appContext.isAdmin && 2),
		},
		{
			title: 'SSM Submission',
			desc: 'Some proof for company verification',
			leftIconClass: '',
			leftIcon: 'ssm',
			rightIcon: timeOutline,
			rightIconClass: 'text-primary-50',
			clickable: true,
			onClick: linkClickHandler,
			index: appContext.isAdmin ? 1 : getAccountType() === AccountType.BUSINESS && 3,
			status: updateStepperStatus(
				appContext.isAdmin ? 3 : getAccountType() === AccountType.BUSINESS && 3,
			),
			navigationStatus: updateStepperCompletionStatus(
				appContext.isAdmin ? 3 : getAccountType() === AccountType.BUSINESS && 3,
			),
		},
		{
			title: "Director's Proof Submission",
			desc: "All Director's/Owner id verification",
			leftIconClass: '',
			leftIcon: 'mykad',
			rightIcon: stopwatchOutline,
			rightIconClass: '',
			clickable: true,
			onClick: linkClickHandler,
			index: appContext.isAdmin ? 2 : getAccountType() === AccountType.BUSINESS && 4,
			status: updateStepperStatus(
				appContext.isAdmin ? 4 : getAccountType() === AccountType.BUSINESS && 4,
			),
			navigationStatus: updateStepperCompletionStatus(
				appContext.isAdmin ? 4 : getAccountType() === AccountType.BUSINESS && 4,
			),
		},
		{
			title: 'Link Bank Account',
			desc: 'Bank details to be given',
			leftIconClass: '',
			leftIcon: 'linkBank',
			rightIcon: stopwatchOutline,
			rightIconClass: '',
			clickable: true,
			onClick: linkClickHandler,
			index: appContext.isAdmin
				? 3
				: getAccountType() === AccountType.INDIVIDUAL
				? 3
				: getAccountType() === AccountType.BUSINESS && 5,
			status: updateStepperStatus(
				appContext.isAdmin
					? 5
					: getAccountType() === AccountType.INDIVIDUAL
					? 5
					: getAccountType() === AccountType.BUSINESS && 5,
			),
			navigationStatus: updateStepperCompletionStatus(
				appContext.isAdmin
					? 5
					: getAccountType() === AccountType.INDIVIDUAL
					? 5
					: getAccountType() === AccountType.BUSINESS && 5,
			),
		},
		{
			title: 'Authorization Letter Submission',
			desc: 'Authorization letter from company',
			leftIconClass: '',
			leftIcon: 'ssm',
			rightIcon: stopwatchOutline,
			rightIconClass: '',
			clickable: true,
			onClick: linkClickHandler,
			index: appContext.isAdmin ? 4 : getAccountType() === AccountType.BUSINESS && 6,
			status: updateStepperStatus(
				appContext.isAdmin ? 6 : getAccountType() === AccountType.BUSINESS && 6,
			),
			navigationStatus: updateStepperCompletionStatus(
				appContext.isAdmin ? 6 : getAccountType() === AccountType.BUSINESS && 6,
			),
		},
	];

	function newMenu(routeNames) {
		if (appContext.isAdmin) {
			routeNames.splice(2, 1);
			routeNames.splice(1, 1);
			console.log('Account creation steps: ', routeNames);
			console.log('Account creation steps length: ', routeNames.length);
			// setCustomerVerificationLinks(newMenu(linksObj));
			return routeNames;
		} else {
			if (getAccountType() === AccountType.INDIVIDUAL) {
				routeNames.splice(6, 1);
				routeNames.splice(4, 1);
				routeNames.splice(3, 1);
				// console.log('Account creation steps: ', routeNames);
				// console.log('Account creation steps length: ', routeNames.length);
				// setCustomerVerificationLinks(newMenu(linksObj));
				return routeNames;
			} else if (getAccountType() === AccountType.BUSINESS) {
				console.log('Account creation steps: ', routeNames);
				console.log('Account creation steps length: ', routeNames.length);
				// setCustomerVerificationLinks(newMenu(linksObj));
				return routeNames;
			}
		}
	}

	const handleLeftArrowClick = () => {
		const newPage = currentPage - 1;
		if (newPage >= 0 && updateStepperStatus(newPage) === appContext.stepperStatus.COMPLETED) {
			setCurrentPage(newPage);
			setShowRightArrow(true);
			setShowLeftArrow(newPage > 0);
			navigateToChild(newPage, totalPages);
		}
	};

	const handleRightArrowClick = () => {
		const newPage = currentPage + 1;
		const prevPageStatus = updateStepperStatus(newPage - 1);

		if (newPage < totalPages && prevPageStatus === appContext.stepperStatus.COMPLETED) {
			setCurrentPage(newPage);
			setShowLeftArrow(true);
			setShowRightArrow(newPage < totalPages - 1);
			navigateToChild(newPage, totalPages);
		} else {
			console.log(`Cannot navigate to page ${newPage} because the current step is not completed.`);
		}
	};

	return (
		<>
			{/* <Toaster /> */}
			{device === 'mobile' && location.pathname !== Routes.CUSTOMER_VERIFICATION && (
				<IonGrid>
					<IonRow className="">
						<IonCol className="w-10 justify-start">
							{showLeftArrow && (
								<IonIcon
									icon={caretBackCircleSharp}
									className={`w-7 h-7 ${totalPages < 5 ? 'ml-5' : 'ml-2'} mt-3`}
									onClick={handleLeftArrowClick}
								/>
							)}
						</IonCol>

						<IonCol size="9" className="justify-center items-center">
							<IonRow className="justify-center items-center">
								{customerVerificationLinks.map((itemDetails, i) => (
									<CustomerVerificationItem
										key={i}
										props={itemDetails}
										children={undefined}
										propsLength={customerVerificationLinks.length}
									></CustomerVerificationItem>
								))}
							</IonRow>
						</IonCol>

						<IonCol className="w-10 justify-end">
							{showRightArrow && (
								<IonIcon
									icon={caretForwardCircleSharp}
									className={`w-7 h-7 ${totalPages < 5 ? 'ml-0' : 'ml-1'} mt-3`}
									onClick={handleRightArrowClick}
								/>
							)}
						</IonCol>
					</IonRow>
					<Outlet />
				</IonGrid>
			)}

			{device !== 'mobile' && location.pathname !== Routes.CUSTOMER_VERIFICATION && (
				<>
					<IonList className="w-screen bg-transparent p-0 h-18 ml-6 pl-0.5">
						<IonRow>
							{customerVerificationLinks.map((itemDetails, i) => (
								<CustomerVerificationItem
									key={i}
									props={itemDetails}
									children={undefined}
									propsLength={customerVerificationLinks.length}
								></CustomerVerificationItem>
							))}
						</IonRow>
					</IonList>

					<Outlet />
				</>
			)}
		</>
	);
};

export default React.memo(CustomerVerification);
