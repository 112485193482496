import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
	Account,
	AccountType,
	DocType,
	DocCategory,
	VerificationStatus,
	AccountStatus,
	Document,
	BankAccount,
	S3Object,
} from 'API';
import AccountApi from 'api/account';
import DocumentApi from 'api/document';
import {
	IonButton,
	IonCol,
	IonIcon,
	IonImg,
	IonLabel,
	IonRow,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonAccordionGroup,
	IonAccordion,
	IonItem,
	IonCardTitle,
	IonText,
	IonSkeletonText,
	IonInput,
} from '@ionic/react';
import {
	alertCircle,
	checkmarkCircle,
	alertCircleOutline,
	createOutline,
	arrowForwardOutline,
	ellipse,
	expandOutline,
} from 'ionicons/icons';
import AppContext from 'utils/app-context';
import useMobileDetector from 'hooks/use-mobile-detector';
import { data } from '@tensorflow/tfjs';
import BankAccountApi from 'api/bankaccount';
import { Routes } from 'utils/routes';
import PDFViewer from 'components/PDFViwer';
import { removeTypenameFromS3 } from 'utils/object-helper';
import RinngitPayLoader from 'components/RinngitPayLoader';
import ImageModal from 'components/ImageModal';
import { Toaster, toast } from 'react-hot-toast';

interface DirectorsId {
	id: string;
	docType: string;
	docCategory: string;
	pages: Array<{
		bucket: string;
		key: string;
		region: string;
		__typename: string;
	}>;
	status: string;
	comments: null | any[];
	verifiedBy: null | string;
	extractedData: string;
	accountId: string;
}

export default function IndividualAccounts() {
	const { accountId } = useParams();
	const appContext = useContext(AppContext);
	const userDetails = appContext.userDetails;
	const [accountDetails, setAccountDetails] = useState<Account | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [uploadedIdDocument, setUploadedIdDocument] = useState<Document | null>(null);
	const [extractedIdData, setExtractedIdData] = useState<undefined | any>(undefined);
	const [uploadedSelfieData, setUploadedSelfieData] = useState<undefined | any>(undefined);
	const [bankAccountData, setBankAccountData] = useState<BankAccount | null>(null);

	const [documentType, setDocumentType] = useState();
	const [individualId0, setIndividualId0] = useState<string | undefined>(undefined);
	const [individualId1, setIndividualId1] = useState<string | undefined>(undefined);
	const [selfieImage, setSelfieImage] = useState<string | undefined>(undefined);
	const [selfieExtactedData, setSelfieExtactedData] = useState<undefined | any>(undefined);
	const [ssmDocument, setSsmDocument] = useState<string | undefined>(undefined); //TODO: why dont we use individual0 like we load id images
	const [ssmRawDocument, setSsmRawDocument] = useState<undefined | any>(undefined);
	const [extractedSsmData, setExtractedSsmData] = useState<undefined | any>(undefined);
	const [businessAuthLetterDocument, setBusinessAuthLetterDocument] = useState<string | undefined>(
		undefined,
	);
	const [businessAuthLetter, setBusinessAuthLetter] = useState<undefined | any>(undefined);
	const [ctosDocument, setCtosDocument] = useState<undefined | any>(undefined);
	const [extractedCtos, setExtractedCtos] = useState<undefined | any>(undefined);
	const [ctosDocument0, setCtosDocument0] = useState<string | undefined>(undefined);

	const [matchingId0, setMatchingId0] = useState<string | undefined>(undefined);
	const [matchingId1, setMatchingId1] = useState<string | undefined>(undefined);

	const [nonMatchingId0, setNonMatchingId0] = useState<string | undefined>(undefined);
	const [nonMatchingId1, setNonMatchingId1] = useState<string | undefined>(undefined);

	const [matchingIdInfo, setMatchingIdInfo] = useState({});
	const [nonMatchingIdInfo, setNonMatchingIdInfo] = useState({});

	const [matchingIdProofs, setMatchingIdProofs] = useState<DirectorsId[] | null>(null);
	const [nonMatchingIdProofs, setNonMatchingIdProofs] = useState<DirectorsId[] | null>(null);
	const [expandedMatchingItem, setExpandedMatchingItem] = useState(null);
	const [expandedNonMatchingItem, setExpandedNonMatchingItem] = useState(null);

	const accordionGroup = useRef<null | HTMLIonAccordionGroupElement>(null);
	const [isVerifiedToggled, setIsVerifiedToggled] = useState(true);
	const [isToggleclicked, setIsToggleclicked] = useState(false);
	const [selectedCard, setSelectedCard] = useState<number | null>(null);
	const [selectedStatuss, setSelectedStatuss] = useState('Verified');
	const [showDirectorProof, setShowDirectorProof] = useState(false);
	const [editIndividualName, setEditIndividualName] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [directorsProof, setDirectorProof] = useState(false);
	const [isCreatedByAdmin, setIsCreatedByAdmin] = useState(false);
	const navigate = useNavigate();
	const [imageModal, setImageModal] = useState<string | undefined>(undefined);
	const [imageModalType, setImageModalType] = useState<string | undefined>(undefined);
	let inputValue;
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const [editMode, setEditMode] = useState({
		name: false,
		idNumber: false,
		accountNumber: false,
		accountHolderName: false,
		bankName: false,
		regNumber: false,
		businessName: false,
		incorporationDate: false,
		nonMatchingDirectorName: false,
		nonMatchingDirectorNumber: false,
	});

	const [inputValues, setInputValues] = useState({
		name: '',
		idNumber: '',
		accountNumber: '',
		accountHolderName: '',
		bankName: '',
		regNumber: '',
		businessName: '',
		incorporationDate: '',
		nonMatchingDirectorName: '',
		nonMatchingDirectorNumber: '',
	});

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 2600,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 2600,
				position: 'top-center',
			});
		}
	};

	useEffect(() => {
		loadAccountData();
		const delayTimeout = setTimeout(() => {
			setShowDirectorProof(true);
		}, 3000);
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);
		return () => clearTimeout(delayTimeout);
		window.removeEventListener('resize', handleResize);
	}, []);

	const cardWidthClass = windowWidth < 310 ? 'w-56' : 'w-64 md:w-80';
	const marginWidth = windowWidth < 310 ? '' : 'ml-3 md:ml-12';

	const isMobile = useMobileDetector() === 'mobile';

	async function getAccountDetails() {
		if (typeof accountId === 'string') {
			try {
				const response: Account = await AccountApi.getById(accountId);
				console.log('Account Details: ', response);
				setAccountDetails(response);
				await getUserDetailsById(response.createdBy);
				return response;
			} catch (error) {
				console.log(error);
			}
		}
	}

	async function loadAccountData() {
		const accountData = await getAccountDetails();
		// console.log('accountData', accountData);

		const individualIdData = await uploadedIndividualIdDocument(accountData);
		console.log('Upload Individual ID Document: ', individualIdData);
		console.log(
			'Before Extrated Individual ID Data Parser: ',
			individualIdData && JSON.parse(JSON.parse(individualIdData.extractedData)),
		);
		setUploadedIdDocument(individualIdData);
		await loadImagefromS3(individualIdData, 'individualId');

		const extractedRawIdData =
			individualIdData == undefined
				? ''
				: await extractInfo(
						JSON.parse(JSON.parse(individualIdData.extractedData)),
						individualIdData.docType,
				  );
		console.log('After Extrated Individual ID Data Parser: ', extractedRawIdData);
		setExtractedIdData(extractedRawIdData);

		const individualSelfieData = uploadedSelfieDocument(accountData);
		console.log('Uplaoded Selfie Data: ', individualSelfieData);
		setUploadedSelfieData(individualSelfieData);

		const uploadedSelfieData = individualSelfieData && individualSelfieData.extractedData;
		console.log(
			'Uplaoded Selfie Matching: ',
			uploadedSelfieData && JSON.parse(JSON.parse(uploadedSelfieData)),
		);
		setSelfieExtactedData(uploadedSelfieData && JSON.parse(JSON.parse(uploadedSelfieData)));
		await loadImagefromS3(individualSelfieData, 'selfieImage');

		const bankAccountList = accountData?.linkedBankAccounts?.items[0] || null;
		console.log('Uploaded Bank Account Details: ', bankAccountList);
		setBankAccountData(bankAccountList);

		if (accountData?.type === AccountType.BUSINESS) {
			const ssmRawData = uploadedSsmDocument(accountData);
			console.log('Uploaded SSM Document: ', ssmRawData);
			setSsmRawDocument(ssmRawData);
			await loadImagefromS3(ssmRawData, 'ssmDocument');
			// const loadedPdf = await loadDocumentFromS3(ssmRawData, 'ssmDocument');
			// console.log(loadedPdf);
			// const mergedPdfFile = await mergePdfFromUrls(loadedPdf);
			// setSsmDocument(mergedPdfFile);

			const ssmData = ssmRawData && JSON.parse(JSON.parse(ssmRawData?.extractedData));
			console.log('Before Extracted SSM Document Parser: ', ssmData);

			const extractedSsmData = await extractInfo(ssmData, ssmRawData?.docType);
			console.log('After Extracted SSM Document Parser: ', extractedSsmData);
			setExtractedSsmData(extractedSsmData);

			const matchingIdProofs = filterUploadedDirectorData(
				ssmData?.directorsDetails,
				uploadedIds(accountData),
			).matchingIds;
			setMatchingIdProofs(matchingIdProofs);
			console.log("Matching ID Proof of Director's: ", matchingIdProofs);

			const nonMatchingIdProofs = filterUploadedDirectorData(
				ssmData?.directorsDetails,
				uploadedIds(accountData),
			).nonMatchingIds;
			setNonMatchingIdProofs(nonMatchingIdProofs);
			console.log("Non-Matching ID Proof of Director's: ", nonMatchingIdProofs);

			// console.log(await directorsProofs(matchingIdProofs));

			const authLetter = uploadedAuthDocument(accountData);
			console.log('Uploaded SSM Document: ', authLetter);
			setBusinessAuthLetter(authLetter);
			await loadImagefromS3(authLetter, 'authLetter');

			const uploadedCtos = uploadedCtosDocument(accountData);
			console.log('Uploaded CTOS Document: ', uploadedCtos);
			setCtosDocument(uploadedCtos);

			const creditReport = uploadedCtos && JSON.parse(JSON.parse(uploadedCtos?.extractedData));
			console.log('Credit Report: ', creditReport);
			// console.log('Credit Report Name: ', creditReport?.summary?.enqSum?.name);
			// console.log('Credit Report ID: ', creditReport.summary.enqSum.nicBrno);
			setExtractedCtos(creditReport);
		}

		setIsLoading(false);
	}

	async function getUserDetailsById(userId) {
		try {
			const userDetails = await AccountApi.getUserByUserId(userId);
			console.log(userDetails);
			console.log(userDetails.data.getUserByUserId.Groups[0] === 'SystemAdmin');
			setIsCreatedByAdmin(userDetails.data.getUserByUserId.Groups[0] === 'SystemAdmin');
		} catch (error) {
			console.error('Error getting final account list:', error);
		}
	}

	//individual
	function uploadedIndividualIdDocument(accountData) {
		const uploadedDocuments =
			accountData?.type === AccountType.INDIVIDUAL
				? findingIndividualIdDocument(accountData)
				: uploadedBusinessAuthDocument(accountData);
		// console.log('uploadedDocuments: ', uploadedDocuments);
		return uploadedDocuments;
	}

	function findingIndividualIdDocument(accountDetails) {
		return accountDetails?.referenceDocs.items.find(
			(doc) =>
				(doc.docType == DocType.MYKAD || doc.docType == DocType.PASSPORT) &&
				doc.docCategory === DocCategory.INDIVIDUAL &&
				doc.account.type === AccountType.INDIVIDUAL,
		);
	}

	function uploadedBusinessAuthDocument(accountDetails) {
		return accountDetails?.referenceDocs.items.find(
			(doc) =>
				(doc.docType == DocType.MYKAD || doc.docType == DocType.PASSPORT) &&
				doc.docCategory === DocCategory.INDIVIDUAL &&
				doc.account.type === AccountType.BUSINESS,
		);
	}

	function uploadedSelfieDocument(accountData) {
		const uploadedSelfieDocument =
			accountData?.type === AccountType.INDIVIDUAL
				? uploadedIndividualSelfieDocument(accountData)
				: uploadedBusinessAuthSelfieDocument(accountData);
		// console.log('uploadedSelfieDocument: ', uploadedSelfieDocument);
		return uploadedSelfieDocument;
	}

	function uploadedIndividualSelfieDocument(accountDetails) {
		return accountDetails?.referenceDocs.items.find(
			(doc) =>
				doc.docType == DocType.SELFIE &&
				doc.docCategory === DocCategory.INDIVIDUAL &&
				doc.account.type === AccountType.INDIVIDUAL,
		);
	}

	function uploadedBusinessAuthSelfieDocument(accountDetails) {
		return accountDetails?.referenceDocs.items.find(
			(doc) =>
				doc.docType == DocType.SELFIE &&
				doc.docCategory === DocCategory.INDIVIDUAL &&
				doc.account.type === AccountType.BUSINESS,
		);
	}

	//business
	function uploadedSsmDocument(accountDetails) {
		return accountDetails?.referenceDocs.items.find(
			(doc) =>
				doc.docType == DocType.SSM &&
				doc.docCategory === DocCategory.BUSINESS &&
				doc.account.type === AccountType.BUSINESS,
		);
	}

	function uploadedAuthDocument(accountDetails) {
		return accountDetails?.referenceDocs.items.find(
			(doc) =>
				doc.docType == DocType.AUTHLETTER &&
				doc.docCategory === DocCategory.BUSINESS &&
				doc.account.type === AccountType.BUSINESS,
		);
	}

	function uploadedIds(accountDetails) {
		return accountDetails?.referenceDocs.items.filter(
			(doc) =>
				(doc.docType == DocType.MYKAD || doc.docType == DocType.PASSPORT) &&
				doc.docCategory === DocCategory.BUSINESS &&
				doc.account.type === AccountType.BUSINESS,
		);
	}

	function filterUploadedDirectorData(ssmData, uploadedDirectorData) {
		const matchingIds: any[] = [];
		const nonMatchingIds: any[] = [];

		// console.log(ssmData, uploadedDirectorData);

		uploadedDirectorData.forEach((data) => {
			console.log(data, '-----------');
			const extractedJson = JSON.parse(JSON.parse(data.extractedData));
			// if (extractedJson.hasOwnProperty('name')) {
			const matchingDirector = ssmData.find((director) => {
				if (data.docType === 'MYKAD') {
					return director.name === extractedJson.name && director.id === extractedJson.idNumber;
				} else if (data.docType === 'PASSPORT') {
					return (
						director.name === extractedJson.name && director.id === extractedJson.documentNumber
					);
				}
				return false;
			});
			if (matchingDirector) {
				matchingIds.push(data);
			} else {
				nonMatchingIds.push(data);
			}
			// }
		});

		return {
			matchingIds,
			nonMatchingIds,
		};
	}

	async function selectedDirector(idProof, type) {
		console.log(idProof, type);
		const parsedIdData = await extractInfo(idProof, type);
		console.log('After Individual ID Data Parser:', parsedIdData);
		setExtractedIdData(parsedIdData);
		// // SelectedDirectorInfo(idProof[currentIdIndex].docType, data);
		// setDocumentType(idProof[currentIdIndex].docType);
		// type === 'matchingId' ? setMatchingIdInfo(data) : setNonMatchingIdInfo(data);
	}

	const matchingToggleAccordion = async (currentIdIndex) => {
		setExpandedMatchingItem(expandedMatchingItem === currentIdIndex ? null : currentIdIndex);
		// expandedMatchingItem !== currentIdIndex &&
		// 	selectedDirectorInfo(extractedSsmData, currentIdIndex);
		// fileChange && onNext(formik.values, formik.isSubmitting);
		const matchingData =
			expandedMatchingItem !== currentIdIndex &&
			matchingIdProofs &&
			matchingIdProofs[currentIdIndex];

		console.log(matchingData);
		const matchingExtractedData = matchingData
			? JSON.parse(JSON.parse(matchingData.extractedData))
			: undefined;
		console.log('Before Matching Individual ID Data Parser: ', matchingExtractedData);

		selectedDirector(matchingExtractedData, matchingData && matchingData.docType);
		await loadImagefromS3(matchingData, 'matchingId');
	};

	const nonMatchingToggleAccordion = async (currentIdIndex) => {
		setExpandedNonMatchingItem(expandedNonMatchingItem === currentIdIndex ? null : currentIdIndex);
		const nonMatchingData =
			expandedNonMatchingItem !== currentIdIndex &&
			nonMatchingIdProofs &&
			nonMatchingIdProofs[currentIdIndex];
		console.log(nonMatchingData);
		const nonMatchingExtractedData = nonMatchingData
			? JSON.parse(JSON.parse(nonMatchingData.extractedData))
			: undefined;
		console.log('Before Non-Matching Individual ID Data Parser: ', nonMatchingExtractedData);

		selectedDirector(nonMatchingExtractedData, nonMatchingData && nonMatchingData.docType);
		await loadImagefromS3(nonMatchingData, 'nonMatchingId');
	};

	//!Common
	function uploadedCtosDocument(accountData) {
		return accountData?.referenceDocs.items.find(
			(doc) =>
				doc.docType === DocType.CREDIT_REPORT &&
				doc.docCategory === DocCategory.INDIVIDUAL &&
				(doc.account.type === AccountType.INDIVIDUAL || doc.account.type === AccountType.BUSINESS),
		);
	}

	async function loadImagefromS3(imageDetails, type) {
		// console.log('Get Document Data: ', imageDetails);
		imageDetails?.pages?.forEach((element, index) => {
			DocumentApi.getImage(element.key).then((res) => {
				if (type === 'ssmDocument') {
					if (element.key.includes('originalPdf')) {
						DocumentApi.getImage(element.key).then((res) => {
							setSsmDocument(res);
							// console.log('SSM Document URL: ', res);
						});
					}
				} else {
					if (type === 'individualId') {
						if (index === 0) {
							setIndividualId0(res);
							// console.log('IndividualId_0 URL: ', res);
						} else {
							setIndividualId1(res);
							// console.log('IndividualId_1 URL: ', res);
						}
					} else if (type === 'matchingId') {
						if (index === 0) {
							setMatchingId0(res);
							// console.log('MatchingId_0 URL: ', res);
						} else {
							setMatchingId1(res);
							// console.log('MatchingId_1 URL: ', res);
						}
					} else if (type === 'nonMatchingId') {
						if (index === 0) {
							setNonMatchingId0(res);
							// console.log('Non-MatchingId_0 URL: ', res);
						} else {
							setNonMatchingId1(res);
							// console.log('Non-MatchingId_1 URL: ', res);
						}
					} else if (type === 'selfieImage') {
						setSelfieImage(res);
						// console.log('Selfie Image URL: ', res);
					} else if (type === 'authLetter') {
						setBusinessAuthLetterDocument(res);
						// console.log('Auth Letter URL: ', res);
					} else {
						// console.log('Unknown image type:', type);
					}
				}
			});
		});
		// console.log(imageUrls);
		// return imageUrls;
	}

	async function extractInfo(data, docType) {
		const extractedData = {};
		if (docType === DocType.PASSPORT) {
			if (data.firstName || data.lastName || data.middleName) {
				extractedData['Name'] = `${data.firstName || ''} ${data.middleName || ''} ${
					data.lastName || ''
				}`.trim();
			} else {
				extractedData['Name'] = 'Data not available';
			}

			if (data.documentNumber) {
				extractedData['Document Number'] = data.documentNumber;
			} else {
				extractedData['Document Number'] = 'Data not available';
			}

			if (data.dateOfExpiry) {
				extractedData['Date of Expiry'] = data.dateOfExpiry;
			} else {
				extractedData['Date of Expiry'] = 'Data not available';
			}

			if (data.dateOfBirth) {
				extractedData['Date of Birth'] = data.dateOfBirth;
			} else {
				extractedData['Date of Birth'] = 'Data not available';
			}

			if (data.dateOfIssue) {
				extractedData['Date of Issue'] = data.dateOfIssue;
			} else {
				extractedData['Date of Issue'] = 'Data not available';
			}
		} else if (docType === DocType.MYKAD) {
			if (data.idNumber) {
				extractedData['ID Number'] = data.idNumber;
			} else {
				extractedData['ID Number'] = 'Data not available';
			}

			if (data.dateOfBirth) {
				extractedData['Date of Birth'] = data.dateOfBirth;
			} else {
				extractedData['Date of Birth'] = 'Data not available';
			}

			if (data.gender) {
				extractedData['Gender'] = data.gender;
			} else {
				extractedData['Gender'] = 'Data not available';
			}

			if (data.name) {
				extractedData['Name'] = data.name;
			} else {
				extractedData['Name'] = 'Data not available';
			}

			if (data.address && data.address.length > 0) {
				extractedData['Address'] = data.address.join(', ');
			} else {
				extractedData['Address'] = 'Data not available';
			}
		} else if (docType === DocType.SSM) {
			extractedData['Reg Number'] = data.regNo.split('(')[0].trim();
			extractedData['Business Name'] = data.businessName.replace(/:/g, '');
			extractedData['Incorporation Date'] = data.incorporationDate.replace(/:/g, '');
			extractedData['Company Type'] = data.companyType.replace(/:/g, '');
			// extractedData['Registered Address'] = data.registredAddress.replace(/:/g, '');
			// extractedData['Business Address'] = data.businessAddress.replace(/:/g, '');
			// extractedData['Nature of Business'] = data.natureOFBusiness.replace(/:/g, '');
			extractedData['Directors'] = await Promise.all(data.directorsDetails.map(directorData));
		}
		// console.log(extractedData);
		return extractedData;
	}

	async function dataCheck(data) {
		return data ? data.trim() : 'Data not available';
	}

	async function directorData(director) {
		return {
			Name: await dataCheck(director.name),
			ID: await dataCheck(director.id),
			'ID Type': await dataCheck(director.idType),
			Designation: await dataCheck(director.designation),
			'Date Of Appointment': await dataCheck(director.dateOfAppointment),
		};
	}

	const onVerifyToggleChange = (event) => {
		if (event.target.checked) {
			setIsToggleclicked(true);
			setIsVerifiedToggled(VerificationStatus.SUSPICIOUS && false);
		} else {
			setIsToggleclicked(true);
			setIsVerifiedToggled(VerificationStatus.VERIFIED && true);
		}
	};

	async function updateStatus(id, type, status) {
		console.log('************');
		try {
			console.log(id);

			const idDocument = {
				id: id,
				status: status,
			};
			console.log('idDocument', idDocument);
			const updateDocumentStatus =
				type == 'Document'
					? await DocumentApi.save(idDocument)
					: await BankAccountApi.save({
							id: id,
							status: status,
					  });
			notify({
				toastMessage: 'Verification status updated successfully',
				toastType: 'success',
			});
			console.log(updateDocumentStatus);
			setIsToggleclicked(false);
		} catch (error) {
			console.log('Failed uploading status', error);
			console.error('Failed uploading status', error);
		}
	}

	async function updateAccountStatus(accountStatus) {
		setIsLoading(true);
		// console.log(accountStatus);
		let status;
		try {
			console.log('Account Status Change');
			if (accountStatus == AccountStatus.ACTIVE) {
				status = AccountStatus.ACTIVE;
			} else if (accountStatus == AccountStatus.DRAFT) {
				status = AccountStatus.DRAFT;
			} else if (accountStatus == AccountStatus.REJECTED) {
				status = AccountStatus.REJECTED;
			} else {
				status = 'UNKNOWN';
			}

			const accountId = {
				id: accountDetails?.id,
				shortName: accountDetails?.shortName || '',
				status: status,
				type: accountDetails?.type || AccountType.INDIVIDUAL,
				owner: accountDetails?.owner || '',
				updatedBy: accountDetails?.id || '',
				createdBy: accountDetails?.createdBy || '',
			};
			console.log('accountId', accountId);
			const updateAccountStatus = await AccountApi.save(accountId);
			console.log(updateAccountStatus);

			if (accountStatus == AccountStatus.ACTIVE) {
				accountsTableClickHandler(
					'KYC details are verified successfully for the account ' + accountDetails?.shortName,
					'success',
				);
			} else if (accountStatus == AccountStatus.DRAFT) {
				accountsTableClickHandler(
					'Requested merchant to re-submit the account details for the account ' +
						accountDetails?.shortName,
					'success',
				);
			} else if (accountStatus == AccountStatus.REJECTED) {
				accountsTableClickHandler(
					'KYC verification has been rejected for the account ' + accountDetails?.shortName,
					'error',
				);
			}
		} catch (error) {
			console.log(error);
			console.error('Failed uploading document', error);
		}
	}

	const handleAccordionChange = (e) => {
		const newValue = e.detail.value;
		if (newValue === 'first') {
			// if (!uploadedIdDocument) {
			// 	const individualIddata = uploadedIndividualId();
			// 	setUploadedIdDocument(individualIddata);
			// 	loadImagefromS3(individualIddata, 'individualId');
			// 	const extractedIdData =
			// 		individualIddata == undefined
			// 			? ''
			// 			: extractInfo(
			// 					JSON.parse(JSON.parse(individualIddata.extractedData)),
			// 					individualIddata.docType,
			// 			  );
			// 	console.log(extractedIdData);
			// 	setExtractedIdData(extractedIdData);
			// 	console.log(
			// 		isVerfiedIndividual ? VerificationStatus.VERIFIED : VerificationStatus.SUSPICIOUS,
			// 	);
			// }
		} else if (newValue === 'second') {
		}
	};

	const accountsTableClickHandler = async (toastMessage, toastType) => {
		navigate(Routes.MANAGE_ACCOUNTS, {
			state: {
				toastMessage: toastMessage,
				toastType: toastType,
			},
		});
	};

	function navigateToAccountUsers() {
		navigate(Routes.ACCOUNT_USERS, { state: { accountId: accountDetails?.id, from: 'KYC' } });
	}

	const handleDocumentEdit = (field, value) => {
		// console.log('New:', field, value);
		// console.log(editMode);
		setInputValues((prevInputValues) => ({
			...prevInputValues,
			[field]: value,
		}));
	};

	const handleDocumentSaveChanges = async (documentType) => {
		if (documentType === 'idVerification') {
			console.log('Updating ID Data');
			console.log(
				'-----Old ID Data: ',
				JSON.parse(JSON.parse(uploadedIdDocument?.extractedData || '')),
				'-----',
			);
			if (editMode.name || editMode.idNumber) {
				const updatedJsonData = JSON.parse(JSON.parse(uploadedIdDocument?.extractedData || ''));
				if (editMode.name) {
					updatedJsonData.name = inputValues.name;
				}
				if (editMode.idNumber) {
					updatedJsonData.idNumber = inputValues.idNumber;
				}
				const updatedJsonDataaa = JSON.stringify(JSON.stringify(updatedJsonData));

				const idDocument: {
					id?: string;
					extractedData?: string | null | undefined;
					pages?: (S3Object | null)[] | null | undefined;
					updatedBy?: string;
				} = uploadedIdDocument || {};

				idDocument.id = uploadedIdDocument?.id || '';
				idDocument.extractedData = updatedJsonDataaa;
				const updatedPages = (idDocument.pages || []).map((page) => removeTypenameFromS3(page));
				idDocument.pages = updatedPages;
				idDocument.updatedBy = userDetails.username;

				console.log(
					'-----Updated JSON Extracted Data: ',
					JSON.parse(JSON.parse(idDocument.extractedData)),
					'-----',
				);
				console.log(
					'-----New ID Data: ',
					JSON.parse(JSON.parse(uploadedIdDocument?.extractedData || '')),
					'-----',
				);

				await DocumentApi.save(idDocument);
				notify({
					toastMessage: 'ID updated successfully',
					toastType: 'success',
				});
			}

			if (isToggleclicked) {
				console.log('Document status changed');
				await updateStatus(
					uploadedIdDocument?.id,
					uploadedIdDocument?.__typename,
					isVerifiedToggled ? VerificationStatus.VERIFIED : VerificationStatus.SUSPICIOUS,
				);
			}

			//! reloading the account
			// const accountData = await getAccountDetails();
			// console.log('accountData', accountData);
			loadAccountData();
		}

		if (documentType === 'selfieVerification') {
			console.log('Updating Selfie status');

			if (isToggleclicked) {
				console.log('Document status changed');
				await updateStatus(
					uploadedSelfieData?.id,
					uploadedIdDocument?.__typename,
					isVerifiedToggled ? VerificationStatus.VERIFIED : VerificationStatus.SUSPICIOUS,
				);
			}

			//! reloading the account
			// const accountData = await getAccountDetails();
			// console.log('accountData', accountData);
			loadAccountData();
		}

		if (documentType === 'bankAccountData') {
			console.log('Updaating Bank Account Data');

			console.log('-----Old Bank Account Data: ', bankAccountData, '-----');

			if (
				bankAccountData &&
				(editMode.bankName || editMode.accountNumber || editMode.accountHolderName)
			) {
				if (editMode.bankName) {
					bankAccountData.bankName = inputValues.bankName;
				}

				if (editMode.accountNumber) {
					bankAccountData.accountNumber = inputValues.accountNumber;
				}

				if (editMode.accountHolderName) {
					bankAccountData.accountHolderName = inputValues.accountHolderName;
				}

				const bankAccount = {
					id: bankAccountData?.id,
					accountHolderName: bankAccountData?.accountHolderName,
					accountNumber: bankAccountData?.accountNumber,
					bankName: bankAccountData?.bankName,
					// status: VerificationStatus.PENDING,
				};

				console.log('-----Update Bank account data: ', bankAccount, '-----');

				console.log('-----New Bank Account Data: ', bankAccountData, '-----');
				await BankAccountApi.save(bankAccount);
				notify({
					toastMessage: 'Bank Account updated successfully',
					toastType: 'success',
				});
			}

			if (isToggleclicked) {
				console.log('Document status changed');
				await updateStatus(
					bankAccountData?.id,
					bankAccountData?.__typename,
					isVerifiedToggled ? VerificationStatus.VERIFIED : VerificationStatus.SUSPICIOUS,
				);
			}

			//! reloading the account
			// const accountData = await getAccountDetails();
			// console.log('accountData', accountData);
			loadAccountData();
		}

		if (documentType === 'ssmDocument') {
			console.log('Updating SSM Data');

			console.log(
				'-----Old SSM Data: ',
				JSON.parse(JSON.parse(ssmRawDocument?.extractedData || '')),
				'-----',
			);

			if (editMode.regNumber || editMode.businessName || editMode.incorporationDate) {
				const updatedJsonData = JSON.parse(JSON.parse(ssmRawDocument.extractedData || ''));

				if (editMode.regNumber) {
					updatedJsonData.regNo = inputValues.regNumber;
				}
				if (editMode.businessName) {
					updatedJsonData.businessName = inputValues.businessName;
				}
				if (editMode.incorporationDate) {
					updatedJsonData.incorporationDate = inputValues.incorporationDate;
				}
				const updatedJsonDataaa = JSON.stringify(JSON.stringify(updatedJsonData));

				const ssmDocument: {
					id?: string;
					extractedData?: string | null | undefined;
					pages?: (S3Object | null)[] | null | undefined;
					updatedBy?: string;
				} = ssmRawDocument || {};

				ssmDocument.id = ssmRawDocument?.id || '';
				ssmDocument.extractedData = updatedJsonDataaa;
				const updatedPages = (ssmDocument.pages || []).map((page) => removeTypenameFromS3(page));
				ssmDocument.pages = updatedPages;
				ssmDocument.updatedBy = userDetails.username;

				console.log(
					'-----Updated JSON Extracted Data: ',
					JSON.parse(JSON.parse(ssmDocument.extractedData)),
					'-----',
				);
				console.log(
					'-----New SSM Data: ',
					JSON.parse(JSON.parse(ssmDocument?.extractedData || '')),
					'-----',
				);

				await DocumentApi.save(ssmDocument);
				notify({
					toastMessage: 'SSM updated successfully',
					toastType: 'success',
				});
			}

			if (isToggleclicked) {
				console.log('Document status changed');
				await updateStatus(
					ssmRawDocument?.id,
					ssmRawDocument?.__typename,
					isVerifiedToggled ? VerificationStatus.VERIFIED : VerificationStatus.SUSPICIOUS,
				);
			}

			//! reloading the account
			// const accountData = await getAccountDetails();
			// console.log('accountData', accountData);
			loadAccountData();
		}

		if (documentType === 'ctosDocument') {
			if (isToggleclicked) {
				console.log('Document status changed');
				await updateStatus(
					ctosDocument?.id,
					ctosDocument?.__typename,
					isVerifiedToggled ? VerificationStatus.VERIFIED : VerificationStatus.SUSPICIOUS,
				);
			}
			//! reloading the account
			// const accountData = await getAccountDetails();
			// console.log('accountData', accountData);
			loadAccountData();
		}

		if (documentType === 'authLetter') {
			if (isToggleclicked) {
				console.log('Document status changed');
				await updateStatus(
					businessAuthLetter?.id,
					businessAuthLetter?.__typename,
					isVerifiedToggled ? VerificationStatus.VERIFIED : VerificationStatus.SUSPICIOUS,
				);
			}
			//! reloading the account
			// const accountData = await getAccountDetails();
			// console.log('accountData', accountData);
			loadAccountData();
		}
	};

	const handleDirectorIdProofChanges = async (index) => {
		console.log('Updating Director ID Data');
		console.log(nonMatchingIdProofs && nonMatchingIdProofs[index]);
		console.log(
			'-----Old ID Data: ',
			JSON.parse(
				JSON.parse((nonMatchingIdProofs && nonMatchingIdProofs[index]?.extractedData) || ''),
			),
			'-----',
		);
		if (editMode.nonMatchingDirectorName || editMode.nonMatchingDirectorNumber) {
			const updatedJsonData = JSON.parse(
				JSON.parse((nonMatchingIdProofs && nonMatchingIdProofs[index]?.extractedData) || ''),
			);
			if (editMode.nonMatchingDirectorName) {
				updatedJsonData.name = inputValues.name;
			}
			if (editMode.nonMatchingDirectorNumber) {
				updatedJsonData.idNumber = inputValues.idNumber;
			}
			const updatedJsonDataaa = JSON.stringify(JSON.stringify(updatedJsonData));

			// if (nonMatchingIdProofs) {
			// 	const idDocument: {
			// 		id?: string;
			// 		extractedData?: string | null | undefined;
			// 		pages?: (S3Object | null)[] | null | undefined;
			// 		updatedBy?: string;
			// 	} = nonMatchingIdProofs[index] || {};

			// 	idDocument.id = (nonMatchingIdProofs && nonMatchingIdProofs[index]?.id) || '';
			// 	idDocument.extractedData = updatedJsonDataaa;
			// 	const updatedPages = (idDocument.pages || []).map((page) => removeTypenameFromS3(page));
			// 	idDocument.pages = updatedPages;
			// 	idDocument.updatedBy = userDetails.username;

			// 	console.log(
			// 		'-----Updated JSON Extracted Data: ',
			// 		JSON.parse(JSON.parse(idDocument.extractedData)),
			// 		'-----',
			// 	);
			// 	console.log(
			// 		'-----New ID Data: ',
			// 		JSON.parse(
			// 			JSON.parse((nonMatchingIdProofs && nonMatchingIdProofs[index]?.extractedData) || ''),
			// 		),
			// 		'-----',
			// 	);

			// 	//
			// 	await DocumentApi.save(idDocument);
			// 	//
			// }
		}
	};

	const openModal = (imageUrl, docType) => {
		if (docType === 'individualId0') {
			setImageModal(individualId0);
			setImageModalType('image');
		}

		if (docType === 'individualId1') {
			setImageModal(individualId1);
			setImageModalType('image');
		}

		if (docType === 'selfieImage') {
			setImageModal(selfieImage);
			setImageModalType('image');
		}

		if (docType === 'selfieImageCompare') {
			setImageModal(individualId0);
			setImageModalType('image');
		}

		if (docType === 'nonMatchingId0') {
			setImageModal(nonMatchingId0);
			setImageModalType('image');
		}

		if (docType === 'nonMatchingId1') {
			setImageModal(nonMatchingId1);
			setImageModalType('image');
		}

		if (docType === 'ssmDocument') {
			setImageModal(ssmDocument);
			setImageModalType('pdf');
		}

		if (docType === 'authLetter') {
			setImageModal(businessAuthLetterDocument);
			setImageModalType('pdf');
		}

		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	const isAllVerified = () => {
		if (accountDetails?.type === AccountType.INDIVIDUAL) {
			if (
				uploadedIdDocument?.status === VerificationStatus.VERIFIED &&
				uploadedSelfieData?.status === VerificationStatus.VERIFIED &&
				bankAccountData?.status === VerificationStatus.VERIFIED
			) {
				return true;
			}
			return false;
		}

		if (accountDetails?.type === AccountType.BUSINESS) {
			if (
				uploadedIdDocument?.status === VerificationStatus.VERIFIED &&
				uploadedSelfieData?.status === VerificationStatus.VERIFIED &&
				bankAccountData?.status === VerificationStatus.VERIFIED &&
				ssmRawDocument?.status === VerificationStatus.VERIFIED &&
				ctosDocument?.status === VerificationStatus.VERIFIED &&
				businessAuthLetter?.status === VerificationStatus.VERIFIED
			) {
				return true;
			}
			return false;
		}
	};

	const directorsProofs = async (directosProofsData) => {
		console.log(directosProofsData);
		return directosProofsData;
	};

	//!render elements
	function BasciDetailAccordion() {
		return (
			<IonRow>
				<IonCol size="12" size-md="6">
					<IonCardContent>
						<IonRow>
							<IonText className="font-bold">Short Name</IonText>
						</IonRow>

						<IonRow>
							<IonLabel>{accountDetails?.shortName}</IonLabel>
						</IonRow>

						<IonRow>
							<IonText className="font-bold">Account Type</IonText>
						</IonRow>

						<IonRow>
							<IonLabel>{accountDetails?.type}</IonLabel>
						</IonRow>

						<IonRow>
							<IonText className="font-bold">What Product or Service you offer?</IonText>
						</IonRow>

						<IonRow>
							<IonLabel>
								{JSON.parse(accountDetails?.questionnaire || '{}').productService}
							</IonLabel>
						</IonRow>

						<IonRow>
							<IonText className="font-bold">
								Who will be paying for your Product or Service?
							</IonText>
						</IonRow>

						<IonRow>
							<IonLabel>{JSON.parse(accountDetails?.questionnaire || '{}').payer}</IonLabel>
						</IonRow>
					</IonCardContent>
				</IonCol>
			</IonRow>
		);
	}

	function BasciDetailAccordionn() {
		const { shortName, type, questionnaire } = accountDetails || {};
		const parseQuestionnaire = JSON.parse(questionnaire || '{}');
		const productService = parseQuestionnaire.productService;
		const payer = parseQuestionnaire.payer;

		const renderRow = (label, value) => (
			<>
				<IonRow>
					<IonText className="font-bold">{label}</IonText>
				</IonRow>
				<IonRow>
					<IonLabel>{value}</IonLabel>
				</IonRow>
			</>
		);

		return (
			<IonRow>
				<IonCol size="12" size-md="6">
					<IonCardContent>
						{renderRow('Short Name', shortName)}
						{renderRow('Account Type', type)}
						{renderRow('What Product or Service you offer?', productService)}
						{renderRow('Who will be paying for your Product or Service?', payer)}
					</IonCardContent>
				</IonCol>
			</IonRow>
		);
	}

	function IndividualIdAccordion() {
		return (
			<IonRow>
				<IonCol size="12" size-md="7">
					<IonCardHeader>
						<IonCardTitle>
							{uploadedIdDocument ? (
								<IonText className="font-bold">
									{uploadedIdDocument?.docType === DocType.MYKAD ? 'MyKad' : 'Passport'} Document
								</IonText>
							) : (
								<IonText className="font-bold">Document</IonText>
							)}
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<IonRow>
							<div>
								<IonCard className="cursor-pointer w-64 h-40 ml-0 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
									{uploadedIdDocument ? (
										individualId0 ? (
											<IonImg
												onClick={() => openModal(individualId0, 'individualId0')}
												src={individualId0}
											></IonImg>
										) : (
											<>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-3/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4 mt-3"
												></IonSkeletonText>
											</>
										)
									) : (
										'Image Not Available'
									)}
								</IonCard>
								<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
									Front Side of
									{uploadedIdDocument?.docType === DocType.MYKAD ? ' MyKad' : ' Passport'}
								</IonLabel>
							</div>
							<div>
								<IonCard className="cursor-pointer w-64 h-40 ml-0 lg:ml-2 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
									{uploadedIdDocument ? (
										individualId1 ? (
											<IonImg
												onClick={() => openModal(individualId1, 'individualId1')}
												src={individualId1}
											></IonImg>
										) : (
											<>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-3/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4 mt-3"
												></IonSkeletonText>
											</>
										)
									) : (
										'Image Not Available'
									)}
								</IonCard>
								<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
									Back Side of
									{uploadedIdDocument?.docType === DocType.MYKAD ? ' MyKad' : ' Passport'}
								</IonLabel>
							</div>
						</IonRow>
					</IonCardContent>
				</IonCol>

				<IonCol size="12" size-md="5">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Extracted Data</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{!uploadedIdDocument && <IonText>ID Not Uploaded</IonText>}

						{uploadedIdDocument && uploadedIdDocument?.docType === DocType.MYKAD && (
							<>
								<IonRow>
									<IonText>Name:</IonText>
								</IonRow>
								<IonRow className=" mb-5 w-60">
									<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
										<IonInput
											readonly={!editMode.name}
											name="name"
											value={!editMode.name ? extractedIdData['Name'] : inputValues.name}
											onIonChange={(e) => handleDocumentEdit('name', e.detail.value)}
										/>
										{accountDetails?.status !== AccountStatus.DRAFT && (
											<>
												<IonIcon
													onClick={() => {
														setEditMode({
															...editMode,
															name: true,
														});
														setInputValues((prevInputValues) => ({
															...prevInputValues,
															['name']: extractedIdData['Name'],
														}));
													}}
													slot="end"
													icon={createOutline}
													className="text-primary-500 cursor-pointer"
												/>
											</>
										)}
									</IonItem>
								</IonRow>

								<IonRow>
									<IonText>ID Number:</IonText>
								</IonRow>
								<IonRow className="mb-5 w-60">
									<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
										<IonInput
											readonly={!editMode.idNumber}
											name="idNumber"
											value={
												!editMode.idNumber ? extractedIdData['ID Number'] : inputValues.idNumber
											}
											onIonChange={(e) => handleDocumentEdit('idNumber', e.detail.value)}
										/>
										{accountDetails?.status !== AccountStatus.DRAFT && (
											<IonIcon
												onClick={() => {
													setEditMode({
														...editMode,
														idNumber: true,
													});
													setInputValues((prevInputValues) => ({
														...prevInputValues,
														['idNumber']: extractedIdData['ID Number'],
													}));
												}}
												slot="end"
												icon={createOutline}
												className="text-primary-500 cursor-pointer"
											/>
										)}
									</IonItem>
								</IonRow>
							</>
						)}

						{uploadedIdDocument && uploadedIdDocument?.docType === DocType.PASSPORT && (
							<>
								<div>
									<p>Name: {extractedIdData['Name']}</p>
									<p>Document Number: {extractedIdData['Document Number']}</p>
								</div>
							</>
						)}
					</IonCardContent>
				</IonCol>

				{accountDetails?.status !== AccountStatus.DRAFT && (
					<IonCol size="12">
						<IonCardContent>
							<IonRow className="w-full mb-5">
								{uploadedIdDocument && (
									<label className="relative inline-flex items-center cursor-pointer">
										<input
											type="checkbox"
											value=""
											className="sr-only peer"
											onChange={onVerifyToggleChange}
											defaultChecked={
												uploadedIdDocument?.status === VerificationStatus.VERIFIED ? false : true
											}
										/>

										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
												isVerifiedToggled ? 'after:rounded-l-full' : 'after:rounded-r-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Verified
										</div>
										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
												isVerifiedToggled ? 'after:rounded-r-full' : 'after:rounded-l-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Suspicious
										</div>
									</label>
								)}
							</IonRow>

							<IonRow>
								<IonButton
									onClick={() => handleDocumentSaveChanges('idVerification')}
									disabled={!editMode.name && !editMode.idNumber && !isToggleclicked}
									className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
								>
									Save Changes
								</IonButton>
							</IonRow>
						</IonCardContent>
					</IonCol>
				)}
			</IonRow>
		);
	}

	function SelfieAccordion() {
		return (
			<IonRow>
				<IonCol size="12" size-md="7">
					<IonCardHeader>
						<IonCardTitle>
							{uploadedSelfieData ? (
								<IonText className="font-bold">Selfie Document</IonText>
							) : (
								<IonText className="font-bold">Selfie Document</IonText>
							)}
						</IonCardTitle>
					</IonCardHeader>

					<IonCardContent>
						<IonRow>
							<IonRow>
								<div>
									<IonCard className="w-64 h-40 ml-0 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
										{uploadedSelfieData ? (
											selfieImage ? (
												<IonImg
													onClick={() => openModal(selfieImage, 'selfieImage')}
													src={selfieImage}
												></IonImg>
											) : (
												<>
													<IonSkeletonText
														animated
														className="bg-primary-500 w-2/4"
													></IonSkeletonText>
													<IonSkeletonText
														animated
														className="bg-primary-500 w-3/4"
													></IonSkeletonText>
													<IonSkeletonText
														animated
														className="bg-primary-500 w-2/4 mt-3"
													></IonSkeletonText>
												</>
											)
										) : (
											'Image Not Available'
										)}
									</IonCard>
									<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
										Selfie Image
									</IonLabel>
								</div>
							</IonRow>
							<IonRow>
								<div>
									<IonCard className="w-64 h-40 ml-0 lg:ml-2 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
										{uploadedIdDocument ? (
											individualId0 ? (
												<IonImg
													onClick={() => openModal(individualId0, 'selfieImageCompare')}
													src={individualId0}
												></IonImg>
											) : (
												<>
													<IonSkeletonText
														animated
														className="bg-primary-500 w-2/4"
													></IonSkeletonText>
													<IonSkeletonText
														animated
														className="bg-primary-500 w-3/4"
													></IonSkeletonText>
													<IonSkeletonText
														animated
														className="bg-primary-500 w-2/4 mt-3"
													></IonSkeletonText>
												</>
											)
										) : (
											'Image Not Available'
										)}
									</IonCard>
									<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
										Front Side of
										{uploadedIdDocument?.docType === DocType.MYKAD ? ' MyKad' : ' Passport'}
									</IonLabel>
								</div>
							</IonRow>
						</IonRow>
					</IonCardContent>
				</IonCol>

				<IonCol size="12" size-md="5">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Face Matched</IonText>
						</IonCardTitle>

						<IonIcon
							icon={
								selfieExtactedData && selfieExtactedData?.faceMatch
									? checkmarkCircle
									: alertCircleOutline
							}
							className={`h-8 w-8 ${
								selfieExtactedData?.faceMatch ? 'text-success' : 'text-pending'
							}`}
						></IonIcon>
					</IonCardHeader>
				</IonCol>

				{accountDetails?.status !== AccountStatus.DRAFT && (
					<IonCol size="12">
						<IonCardContent>
							<IonRow className="w-full mb-5">
								{uploadedSelfieData && (
									<label className="relative inline-flex items-center cursor-pointer">
										<input
											type="checkbox"
											value=""
											className="sr-only peer"
											onChange={onVerifyToggleChange}
											defaultChecked={
												uploadedSelfieData?.status === VerificationStatus.VERIFIED ? false : true
											}
										/>

										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
												isVerifiedToggled ? 'after:rounded-l-full' : 'after:rounded-r-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Verified
										</div>
										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
												isVerifiedToggled ? 'after:rounded-r-full' : 'after:rounded-l-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Suspicious
										</div>
									</label>
								)}
							</IonRow>

							<IonRow>
								{uploadedSelfieData && (
									<IonButton
										onClick={() => handleDocumentSaveChanges('selfieVerification')}
										disabled={!isToggleclicked}
										className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
									>
										Save Changes
									</IonButton>
								)}
							</IonRow>
						</IonCardContent>
					</IonCol>
				)}
			</IonRow>
		);
	}

	function BankAccountDetails() {
		return (
			<IonRow>
				<IonCol size="12" size-md="3">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Account Number</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<>
							<IonRow>
								<IonText> From Merchant: </IonText>
							</IonRow>

							<IonRow className="mb-5 w-60">
								<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
									<IonInput
										readonly={!editMode.accountNumber}
										name="accountNumber"
										value={
											!editMode.accountNumber
												? bankAccountData?.accountNumber
												: inputValues.accountNumber
										}
										onIonChange={(e) => handleDocumentEdit('accountNumber', e.detail.value)}
									/>
									{accountDetails?.status !== AccountStatus.DRAFT && (
										<IonIcon
											onClick={() => {
												setEditMode({
													...editMode,
													accountNumber: true,
												});
												setInputValues((prevInputValues) => ({
													...prevInputValues,
													['accountNumber']: bankAccountData?.accountNumber ?? '',
												}));
											}}
											slot="end"
											icon={createOutline}
											className="text-primary-500 cursor-pointer"
										/>
									)}
								</IonItem>
							</IonRow>

							<IonRow>
								<IonText>From CTOS: </IonText>
							</IonRow>

							<IonRow>
								<IonText>Data Not Available</IonText>
							</IonRow>
						</>
					</IonCardContent>
				</IonCol>

				<IonCol size="12" size-md="3">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Account Holder Name</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<>
							<IonRow>
								<IonText> From Merchant: </IonText>
							</IonRow>
							{/* <IonRow>
																<IonText>
																	{bankAccountData?.accountHolderName
																		? bankAccountData?.accountHolderName
																		: 'Not Available'}
																</IonText>
															</IonRow> */}
							<IonRow className="mb-5 w-60">
								<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
									<IonInput
										readonly={!editMode.accountHolderName}
										name="accountHolderName"
										value={
											!editMode.accountHolderName
												? bankAccountData?.accountHolderName
												: inputValues.accountHolderName
										}
										onIonChange={(e) => handleDocumentEdit('accountHolderName', e.detail.value)}
									/>
									{accountDetails?.status !== AccountStatus.DRAFT && (
										<IonIcon
											onClick={() => {
												setEditMode({
													...editMode,
													accountHolderName: true,
												});
												setInputValues((prevInputValues) => ({
													...prevInputValues,
													['accountHolderName']: bankAccountData?.accountHolderName ?? '',
												}));
											}}
											slot="end"
											icon={createOutline}
											className="text-primary-500 cursor-pointer"
										/>
									)}
								</IonItem>
							</IonRow>
							<IonRow>
								<IonText>From CTOS: </IonText>
							</IonRow>
							<IonRow>
								<IonText>Data Not Available</IonText>
							</IonRow>
						</>
					</IonCardContent>
				</IonCol>

				<IonCol size="12" size-md="3">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Bank Name</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<>
							<IonRow>
								<IonText> From Merchant: </IonText>
							</IonRow>
							{/* <IonRow>
																<IonText>{bankAccountData?.bankName}</IonText>
															</IonRow> */}
							<IonRow className="mb-5 w-60">
								<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
									<IonInput
										readonly={!editMode.bankName}
										name="bankName"
										value={!editMode.bankName ? bankAccountData?.bankName : inputValues.bankName}
										onIonChange={(e) => handleDocumentEdit('bankName', e.detail.value)}
									/>
									{accountDetails?.status !== AccountStatus.DRAFT && (
										<IonIcon
											onClick={() => {
												setEditMode({
													...editMode,
													bankName: true,
												});
												setInputValues((prevInputValues) => ({
													...prevInputValues,
													['bankName']: bankAccountData?.bankName ?? '',
												}));
											}}
											slot="end"
											icon={createOutline}
											className="text-primary-500 cursor-pointer"
										/>
									)}
								</IonItem>
							</IonRow>
							<IonRow>
								<IonText>From CTOS: </IonText>
							</IonRow>
							<IonRow>
								<IonText>Data Not Available</IonText>
							</IonRow>
						</>
					</IonCardContent>
				</IonCol>

				{accountDetails?.status !== AccountStatus.DRAFT && (
					<IonCol size="12">
						<IonCardContent>
							<IonRow className="w-full mb-5">
								{bankAccountData && (
									<label className="relative inline-flex items-center cursor-pointer">
										<input
											type="checkbox"
											value=""
											className="sr-only peer"
											onChange={onVerifyToggleChange}
											defaultChecked={
												bankAccountData?.status === VerificationStatus.VERIFIED ? false : true
											}
										/>

										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
												isVerifiedToggled ? 'after:rounded-l-full' : 'after:rounded-r-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Verified
										</div>
										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
												isVerifiedToggled ? 'after:rounded-r-full' : 'after:rounded-l-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Suspicious
										</div>
									</label>
								)}
							</IonRow>

							<IonRow>
								<IonButton
									onClick={() => handleDocumentSaveChanges('bankAccountData')}
									disabled={
										!editMode.bankName &&
										!editMode.accountNumber &&
										!isToggleclicked &&
										!editMode.accountHolderName
									}
									className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
								>
									Save Changes
								</IonButton>
							</IonRow>
						</IonCardContent>
					</IonCol>
				)}
			</IonRow>
		);
	}

	function SsmDocument() {
		return (
			<IonRow>
				<IonCol size="12" size-md="7">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">SSM Document</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<IonRow>
							<IonRow>
								<IonCard
									className={`ml-0 md:ml-3 w-64 md:w-80 ${
										ssmRawDocument ? '' : 'h-96'
									} pb-5 p-2 border-2 border-secondary-25 rounded-lg overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00`}
								>
									{ssmRawDocument && (
										<>
											<IonText className="ml-10 mb-5">Click expand icon to expand</IonText>
											<IonIcon
												color="black"
												icon={expandOutline}
												className="absolute top-0 right-0 m-2 cursor-pointer h-5 w-5"
												onClick={() => openModal(ssmDocument, 'ssmDocument')}
											/>
										</>
									)}

									{ssmRawDocument ? (
										ssmDocument ? (
											// <IonImg src={ssmDocument}></IonImg>
											<PDFViewer pdfFile={ssmDocument} popup={false} />
										) : (
											<>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-3/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4 mt-3"
												></IonSkeletonText>
											</>
										)
									) : (
										'Image Not Available'
									)}
								</IonCard>
							</IonRow>
						</IonRow>
					</IonCardContent>
				</IonCol>

				<IonCol size="12" size-md="5">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Extracted Data</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{!ssmRawDocument && <IonText>SSM Not Uploaded</IonText>}

						{ssmRawDocument && (
							<>
								<IonRow>
									<IonText>Reg Number:</IonText>
								</IonRow>

								<IonRow className=" mb-5 w-60">
									<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
										<IonInput
											readonly={!editMode.regNumber}
											name="regNumber"
											value={
												!editMode.regNumber ? extractedSsmData['Reg Number'] : inputValues.regNumber
											}
											onIonChange={(e) => handleDocumentEdit('regNumber', e.detail.value)}
										/>
										{accountDetails?.status !== AccountStatus.DRAFT && (
											<IonIcon
												onClick={() => {
													setEditMode({
														...editMode,
														regNumber: true,
													});
													setInputValues((prevInputValues) => ({
														...prevInputValues,
														['regNumber']: extractedSsmData['Reg Number'],
													}));
												}}
												slot="end"
												icon={createOutline}
												className="text-primary-500 cursor-pointer"
											/>
										)}
									</IonItem>
								</IonRow>
								<IonRow>
									<IonText>Business Name:</IonText>
								</IonRow>

								<IonRow className=" mb-5 w-60">
									<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
										<IonInput
											readonly={!editMode.businessName}
											name="businessName"
											value={
												!editMode.businessName
													? extractedSsmData['Business Name']
													: inputValues.businessName
											}
											onIonChange={(e) => handleDocumentEdit('businessName', e.detail.value)}
										/>
										{accountDetails?.status !== AccountStatus.DRAFT && (
											<IonIcon
												onClick={() => {
													setEditMode({
														...editMode,
														businessName: true,
													});
													setInputValues((prevInputValues) => ({
														...prevInputValues,
														['businessName']: extractedSsmData['Business Name'],
													}));
												}}
												slot="end"
												icon={createOutline}
												className="text-primary-500 cursor-pointer"
											/>
										)}
									</IonItem>
								</IonRow>
								<IonRow>
									<IonText>Incorporation Date:</IonText>
								</IonRow>

								<IonRow className=" mb-5 w-60">
									<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
										<IonInput
											readonly={!editMode.incorporationDate}
											name="incorporationDate"
											value={
												!editMode.incorporationDate
													? extractedSsmData['Incorporation Date']
													: inputValues.incorporationDate
											}
											onIonChange={(e) => handleDocumentEdit('incorporationDate', e.detail.value)}
										/>
										{accountDetails?.status !== AccountStatus.DRAFT && (
											<IonIcon
												onClick={() => {
													setEditMode({
														...editMode,
														incorporationDate: true,
													});
													setInputValues((prevInputValues) => ({
														...prevInputValues,
														['incorporationDate']: extractedSsmData['Incorporation Date'],
													}));
												}}
												slot="end"
												icon={createOutline}
												className="text-primary-500 cursor-pointer"
											/>
										)}
									</IonItem>
								</IonRow>
							</>
						)}
					</IonCardContent>
				</IonCol>

				{accountDetails?.status !== AccountStatus.DRAFT && (
					<IonCol size="12">
						<IonCardContent>
							<IonRow className="w-full mb-5">
								{ssmRawDocument && (
									<label className="relative inline-flex items-center cursor-pointer">
										<input
											type="checkbox"
											value=""
											className="sr-only peer"
											onChange={onVerifyToggleChange}
											defaultChecked={
												ssmRawDocument?.status === VerificationStatus.VERIFIED ? false : true
											}
										/>

										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
												isVerifiedToggled ? 'after:rounded-l-full' : 'after:rounded-r-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Verified
										</div>
										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
												isVerifiedToggled ? 'after:rounded-r-full' : 'after:rounded-l-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Suspicious
										</div>
									</label>
								)}
							</IonRow>

							<IonRow>
								<IonButton
									onClick={() => handleDocumentSaveChanges('ssmDocument')}
									disabled={
										!editMode.regNumber &&
										!editMode.businessName &&
										!isToggleclicked &&
										!editMode.incorporationDate
									}
									className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
								>
									Save Changes
								</IonButton>
							</IonRow>
						</IonCardContent>
					</IonCol>
				)}
			</IonRow>
		);
	}

	function DirectorProof() {
		return (
			<IonRow>
				<IonCol size="12" size-md="6">
					{/* matching id */}
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Matching ID Proof's</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{showDirectorProof && matchingIdProofs
							? matchingIdProofs.map((directorsName, currentIdIndex) => {
									// console.log(nonMatchingIdProofs);
									// console.log(JSON.parse(JSON.parse(directorsName.extractedData)));
									const matchingData = directorsName
										? JSON.parse(JSON.parse(directorsName.extractedData))
										: undefined;

									return (
										<IonCol
											key={currentIdIndex}
											size="12"
											size-md="6"
											size-lg="4"
											className="m-0 p-0"
										>
											<IonCard className="bg-primary-200 mx-0 my-1 cursor-pointer">
												<IonCardHeader
													onClick={() => matchingToggleAccordion(currentIdIndex)}
													style={{
														height: '60px',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														width: '100%',
													}}
												>
													<div className="flex items-center justify-between w-full">
														<div className="flex items-center flex-wrap">
															<div className="font-normal text-secondary-100 text-center">
																<div className="w-full mx-auto">
																	{matchingData ? matchingData.name : 'Not-available'}
																</div>
															</div>
														</div>

														<div className="flex">
															<IonIcon
																icon={checkmarkCircle}
																className="h-8 w-8 text-success"
															></IonIcon>
														</div>
													</div>
												</IonCardHeader>

												{expandedMatchingItem === currentIdIndex && (
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center',
															marginBottom: '22px',
														}}
													>
														<IonRow className="md:w-full md:pl-8">
															<div>
																<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																	{matchingData ? (
																		matchingId0 ? (
																			<IonImg src={matchingId0}></IonImg>
																		) : (
																			<>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-2/4"
																				></IonSkeletonText>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-3/4"
																				></IonSkeletonText>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-2/4 mt-3"
																				></IonSkeletonText>
																			</>
																		)
																	) : (
																		'Not Available'
																	)}
																</IonCard>
																<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																	Front Side of
																	{directorsName?.docType === DocType.MYKAD
																		? ' MyKad'
																		: ' Passport'}
																</IonLabel>
															</div>
															<div>
																<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																	{matchingData ? (
																		matchingId1 ? (
																			<IonImg src={matchingId1}></IonImg>
																		) : (
																			<>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-2/4"
																				></IonSkeletonText>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-3/4"
																				></IonSkeletonText>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-2/4 mt-3"
																				></IonSkeletonText>
																			</>
																		)
																	) : (
																		'Image Not Available'
																	)}
																</IonCard>
																<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																	Back Side of
																	{directorsName?.docType === DocType.MYKAD
																		? ' MyKad'
																		: ' Passport'}
																</IonLabel>
															</div>
														</IonRow>
														<IonCardHeader className="w-full md:pl-10">
															<IonCardTitle>
																<IonText className="font-bold">Extracted Data</IonText>
															</IonCardTitle>
														</IonCardHeader>
														<IonCardContent className="w-full md:pl-10">
															{!matchingData && <IonText>ID Not Uploaded</IonText>}

															{matchingData && directorsName?.docType === DocType.MYKAD && (
																<>
																	<IonRow>
																		<IonText>Name:</IonText>
																	</IonRow>
																	<IonRow>
																		<IonText>{extractedIdData['Name']}</IonText>
																	</IonRow>
																	<IonRow>
																		<IonText>ID Number:</IonText>
																	</IonRow>
																	<IonRow>
																		<IonText>{extractedIdData['ID Number']}</IonText>
																	</IonRow>
																</>
															)}
															{matchingData && directorsName?.docType === DocType.PASSPORT && (
																<>
																	<IonRow>
																		<IonText>Name:</IonText>
																	</IonRow>
																	<IonRow>
																		<IonText>{extractedIdData['Name']}</IonText>
																	</IonRow>
																	<IonRow>
																		<IonText>ID Number:</IonText>
																	</IonRow>
																	<IonRow>
																		<IonText>{extractedIdData['Document Number']}</IonText>
																	</IonRow>
																</>
															)}
														</IonCardContent>
														<IonCardContent className="w-full md:pl-10">
															<IonRow className="w-full mb-5">
																{uploadedIdDocument && (
																	<label className="relative inline-flex items-center cursor-pointer">
																		<input
																			type="checkbox"
																			value=""
																			className="sr-only peer"
																			onChange={onVerifyToggleChange}
																			defaultChecked={
																				uploadedIdDocument?.status === VerificationStatus.VERIFIED
																					? false
																					: true
																			}
																		/>

																		<div
																			className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																				isVerifiedToggled
																					? 'after:rounded-l-full'
																					: 'after:rounded-r-full'
																			} text-secondary-00 flex items-center justify-center`}
																		>
																			Verified
																		</div>
																		<div
																			className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																				isVerifiedToggled
																					? 'after:rounded-r-full'
																					: 'after:rounded-l-full'
																			} text-secondary-00 flex items-center justify-center`}
																		>
																			Suspicious
																		</div>
																	</label>
																)}
															</IonRow>

															<IonRow>
																<IonButton
																	onClick={() =>
																		updateStatus(
																			uploadedIdDocument?.id,
																			uploadedIdDocument?.__typename,
																			isVerifiedToggled
																				? VerificationStatus.VERIFIED
																				: VerificationStatus.SUSPICIOUS,
																		)
																	}
																	disabled={!isToggleclicked}
																	className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																>
																	Save Changes
																</IonButton>
															</IonRow>
														</IonCardContent>
													</div>
												)}
											</IonCard>
										</IonCol>
									);
							  })
							: 'Not Availablee'}
					</IonCardContent>
				</IonCol>

				<IonCol size="12" size-md="6">
					{/* nonmatching id */}
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Non-Matching ID Proof's</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{showDirectorProof && nonMatchingIdProofs
							? nonMatchingIdProofs.map((directorsName, currentIdIndex) => {
									// console.log(nonMatchingIdProofs);
									// console.log(JSON.parse(JSON.parse(directorsName.extractedData)));
									const nonMatchData = directorsName
										? JSON.parse(JSON.parse(directorsName.extractedData))
										: undefined;
									// console.log(nonMatchData);
									return (
										<IonCol
											key={currentIdIndex}
											size="12"
											size-md="6"
											size-lg="4"
											className="m-0 p-0"
										>
											<IonCard className="bg-primary-200 mx-0 my-1 cursor-pointer">
												<IonCardHeader
													onClick={() => nonMatchingToggleAccordion(currentIdIndex)}
													style={{
														height: '60px',
														display: 'flex',
														justifyContent: 'center',
														alignItems: 'center',
														width: '100%',
														cursor: 'pointer',
													}}
												>
													<div className="flex items-center justify-between w-full">
														<div className="flex items-center flex-wrap">
															<div className="font-normal text-secondary-100 text-center">
																<div className="w-full mx-auto">
																	{nonMatchData ? nonMatchData.name : 'Not-available'}
																</div>
															</div>
														</div>

														<div className="flex">
															<IonIcon
																icon={alertCircle}
																className="h-8 w-8 text-pending"
															></IonIcon>
														</div>
													</div>
												</IonCardHeader>

												{expandedNonMatchingItem === currentIdIndex && (
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center',
															marginBottom: '22px',
														}}
													>
														<IonRow className="md:w-full md:pl-8">
															<div>
																<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																	{nonMatchData ? (
																		nonMatchingId0 ? (
																			<IonImg
																				onClick={() => openModal(nonMatchingId0, 'nonMatchingId0')}
																				src={nonMatchingId0}
																			></IonImg>
																		) : (
																			<>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-2/4"
																				></IonSkeletonText>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-3/4"
																				></IonSkeletonText>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-2/4 mt-3"
																				></IonSkeletonText>
																			</>
																		)
																	) : (
																		'Image Not Available'
																	)}
																</IonCard>
																<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																	Front Side of
																	{directorsName?.docType === DocType.MYKAD
																		? ' MyKad'
																		: ' Passport'}
																</IonLabel>
															</div>
															<div>
																<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																	{nonMatchData ? (
																		nonMatchingId1 ? (
																			<IonImg
																				onClick={() => openModal(nonMatchingId1, 'nonMatchingId1')}
																				src={nonMatchingId1}
																			></IonImg>
																		) : (
																			<>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-2/4"
																				></IonSkeletonText>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-3/4"
																				></IonSkeletonText>
																				<IonSkeletonText
																					animated
																					className="bg-primary-500 w-2/4 mt-3"
																				></IonSkeletonText>
																			</>
																		)
																	) : (
																		'Image Not Available'
																	)}
																</IonCard>
																<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																	Back Side of
																	{directorsName?.docType === DocType.MYKAD
																		? ' MyKad'
																		: ' Passport'}
																</IonLabel>
															</div>
														</IonRow>
														<IonCardHeader className="w-full md:pl-10">
															<IonCardTitle>
																<IonText className="font-bold">Extracted Data</IonText>
															</IonCardTitle>
														</IonCardHeader>
														<IonCardContent className="w-full md:pl-10">
															{!nonMatchData && <IonText>ID Not Uploaded</IonText>}

															{nonMatchData && directorsName?.docType === DocType.MYKAD && (
																<>
																	<IonRow>
																		<IonText>Name:</IonText>
																	</IonRow>
																	{/* <IonRow>
																												<IonText>{extractedIdData['Name']}</IonText>
																											</IonRow> */}
																	<IonRow className=" mb-5 w-60">
																		<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																			<IonInput
																				readonly={!editMode.nonMatchingDirectorName}
																				name="nonMatchingDirectorName"
																				value={
																					!editMode.nonMatchingDirectorName
																						? extractedIdData['Name']
																						: inputValues.nonMatchingDirectorName
																				}
																				onIonChange={(e) =>
																					handleDocumentEdit(
																						'nonMatchingDirectorName',
																						e.detail.value,
																					)
																				}
																			/>
																			<IonIcon
																				onClick={() => {
																					setEditMode({
																						...editMode,
																						nonMatchingDirectorName: true,
																					});
																					setInputValues((prevInputValues) => ({
																						...prevInputValues,
																						['nonMatchingDirectorName']: extractedIdData['Name'],
																					}));
																				}}
																				slot="end"
																				icon={createOutline}
																				className="text-primary-500 cursor-pointer"
																			/>
																		</IonItem>
																	</IonRow>

																	<IonRow>
																		<IonText>ID Number:</IonText>
																	</IonRow>
																	{/* <IonRow>
																												<IonText>
																													{extractedIdData['ID Number']}
																												</IonText>
																											</IonRow> */}
																	<IonRow className=" mb-5 w-60">
																		<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																			<IonInput
																				readonly={!editMode.nonMatchingDirectorNumber}
																				name="nonMatchingDirectorNumber"
																				value={
																					!editMode.nonMatchingDirectorNumber
																						? extractedIdData['ID Number']
																						: inputValues.nonMatchingDirectorNumber
																				}
																				onIonChange={(e) =>
																					handleDocumentEdit(
																						'nonMatchingDirectorNumber',
																						e.detail.value,
																					)
																				}
																			/>
																			<IonIcon
																				onClick={() => {
																					setEditMode({
																						...editMode,
																						nonMatchingDirectorNumber: true,
																					});
																					setInputValues((prevInputValues) => ({
																						...prevInputValues,
																						['nonMatchingDirectorNumber']: extractedIdData['ID'],
																					}));
																				}}
																				slot="end"
																				icon={createOutline}
																				className="text-primary-500 cursor-pointer"
																			/>
																		</IonItem>
																	</IonRow>
																</>
															)}
															{nonMatchData && directorsName?.docType === DocType.PASSPORT && (
																<>
																	<IonRow>
																		<IonText>Name:</IonText>
																	</IonRow>
																	{/* <IonRow>
																												<IonText>{extractedIdData['Name']}</IonText>
																											</IonRow> */}
																	<IonRow className=" mb-5 w-60">
																		<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																			<IonInput
																				readonly={!editMode.nonMatchingDirectorName}
																				name="nonMatchingDirectorName"
																				value={
																					!editMode.nonMatchingDirectorName
																						? extractedIdData['Name']
																						: inputValues.nonMatchingDirectorName
																				}
																				onIonChange={(e) =>
																					handleDocumentEdit(
																						'nonMatchingDirectorName',
																						e.detail.value,
																					)
																				}
																			/>
																			<IonIcon
																				onClick={() => {
																					setEditMode({
																						...editMode,
																						nonMatchingDirectorName: true,
																					});
																					setInputValues((prevInputValues) => ({
																						...prevInputValues,
																						['nonMatchingDirectorName']: extractedIdData['Name'],
																					}));
																				}}
																				slot="end"
																				icon={createOutline}
																				className="text-primary-500 cursor-pointer"
																			/>
																		</IonItem>
																	</IonRow>

																	<IonRow>
																		<IonText>ID Number:</IonText>
																	</IonRow>
																	{/* <IonRow>
																												<IonText>
																													{extractedIdData['Document Number']}
																												</IonText>
																											</IonRow> */}
																	<IonRow className=" mb-5 w-60">
																		<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																			<IonInput
																				readonly={!editMode.nonMatchingDirectorNumber}
																				name="nonMatchingDirectorNumber"
																				value={
																					!editMode.nonMatchingDirectorNumber
																						? extractedIdData['Document Number']
																						: inputValues.nonMatchingDirectorNumber
																				}
																				onIonChange={(e) =>
																					handleDocumentEdit(
																						'nonMatchingDirectorNumber',
																						e.detail.value,
																					)
																				}
																			/>
																			<IonIcon
																				onClick={() => {
																					setEditMode({
																						...editMode,
																						nonMatchingDirectorNumber: true,
																					});
																					setInputValues((prevInputValues) => ({
																						...prevInputValues,
																						['nonMatchingDirectorNumber']:
																							extractedIdData['Document Number'],
																					}));
																				}}
																				slot="end"
																				icon={createOutline}
																				className="text-primary-500 cursor-pointer"
																			/>
																		</IonItem>
																	</IonRow>
																</>
															)}
														</IonCardContent>
														<IonCardContent className="w-full md:pl-10">
															{/* <IonRow className="w-full mb-5">
																								{uploadedIdDocument && (
																									<label className="relative inline-flex items-center cursor-pointer">
																										<input
																											type="checkbox"
																											value=""
																											className="sr-only peer"
																											onChange={onVerifyToggleChange}
																											defaultChecked={
																												uploadedIdDocument?.status ===
																												VerificationStatus.VERIFIED
																													? false
																													: true
																											}
																										/>

																										<div
																											className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																												isVerifiedToggled
																													? 'after:rounded-l-full'
																													: 'after:rounded-r-full'
																											} text-secondary-00 flex items-center justify-center`}
																										>
																											Verified
																										</div>
																										<div
																											className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																												isVerifiedToggled
																													? 'after:rounded-r-full'
																													: 'after:rounded-l-full'
																											} text-secondary-00 flex items-center justify-center`}
																										>
																											Suspicious
																										</div>
																									</label>
																								)}
																							</IonRow> */}

															<IonRow>
																<IonButton
																	onClick={() => handleDirectorIdProofChanges(currentIdIndex)}
																	disabled={
																		!editMode.nonMatchingDirectorName &&
																		!editMode.nonMatchingDirectorNumber
																	}
																	className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																>
																	Save Changes
																</IonButton>
															</IonRow>
														</IonCardContent>
													</div>
												)}
											</IonCard>
										</IonCol>
									);
							  })
							: 'Not Availablee'}
					</IonCardContent>
				</IonCol>
			</IonRow>
		);
	}

	function CtosDocument() {
		return (
			<IonRow>
				<IonCol size="12" size-md="7">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold text-16px">CTOS Document</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<IonRow>
							<IonRow>
								<IonCard className="w-60 h-96 ml-0 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
									{ctosDocument ? (
										ctosDocument0 ? (
											<IonImg src={ctosDocument0}></IonImg>
										) : (
											<>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-3/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4 mt-3"
												></IonSkeletonText>
											</>
										)
									) : (
										'Credit Report Not Available'
									)}
								</IonCard>
							</IonRow>
						</IonRow>
					</IonCardContent>
				</IonCol>

				<IonCol size="12" size-md="5">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Extracted Data</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						{!ctosDocument && <IonText>Credit Report Not-available</IonText>}

						{ctosDocument && (
							<>
								<IonRow>
									<IonText>SME Score:</IonText>
								</IonRow>
								<IonRow>
									<IonText>500</IonText>
								</IonRow>
							</>
						)}
					</IonCardContent>
				</IonCol>
			</IonRow>
		);
	}

	function AuthDocument() {
		return (
			<IonRow>
				<IonCol size="12" size-md="7">
					<IonCardHeader>
						<IonCardTitle>
							<IonText className="font-bold">Authorization</IonText>
						</IonCardTitle>
					</IonCardHeader>
					<IonCardContent>
						<IonRow>
							<IonRow>
								<IonCard
									className={`ml-0 md:ml-3 w-64 md:w-80  ${
										businessAuthLetter ? '' : 'h-96'
									} pb-5 p-2 h-104 border-2 border-secondary-25 rounded-lg overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00`}
								>
									{businessAuthLetter && (
										<>
											<IonText className="ml-10 mb-5">Click expand icon to expand</IonText>
											<IonIcon
												color="black"
												icon={expandOutline}
												className="absolute top-0 right-0 m-2 cursor-pointer h-5 w-5"
												onClick={() => openModal(businessAuthLetterDocument, 'authLetter')}
											/>
										</>
									)}

									{businessAuthLetter ? (
										businessAuthLetter ? (
											// <IonImg src={businessAuthLetter}></IonImg>
											<PDFViewer pdfFile={businessAuthLetterDocument} popup={false} />
										) : (
											<>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-3/4"
												></IonSkeletonText>
												<IonSkeletonText
													animated
													className="bg-primary-500 w-2/4 mt-3"
												></IonSkeletonText>
											</>
										)
									) : (
										'Auth Not Available'
									)}
								</IonCard>
							</IonRow>
						</IonRow>
					</IonCardContent>
				</IonCol>

				<IonCol size="12" size-md="5">
					<IonCardContent>
						{!businessAuthLetter && <IonText>Auth Letter Not Uploaded</IonText>}
					</IonCardContent>
				</IonCol>

				{accountDetails?.status !== AccountStatus.DRAFT && (
					<IonCol size="12">
						<IonCardContent>
							<IonRow className="w-full mb-5">
								{businessAuthLetter && (
									<label className="relative inline-flex items-center cursor-pointer">
										<input
											type="checkbox"
											value=""
											className="sr-only peer"
											onChange={onVerifyToggleChange}
											defaultChecked={
												businessAuthLetter?.status === VerificationStatus.VERIFIED ? false : true
											}
										/>

										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
												isVerifiedToggled ? 'after:rounded-l-full' : 'after:rounded-r-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Verified
										</div>
										<div
											className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
												isVerifiedToggled ? 'after:rounded-r-full' : 'after:rounded-l-full'
											} text-secondary-00 flex items-center justify-center`}
										>
											Suspicious
										</div>
									</label>
								)}
							</IonRow>

							<IonRow>
								<IonButton
									onClick={() => handleDocumentSaveChanges('authLetter')}
									disabled={!isToggleclicked}
									className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
								>
									Save Changes
								</IonButton>
							</IonRow>
						</IonCardContent>
					</IonCol>
				)}
			</IonRow>
		);
	}

	function AccountStatusUpdateButton() {
		return (
			<>
				<IonRow>
					{accountDetails?.status !== AccountStatus.DRAFT && (
						<>
							<IonCard
								className={`w-36 h-10 ${
									!isAllVerified() ? 'opacity-50' : ''
								} flex items-center justify-center bg-success rounded-md cursor-pointer`}
								disabled={!isAllVerified()}
								onClick={() => {
									updateAccountStatus(AccountStatus.ACTIVE);
								}}
							>
								<IonText className="text-secondary-00">Accept</IonText>
							</IonCard>

							<IonCard
								className="w-36 h-10 flex items-center justify-center bg-primary-100 rounded-md cursor-pointer"
								onClick={() => {
									updateAccountStatus(AccountStatus.DRAFT);
								}}
							>
								<IonText className="text-secondary-00">Re-Submit</IonText>
							</IonCard>

							<IonCard
								className="w-36 h-10 flex items-center justify-center bg-failure rounded-md cursor-pointer"
								onClick={() => {
									updateAccountStatus(AccountStatus.REJECTED);
								}}
							>
								<IonText className="text-secondary-00">Reject</IonText>
							</IonCard>
						</>
					)}

					<IonCard
						className="w-36 h-10 flex items-center justify-center border-2 border-failure rounded-md cursor-pointer"
						onClick={() => {
							navigate(Routes.MANAGE_ACCOUNTS);
						}}
					>
						<IonText className="text-failure">Cancel</IonText>
					</IonCard>
				</IonRow>

				{/* <IonRow>
					<IonRow className="mt-3 mb-5">
						{accountDetails?.status !== AccountStatus.DRAFT && (
							<>
								{isAllVerified() && (
									<>
										<IonButton
											color={'success'}
											onClick={() => {
												updateAccountStatus(AccountStatus.ACTIVE);
												
											}}
											className="w-32 lg:w-36 capitalize bg-success rounded-md h-9 mt-1 ml-3 text-secondary-00 flex items-center justify-center cursor-pointer"
										>
											<IonText className="font-bold text-secondary-00 capitalize">Accept</IonText>
										</IonButton>
									</>
								)}

								<IonButton
									color={'pending'}
									onClick={() => {
										updateAccountStatus(AccountStatus.DRAFT);
										
									}}
									className="w-32 lg:w-36 capitalize bg-primary-100 rounded-md h-9 mt-1 ml-3 text-secondary-00 flex items-center justify-center cursor-pointer"
								>
									<IonText className="font-bold text-secondary-00 capitalize">Re-Submit</IonText>
								</IonButton>

								<IonButton
									color={'failure'}
									onClick={() => {
										updateAccountStatus(AccountStatus.REJECTED);
									
									}}
									className="w-32 lg:w-36 capitalize bg-failure rounded-md h-9 mt-1 ml-3 text-secondary-00 flex items-center justify-center cursor-pointer"
								>
									<IonText className="font-bold text-secondary-00 capitalize">Reject</IonText>
								</IonButton>
							</>
						)}

						<IonButton
							className="w-32 lg:w-36 capitalize bg-failure rounded-md h-9 mt-1 ml-3 text-secondary-00 flex items-center justify-center cursor-pointer"
							color={'failure'}
							onClick={() => {
								navigate(Routes.MANAGE_ACCOUNTS);
							}}
						>
							<IonText className="font-bold text-secondary-00 capitalize">Cancel</IonText>
						</IonButton>
					</IonRow>
				</IonRow> */}
			</>
		);
	}

	return (
		<section className="mt-2 flex-col md:ml-2" style={{ maxWidth: '98%' }}>
			<Toaster />
			{!isLoading ? (
				<div className="grid full-page-height">
					<div className="content">
						<div>
							<IonAccordionGroup
								className="w-full"
								ref={accordionGroup}
								onIonChange={handleAccordionChange}
							>
								<IonRow className="w-full flex">
									<IonCard className=" w-32 mr-1 cursor-pointer shadow-none md:w-52 h-14 md:h-16 bg-primary-100 rounded-t-xl md:rounded-t-xl rounded-b-none flex justify-center items-center">
										<div>
											<div className="ml-11 mt-1 ssm w-7 h-7 bg-secondary-00"></div>
											<IonLabel className="text-secondary-00 text-14px md:text-base text-center">
												KYC Verification
											</IonLabel>
										</div>
									</IonCard>

									<IonCard
										className="w-32 ml-1 cursor-pointer shadow-none md:w-52 h-14 md:h-16 bg-secondary-100 rounded-t-xl md:rounded-t-xl rounded-b-none flex justify-center items-center"
										onClick={navigateToAccountUsers}
									>
										<div>
											<div className="md:ml-1 mt-1 personal w-7 h-7 bg-secondary-00"></div>
											<IonLabel className="text-secondary-00 text-14px md:text-base text-center">
												User
											</IonLabel>
										</div>
									</IonCard>
									<div className="flex-grow"></div>

									<IonRow className=" w-full md:w-auto flex flex-col md:flex-row">
										<IonLabel className="flex justify-end items-center  mr-1 text-right font-bold">
											{/* <IonIcon
										icon={ellipse}
										className="col-span-1 flex justify-start items-start mr-1 my-1 pb-0.5 text-22px text-success"
									/> */}
											Account Status:
										</IonLabel>
									</IonRow>

									<IonRow className="w-full md:w-auto flex">
										{isMobile && (
											<>
												<IonLabel className="w-5/12 ml-3 font-bold">Basic Details</IonLabel>
											</>
										)}

										<IonLabel
											className={`flex w-6/12 md:w-auto justify-end items-center font-bold ${
												accountDetails?.status === AccountStatus.ACTIVE
													? 'text-success'
													: accountDetails?.status === AccountStatus.REJECTED
													? 'text-failure'
													: accountDetails?.status === AccountStatus.PENDING
													? 'text-primary-100'
													: accountDetails?.status === AccountStatus.DRAFT && 'text-primary-100'
											}  pr-2 md:mr-7`}
										>
											{accountDetails?.status === AccountStatus.ACTIVE
												? AccountStatus.ACTIVE
												: accountDetails?.status === AccountStatus.REJECTED
												? AccountStatus.REJECTED
												: accountDetails?.status === AccountStatus.PENDING
												? AccountStatus.PENDING
												: accountDetails?.status === AccountStatus.DRAFT && AccountStatus.DRAFT}
										</IonLabel>
									</IonRow>
								</IonRow>

								{!isMobile && (
									<IonRow>
										<IonLabel className={`w-full md:w-11/12 ml-3 font-bold `}>
											Basic Details
										</IonLabel>
									</IonRow>
								)}

								<IonRow className="ml-3 ">
									<IonAccordion value="first" className="rounded-t-lg rounded-b-lg mt-2 mb-3">
										<IonItem slot="header" color="light">
											{/*desktop view */}
											{!isMobile && (
												<>
													<IonLabel className="w-full md:w-11/12 text-secondary-100 font-normal">
														Basic Details
													</IonLabel>
												</>
											)}

											{/*mobile view */}
											{isMobile && (
												<div className="text-center">
													<IonLabel className="mb-3 mt-2 text-secondary-100 font-normal">
														Basic Details
													</IonLabel>
												</div>
											)}
										</IonItem>

										<div className="ion-padding" slot="content">
											<BasciDetailAccordion />
										</div>
									</IonAccordion>
								</IonRow>

								{!isCreatedByAdmin && (
									<>
										<IonRow>
											<IonLabel className="w-full md:w-11/12 ml-3 font-bold">Individual</IonLabel>
										</IonRow>

										<IonRow className="ml-3 ">
											<IonAccordion value="second" className="rounded-t-lg rounded-b-lg mt-2">
												<IonItem slot="header" color="light">
													{/*desktop view */}
													{uploadedIdDocument && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																ID Verification
															</IonLabel>

															<IonRow
																className={`w-full flex justify-end font-bold items-end md:w-2/12  ${
																	uploadedIdDocument?.status === VerificationStatus.PENDING
																		? 'text-primary-100'
																		: uploadedIdDocument?.status === VerificationStatus.SUSPICIOUS
																		? 'text-failure'
																		: 'text-success'
																}`}
															>
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 ${
																		uploadedIdDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: uploadedIdDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																{uploadedIdDocument?.status}
															</IonRow>
														</>
													)}

													{!uploadedIdDocument && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																ID Verification
															</IonLabel>
															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12 text-primary-100`}
															>
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																PENDING
															</IonRow>
														</>
													)}

													{/*mobile view */}
													{uploadedIdDocument && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-3 mt-2 text-secondary-100 font-normal">
																ID Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 mb-2 text-14px ${
																		uploadedIdDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: uploadedIdDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																<IonText
																	className={`mb-2 text-14px font-bold ${
																		uploadedIdDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: uploadedIdDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																>
																	{uploadedIdDocument?.status}
																</IonText>
															</IonRow>
														</div>
													)}

													{!uploadedIdDocument && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-2 text-secondary-100 font-normal">
																ID Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																<IonText className="text-primary-100 text-14px font-bold">
																	PENDING
																</IonText>
															</IonRow>
														</div>
													)}
												</IonItem>

												<div className="ion-padding" slot="content">
													<IonRow>
														<IonCol size="12" size-md="7">
															<IonCardHeader>
																<IonCardTitle>
																	{uploadedIdDocument ? (
																		<IonText className="font-bold">
																			{uploadedIdDocument?.docType === DocType.MYKAD
																				? 'MyKad'
																				: 'Passport'}{' '}
																			Document
																		</IonText>
																	) : (
																		<IonText className="font-bold">Document</IonText>
																	)}
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																<IonRow>
																	<div>
																		<IonCard className="cursor-pointer w-64 h-40 ml-0 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																			{uploadedIdDocument ? (
																				individualId0 ? (
																					<IonImg
																						onClick={() =>
																							openModal(individualId0, 'individualId0')
																						}
																						src={individualId0}
																					></IonImg>
																				) : (
																					<>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-3/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4 mt-3"
																						></IonSkeletonText>
																					</>
																				)
																			) : (
																				'Image Not Available'
																			)}
																		</IonCard>
																		<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																			Front Side of
																			{uploadedIdDocument?.docType === DocType.MYKAD
																				? ' MyKad'
																				: ' Passport'}
																		</IonLabel>
																	</div>
																	<div>
																		<IonCard className="cursor-pointer w-64 h-40 ml-0 lg:ml-2 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																			{uploadedIdDocument ? (
																				individualId1 ? (
																					<IonImg
																						onClick={() =>
																							openModal(individualId1, 'individualId1')
																						}
																						src={individualId1}
																					></IonImg>
																				) : (
																					<>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-3/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4 mt-3"
																						></IonSkeletonText>
																					</>
																				)
																			) : (
																				'Image Not Available'
																			)}
																		</IonCard>
																		<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																			Back Side of
																			{uploadedIdDocument?.docType === DocType.MYKAD
																				? ' MyKad'
																				: ' Passport'}
																		</IonLabel>
																	</div>
																</IonRow>
															</IonCardContent>
														</IonCol>

														<IonCol size="12" size-md="5">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Extracted Data</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																{!uploadedIdDocument && <IonText>ID Not Uploaded</IonText>}

																{uploadedIdDocument &&
																	uploadedIdDocument?.docType === DocType.MYKAD && (
																		<>
																			<IonRow>
																				<IonText>Name:</IonText>
																			</IonRow>
																			<IonRow className=" mb-5 w-60">
																				<IonItem
																					className="mt-2.5 app-input-filed w-80"
																					lines="none"
																				>
																					<IonInput
																						readonly={!editMode.name}
																						name="name"
																						value={
																							!editMode.name
																								? extractedIdData['Name']
																								: inputValues.name
																						}
																						onIonChange={(e) =>
																							handleDocumentEdit('name', e.detail.value)
																						}
																					/>
																					{accountDetails?.status !== AccountStatus.DRAFT && (
																						<>
																							<IonIcon
																								onClick={() => {
																									setEditMode({
																										...editMode,
																										name: true,
																									});
																									setInputValues((prevInputValues) => ({
																										...prevInputValues,
																										['name']: extractedIdData['Name'],
																									}));
																								}}
																								slot="end"
																								icon={createOutline}
																								className="text-primary-500 cursor-pointer"
																							/>
																						</>
																					)}
																				</IonItem>
																			</IonRow>

																			<IonRow>
																				<IonText>ID Number:</IonText>
																			</IonRow>
																			<IonRow className="mb-5 w-60">
																				<IonItem
																					className="mt-2.5 app-input-filed w-80"
																					lines="none"
																				>
																					<IonInput
																						readonly={!editMode.idNumber}
																						name="idNumber"
																						value={
																							!editMode.idNumber
																								? extractedIdData['ID Number']
																								: inputValues.idNumber
																						}
																						onIonChange={(e) =>
																							handleDocumentEdit('idNumber', e.detail.value)
																						}
																					/>
																					{accountDetails?.status !== AccountStatus.DRAFT && (
																						<IonIcon
																							onClick={() => {
																								setEditMode({
																									...editMode,
																									idNumber: true,
																								});
																								setInputValues((prevInputValues) => ({
																									...prevInputValues,
																									['idNumber']: extractedIdData['ID Number'],
																								}));
																							}}
																							slot="end"
																							icon={createOutline}
																							className="text-primary-500 cursor-pointer"
																						/>
																					)}
																				</IonItem>
																			</IonRow>
																		</>
																	)}

																{uploadedIdDocument &&
																	uploadedIdDocument?.docType === DocType.PASSPORT && (
																		<>
																			<div>
																				<p>Name: {extractedIdData['Name']}</p>
																				<p>Document Number: {extractedIdData['Document Number']}</p>
																			</div>
																		</>
																	)}
															</IonCardContent>
														</IonCol>

														{accountDetails?.status !== AccountStatus.DRAFT && (
															<IonCol size="12">
																<IonCardContent>
																	<IonRow className="w-full mb-5">
																		{uploadedIdDocument && (
																			<label className="relative inline-flex items-center cursor-pointer">
																				<input
																					type="checkbox"
																					value=""
																					className="sr-only peer"
																					onChange={onVerifyToggleChange}
																					defaultChecked={
																						uploadedIdDocument?.status ===
																						VerificationStatus.VERIFIED
																							? false
																							: true
																					}
																				/>

																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																						isVerifiedToggled
																							? 'after:rounded-l-full'
																							: 'after:rounded-r-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Verified
																				</div>
																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																						isVerifiedToggled
																							? 'after:rounded-r-full'
																							: 'after:rounded-l-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Suspicious
																				</div>
																			</label>
																		)}
																	</IonRow>

																	<IonRow>
																		<IonButton
																			onClick={() => handleDocumentSaveChanges('idVerification')}
																			disabled={
																				!editMode.name && !editMode.idNumber && !isToggleclicked
																			}
																			className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																		>
																			Save Changes
																		</IonButton>
																	</IonRow>
																</IonCardContent>
															</IonCol>
														)}
													</IonRow>
												</div>
											</IonAccordion>

											<IonAccordion value="third" className="rounded-t-lg rounded-b-lg mt-3">
												<IonItem slot="header" color="light">
													{/* <IonLabel className="w-full md:w-11/12">ID Verification</IonLabel> */}
													{/*desktop view */}
													{uploadedSelfieData && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Selfie Verification
															</IonLabel>

															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12  ${
																	uploadedSelfieData?.status === VerificationStatus.PENDING
																		? 'text-primary-100'
																		: uploadedSelfieData?.status === VerificationStatus.SUSPICIOUS
																		? 'text-failure'
																		: 'text-success'
																}`}
															>
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 ${
																		uploadedSelfieData?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: uploadedSelfieData?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																{uploadedSelfieData?.status}
															</IonRow>
														</>
													)}

													{!uploadedSelfieData && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Selfie Verification
															</IonLabel>

															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12 text-primary-100`}
															>
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																PENDING
															</IonRow>
														</>
													)}

													{/*mobile view */}
													{uploadedSelfieData && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-3 mt-2 text-secondary-100 font-normal">
																Selfie Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 mb-2 text-14px ${
																		uploadedSelfieData?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: uploadedSelfieData?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																<IonText
																	className={`mb-2 text-14px font-bold ${
																		uploadedSelfieData?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: uploadedSelfieData?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																>
																	{uploadedSelfieData?.status}
																</IonText>
															</IonRow>
														</div>
													)}

													{!uploadedSelfieData && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-2 text-secondary-100 font-normal">
																Selfie Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																<IonText className="text-primary-100 text-14px font-bold">
																	PENDING
																</IonText>
															</IonRow>
														</div>
													)}
												</IonItem>

												<div className="ion-padding" slot="content">
													<IonRow>
														<IonCol size="12" size-md="7">
															<IonCardHeader>
																<IonCardTitle>
																	{uploadedSelfieData ? (
																		<IonText className="font-bold">Selfie Document</IonText>
																	) : (
																		<IonText className="font-bold">Selfie Document</IonText>
																	)}
																</IonCardTitle>
															</IonCardHeader>

															<IonCardContent>
																<IonRow>
																	<IonRow>
																		<div>
																			<IonCard className="w-64 h-40 ml-0 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																				{uploadedSelfieData ? (
																					selfieImage ? (
																						<IonImg
																							onClick={() => openModal(selfieImage, 'selfieImage')}
																							src={selfieImage}
																						></IonImg>
																					) : (
																						<>
																							<IonSkeletonText
																								animated
																								className="bg-primary-500 w-2/4"
																							></IonSkeletonText>
																							<IonSkeletonText
																								animated
																								className="bg-primary-500 w-3/4"
																							></IonSkeletonText>
																							<IonSkeletonText
																								animated
																								className="bg-primary-500 w-2/4 mt-3"
																							></IonSkeletonText>
																						</>
																					)
																				) : (
																					'Image Not Available'
																				)}
																			</IonCard>
																			<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																				Selfie Image
																			</IonLabel>
																		</div>
																	</IonRow>
																	<IonRow>
																		<div>
																			<IonCard className="w-64 h-40 ml-0 lg:ml-2 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																				{uploadedIdDocument ? (
																					individualId0 ? (
																						<IonImg
																							onClick={() =>
																								openModal(individualId0, 'selfieImageCompare')
																							}
																							src={individualId0}
																						></IonImg>
																					) : (
																						<>
																							<IonSkeletonText
																								animated
																								className="bg-primary-500 w-2/4"
																							></IonSkeletonText>
																							<IonSkeletonText
																								animated
																								className="bg-primary-500 w-3/4"
																							></IonSkeletonText>
																							<IonSkeletonText
																								animated
																								className="bg-primary-500 w-2/4 mt-3"
																							></IonSkeletonText>
																						</>
																					)
																				) : (
																					'Image Not Available'
																				)}
																			</IonCard>
																			<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																				Front Side of
																				{uploadedIdDocument?.docType === DocType.MYKAD
																					? ' MyKad'
																					: ' Passport'}
																			</IonLabel>
																		</div>
																	</IonRow>
																</IonRow>
															</IonCardContent>
														</IonCol>

														<IonCol size="12" size-md="5">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Face Matched</IonText>
																</IonCardTitle>

																<IonIcon
																	icon={
																		selfieExtactedData && selfieExtactedData?.faceMatch
																			? checkmarkCircle
																			: alertCircleOutline
																	}
																	className={`h-8 w-8 ${
																		selfieExtactedData?.faceMatch ? 'text-success' : 'text-pending'
																	}`}
																></IonIcon>
															</IonCardHeader>
														</IonCol>

														{accountDetails?.status !== AccountStatus.DRAFT && (
															<IonCol size="12">
																<IonCardContent>
																	<IonRow className="w-full mb-5">
																		{uploadedSelfieData && (
																			<label className="relative inline-flex items-center cursor-pointer">
																				<input
																					type="checkbox"
																					value=""
																					className="sr-only peer"
																					onChange={onVerifyToggleChange}
																					defaultChecked={
																						uploadedSelfieData?.status ===
																						VerificationStatus.VERIFIED
																							? false
																							: true
																					}
																				/>

																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																						isVerifiedToggled
																							? 'after:rounded-l-full'
																							: 'after:rounded-r-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Verified
																				</div>
																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																						isVerifiedToggled
																							? 'after:rounded-r-full'
																							: 'after:rounded-l-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Suspicious
																				</div>
																			</label>
																		)}
																	</IonRow>

																	<IonRow>
																		{uploadedSelfieData && (
																			<IonButton
																				onClick={() =>
																					handleDocumentSaveChanges('selfieVerification')
																				}
																				disabled={!isToggleclicked}
																				className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																			>
																				Save Changes
																			</IonButton>
																		)}
																	</IonRow>
																</IonCardContent>
															</IonCol>
														)}
													</IonRow>
												</div>
											</IonAccordion>

											{accountDetails?.type === AccountType.INDIVIDUAL && (
												<IonAccordion value="fourth" className="rounded-t-lg rounded-b-lg mt-3">
													<IonItem slot="header" color="light">
														{/* <IonLabel className="w-full md:w-11/12">ID Verification</IonLabel> */}
														{/*desktop view */}
														{bankAccountData && !isMobile && (
															<>
																<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																	Bank Account Verification
																</IonLabel>

																<IonRow
																	className={`w-full flex font-bold justify-end items-end md:w-2/12  ${
																		bankAccountData?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: bankAccountData?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																>
																	<IonIcon
																		icon={ellipse}
																		className={`mr-2 ${
																			bankAccountData?.status === VerificationStatus.PENDING
																				? 'text-primary-100'
																				: bankAccountData?.status === VerificationStatus.SUSPICIOUS
																				? 'text-failure'
																				: 'text-success'
																		}`}
																	/>
																	{bankAccountData?.status}
																</IonRow>
															</>
														)}

														{!bankAccountData && !isMobile && (
															<>
																<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																	Bank Account Verification
																</IonLabel>

																<IonRow
																	className={`w-full flex font-bold justify-end items-end md:w-2/12 text-primary-100`}
																>
																	<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																	PENDING
																</IonRow>
															</>
														)}

														{/*mobile view */}
														{bankAccountData && isMobile && (
															<div className="text-center">
																<IonLabel className="mb-3 mt-2 text-secondary-100 font-normal">
																	{' '}
																	Bank Account Verification
																</IonLabel>
																<IonRow className="flex items-center">
																	<IonIcon
																		icon={ellipse}
																		className={`mr-2 mb-2 text-14px ${
																			bankAccountData?.status === VerificationStatus.PENDING
																				? 'text-primary-100'
																				: bankAccountData?.status === VerificationStatus.SUSPICIOUS
																				? 'text-failure'
																				: 'text-success'
																		}`}
																	/>
																	<IonText
																		// className=""
																		className={`mb-2 text-14px font-bold ${
																			bankAccountData?.status === VerificationStatus.PENDING
																				? 'text-primary-100'
																				: bankAccountData?.status === VerificationStatus.SUSPICIOUS
																				? 'text-failure'
																				: 'text-success'
																		}`}
																	>
																		{bankAccountData?.status}
																	</IonText>
																</IonRow>
															</div>
														)}

														{!bankAccountData && isMobile && (
															<div className="text-center">
																<IonLabel className="mb-2 text-secondary-100 font-normal">
																	{' '}
																	Bank Account Verification
																</IonLabel>
																<IonRow className="flex items-center">
																	<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																	<IonText className="text-primary-100 text-14px font-bold">
																		PENDING
																	</IonText>
																</IonRow>
															</div>
														)}
													</IonItem>

													<div className="ion-padding" slot="content">
														<IonRow>
															<IonCol size="12" size-md="3">
																<IonCardHeader>
																	<IonCardTitle>
																		<IonText className="font-bold">Account Number</IonText>
																	</IonCardTitle>
																</IonCardHeader>
																<IonCardContent>
																	<>
																		<IonRow>
																			<IonText> From Merchant: </IonText>
																		</IonRow>

																		<IonRow className="mb-5 w-60">
																			<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																				<IonInput
																					readonly={!editMode.accountNumber}
																					name="accountNumber"
																					value={
																						!editMode.accountNumber
																							? bankAccountData?.accountNumber
																							: inputValues.accountNumber
																					}
																					onIonChange={(e) =>
																						handleDocumentEdit('accountNumber', e.detail.value)
																					}
																				/>
																				{accountDetails?.status !== AccountStatus.DRAFT && (
																					<IonIcon
																						onClick={() => {
																							setEditMode({
																								...editMode,
																								accountNumber: true,
																							});
																							setInputValues((prevInputValues) => ({
																								...prevInputValues,
																								['accountNumber']:
																									bankAccountData?.accountNumber ?? '',
																							}));
																						}}
																						slot="end"
																						icon={createOutline}
																						className="text-primary-500 cursor-pointer"
																					/>
																				)}
																			</IonItem>
																		</IonRow>

																		<IonRow>
																			<IonText>From CTOS: </IonText>
																		</IonRow>

																		<IonRow>
																			<IonText>Data Not Available</IonText>
																		</IonRow>
																	</>
																</IonCardContent>
															</IonCol>

															<IonCol size="12" size-md="3">
																<IonCardHeader>
																	<IonCardTitle>
																		<IonText className="font-bold">Account Holder Name</IonText>
																	</IonCardTitle>
																</IonCardHeader>
																<IonCardContent>
																	<>
																		<IonRow>
																			<IonText> From Merchant: </IonText>
																		</IonRow>
																		{/* <IonRow>
																<IonText>
																	{bankAccountData?.accountHolderName
																		? bankAccountData?.accountHolderName
																		: 'Not Available'}
																</IonText>
															</IonRow> */}
																		<IonRow className="mb-5 w-60">
																			<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																				<IonInput
																					readonly={!editMode.accountHolderName}
																					name="accountHolderName"
																					value={
																						!editMode.accountHolderName
																							? bankAccountData?.accountHolderName
																							: inputValues.accountHolderName
																					}
																					onIonChange={(e) =>
																						handleDocumentEdit('accountHolderName', e.detail.value)
																					}
																				/>
																				{accountDetails?.status !== AccountStatus.DRAFT && (
																					<IonIcon
																						onClick={() => {
																							setEditMode({
																								...editMode,
																								accountHolderName: true,
																							});
																							setInputValues((prevInputValues) => ({
																								...prevInputValues,
																								['accountHolderName']:
																									bankAccountData?.accountHolderName ?? '',
																							}));
																						}}
																						slot="end"
																						icon={createOutline}
																						className="text-primary-500 cursor-pointer"
																					/>
																				)}
																			</IonItem>
																		</IonRow>
																		<IonRow>
																			<IonText>From CTOS: </IonText>
																		</IonRow>
																		<IonRow>
																			<IonText>Data Not Available</IonText>
																		</IonRow>
																	</>
																</IonCardContent>
															</IonCol>

															<IonCol size="12" size-md="3">
																<IonCardHeader>
																	<IonCardTitle>
																		<IonText className="font-bold">Bank Name</IonText>
																	</IonCardTitle>
																</IonCardHeader>
																<IonCardContent>
																	<>
																		<IonRow>
																			<IonText> From Merchant: </IonText>
																		</IonRow>
																		{/* <IonRow>
																<IonText>{bankAccountData?.bankName}</IonText>
															</IonRow> */}
																		<IonRow className="mb-5 w-60">
																			<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																				<IonInput
																					readonly={!editMode.bankName}
																					name="bankName"
																					value={
																						!editMode.bankName
																							? bankAccountData?.bankName
																							: inputValues.bankName
																					}
																					onIonChange={(e) =>
																						handleDocumentEdit('bankName', e.detail.value)
																					}
																				/>
																				{accountDetails?.status !== AccountStatus.DRAFT && (
																					<IonIcon
																						onClick={() => {
																							setEditMode({
																								...editMode,
																								bankName: true,
																							});
																							setInputValues((prevInputValues) => ({
																								...prevInputValues,
																								['bankName']: bankAccountData?.bankName ?? '',
																							}));
																						}}
																						slot="end"
																						icon={createOutline}
																						className="text-primary-500 cursor-pointer"
																					/>
																				)}
																			</IonItem>
																		</IonRow>
																		<IonRow>
																			<IonText>From CTOS: </IonText>
																		</IonRow>
																		<IonRow>
																			<IonText>Data Not Available</IonText>
																		</IonRow>
																	</>
																</IonCardContent>
															</IonCol>

															{accountDetails?.status !== AccountStatus.DRAFT && (
																<IonCol size="12">
																	<IonCardContent>
																		<IonRow className="w-full mb-5">
																			{bankAccountData && (
																				<label className="relative inline-flex items-center cursor-pointer">
																					<input
																						type="checkbox"
																						value=""
																						className="sr-only peer"
																						onChange={onVerifyToggleChange}
																						defaultChecked={
																							bankAccountData?.status ===
																							VerificationStatus.VERIFIED
																								? false
																								: true
																						}
																					/>

																					<div
																						className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																							isVerifiedToggled
																								? 'after:rounded-l-full'
																								: 'after:rounded-r-full'
																						} text-secondary-00 flex items-center justify-center`}
																					>
																						Verified
																					</div>
																					<div
																						className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																							isVerifiedToggled
																								? 'after:rounded-r-full'
																								: 'after:rounded-l-full'
																						} text-secondary-00 flex items-center justify-center`}
																					>
																						Suspicious
																					</div>
																				</label>
																			)}
																		</IonRow>

																		<IonRow>
																			<IonButton
																				onClick={() => handleDocumentSaveChanges('bankAccountData')}
																				disabled={
																					!editMode.bankName &&
																					!editMode.accountNumber &&
																					!isToggleclicked &&
																					!editMode.accountHolderName
																				}
																				className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																			>
																				Save Changes
																			</IonButton>
																		</IonRow>
																	</IonCardContent>
																</IonCol>
															)}
														</IonRow>
													</div>
												</IonAccordion>
											)}
										</IonRow>
									</>
								)}

								{showModal && (
									<ImageModal
										documentDetails={{ documentType: imageModalType, url: imageModal }}
										onClose={closeModal}
									/>
								)}

								{accountDetails?.type === AccountType.INDIVIDUAL && (
									<IonRow>
										{accountDetails?.status !== AccountStatus.DRAFT && (
											<>
												{accountDetails?.status !== AccountStatus.ACTIVE && (
													<IonCard
														className={`w-36 h-10 ${
															!isAllVerified() ? 'opacity-50' : ''
														} flex items-center justify-center bg-success rounded-md cursor-pointer`}
														disabled={!isAllVerified()}
														onClick={() => {
															updateAccountStatus(AccountStatus.ACTIVE);
														}}
													>
														<IonText className="text-secondary-00">Accept</IonText>
													</IonCard>
												)}

												<IonCard
													className="w-36 h-10 flex items-center justify-center bg-primary-100 rounded-md cursor-pointer"
													onClick={() => {
														updateAccountStatus(AccountStatus.DRAFT);
													}}
												>
													<IonText className="text-secondary-00">Re-Submit</IonText>
												</IonCard>

												{accountDetails?.status !== AccountStatus.REJECTED && (
													<IonCard
														className="w-36 h-10 flex items-center justify-center bg-failure rounded-md cursor-pointer"
														onClick={() => {
															updateAccountStatus(AccountStatus.REJECTED);
														}}
													>
														<IonText className="text-secondary-00">Reject</IonText>
													</IonCard>
												)}
											</>
										)}

										<IonCard
											className="w-36 h-10 flex items-center justify-center border-2 border-failure rounded-md cursor-pointer"
											onClick={() => {
												navigate(Routes.MANAGE_ACCOUNTS);
											}}
										>
											<IonText className="text-failure">Cancel</IonText>
										</IonCard>
									</IonRow>
								)}

								{/* Business Content */}
								{accountDetails?.type === AccountType.BUSINESS && (
									<>
										<IonRow>
											<IonLabel className="w-full md:w-11/12 ml-3 font-bold mt-3">
												Business
											</IonLabel>
										</IonRow>

										<IonRow className="ml-3 ">
											<IonAccordion value="fifth" className="rounded-t-lg rounded-b-lg mt-2">
												<IonItem slot="header" color="light">
													{/* <IonLabel className="w-full md:w-11/12">ID Verification</IonLabel> */}
													{/*desktop view */}
													{ssmRawDocument && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Business Verification
															</IonLabel>

															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12  ${
																	ssmRawDocument?.status === VerificationStatus.PENDING
																		? 'text-primary-100'
																		: ssmRawDocument?.status === VerificationStatus.SUSPICIOUS
																		? 'text-failure'
																		: 'text-success'
																}`}
															>
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 ${
																		ssmRawDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: ssmRawDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																{ssmRawDocument?.status}
															</IonRow>
														</>
													)}

													{!ssmRawDocument && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Business Verification
															</IonLabel>
															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12 text-primary-100`}
															>
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																PENDING
															</IonRow>
														</>
													)}

													{/*mobile view */}
													{ssmRawDocument && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-3 mt-2 text-secondary-100 font-normal">
																Business Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 text-14px mb-2 ${
																		ssmRawDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: ssmRawDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																<IonText
																	className={`mb-2 text-14px font-bold ${
																		ssmRawDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: ssmRawDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																>
																	{ssmRawDocument?.status}
																</IonText>
															</IonRow>
														</div>
													)}

													{!ssmRawDocument && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-2 text-secondary-100 font-normal">
																Business Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																<IonText className="text-primary-100 text-14px font-bold">
																	PENDING
																</IonText>
															</IonRow>
														</div>
													)}
												</IonItem>

												<div className="ion-padding" slot="content">
													<IonRow>
														<IonCol size="12" size-md="7">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">SSM Document</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																<IonRow>
																	<IonRow>
																		<IonCard
																			className={`ml-0 md:ml-3 ${cardWidthClass} ${
																				ssmRawDocument ? '' : 'h-96'
																			} pb-5 p-2 border-2 border-secondary-25 rounded-lg overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00`}
																		>
																			{ssmRawDocument && (
																				<>
																					<IonRow>
																						<IonText
																							className={` ${marginWidth}  mb-5 mr-0 md:mr-0`}
																						>
																							Click here to expand
																						</IonText>
																						<IonIcon
																							icon={arrowForwardOutline}
																							className=" ml-2 h-5 w-5"
																						/>
																					</IonRow>

																					<IonIcon
																						// color="black"
																						icon={expandOutline}
																						className="absolute top-0 right-0 m-2 cursor-pointer text-primary-100 h-5 w-5"
																						onClick={() => openModal(ssmDocument, 'ssmDocument')}
																					/>
																				</>
																			)}

																			{ssmRawDocument ? (
																				ssmDocument ? (
																					// <IonImg src={ssmDocument}></IonImg>
																					<PDFViewer pdfFile={ssmDocument} popup={false} />
																				) : (
																					<>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-3/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4 mt-3"
																						></IonSkeletonText>
																					</>
																				)
																			) : (
																				'Image Not Available'
																			)}
																		</IonCard>
																	</IonRow>
																</IonRow>
															</IonCardContent>
														</IonCol>

														<IonCol size="12" size-md="5">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Extracted Data</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																{!ssmRawDocument && <IonText>SSM Not Uploaded</IonText>}

																{ssmRawDocument && (
																	<>
																		<IonRow>
																			<IonText>Reg Number:</IonText>
																		</IonRow>

																		<IonRow className=" mb-5 w-60">
																			<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																				<IonInput
																					readonly={!editMode.regNumber}
																					name="regNumber"
																					value={
																						!editMode.regNumber
																							? extractedSsmData['Reg Number']
																							: inputValues.regNumber
																					}
																					onIonChange={(e) =>
																						handleDocumentEdit('regNumber', e.detail.value)
																					}
																				/>
																				{accountDetails?.status !== AccountStatus.DRAFT && (
																					<IonIcon
																						onClick={() => {
																							setEditMode({
																								...editMode,
																								regNumber: true,
																							});
																							setInputValues((prevInputValues) => ({
																								...prevInputValues,
																								['regNumber']: extractedSsmData['Reg Number'],
																							}));
																						}}
																						slot="end"
																						icon={createOutline}
																						className="text-primary-500 cursor-pointer"
																					/>
																				)}
																			</IonItem>
																		</IonRow>
																		<IonRow>
																			<IonText>Business Name:</IonText>
																		</IonRow>

																		<IonRow className=" mb-5 w-60">
																			<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																				<IonInput
																					readonly={!editMode.businessName}
																					name="businessName"
																					value={
																						!editMode.businessName
																							? extractedSsmData['Business Name']
																							: inputValues.businessName
																					}
																					onIonChange={(e) =>
																						handleDocumentEdit('businessName', e.detail.value)
																					}
																				/>
																				{accountDetails?.status !== AccountStatus.DRAFT && (
																					<IonIcon
																						onClick={() => {
																							setEditMode({
																								...editMode,
																								businessName: true,
																							});
																							setInputValues((prevInputValues) => ({
																								...prevInputValues,
																								['businessName']: extractedSsmData['Business Name'],
																							}));
																						}}
																						slot="end"
																						icon={createOutline}
																						className="text-primary-500 cursor-pointer"
																					/>
																				)}
																			</IonItem>
																		</IonRow>
																		<IonRow>
																			<IonText>Incorporation Date:</IonText>
																		</IonRow>

																		<IonRow className=" mb-5 w-60">
																			<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																				<IonInput
																					readonly={!editMode.incorporationDate}
																					name="incorporationDate"
																					value={
																						!editMode.incorporationDate
																							? extractedSsmData['Incorporation Date']
																							: inputValues.incorporationDate
																					}
																					onIonChange={(e) =>
																						handleDocumentEdit('incorporationDate', e.detail.value)
																					}
																				/>
																				{accountDetails?.status !== AccountStatus.DRAFT && (
																					<IonIcon
																						onClick={() => {
																							setEditMode({
																								...editMode,
																								incorporationDate: true,
																							});
																							setInputValues((prevInputValues) => ({
																								...prevInputValues,
																								['incorporationDate']:
																									extractedSsmData['Incorporation Date'],
																							}));
																						}}
																						slot="end"
																						icon={createOutline}
																						className="text-primary-500 cursor-pointer"
																					/>
																				)}
																			</IonItem>
																		</IonRow>
																	</>
																)}
															</IonCardContent>
														</IonCol>

														{accountDetails?.status !== AccountStatus.DRAFT && (
															<IonCol size="12">
																<IonCardContent>
																	<IonRow className="w-full mb-5">
																		{ssmRawDocument && (
																			<label className="relative inline-flex items-center cursor-pointer">
																				<input
																					type="checkbox"
																					value=""
																					className="sr-only peer"
																					onChange={onVerifyToggleChange}
																					defaultChecked={
																						ssmRawDocument?.status === VerificationStatus.VERIFIED
																							? false
																							: true
																					}
																				/>

																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																						isVerifiedToggled
																							? 'after:rounded-l-full'
																							: 'after:rounded-r-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Verified
																				</div>
																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																						isVerifiedToggled
																							? 'after:rounded-r-full'
																							: 'after:rounded-l-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Suspicious
																				</div>
																			</label>
																		)}
																	</IonRow>

																	<IonRow>
																		<IonButton
																			onClick={() => handleDocumentSaveChanges('ssmDocument')}
																			disabled={
																				!editMode.regNumber &&
																				!editMode.businessName &&
																				!isToggleclicked &&
																				!editMode.incorporationDate
																			}
																			className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																		>
																			Save Changes
																		</IonButton>
																	</IonRow>
																</IonCardContent>
															</IonCol>
														)}
													</IonRow>
												</div>
											</IonAccordion>

											<IonAccordion value="sixth" className="rounded-t-lg rounded-b-lg mt-3">
												<IonItem slot="header" color="light">
													{/*desktop view */}
													{ssmRawDocument && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Director's Verification
															</IonLabel>

															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12  ${
																	!directorsProof ? 'text-primary-100' : 'text-success'
																}`}
															>
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 ${
																		!directorsProof ? 'text-primary-100' : 'text-success'
																	}`}
																/>
																{!directorsProof ? 'PENDING' : 'VERIFIED'}
															</IonRow>
														</>
													)}

													{!ssmRawDocument && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Director's Verification
															</IonLabel>
															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12 text-primary-100`}
															>
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																PENDING
															</IonRow>
														</>
													)}

													{/*mobile view */}
													{ssmRawDocument && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-3 mt-2 text-secondary-100 font-normal">
																Director's Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 text-14px mb-2 ${
																		!directorsProof ? 'text-primary-100' : 'text-success'
																	}`}
																/>
																<IonText
																	className={`mb-2 font-bold text-14px ${
																		!directorsProof ? 'text-primary-100' : 'text-success'
																	}`}
																>
																	{!directorsProof ? 'PENDING' : 'VERIFIED'}
																</IonText>
															</IonRow>
														</div>
													)}

													{!ssmRawDocument && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-2 text-secondary-100 font-normal">
																Director's Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																<IonText className="text-primary-100 text-14px font-bold">
																	PENDING
																</IonText>
															</IonRow>
														</div>
													)}
												</IonItem>

												<div className="ion-padding" slot="content">
													<IonRow>
														<IonCol size="12" size-md="6">
															{/* matching id */}
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Matching ID Proof's</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																{showDirectorProof && matchingIdProofs
																	? matchingIdProofs.map((directorsName, currentIdIndex) => {
																			// console.log(nonMatchingIdProofs);
																			// console.log(JSON.parse(JSON.parse(directorsName.extractedData)));
																			const matchingData = directorsName
																				? JSON.parse(JSON.parse(directorsName.extractedData))
																				: undefined;

																			return (
																				<IonCol
																					key={currentIdIndex}
																					size="12"
																					size-md="6"
																					size-lg="4"
																					className="m-0 p-0"
																				>
																					<IonCard className="bg-primary-200 mx-0 my-1 cursor-pointer">
																						<IonCardHeader
																							onClick={() =>
																								matchingToggleAccordion(currentIdIndex)
																							}
																							style={{
																								height: '60px',
																								display: 'flex',
																								justifyContent: 'center',
																								alignItems: 'center',
																								width: '100%',
																							}}
																						>
																							<div className="flex items-center justify-between w-full">
																								<div className="flex items-center flex-wrap">
																									<div className="font-normal text-secondary-100 text-center">
																										<div className="w-full mx-auto">
																											{matchingData
																												? matchingData.name
																												: 'Not-available'}
																										</div>
																									</div>
																								</div>

																								<div className="flex">
																									<IonIcon
																										icon={checkmarkCircle}
																										className="h-8 w-8 text-success"
																									></IonIcon>
																								</div>
																							</div>
																						</IonCardHeader>

																						{expandedMatchingItem === currentIdIndex && (
																							<div
																								style={{
																									display: 'flex',
																									flexDirection: 'column',
																									alignItems: 'center',
																									marginBottom: '22px',
																								}}
																							>
																								<IonRow className="md:w-full md:pl-8">
																									<div>
																										<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																											{matchingData ? (
																												matchingId0 ? (
																													<IonImg src={matchingId0}></IonImg>
																												) : (
																													<>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-2/4"
																														></IonSkeletonText>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-3/4"
																														></IonSkeletonText>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-2/4 mt-3"
																														></IonSkeletonText>
																													</>
																												)
																											) : (
																												'Not Available'
																											)}
																										</IonCard>
																										<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																											Front Side of
																											{directorsName?.docType === DocType.MYKAD
																												? ' MyKad'
																												: ' Passport'}
																										</IonLabel>
																									</div>
																									<div>
																										<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																											{matchingData ? (
																												matchingId1 ? (
																													<IonImg src={matchingId1}></IonImg>
																												) : (
																													<>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-2/4"
																														></IonSkeletonText>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-3/4"
																														></IonSkeletonText>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-2/4 mt-3"
																														></IonSkeletonText>
																													</>
																												)
																											) : (
																												'Image Not Available'
																											)}
																										</IonCard>
																										<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																											Back Side of
																											{directorsName?.docType === DocType.MYKAD
																												? ' MyKad'
																												: ' Passport'}
																										</IonLabel>
																									</div>
																								</IonRow>
																								<IonCardHeader className="w-full md:pl-10">
																									<IonCardTitle>
																										<IonText className="font-bold">
																											Extracted Data
																										</IonText>
																									</IonCardTitle>
																								</IonCardHeader>
																								<IonCardContent className="w-full md:pl-10">
																									{!matchingData && (
																										<IonText>ID Not Uploaded</IonText>
																									)}

																									{matchingData &&
																										directorsName?.docType === DocType.MYKAD && (
																											<>
																												<IonRow>
																													<IonText>Name:</IonText>
																												</IonRow>
																												<IonRow>
																													<IonText>
																														{extractedIdData['Name']}
																													</IonText>
																												</IonRow>
																												<IonRow>
																													<IonText>ID Number:</IonText>
																												</IonRow>
																												<IonRow>
																													<IonText>
																														{extractedIdData['ID Number']}
																													</IonText>
																												</IonRow>
																											</>
																										)}
																									{matchingData &&
																										directorsName?.docType === DocType.PASSPORT && (
																											<>
																												<IonRow>
																													<IonText>Name:</IonText>
																												</IonRow>
																												<IonRow>
																													<IonText>
																														{extractedIdData['Name']}
																													</IonText>
																												</IonRow>
																												<IonRow>
																													<IonText>ID Number:</IonText>
																												</IonRow>
																												<IonRow>
																													<IonText>
																														{extractedIdData['Document Number']}
																													</IonText>
																												</IonRow>
																											</>
																										)}
																								</IonCardContent>
																								<IonCardContent className="w-full md:pl-10">
																									<IonRow className="w-full mb-5">
																										{uploadedIdDocument && (
																											<label className="relative inline-flex items-center cursor-pointer">
																												<input
																													type="checkbox"
																													value=""
																													className="sr-only peer"
																													onChange={onVerifyToggleChange}
																													defaultChecked={
																														uploadedIdDocument?.status ===
																														VerificationStatus.VERIFIED
																															? false
																															: true
																													}
																												/>

																												<div
																													className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																														isVerifiedToggled
																															? 'after:rounded-l-full'
																															: 'after:rounded-r-full'
																													} text-secondary-00 flex items-center justify-center`}
																												>
																													Verified
																												</div>
																												<div
																													className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																														isVerifiedToggled
																															? 'after:rounded-r-full'
																															: 'after:rounded-l-full'
																													} text-secondary-00 flex items-center justify-center`}
																												>
																													Suspicious
																												</div>
																											</label>
																										)}
																									</IonRow>

																									<IonRow>
																										<IonButton
																											onClick={() =>
																												updateStatus(
																													uploadedIdDocument?.id,
																													uploadedIdDocument?.__typename,
																													isVerifiedToggled
																														? VerificationStatus.VERIFIED
																														: VerificationStatus.SUSPICIOUS,
																												)
																											}
																											disabled={!isToggleclicked}
																											className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																										>
																											Save Changes
																										</IonButton>
																									</IonRow>
																								</IonCardContent>
																							</div>
																						)}
																					</IonCard>
																				</IonCol>
																			);
																	  })
																	: 'Not Availablee'}
															</IonCardContent>
														</IonCol>

														<IonCol size="12" size-md="6">
															{/* nonmatching id */}
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Non-Matching ID Proof's</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																{showDirectorProof && nonMatchingIdProofs
																	? nonMatchingIdProofs.map((directorsName, currentIdIndex) => {
																			// console.log(nonMatchingIdProofs);
																			// console.log(JSON.parse(JSON.parse(directorsName.extractedData)));
																			const nonMatchData = directorsName
																				? JSON.parse(JSON.parse(directorsName.extractedData))
																				: undefined;
																			// console.log(nonMatchData);
																			return (
																				<IonCol
																					key={currentIdIndex}
																					size="12"
																					size-md="6"
																					size-lg="4"
																					className="m-0 p-0"
																				>
																					<IonCard className="bg-primary-200 mx-0 my-1 cursor-pointer">
																						<IonCardHeader
																							onClick={() =>
																								nonMatchingToggleAccordion(currentIdIndex)
																							}
																							style={{
																								height: '60px',
																								display: 'flex',
																								justifyContent: 'center',
																								alignItems: 'center',
																								width: '100%',
																								cursor: 'pointer',
																							}}
																						>
																							<div className="flex items-center justify-between w-full">
																								<div className="flex items-center flex-wrap">
																									<div className="font-normal text-secondary-100 text-center">
																										<div className="w-full mx-auto">
																											{nonMatchData
																												? nonMatchData.name
																												: 'Not-available'}
																										</div>
																									</div>
																								</div>

																								<div className="flex">
																									<IonIcon
																										icon={alertCircle}
																										className="h-8 w-8 text-pending"
																									></IonIcon>
																								</div>
																							</div>
																						</IonCardHeader>

																						{expandedNonMatchingItem === currentIdIndex && (
																							<div
																								style={{
																									display: 'flex',
																									flexDirection: 'column',
																									alignItems: 'center',
																									marginBottom: '22px',
																								}}
																							>
																								<IonRow className="md:w-full md:pl-8">
																									<div>
																										<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																											{nonMatchData ? (
																												nonMatchingId0 ? (
																													<IonImg
																														onClick={() =>
																															openModal(
																																nonMatchingId0,
																																'nonMatchingId0',
																															)
																														}
																														src={nonMatchingId0}
																													></IonImg>
																												) : (
																													<>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-2/4"
																														></IonSkeletonText>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-3/4"
																														></IonSkeletonText>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-2/4 mt-3"
																														></IonSkeletonText>
																													</>
																												)
																											) : (
																												'Image Not Available'
																											)}
																										</IonCard>
																										<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																											Front Side of
																											{directorsName?.docType === DocType.MYKAD
																												? ' MyKad'
																												: ' Passport'}
																										</IonLabel>
																									</div>
																									<div>
																										<IonCard className="w-64 h-40 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																											{nonMatchData ? (
																												nonMatchingId1 ? (
																													<IonImg
																														onClick={() =>
																															openModal(
																																nonMatchingId1,
																																'nonMatchingId1',
																															)
																														}
																														src={nonMatchingId1}
																													></IonImg>
																												) : (
																													<>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-2/4"
																														></IonSkeletonText>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-3/4"
																														></IonSkeletonText>
																														<IonSkeletonText
																															animated
																															className="bg-primary-500 w-2/4 mt-3"
																														></IonSkeletonText>
																													</>
																												)
																											) : (
																												'Image Not Available'
																											)}
																										</IonCard>
																										<IonLabel className="text-16px w-64 flex items-center justify-center text-secondary-75 font-normal">
																											Back Side of
																											{directorsName?.docType === DocType.MYKAD
																												? ' MyKad'
																												: ' Passport'}
																										</IonLabel>
																									</div>
																								</IonRow>
																								<IonCardHeader className="w-full md:pl-10">
																									<IonCardTitle>
																										<IonText className="font-bold">
																											Extracted Data
																										</IonText>
																									</IonCardTitle>
																								</IonCardHeader>
																								<IonCardContent className="w-full md:pl-10">
																									{!nonMatchData && (
																										<IonText>ID Not Uploaded</IonText>
																									)}

																									{nonMatchData &&
																										directorsName?.docType === DocType.MYKAD && (
																											<>
																												<IonRow>
																													<IonText>Name:</IonText>
																												</IonRow>
																												{/* <IonRow>
																												<IonText>{extractedIdData['Name']}</IonText>
																											</IonRow> */}
																												<IonRow className=" mb-5 w-60">
																													<IonItem
																														className="mt-2.5 app-input-filed w-80"
																														lines="none"
																													>
																														<IonInput
																															readonly={
																																!editMode.nonMatchingDirectorName
																															}
																															name="nonMatchingDirectorName"
																															value={
																																!editMode.nonMatchingDirectorName
																																	? extractedIdData['Name']
																																	: inputValues.nonMatchingDirectorName
																															}
																															onIonChange={(e) =>
																																handleDocumentEdit(
																																	'nonMatchingDirectorName',
																																	e.detail.value,
																																)
																															}
																														/>
																														<IonIcon
																															onClick={() => {
																																setEditMode({
																																	...editMode,
																																	nonMatchingDirectorName: true,
																																});
																																setInputValues(
																																	(prevInputValues) => ({
																																		...prevInputValues,
																																		['nonMatchingDirectorName']:
																																			extractedIdData['Name'],
																																	}),
																																);
																															}}
																															slot="end"
																															icon={createOutline}
																															className="text-primary-500 cursor-pointer"
																														/>
																													</IonItem>
																												</IonRow>

																												<IonRow>
																													<IonText>ID Number:</IonText>
																												</IonRow>
																												{/* <IonRow>
																												<IonText>
																													{extractedIdData['ID Number']}
																												</IonText>
																											</IonRow> */}
																												<IonRow className=" mb-5 w-60">
																													<IonItem
																														className="mt-2.5 app-input-filed w-80"
																														lines="none"
																													>
																														<IonInput
																															readonly={
																																!editMode.nonMatchingDirectorNumber
																															}
																															name="nonMatchingDirectorNumber"
																															value={
																																!editMode.nonMatchingDirectorNumber
																																	? extractedIdData['ID Number']
																																	: inputValues.nonMatchingDirectorNumber
																															}
																															onIonChange={(e) =>
																																handleDocumentEdit(
																																	'nonMatchingDirectorNumber',
																																	e.detail.value,
																																)
																															}
																														/>
																														<IonIcon
																															onClick={() => {
																																setEditMode({
																																	...editMode,
																																	nonMatchingDirectorNumber: true,
																																});
																																setInputValues(
																																	(prevInputValues) => ({
																																		...prevInputValues,
																																		['nonMatchingDirectorNumber']:
																																			extractedIdData['ID'],
																																	}),
																																);
																															}}
																															slot="end"
																															icon={createOutline}
																															className="text-primary-500 cursor-pointer"
																														/>
																													</IonItem>
																												</IonRow>
																											</>
																										)}
																									{nonMatchData &&
																										directorsName?.docType === DocType.PASSPORT && (
																											<>
																												<IonRow>
																													<IonText>Name:</IonText>
																												</IonRow>
																												{/* <IonRow>
																												<IonText>{extractedIdData['Name']}</IonText>
																											</IonRow> */}
																												<IonRow className=" mb-5 w-60">
																													<IonItem
																														className="mt-2.5 app-input-filed w-80"
																														lines="none"
																													>
																														<IonInput
																															readonly={
																																!editMode.nonMatchingDirectorName
																															}
																															name="nonMatchingDirectorName"
																															value={
																																!editMode.nonMatchingDirectorName
																																	? extractedIdData['Name']
																																	: inputValues.nonMatchingDirectorName
																															}
																															onIonChange={(e) =>
																																handleDocumentEdit(
																																	'nonMatchingDirectorName',
																																	e.detail.value,
																																)
																															}
																														/>
																														<IonIcon
																															onClick={() => {
																																setEditMode({
																																	...editMode,
																																	nonMatchingDirectorName: true,
																																});
																																setInputValues(
																																	(prevInputValues) => ({
																																		...prevInputValues,
																																		['nonMatchingDirectorName']:
																																			extractedIdData['Name'],
																																	}),
																																);
																															}}
																															slot="end"
																															icon={createOutline}
																															className="text-primary-500 cursor-pointer"
																														/>
																													</IonItem>
																												</IonRow>

																												<IonRow>
																													<IonText>ID Number:</IonText>
																												</IonRow>
																												{/* <IonRow>
																												<IonText>
																													{extractedIdData['Document Number']}
																												</IonText>
																											</IonRow> */}
																												<IonRow className=" mb-5 w-60">
																													<IonItem
																														className="mt-2.5 app-input-filed w-80"
																														lines="none"
																													>
																														<IonInput
																															readonly={
																																!editMode.nonMatchingDirectorNumber
																															}
																															name="nonMatchingDirectorNumber"
																															value={
																																!editMode.nonMatchingDirectorNumber
																																	? extractedIdData[
																																			'Document Number'
																																	  ]
																																	: inputValues.nonMatchingDirectorNumber
																															}
																															onIonChange={(e) =>
																																handleDocumentEdit(
																																	'nonMatchingDirectorNumber',
																																	e.detail.value,
																																)
																															}
																														/>
																														<IonIcon
																															onClick={() => {
																																setEditMode({
																																	...editMode,
																																	nonMatchingDirectorNumber: true,
																																});
																																setInputValues(
																																	(prevInputValues) => ({
																																		...prevInputValues,
																																		['nonMatchingDirectorNumber']:
																																			extractedIdData[
																																				'Document Number'
																																			],
																																	}),
																																);
																															}}
																															slot="end"
																															icon={createOutline}
																															className="text-primary-500 cursor-pointer"
																														/>
																													</IonItem>
																												</IonRow>
																											</>
																										)}
																								</IonCardContent>
																								<IonCardContent className="w-full md:pl-10">
																									{/* <IonRow className="w-full mb-5">
																								{uploadedIdDocument && (
																									<label className="relative inline-flex items-center cursor-pointer">
																										<input
																											type="checkbox"
																											value=""
																											className="sr-only peer"
																											onChange={onVerifyToggleChange}
																											defaultChecked={
																												uploadedIdDocument?.status ===
																												VerificationStatus.VERIFIED
																													? false
																													: true
																											}
																										/>

																										<div
																											className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																												isVerifiedToggled
																													? 'after:rounded-l-full'
																													: 'after:rounded-r-full'
																											} text-secondary-00 flex items-center justify-center`}
																										>
																											Verified
																										</div>
																										<div
																											className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																												isVerifiedToggled
																													? 'after:rounded-r-full'
																													: 'after:rounded-l-full'
																											} text-secondary-00 flex items-center justify-center`}
																										>
																											Suspicious
																										</div>
																									</label>
																								)}
																							</IonRow> */}

																									<IonRow>
																										<IonButton
																											onClick={() =>
																												handleDirectorIdProofChanges(currentIdIndex)
																											}
																											disabled={
																												!editMode.nonMatchingDirectorName &&
																												!editMode.nonMatchingDirectorNumber
																											}
																											className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																										>
																											Save Changes
																										</IonButton>
																									</IonRow>
																								</IonCardContent>
																							</div>
																						)}
																					</IonCard>
																				</IonCol>
																			);
																	  })
																	: 'Not Availablee'}
															</IonCardContent>
														</IonCol>
													</IonRow>
												</div>
											</IonAccordion>

											<IonAccordion value="seventh" className="rounded-t-lg rounded-b-lg mt-3">
												<IonItem slot="header" color="light">
													{/* <IonLabel className="w-full md:w-11/12">ID Verification</IonLabel> */}
													{/*desktop view */}
													{bankAccountData && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Bank Account Verification
															</IonLabel>

															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12  ${
																	bankAccountData?.status === VerificationStatus.PENDING
																		? 'text-primary-100'
																		: bankAccountData?.status === VerificationStatus.SUSPICIOUS
																		? 'text-failure'
																		: 'text-success'
																}`}
															>
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 ${
																		bankAccountData?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: bankAccountData?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																{bankAccountData?.status}
															</IonRow>
														</>
													)}

													{!bankAccountData && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Bank Account Verification
															</IonLabel>
															<IonRow
																className={`w-full flex justify-end items-end md:w-2/12 text-primary-100 font-bold`}
															>
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																PENDING
															</IonRow>
														</>
													)}

													{/*mobile view */}
													{bankAccountData && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-3 mt-2 text-secondary-100 font-normal">
																Bank Account Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 text-14px mb-2 ${
																		bankAccountData?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: bankAccountData?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																<IonText
																	className={`mb-2 text-14px font-bold ${
																		bankAccountData?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: bankAccountData?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																>
																	{bankAccountData?.status}
																</IonText>
															</IonRow>
														</div>
													)}

													{!bankAccountData && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-2 text-secondary-100 font-normal">
																Bank Account Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																<IonText className="text-primary-100 text-14px font-bold">
																	PENDING
																</IonText>
															</IonRow>
														</div>
													)}
												</IonItem>

												<div className="ion-padding" slot="content">
													<IonRow>
														<IonCol size="12" size-md="3">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Account Number</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																<>
																	<IonRow>
																		<IonText> From Merchant: </IonText>
																	</IonRow>

																	<IonRow className="mb-5 w-60">
																		<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																			<IonInput
																				readonly={!editMode.accountNumber}
																				name="accountNumber"
																				value={
																					!editMode.accountNumber
																						? bankAccountData?.accountNumber
																						: inputValues.accountNumber
																				}
																				onIonChange={(e) =>
																					handleDocumentEdit('accountNumber', e.detail.value)
																				}
																			/>
																			{accountDetails?.status !== AccountStatus.DRAFT && (
																				<IonIcon
																					onClick={() => {
																						setEditMode({
																							...editMode,
																							accountNumber: true,
																						});
																						setInputValues((prevInputValues) => ({
																							...prevInputValues,
																							['accountNumber']:
																								bankAccountData?.accountNumber ?? '',
																						}));
																					}}
																					slot="end"
																					icon={createOutline}
																					className="text-primary-500 cursor-pointer"
																				/>
																			)}
																		</IonItem>
																	</IonRow>

																	<IonRow>
																		<IonText>From CTOS: </IonText>
																	</IonRow>

																	<IonRow>
																		<IonText>Data Not Available</IonText>
																	</IonRow>
																</>
															</IonCardContent>
														</IonCol>

														<IonCol size="12" size-md="3">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Account Holder Name</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																<>
																	<IonRow>
																		<IonText> From Merchant: </IonText>
																	</IonRow>
																	{/* <IonRow>
																<IonText>
																	{bankAccountData?.accountHolderName
																		? bankAccountData?.accountHolderName
																		: 'Not Available'}
																</IonText>
															</IonRow> */}
																	<IonRow className="mb-5 w-60">
																		<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																			<IonInput
																				readonly={!editMode.accountHolderName}
																				name="accountHolderName"
																				value={
																					!editMode.accountHolderName
																						? bankAccountData?.accountHolderName
																						: inputValues.accountHolderName
																				}
																				onIonChange={(e) =>
																					handleDocumentEdit('accountHolderName', e.detail.value)
																				}
																			/>
																			{accountDetails?.status !== AccountStatus.DRAFT && (
																				<IonIcon
																					onClick={() => {
																						setEditMode({
																							...editMode,
																							accountHolderName: true,
																						});
																						setInputValues((prevInputValues) => ({
																							...prevInputValues,
																							['accountHolderName']:
																								bankAccountData?.accountHolderName ?? '',
																						}));
																					}}
																					slot="end"
																					icon={createOutline}
																					className="text-primary-500 cursor-pointer"
																				/>
																			)}
																		</IonItem>
																	</IonRow>
																	<IonRow>
																		<IonText>From CTOS: </IonText>
																	</IonRow>
																	<IonRow>
																		<IonText>Data Not Available</IonText>
																	</IonRow>
																</>
															</IonCardContent>
														</IonCol>

														<IonCol size="12" size-md="3">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Bank Name</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																<>
																	<IonRow>
																		<IonText> From Merchant: </IonText>
																	</IonRow>
																	{/* <IonRow>
																<IonText>{bankAccountData?.bankName}</IonText>
															</IonRow> */}
																	<IonRow className="mb-5 w-60">
																		<IonItem className="mt-2.5 app-input-filed w-80" lines="none">
																			<IonInput
																				readonly={!editMode.bankName}
																				name="bankName"
																				value={
																					!editMode.bankName
																						? bankAccountData?.bankName
																						: inputValues.bankName
																				}
																				onIonChange={(e) =>
																					handleDocumentEdit('bankName', e.detail.value)
																				}
																			/>
																			{accountDetails?.status !== AccountStatus.DRAFT && (
																				<IonIcon
																					onClick={() => {
																						setEditMode({
																							...editMode,
																							bankName: true,
																						});
																						setInputValues((prevInputValues) => ({
																							...prevInputValues,
																							['bankName']: bankAccountData?.bankName ?? '',
																						}));
																					}}
																					slot="end"
																					icon={createOutline}
																					className="text-primary-500 cursor-pointer"
																				/>
																			)}
																		</IonItem>
																	</IonRow>
																	<IonRow>
																		<IonText>From CTOS: </IonText>
																	</IonRow>
																	<IonRow>
																		<IonText>Data Not Available</IonText>
																	</IonRow>
																</>
															</IonCardContent>
														</IonCol>

														{accountDetails?.status !== AccountStatus.DRAFT && (
															<IonCol size="12">
																<IonCardContent>
																	<IonRow className="w-full mb-5">
																		{bankAccountData && (
																			<label className="relative inline-flex items-center cursor-pointer">
																				<input
																					type="checkbox"
																					value=""
																					className="sr-only peer"
																					onChange={onVerifyToggleChange}
																					defaultChecked={
																						bankAccountData?.status === VerificationStatus.VERIFIED
																							? false
																							: true
																					}
																				/>

																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																						isVerifiedToggled
																							? 'after:rounded-l-full'
																							: 'after:rounded-r-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Verified
																				</div>
																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																						isVerifiedToggled
																							? 'after:rounded-r-full'
																							: 'after:rounded-l-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Suspicious
																				</div>
																			</label>
																		)}
																	</IonRow>

																	<IonRow>
																		<IonButton
																			onClick={() => handleDocumentSaveChanges('bankAccountData')}
																			disabled={
																				!editMode.bankName &&
																				!editMode.accountNumber &&
																				!isToggleclicked &&
																				!editMode.accountHolderName
																			}
																			className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																		>
																			Save Changes
																		</IonButton>
																	</IonRow>
																</IonCardContent>
															</IonCol>
														)}
													</IonRow>
												</div>
											</IonAccordion>

											{/* CTOS */}
											{/* TODO: need to change for CTOS Report fetch is failing  and update in DB so can change it to verify*/}
											<IonAccordion value="eighth" className="rounded-t-lg rounded-b-lg mt-2">
												<IonItem slot="header" color="light">
													{ctosDocument && !isMobile && (
														<>
															<IonLabel className="w-full text-16px md:w-10/12 text-secondary-100 font-normal">
																CTOS Verification
															</IonLabel>

															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12  ${
																	ctosDocument?.status === VerificationStatus.PENDING
																		? 'text-primary-100'
																		: ctosDocument?.status === VerificationStatus.SUSPICIOUS
																		? 'text-failure'
																		: 'text-success'
																}`}
															>
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 ${
																		ctosDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: ctosDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																{ctosDocument?.status}
															</IonRow>
														</>
													)}

													{!ctosDocument && !isMobile && (
														<>
															<IonLabel className="w-full text-16px md:w-10/12 text-secondary-100 font-normal">
																CTOS Verification
															</IonLabel>
															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12 text-primary-100`}
															>
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																PENDING
															</IonRow>
														</>
													)}

													{ctosDocument && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-3 text-16px mt-2 text-secondary-100 font-normal">
																CTOS Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 text-14px mb-2 ${
																		ctosDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: ctosDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																<IonText
																	className={`mb-2 text-14px font-bold ${
																		ctosDocument?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: ctosDocument?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																>
																	{ctosDocument?.status}
																</IonText>
															</IonRow>
														</div>
													)}

													{!ctosDocument && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-2 text-16px text-secondary-100 font-normal">
																CTOS Verification
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																<IonText className="text-primary-100 text-14px font-bold">
																	PENDING
																</IonText>
															</IonRow>
														</div>
													)}
												</IonItem>

												<div className="ion-padding" slot="content">
													<IonRow>
														<IonCol size="12" size-md="7">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold text-16px">CTOS Document</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																<IonRow>
																	<IonRow>
																		<IonCard className="w-60 h-96 ml-0 border-2 border-primary-50 rounded-lg relative overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																			{ctosDocument ? (
																				ctosDocument0 ? (
																					<IonImg src={ctosDocument0}></IonImg>
																				) : (
																					<>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-3/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4 mt-3"
																						></IonSkeletonText>
																					</>
																				)
																			) : (
																				'Credit Report Not Available'
																			)}
																		</IonCard>
																	</IonRow>
																</IonRow>
															</IonCardContent>
														</IonCol>

														<IonCol size="12" size-md="5">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Extracted Data</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																{!ctosDocument && <IonText>Credit Report Not-available</IonText>}

																{ctosDocument && (
																	<>
																		<IonRow>
																			<IonText>SME Score:</IonText>
																		</IonRow>
																		<IonRow>
																			<IonText>500</IonText>
																		</IonRow>
																	</>
																)}
															</IonCardContent>
														</IonCol>
													</IonRow>
												</div>
											</IonAccordion>

											{/* Auth Letter */}
											<IonAccordion value="ninth" className="rounded-t-lg rounded-b-lg mt-2">
												<IonItem slot="header" color="light">
													{businessAuthLetter && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Authorization
															</IonLabel>

															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12  ${
																	businessAuthLetter?.status === VerificationStatus.PENDING
																		? 'text-primary-100'
																		: businessAuthLetter?.status === VerificationStatus.SUSPICIOUS
																		? 'text-failure'
																		: 'text-success'
																}`}
															>
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 ${
																		businessAuthLetter?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: businessAuthLetter?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																{businessAuthLetter?.status}
															</IonRow>
														</>
													)}

													{!businessAuthLetter && !isMobile && (
														<>
															<IonLabel className="w-full md:w-10/12 text-secondary-100 font-normal">
																Authorization
															</IonLabel>
															<IonRow
																className={`w-full flex font-bold justify-end items-end md:w-2/12 text-primary-100`}
															>
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																PENDING
															</IonRow>
														</>
													)}

													{businessAuthLetter && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-3 mt-2 text-secondary-100 font-normal">
																Authorization
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon
																	icon={ellipse}
																	className={`mr-2 text-14px mb-2 ${
																		businessAuthLetter?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: businessAuthLetter?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																/>
																<IonText
																	className={`mb-2 text-14px font-bold ${
																		businessAuthLetter?.status === VerificationStatus.PENDING
																			? 'text-primary-100'
																			: businessAuthLetter?.status === VerificationStatus.SUSPICIOUS
																			? 'text-failure'
																			: 'text-success'
																	}`}
																>
																	{businessAuthLetter?.status}
																</IonText>
															</IonRow>
														</div>
													)}

													{!businessAuthLetter && isMobile && (
														<div className="text-center">
															<IonLabel className="mb-2 text-secondary-100 font-normal">
																Authorization
															</IonLabel>
															<IonRow className="flex items-center">
																<IonIcon icon={ellipse} className={`mr-2 text-primary-100`} />
																<IonText className="text-primary-100 text-14px font-bold">
																	PENDING
																</IonText>
															</IonRow>
														</div>
													)}
												</IonItem>

												<div className="ion-padding" slot="content">
													<IonRow>
														<IonCol size="12" size-md="7">
															<IonCardHeader>
																<IonCardTitle>
																	<IonText className="font-bold">Authorization</IonText>
																</IonCardTitle>
															</IonCardHeader>
															<IonCardContent>
																<IonRow>
																	<IonRow>
																		<IonCard
																			className={`ml-0 md:ml-3 ${cardWidthClass} ${
																				ssmRawDocument ? '' : 'h-96'
																			}  ${
																				businessAuthLetter ? '' : 'h-96'
																			} pb-5 p-2 border-2 border-secondary-25 rounded-lg overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00`}
																		>
																			{businessAuthLetter && (
																				<>
																					<IonRow>
																						<IonText className={` ${marginWidth}  mb-5 mr-0 `}>
																							Click here to expand
																						</IonText>
																						<IonIcon
																							icon={arrowForwardOutline}
																							className=" ml-2 h-5 w-5"
																						/>
																					</IonRow>

																					<IonIcon
																						// color="black"
																						icon={expandOutline}
																						className="absolute top-0 right-0 m-2 cursor-pointer text-primary-100 h-5 w-5"
																						onClick={() =>
																							openModal(businessAuthLetterDocument, 'authLetter')
																						}
																					/>
																				</>
																			)}

																			{businessAuthLetter ? (
																				businessAuthLetter ? (
																					// <IonImg src={businessAuthLetter}></IonImg>
																					<PDFViewer
																						pdfFile={businessAuthLetterDocument}
																						popup={false}
																					/>
																				) : (
																					<>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-3/4"
																						></IonSkeletonText>
																						<IonSkeletonText
																							animated
																							className="bg-primary-500 w-2/4 mt-3"
																						></IonSkeletonText>
																					</>
																				)
																			) : (
																				'Auth Not Available'
																			)}
																		</IonCard>
																	</IonRow>
																</IonRow>
															</IonCardContent>
														</IonCol>

														<IonCol size="12" size-md="5">
															<IonCardContent>
																{!businessAuthLetter && <IonText>Auth Letter Not Uploaded</IonText>}
															</IonCardContent>
														</IonCol>

														{accountDetails?.status !== AccountStatus.DRAFT && (
															<IonCol size="12">
																<IonCardContent>
																	<IonRow className="w-full mb-5">
																		{businessAuthLetter && (
																			<label className="relative inline-flex items-center cursor-pointer">
																				<input
																					type="checkbox"
																					value=""
																					className="sr-only peer"
																					onChange={onVerifyToggleChange}
																					defaultChecked={
																						businessAuthLetter?.status ===
																						VerificationStatus.VERIFIED
																							? false
																							: true
																					}
																				/>

																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-l-md bg-primary-100 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-gray-300 ${
																						isVerifiedToggled
																							? 'after:rounded-l-full'
																							: 'after:rounded-r-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Verified
																				</div>
																				<div
																					className={`w-24 md:w-88px h-8 text-12px rounded-r-md bg-gray-200 peer-focus:outline-none peer dark:bg-secondary-50 peer-checked:after:translate-x-full  after:content-[''] after:absolute  after:h-10 after:w-20 after:transition-all dark:border-gray-600 peer-checked:bg-primary-100 ${
																						isVerifiedToggled
																							? 'after:rounded-r-full'
																							: 'after:rounded-l-full'
																					} text-secondary-00 flex items-center justify-center`}
																				>
																					Suspicious
																				</div>
																			</label>
																		)}
																	</IonRow>

																	<IonRow>
																		<IonButton
																			onClick={() => handleDocumentSaveChanges('authLetter')}
																			disabled={!isToggleclicked}
																			className="lg:w-40 capitalize bg-primary-100 rounded-3xl text-secondary-00 flex items-center justify-center"
																		>
																			Save Changes
																		</IonButton>
																	</IonRow>
																</IonCardContent>
															</IonCol>
														)}
													</IonRow>
												</div>
											</IonAccordion>
										</IonRow>

										<IonRow>
											{accountDetails?.status !== AccountStatus.DRAFT && (
												<>
													<IonCard
														className={`w-36 h-10 ${
															!isAllVerified() ? 'opacity-50' : ''
														} flex items-center justify-center bg-success rounded-md cursor-pointer`}
														disabled={!isAllVerified()}
														onClick={() => {
															updateAccountStatus(AccountStatus.ACTIVE);
														}}
													>
														<IonText className="text-secondary-00">Accept</IonText>
													</IonCard>

													<IonCard
														className="w-36 h-10 flex items-center justify-center bg-primary-100 rounded-md cursor-pointer"
														onClick={() => {
															updateAccountStatus(AccountStatus.DRAFT);
														}}
													>
														<IonText className="text-secondary-00">Re-Submit</IonText>
													</IonCard>

													<IonCard
														className="w-36 h-10 flex items-center justify-center bg-failure rounded-md cursor-pointer"
														onClick={() => {
															updateAccountStatus(AccountStatus.REJECTED);
														}}
													>
														<IonText className="text-secondary-00">Reject</IonText>
													</IonCard>
												</>
											)}

											<IonCard
												className="w-36 h-10 flex items-center justify-center border-2 border-failure rounded-md cursor-pointer"
												onClick={() => {
													navigate(Routes.MANAGE_ACCOUNTS);
												}}
											>
												<IonText className="text-failure">Cancel</IonText>
											</IonCard>
										</IonRow>
									</>
								)}
							</IonAccordionGroup>
						</div>
					</div>
				</div>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</section>
	);
}
