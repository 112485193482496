const axios = require('axios');
const { PDFDocument } = require('pdf-lib')


export async function pdfSplitter(pdfDetails) {
  // console.log(pdfDetails)
  // console.log(await pdfDetails[0].arrayBuffer())
  const pdfDoc = await PDFDocument.load(await pdfDetails[0].arrayBuffer(), { ignoreEncryption: true });
  const pageCount = pdfDoc.getPages()
  // console.log(pageCount)
  const copiedPages: File[] = [];
  for (let i = 0; i < pageCount.length; i++) {
    const newPdfDoc = await PDFDocument.create();
    const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [i]);
    newPdfDoc.addPage(copiedPage);
    const newPdfBytes = await newPdfDoc.save();
    copiedPages.push(newPdfBytes)
  }
  console.log(copiedPages)
  return copiedPages;
}

export async function downloadPdf(url) {
  const response = await axios.get(url, { responseType: 'arraybuffer' });
  return response.data;
}

export async function mergePdfFromUrls(pdfUrls) {
  const pdfBytesArray = await Promise.all(pdfUrls.map(downloadPdf));
  const mergedPdfBytes = await pdfMerger(pdfBytesArray);
  return mergedPdfBytes;
}


export async function pdfMerger(pagesArray) {
  const mergedPdfDoc = await PDFDocument.create();

  for (const pdfBytes of pagesArray) {
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const pageCount = pdfDoc.getPageCount();

    for (let i = 0; i < pageCount; i++) {
      const [copiedPage] = await mergedPdfDoc.copyPages(pdfDoc, [i]);
      mergedPdfDoc.addPage(copiedPage);
    }
  }

  const mergedPdfBytes = await mergedPdfDoc.save();
  return mergedPdfBytes;
}





