import {
	IonButton,
	IonCard,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonInput,
	IonItem,
	IonLabel,
	IonRow,
	IonSpinner,
	IonText,
	useIonToast,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountType, DocType, DocCategory, VerificationStatus } from 'API';
import DocumentApi from 'api/document';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { pdfSplitter } from 'utils/pdf-helper';
import { Routes } from 'utils/routes';
import PDFViewer from 'components/PDFViwer';
import RinngitPayLoader from 'components/RinngitPayLoader';
import AccountApi from 'api/account';
import { mergePdfFromUrls } from 'utils/pdf-helper';
import { removeTypenameFromS3 } from 'utils/object-helper';

export default function SsmDocumentSubmission() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const [showToast] = useIonToast();
	const fileTypes = ['PDF'];
	const document0 = useRef<HTMLInputElement>(null);
	let [imageIndex, setImageIndex] = useState<number>(0);
	const [fileChange, setFileChange] = useState<boolean>(false);
	const isAdmin = appContext.userDetails.signInUserSession.idToken.payload['cognito:groups'] || [];
	const accountDetails = appContext.selectedAccount();
	const [isLoading, setIsLoading] = useState(false);
	const [imageUrlArray, setImageUrlArray] = useState<string[]>([]);
	const [ssmArray, setSsmArray] = useState<string[]>([]);
	const [ssmUserInput, setSsmUserInput] = useState<boolean>(false);
	const [encryptedBusinessName, setEncryptedBusinessName] = useState('');
	const [encryptedRegNo, setEncryptedRegNo] = useState('');

	const docType = DocType.SSM;
	const validationSchema = Yup.object({
		documentType: Yup.string().required(),
		docCategory: Yup.string().required(),
		document0: Yup.string(),
	});

	let formik = useFormik({
		initialValues: {
			documentType: docType,
			docCategory: DocCategory.BUSINESS,
			document0: undefined as any,
		},
		validationSchema: validationSchema,

		onSubmit: (values, { setSubmitting }) => {
			fileChange
				? onNext(values, setSubmitting)
				: navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_ID_SUBMISSION_FOR_BUSINESS, {
						state: { id: accountDetails?.id, page: 4 },
				  });
		},
	});

	useEffect(() => {
		console.log('SSM Submission Page for Business');
		console.log('Selected Account: ', accountDetails);
		formik.setFieldValue('documentType', docType);
		let document = accountDetails.referenceDocs?.items?.filter((obj) => obj.docType === docType);
		console.log('Uploaded SSM Document: ', document && document[0]);
		// document[0] && loadPdfFile(document && document[0]);
		loadImagefromS3(document && document[0]);
	}, []);

	async function getAccount() {
		console.log();
		setIsLoading(true);
		const getAccountDetails = await AccountApi.getByUser(
			appContext.isAdmin ? accountDetails?.owner : appContext.userDetails?.username,
		).then((accounts) => {
			setIsLoading(false);
			return accounts;
		});

		const selectedAccount = getAccountDetails.find((account) => {
			return account.id === appContext.selectedAccount().id;
		});
		console.log('--------', selectedAccount);
		appContext.setSelectedAccount(selectedAccount);
		return selectedAccount;
	}

	const initialDocument = async () => {
		const ssmDocument = await setUplaodDocument();
		const idDocument = uploadedDocument() || {};
		idDocument.docType = docType;
		idDocument.docCategory = DocCategory.BUSINESS;
		idDocument.accountId = accountDetails?.id;
		idDocument.updatedBy = appContext.userDetails.username;
		idDocument.owner = accountDetails.owner;
		idDocument.status = VerificationStatus.PENDING;
		idDocument.pages = (idDocument.pages || []).map((page) => removeTypenameFromS3(page));
		const createDocument = await DocumentApi.save(idDocument);
		// console.log('Create Document: ', createDocument);
		return { createDocument, ssmDocument };
	};

	const deleteExistingDocument = async (idDocument) => {
		const deletePath = idDocument.pages[0].key.replace('/originalPdf', '/');
		console.log('Folder Path: ', deletePath);
		await DocumentApi.deleteS3Object(deletePath);
	};

	const deleteExistingDirectorIdProofs = async () => {
		try {
			const uploadDirectorIds = await uploadedIds();
			console.log(uploadDirectorIds);

			await Promise.all(
				uploadDirectorIds.map(async (uploadDirectorId) => {
					const deletePath = uploadDirectorId.pages[0].key.replace('/0', '/');
					console.log('Folder Path: ', deletePath);
					await Promise.all([
						DocumentApi.deleteS3Object(deletePath),
						DocumentApi.deleteDocument({ id: uploadDirectorId.id }),
					]);
				}),
			);
		} catch (error) {
			console.error('Error occurred while deleting director ID proofs:', error);
		}
	};

	const handleOrginalPdfUpload = async (idDocument) => {
		console.log('handleOrginalPdfUpload: ', idDocument.createDocument);
		try {
			if (fileChange && uploadedDocument()) {
				await deleteExistingDocument(idDocument.createDocument);
				idDocument.createDocument.pages = [];
				await deleteExistingDirectorIdProofs();
			}

			await DocumentApi.upload(
				idDocument.createDocument,
				idDocument.ssmDocument,
				idDocument.createDocument.id,
				true,
			);
			idDocument.createDocument.id = idDocument.createDocument.id;
			const updateOrginalPdf = await DocumentApi.save(idDocument.createDocument);
			console.log(updateOrginalPdf.pages);

			return updateOrginalPdf;
		} catch (error) {
			console.error('Failed handling document upload', error);
			throw error;
		}
	};

	const handleSplittedDocumentUpload = async (idDocument) => {
		console.log('handleSplittedDocumentUpload: ', idDocument.createDocument);
		try {
			const splittedPages = await pdfSplitter(idDocument.ssmDocument);
			await DocumentApi.upload(
				idDocument.createDocument,
				splittedPages,
				idDocument.createDocument.id,
				false,
			);
			idDocument.createDocument.id = idDocument.createDocument.id;
			const updateDocumentSplitted = await DocumentApi.save(idDocument.createDocument);
			console.log(updateDocumentSplitted.pages);

			return updateDocumentSplitted;
		} catch (error) {
			console.error('Failed handling splitted document upload', error);
			throw error;
		}
	};

	const combineOriginalAndSplittedPages = (updateOrginalPdf, updateDocumentSplitted) => {
		const updateOrginalPdfPages = updateOrginalPdf?.pages || [];
		const updateDocumentSplittedPages = updateDocumentSplitted?.pages || [];
		return [...updateOrginalPdfPages, ...updateDocumentSplittedPages];
	};

	const updateIdDocumentWithCombinedPages = async (idDocument, combinedPages) => {
		try {
			idDocument.createDocument.pages = combinedPages.map((page) => removeTypenameFromS3(page));
			const updatedDocument = await DocumentApi.save(idDocument.createDocument);
			console.log(updatedDocument);
			return updatedDocument;
		} catch (error) {
			console.error('Failed to update combined document update', error);
			throw error;
		}
	};

	const onNext = async (formState, setSubmitting) => {
		try {
			setIsLoading(true);
			const createDocument = await initialDocument();

			console.log('Create Document: ', createDocument);

			const orginalPdfUpload = await handleOrginalPdfUpload(createDocument);
			console.log('Updated Document after original PDF upload: ', orginalPdfUpload);

			const updateDocumentSplitted = await handleSplittedDocumentUpload(createDocument);
			console.log('Updated Splitted Document after original PDF upload: ', updateDocumentSplitted);

			const combinedPages = combineOriginalAndSplittedPages(
				orginalPdfUpload,
				updateDocumentSplitted,
			);

			const updatedDocument = await updateIdDocumentWithCombinedPages(
				createDocument,
				combinedPages,
			);

			console.log('Updated Document: ', updatedDocument);

			const extractedSsm = await DocumentApi.extractID(createDocument.createDocument.id);
			console.log('Extracted SSM Document: ', JSON.parse(extractedSsm.data));

			if (
				JSON.parse(extractedSsm.data).businessName === 'Data not available' ||
				JSON.parse(extractedSsm.data).regNo === 'Data not available'
			) {
				setSsmUserInput(true);
			} else {
				const updatedAccount = await getAccount();
				appContext.setSelectedAccount(updatedAccount);

				const nextPage = appContext.isAdmin ? 2 : 4;
				navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_ID_SUBMISSION_FOR_BUSINESS, {
					state: { id: accountDetails?.id, page: nextPage },
				});
			}
		} catch (error) {
			console.error('Failed uploading document', error);
			setIsLoading(false);
		}
	};

	const userInputForSsm = () => {
		console.log('user input for ssm');
	};

	function uploadedDocument() {
		return appContext
			.selectedAccount()
			?.referenceDocs?.items?.find(
				(doc) =>
					doc.docType == docType &&
					doc.docCategory === DocCategory.BUSINESS &&
					doc.account.type === AccountType.BUSINESS,
			);
	}

	async function setUplaodDocument() {
		return [formik.values?.document0 as unknown as File];
	}

	function loadImagefromS3(imageDetails) {
		// console.log('get image url', imageDetails);
		setIsLoading(true);
		imageDetails?.pages?.forEach((element, index) => {
			// console.log(element);
			DocumentApi.getImage(element.key.includes('originalPdf') && element.key).then((res) => {
				// console.log(res);
				formik.setFieldValue('document' + index, res);
			});
		});
		setIsLoading(false);
	}

	const loadPdfFile = async (pdfFile) => {
		const loadedPdf = await loadImagefromS3(pdfFile);
		const mergedPdfFile = await mergePdfFromUrls(loadedPdf);
		formik.setFieldValue('document0', mergedPdfFile);
	};

	function uploadedIds() {
		return appContext
			.selectedAccount()
			?.referenceDocs.items.filter(
				(doc) =>
					(doc.docType == DocType.MYKAD || doc.docType == DocType.PASSPORT) &&
					doc.docCategory === DocCategory.BUSINESS &&
					doc.account.type === AccountType.BUSINESS,
			);
	}

	const handleFileChange = async (file, key) => {
		setFileChange(true);
		console.log(file, key);
		formik.setFieldValue(key, file);
	};

	const isFormValid = () => {
		if (docType === DocType.SSM) {
			return formik.values.document0?.type || formik.values.document0;
		}
		return false;
	};

	const handleButtonClick = () => {};

	const handleSsmInputChange = (e, inputType) => {
		const value = e.detail.value;

		if (inputType === 'businessName') {
			setEncryptedBusinessName(value);
			console.log('Business Name Input Value:', value);
		} else if (inputType === 'regNo') {
			setEncryptedRegNo(value);
			console.log('Reg No Input Value:', value);
		}
	};

	const handleUpdateSsmDetail = () => {
		console.log('Business Name:', encryptedBusinessName);
		console.log('Reg No:', encryptedRegNo);
		// You can perform other actions or validations here
	};

	return (
		<form className="ml-1.5 mt-4 mb-5" onSubmit={formik.handleSubmit}>
			{!isLoading ? (
				<IonRow>
					<IonRow className="ml-3.5">
						<IonRow className="md:ml-5 w-3/4">
							<div className="mt-1 mb-1 font-bold text-22px text-secondary-75 text-center">
								SSM Submission
							</div>
						</IonRow>

						<IonRow className="md:ml-5 w-10/12">
							<div className="text-16px mt-5 text-secondary-75 font-normal md:w-screen">
								Once you upload a copy of Company SSM, the system will automatically capture the
								information in the document and auto fill your details.
							</div>
						</IonRow>

						<IonRow class="ml-0 mt-2">
							<IonRow className="md:ml-2.5">
								<IonRow class=" mt-0 w-screen">
									{docType === DocType.SSM && !ssmUserInput && (
										<div className="mt-0">
											<input
												type="file"
												ref={document0}
												className="hidden"
												accept="application/pdf"
												onChange={(e) => {
													// @ts-ignore
													handleFileChange(Array.from(e.target.files)[0], 'document0');
												}}
											/>

											{!formik.values.document0 && (
												<>
													<FileUploader
														handleChange={(e) => {
															formik.setFieldValue('document0', e);
															setFileChange(true);
														}}
														name="file"
														types={fileTypes}
													>
														<IonCard className="ml-0 md:ml-3 w-60 h-96 border-2 border-dashed  border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00">
															<IonRow className=" mb-1 justify-center">
																<i
																	className={`icon bg-primary-100 h-7 w-9 uploadIcon m-auto mb-1 ${
																		docType === DocType.SSM ? 'bg-primary-50' : 'bg-secondary-25'
																	}`}
																></i>
															</IonRow>
															<IonRow className="mt-2 mb-1 justify-center">
																<IonLabel className="text-64px text-secondary-75 font-normal text-center">
																	{' '}
																	Browse SSM Document
																</IonLabel>
															</IonRow>
														</IonCard>
													</FileUploader>
													<IonRow className="md:ml-0.5">
														<IonButton
															type="submit"
															expand="block"
															className=" h-36px capitalize text-16px font-normal rounded-md mt-5 mb-3.5 md:mb-5 ml-0 md:ml-2 pl-0 md:pl-0.5"
															disabled={!isFormValid() || formik.isSubmitting}
															onClick={handleButtonClick}
														>
															{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
														</IonButton>
													</IonRow>
												</>
											)}

											{formik.values.document0 && (
												<>
													<IonCard className="ml-0 md:ml-3 w-80 pb-5 p-2 h-104 border-2 border-secondary-25 rounded-lg overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00">
														<PDFViewer pdfFile={formik.values.document0} popup={false} />
													</IonCard>
													<IonRow className="mt-3 mb-3 ml-0 md:ml-3">
														<div className="mt-5">
															<IonButton
																className="capitalize m-0  text-16px font-normal  bg-primary-100 text-secondary-00 rounded-md text-center w-20 "
																onClick={() => {
																	handleFileChange('', 'document0');
																}}
															>
																Change
															</IonButton>
														</div>
														<div className="mb-1 mt-1">
															<IonButton
																type="submit"
																expand="block"
																className=" h-36px mt-4 capitalize text-16px font-normal rounded-md md:ml-3 pl-3 md:pl-0.5"
																disabled={!isFormValid() || formik.isSubmitting}
																onClick={handleButtonClick}
															>
																{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
															</IonButton>
														</div>
													</IonRow>
												</>
											)}
										</div>
									)}

									{docType === DocType.SSM && ssmUserInput && (
										<>
											<IonCard className="max-w-md p-4 bg-white rounded shadow-md">
												<IonText className="text-16px text-secondary-75">
													User Input for SSM
												</IonText>

												<IonRow className="my-1 mt-5 mb-5 w-80">
													<IonCol className="p-0">
														<div className="pb-0.5">
															<IonLabel
																position="stacked"
																className="font-normal text-secondary-75 text-16px"
															>
																Business Name:
															</IonLabel>
														</div>
														<IonItem
															className="app-input-filed bg-secondary-00 mt-2.5 "
															lines="none"
														>
															<IonInput
																placeholder="Business Name"
																className="text-16px text-secondary-75 font-medium bg-secondary-00"
																value={encryptedBusinessName}
																onIonChange={(e) => handleSsmInputChange(e, 'businessName')}
															></IonInput>
														</IonItem>
													</IonCol>
												</IonRow>

												<IonRow className="my-1 mb-10 w-80">
													<IonCol className="p-0">
														<div className="pb-0.5">
															<IonLabel
																position="stacked"
																className="font-normal text-secondary-75 text-16px"
															>
																Reg No:
															</IonLabel>
														</div>
														<IonItem
															className="app-input-filed bg-secondary-00 mt-2.5 "
															lines="none"
														>
															<IonInput
																placeholder="Reg No"
																className="text-16px text-secondary-75 font-medium bg-secondary-00"
																value={encryptedRegNo}
																onIonChange={(e) => handleSsmInputChange(e, 'regNo')}
															></IonInput>
														</IonItem>
													</IonCol>
												</IonRow>

												<IonButton onClick={handleUpdateSsmDetail}>Update SSM Details</IonButton>
											</IonCard>
										</>
									)}
								</IonRow>
							</IonRow>
						</IonRow>
					</IonRow>
				</IonRow>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</form>
	);
}
