/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateAccount = /* GraphQL */ `subscription OnCreateAccount(
  $filter: ModelSubscriptionAccountFilterInput
  $owner: String
) {
  onCreateAccount(filter: $filter, owner: $owner) {
    id
    shortName
    type
    linkedBankAccounts {
      items {
        id
        bankName
        accountNumber
        accountHolderName
        accountCurrency
        default
        disabled
        status
        owner
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    referenceDocs {
      items {
        id
        docType
        docCategory
        pages {
          bucket
          key
          region
          __typename
        }
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        extractedData
        owner
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    attributes
    questionnaire
    owner
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    merchantProfile
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountSubscriptionVariables,
  APITypes.OnCreateAccountSubscription
>;
export const onUpdateAccount = /* GraphQL */ `subscription OnUpdateAccount(
  $filter: ModelSubscriptionAccountFilterInput
  $owner: String
) {
  onUpdateAccount(filter: $filter, owner: $owner) {
    id
    shortName
    type
    linkedBankAccounts {
      items {
        id
        bankName
        accountNumber
        accountHolderName
        accountCurrency
        default
        disabled
        status
        owner
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    referenceDocs {
      items {
        id
        docType
        docCategory
        pages {
          bucket
          key
          region
          __typename
        }
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        extractedData
        owner
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    attributes
    questionnaire
    owner
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    merchantProfile
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountSubscriptionVariables,
  APITypes.OnUpdateAccountSubscription
>;
export const onDeleteAccount = /* GraphQL */ `subscription OnDeleteAccount(
  $filter: ModelSubscriptionAccountFilterInput
  $owner: String
) {
  onDeleteAccount(filter: $filter, owner: $owner) {
    id
    shortName
    type
    linkedBankAccounts {
      items {
        id
        bankName
        accountNumber
        accountHolderName
        accountCurrency
        default
        disabled
        status
        owner
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    referenceDocs {
      items {
        id
        docType
        docCategory
        pages {
          bucket
          key
          region
          __typename
        }
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        extractedData
        owner
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    attributes
    questionnaire
    owner
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    merchantProfile
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountSubscriptionVariables,
  APITypes.OnDeleteAccountSubscription
>;
export const onCreateBankAccount = /* GraphQL */ `subscription OnCreateBankAccount(
  $filter: ModelSubscriptionBankAccountFilterInput
  $owner: String
) {
  onCreateBankAccount(filter: $filter, owner: $owner) {
    id
    bankName
    accountNumber
    accountHolderName
    accountCurrency
    default
    disabled
    status
    owner
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBankAccountSubscriptionVariables,
  APITypes.OnCreateBankAccountSubscription
>;
export const onUpdateBankAccount = /* GraphQL */ `subscription OnUpdateBankAccount(
  $filter: ModelSubscriptionBankAccountFilterInput
  $owner: String
) {
  onUpdateBankAccount(filter: $filter, owner: $owner) {
    id
    bankName
    accountNumber
    accountHolderName
    accountCurrency
    default
    disabled
    status
    owner
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBankAccountSubscriptionVariables,
  APITypes.OnUpdateBankAccountSubscription
>;
export const onDeleteBankAccount = /* GraphQL */ `subscription OnDeleteBankAccount(
  $filter: ModelSubscriptionBankAccountFilterInput
  $owner: String
) {
  onDeleteBankAccount(filter: $filter, owner: $owner) {
    id
    bankName
    accountNumber
    accountHolderName
    accountCurrency
    default
    disabled
    status
    owner
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBankAccountSubscriptionVariables,
  APITypes.OnDeleteBankAccountSubscription
>;
export const onCreateAccountUser = /* GraphQL */ `subscription OnCreateAccountUser(
  $filter: ModelSubscriptionAccountUserFilterInput
  $userId: String
) {
  onCreateAccountUser(filter: $filter, userId: $userId) {
    accountId
    userId
    role
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAccountUserSubscriptionVariables,
  APITypes.OnCreateAccountUserSubscription
>;
export const onUpdateAccountUser = /* GraphQL */ `subscription OnUpdateAccountUser(
  $filter: ModelSubscriptionAccountUserFilterInput
  $userId: String
) {
  onUpdateAccountUser(filter: $filter, userId: $userId) {
    accountId
    userId
    role
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAccountUserSubscriptionVariables,
  APITypes.OnUpdateAccountUserSubscription
>;
export const onDeleteAccountUser = /* GraphQL */ `subscription OnDeleteAccountUser(
  $filter: ModelSubscriptionAccountUserFilterInput
  $userId: String
) {
  onDeleteAccountUser(filter: $filter, userId: $userId) {
    accountId
    userId
    role
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAccountUserSubscriptionVariables,
  APITypes.OnDeleteAccountUserSubscription
>;
export const onCreateDocument = /* GraphQL */ `subscription OnCreateDocument(
  $filter: ModelSubscriptionDocumentFilterInput
  $owner: String
) {
  onCreateDocument(filter: $filter, owner: $owner) {
    id
    docType
    docCategory
    pages {
      bucket
      key
      region
      __typename
    }
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    extractedData
    owner
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDocumentSubscriptionVariables,
  APITypes.OnCreateDocumentSubscription
>;
export const onUpdateDocument = /* GraphQL */ `subscription OnUpdateDocument(
  $filter: ModelSubscriptionDocumentFilterInput
  $owner: String
) {
  onUpdateDocument(filter: $filter, owner: $owner) {
    id
    docType
    docCategory
    pages {
      bucket
      key
      region
      __typename
    }
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    extractedData
    owner
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDocumentSubscriptionVariables,
  APITypes.OnUpdateDocumentSubscription
>;
export const onDeleteDocument = /* GraphQL */ `subscription OnDeleteDocument(
  $filter: ModelSubscriptionDocumentFilterInput
  $owner: String
) {
  onDeleteDocument(filter: $filter, owner: $owner) {
    id
    docType
    docCategory
    pages {
      bucket
      key
      region
      __typename
    }
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    extractedData
    owner
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDocumentSubscriptionVariables,
  APITypes.OnDeleteDocumentSubscription
>;
export const onCreateUserPreference = /* GraphQL */ `subscription OnCreateUserPreference(
  $filter: ModelSubscriptionUserPreferenceFilterInput
  $id: String
) {
  onCreateUserPreference(filter: $filter, id: $id) {
    userId
    preferences
    updatedBy
    createdAt
    updatedAt
    id
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserPreferenceSubscriptionVariables,
  APITypes.OnCreateUserPreferenceSubscription
>;
export const onUpdateUserPreference = /* GraphQL */ `subscription OnUpdateUserPreference(
  $filter: ModelSubscriptionUserPreferenceFilterInput
  $id: String
) {
  onUpdateUserPreference(filter: $filter, id: $id) {
    userId
    preferences
    updatedBy
    createdAt
    updatedAt
    id
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserPreferenceSubscriptionVariables,
  APITypes.OnUpdateUserPreferenceSubscription
>;
export const onDeleteUserPreference = /* GraphQL */ `subscription OnDeleteUserPreference(
  $filter: ModelSubscriptionUserPreferenceFilterInput
  $id: String
) {
  onDeleteUserPreference(filter: $filter, id: $id) {
    userId
    preferences
    updatedBy
    createdAt
    updatedAt
    id
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserPreferenceSubscriptionVariables,
  APITypes.OnDeleteUserPreferenceSubscription
>;
