import { IonApp, IonContent } from '@ionic/react';
import React, { useContext } from 'react';
import { RouterProvider } from 'react-router-dom';
import './App.scss';
import AppContext from './utils/app-context';
import { Router } from './utils/routes';

/***SETUP IONIC STYLES***/
import { setupIonicReact } from '@ionic/react';
import NavBar from 'components/NavBar';
import { ProgressBar } from '@ionic/core/dist/types/components/progress-bar/progress-bar';
import ProgressBars from 'UI/ProgressBars';
setupIonicReact();
/***SETUP IONIC STYLES***/

function App() {
	const appContext = useContext(AppContext);

	return (
		<IonApp className={`theme-${appContext.appTheme} ${appContext.appTheme}`}>
			<IonContent className="p-4 overflow-hidden">
				<RouterProvider router={Router} />
			</IonContent>
		</IonApp>
	);
}

export default App;
