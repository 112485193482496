import { IonCard } from '@ionic/react';
import React, { useState, useEffect, useRef, useContext } from 'react';
import logoimg from '../../images/PendingMessageVector.svg';
import useMobileDetector from 'hooks/use-mobile-detector';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'utils/routes';

function PendingStatus() {
	const isMobile = useMobileDetector() === 'mobile';
	const navigate = useNavigate();

	useEffect(() => {
		const delayTimeout = setTimeout(() => {
			console.log('Navigating to account selection page.....');
			navigate(Routes.ACCOUNT_SELECTION);
		}, 3000);
		return () => clearTimeout(delayTimeout);
	}, []);

	return (
		<div className="bg-primary-100 h-screen w-screen">
			<div className="container mx-auto w-full flex items-center justify-center">
				{/* Replace text with SVG logo */}

				<img
					className="mt-36"
					src={logoimg}
					alt="Logo"
					style={{
						width: isMobile ? '100%' : '25%',
					}}
				/>
			</div>
			<div className="text-22px my-5 text-secondary-00 md:w-screen ml-5 mr-5 text-center">
				Your account is in process. you will be notified via email once the account is approved.
			</div>
		</div>
	);
}

export default PendingStatus;
