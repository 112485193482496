import {
	IonAvatar,
	IonCard,
	IonCardContent,
	IonCol,
	IonIcon,
	IonLabel,
	IonRow,
	IonToggle,
} from '@ionic/react';
import { Auth } from 'aws-amplify';
import LoadImage from 'components/LoadImage';
import { chevronForwardOutline, contrastOutline, keyOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';

const Profile = () => {
	const appContext: any = useContext(AppContext);
	console.log(appContext.userDetails);
	const [isChecked, setIsChecked] = useState(false);
	const navigate = useNavigate();
	// const imgSrc = "https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
	const imageToLoad = { imageName: 'person', className: '' };

	const signOutHandler = async () => {
		await Auth.signOut();
		appContext.getUserDetails();
		navigate(Routes.SIGNIN, { replace: true });
	};
	const themeChangeHandler = () => {
		setIsChecked(!isChecked);
	};
	return (
		<IonCardContent className="p-0">
			<IonCard className="mt-2 mb-1 bg-primary-200 rounded-xl">
				<IonRow className="p-3">
					<IonCol size="3" className="text-center">
						<IonAvatar className="">
							<LoadImage props={imageToLoad} />
						</IonAvatar>
					</IonCol>
					<IonCol size="7">
						<h3 className="text-secondary-50">
							{appContext.userDetails.name ||
								appContext.userDetails.fullname ||
								appContext.userDetails.attributes.email.split('@')[0]}
						</h3>
						{/* <IonLabel className="text-xs">{appContext.userDetails.username}</IonLabel> */}
						{appContext.userDetails.attributes.email && (
							<IonLabel className="text-sm text-secondary-100">
								{appContext.userDetails.attributes.email}
							</IonLabel>
						)}
						{appContext.userDetails.attributes.phone_number && (
							<IonLabel className="text-sm text-secondary-100">
								{appContext.userDetails.attributes.phone_number}
							</IonLabel>
						)}
					</IonCol>
					<IonCol size="2" className="flex items-center justify-center">
						<IonIcon icon={chevronForwardOutline} className="h-4 w-4  text-secondary-50"></IonIcon>
					</IonCol>
				</IonRow>
			</IonCard>
			<IonCard className="my-2 bg-primary-200 rounded-xl">
				<IonRow className="p-3 border-b-2 border-secondary-200">
					<IonCol size="1" className="flex items-center justify-center">
						<IonIcon icon={keyOutline} className="h-4 w-4  text-secondary-50"></IonIcon>
					</IonCol>
					<IonCol size="9">
						<IonLabel className="text-secondary-50">Change Password</IonLabel>
					</IonCol>
					<IonCol size="2" className="flex items-center justify-center">
						<IonIcon icon={chevronForwardOutline} className="h-4 w-4 text-secondary-50"></IonIcon>
					</IonCol>
				</IonRow>
				<IonRow className="p-3 ">
					<IonCol size="1" className="flex items-center justify-center">
						<IonIcon icon={contrastOutline} className="h-4 w-4  text-secondary-50"></IonIcon>
					</IonCol>
					<IonCol size="9" className="justify-left flex items-center">
						<IonLabel className="text-secondary-50">Dark Mode</IonLabel>
					</IonCol>
					<IonCol size="2" className="flex items-center justify-center">
						<div className="flex items-center">
							<IonToggle
								slot="end"
								checked={isChecked}
								onIonChange={themeChangeHandler}
							></IonToggle>
						</div>
					</IonCol>
				</IonRow>
			</IonCard>

			<IonCard
				className="my-2 bg-primary-200 text-secondary-50 justify-center p-1 rounded-lg h-11 flex items-center"
				onClick={signOutHandler}
			>
				Sign out
			</IonCard>
		</IonCardContent>
	);
};
export default Profile;
