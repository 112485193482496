import { IonSpinner, useIonToast } from '@ionic/react';
import { Auth } from 'aws-amplify';
import useThemeDetector from 'hooks/use-theme-detector';
import React, { useState } from 'react';
import * as clientStorage from 'utils/client-storage';
import AccountApi from 'api/account';
import RinngitPayLoader from 'components/RinngitPayLoader';

enum Theme {
	DARK = 'dark',
	LIGHT = 'light',
}

export type AppContextType = {
	userDetails?: any;
	appTheme: Theme;
	isSignedIn: () => boolean;
	getUserDetails: () => Promise<any>;
	signIn: (credentials: SignInOpts) => Promise<any>;
	selectedAccount: () => any;
	setSelectedAccount: (account: any) => void;
	selectedAccountKycReady: boolean;
	setSelectedAccountKyc: (account: any) => void;
	registrationDetails?: any;
	nationality?: any;
	promotionTab: boolean;
	setPromotionTab: (value: boolean) => void;
	stepperStatus?: any;
	stepperCompletionStatus?: any;
	isAdmin: boolean;
	setIsAdmin: (userDetails: any) => void;
};

type SignInOpts = {
	username: string;
	password: string;
};

const context: AppContextType = {
	isSignedIn() {
		return !!this.userDetails;
	},
	userDetails: null,
	selectedAccountKycReady: false,
	isAdmin: false,
	promotionTab: true,
	appTheme: Theme.LIGHT,
	async getUserDetails() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			// this.setIsAdmin(user);
			return user;
		} catch (e: any) {
			console.log(e);
		}
	},
	async signIn(credentials: SignInOpts) {
		const user = await Auth.signIn(credentials);
		this.userDetails = user;
		this.setIsAdmin(user);
		return user;
	},
	async setIsAdmin(user) {
		if (user.challengeName !== 'NEW_PASSWORD_REQUIRED') {
			// console.log(user.signInUserSession);
			const isAdmin =
				(user.signInUserSession.idToken.payload['cognito:groups'] || [])[0] === 'SystemAdmin';
			// console.log('Logged user is', isAdmin ? 'Admin' : 'not Admin');
			this.isAdmin = isAdmin;
		}
	},
	async setSelectedAccountKyc(accountId) {
		AccountApi.checkKycReadiness(accountId).then((res) => {
			this.selectedAccountKycReady = false;
		});
	},
	selectedAccount() {
		return clientStorage.getSelectedAccount();
	},
	setSelectedAccount(account) {
		clientStorage.setSelectedAccount(account);
		console.log('Selected Account: ', this.selectedAccount());
		// this.setSelectedAccountKyc(account.id);
		// AccountApi.onUpdate(account.id).subscribe({
		// 	next: () => {
		// 		// this.setSelectedAccountKyc(account.id);
		// 		AccountApi.getById(account.id).then((account) => {
		// 			this.setSelectedAccount(account);
		// 			console.log('AfterChange Account: ', this.selectedAccount());
		// 		});
		// 	},
		// 	error: (error) => console.warn(error),
		// });
	},
	registrationDetails: {
		accountType: 'INDIVIDUAL',
		nationality: 'MALAYSIAN',
	},
	nationality: {
		MALAYSIAN: 'MALAYSIAN',
		NON_MALAYSIAN: 'NON-MALAYSIAN',
	},
	stepperStatus: {
		OPEN: 'OPEN',
		INPROGRESS: 'INPROGRESS',
		COMPLETED: 'COMPLETED',
	},
	stepperCompletionStatus: {
		OPEN: 'OPEN',
		INPROGRESS: 'INPROGRESS',
		COMPLETED: 'COMPLETED',
	},
	setPromotionTab(value: boolean) {
		this.promotionTab = value;
	},
};

const AppContext = React.createContext<AppContextType>(context);

export const AppContextProvider = (props) => {
	//!to enable dark theme
	// const isDarkTheme = useThemeDetector();
	// Object.assign(context, {
	// 	appTheme: isDarkTheme ? Theme.DARK : Theme.LIGHT,
	// });

	const [loading, setLoading] = useState(true);

	// @ts-ignore
	// window.Auth = Auth;

	Auth.currentAuthenticatedUser()
		.then((authUser) => {
			// console.log('-----AppContextProvider-----');
			context.userDetails = authUser;
			context.setIsAdmin(authUser);
			setLoading(false);
		})
		.catch((error) => {
			console.log(error);
			setLoading(false);
		});

	if (loading) {
		return (
			// <div
			// 	style={{
			// 		position: 'absolute',
			// 		top: '50%',
			// 		left: '50%',
			// 	}}
			// >
			// 	<IonSpinner name="dots" />
			// </div>
			<RinngitPayLoader props={{ overlay: true }} />
		);
	}

	return <AppContext.Provider value={context}>{props.children}</AppContext.Provider>;
};

export default AppContext;
