import {
	IonButton,
	IonCol,
	IonGrid,
	IonLabel,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonSpinner,
	useIonToast,
} from '@ionic/react';
import { AccountType } from 'API';
import AccountApi from 'api/account';
import RinngitPayLoader from 'components/RinngitPayLoader';
import { useFormik } from 'formik';
import useMobileDetector from 'hooks/use-mobile-detector';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';
import * as Yup from 'yup';
import { Toaster, toast } from 'react-hot-toast';

const answer1 = [
	{
		key: '1',
		value: 'Physical goods (e.g. clothing, electronics, etc.)',
	},
	{
		key: '2',
		value: 'Digital goods (e.g. ebooks, software, music, financial assets etc.)',
	},
	{
		key: '3',
		value: 'Services (e.g. coaching, consulting, design, programming, etc.)',
	},
	{
		key: '4',
		value: 'Property Management or Rental',
	},
	{
		key: '5',
		value: 'Other (please specify)',
	},
];

const answer2 = [
	{
		key: '1',
		value: "Individual's",
	},
	{
		key: '2',
		value: 'Business',
	},
	{
		key: '3',
		value: "Both Individual's and Business",
	},
	{
		key: '4',
		value: 'Other (please specify)',
	},
];

export const Questionnaire = () => {
	const appContext = useContext(AppContext);
	const [showForm, setShowForm] = useState(false);
	const [updateQuestionnaire, setUpdateQuestionnaire] = useState<any>(null);
	const userDetails = appContext.userDetails;
	const isDesktop = useMobileDetector() === 'desktop';
	const [present] = useIonToast();
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	useEffect(() => {
		console.log('Questionnaire Page');
	}, []);

	const validationSchema = Yup.object({
		answer1: Yup.string().required(),
		answer2: Yup.string().required(),
	});

	let formik = useFormik({
		initialValues: {
			answer1: '',
			answer2: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			onNext(values, setSubmitting);
		},
	});

	const onNext = async (formState, setSubmitting) => {
		console.log(formState);

		AccountApi.save({
			id: appContext.selectedAccount()?.id,
			owner: userDetails.username,
			shortName: userDetails.shortName,
			updatedBy: userDetails.username,
			createdBy: userDetails.username,
			type: formState.accountType,
			questionnaire: JSON.stringify(formState),
		})
			.then((account) => {
				appContext.setSelectedAccount(account);
				const route =
					appContext.selectedAccount().type == AccountType.INDIVIDUAL
						? Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_ID_SUBMISSION_FOR_INDIVIDUAL
						: appContext.selectedAccount().type == AccountType.BUSINESS
						? Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_SSM_SUBMISSION_FOR_BUSINESS
						: Routes.CUSTOMER_VERIFICATION;
				navigate(route);
			})
			.catch((error) => {
				setSubmitting(false);
				console.error('Failed Saving Account', error);

				notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
			});
	};

	const handleChange = (key, answer) => {
		console.log(key, answer);
		if (key === 'answer1') {
			formik.setFieldValue('answer1', answer);
		} else if (key === 'answer2') {
			formik.setFieldValue('answer2', answer);
		}
	};

	const updateUserQuestionnaire = (account) => {
		console.log(account);
	};

	return (
		<form className="m-4 lg:w-9/12 centerbox" onSubmit={formik.handleSubmit}>
			<section className="m-4 selfie-verification lg:w-9/12 centerbox">
				{!isLoading ? (
					<IonGrid className="p-0">
						<IonRow className="my-4">
							<IonCol className="p-0">
								<IonLabel position="stacked" className="font-normal text-secondary-50">
									What Product or Service you offer?
								</IonLabel>

								<IonSelect
									placeholder="Product or Service you offer"
									className={`app-input-filed truncate ... bg-primary-500 mt-1 ${
										formik.touched.answer1 && formik.errors.answer1 ? 'app-input-has-error' : ''
									}`}
									name="productAndServiceOffer"
									id="productAndServiceOffer"
									onIonChange={(answer) => {
										handleChange('answer1', answer.detail.value);
									}}
									selectedText={
										answer1.filter((obj) => obj.key === formik.values.answer1)[0]?.value
									}
								>
									{answer1.map((answer1, i) => {
										return (
											<IonSelectOption key={answer1.key} value={answer1.value}>
												{answer1.value}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonCol>
						</IonRow>

						<IonRow className="my-4">
							<IonCol className="p-0">
								<IonLabel position="stacked" className="font-normal text-secondary-50">
									Who will be paying for your Product or Service?
								</IonLabel>

								<IonSelect
									placeholder="Choose your customer"
									className={`app-input-filed truncate ... bg-primary-500 mt-1 ${
										formik.touched.answer2 && formik.errors.answer2 ? 'app-input-has-error' : ''
									}`}
									name="productAndServiceOffer"
									id="productAndServiceOffer"
									onIonChange={(answer) => {
										handleChange('answer2', answer.detail.value);
									}}
									selectedText={
										answer2.filter((obj) => obj.key === formik.values.answer2)[0]?.value
									}
								>
									{answer2.map((answer2, i) => {
										return (
											<IonSelectOption key={answer2.key} value={answer2.value}>
												{answer2.value}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonCol>
						</IonRow>

						<IonButton
							type="submit"
							expand="block"
							className="lg:w-32 mx-auto shadow-none mt-6"
							disabled={formik.isSubmitting}
						>
							{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
						</IonButton>
					</IonGrid>
				) : (
					<RinngitPayLoader props={{ overlay: true }} />
				)}
			</section>
		</form>
	);
};

export default Questionnaire;
