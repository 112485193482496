import { useEffect, useState } from 'react';

const useMobileDetector = () => {
	const [width, setWidth] = useState<number>(window.innerWidth);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}
	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);
	const device = width <= 640 ? 'mobile' : width > 640 && width <= 960 ? 'tablet' : 'desktop';
	return device;
};

export default useMobileDetector;
