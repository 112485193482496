
const RP_SELECTED_ACCOUNT = 'rp-selected-account';

export function preferredStorage() {
    // return sessionStorage;
    return localStorage;
}

export function setItem(key, data) {
    // console.log("client storage stringify", data, JSON.stringify(data))
    // console.log("client storage stringify", data)
    return preferredStorage().setItem(key, JSON.stringify(data));
}

export function getItem(key) {
    const data = preferredStorage().getItem(key);
    try {
        return data ? JSON.parse(data) : data;
    } catch (error) {
        console.log('Expection parsing', key)
    }
    return data;
}

export function setSelectedAccount(data) {
    return setItem(RP_SELECTED_ACCOUNT, data);
}

export function getSelectedAccount() {
    return getItem(RP_SELECTED_ACCOUNT);
}

export function clearSelectedAccount() {
  // sessionStorage.removeItem(RP_SELECTED_ACCOUNT);
    return setItem(RP_SELECTED_ACCOUNT, null);
}


export function clear() {
    sessionStorage.clear()
}