import { IonCol, IonGrid, IonPage, IonRow } from '@ionic/react';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import useMobileDetector from 'hooks/use-mobile-detector';
import Promotion from './Promotion';
import './Layout.scss';
import LoadImage from './LoadImage';
import AppContext from 'utils/app-context';
import loginVector from '../images/loginVector.svg';
import logoWhite from '../images/LogoWhite.svg';

const SignInLayout = () => {
	const device = useMobileDetector();
	const appContext = useContext(AppContext);
	const imageToLoad = { imageName: 'logo', className: 'h-12' };
	const ringgitpayTxt = { imageName: 'rpSignIn', className: 'mt-7 h-40 lg:mt-5' };
	const isMobile = useMobileDetector() === 'mobile';

	const version = 'V1.6.0';
	const env = 'DEV';

	return (
		<IonPage className="signin  bg-secondary-00 dark:bg-gradient-to-r from-primary-400 to-primary-300 overflow-x-hidden">
			<IonGrid className="p-0 m-0">
				<IonRow className=" justify-center  h-screen ">
					{!isMobile && (
						<>
							<div className="bg-primary-100 h-full w-7/12">
								<div className="pt-28 flex justify-center">
									<img src={logoWhite} alt="logo" style={{ width: '25%' }} />
								</div>
								<div className="pt-12 flex justify-center lg:pb-4">
									<img src={loginVector} alt="Logo" style={{ width: '70%' }} />
								</div>
								<IonRow className="justify-center mt-4">
									<p className="text-white">
										{env} - {version}
									</p>
								</IonRow>
							</div>
						</>
					)}
					<IonRow
						size-md="6"
						offset-lg="6"
						pull-lg="3"
						size-lg="3"
						className={`justify-center bg-transparent ${isMobile ? 'w-screen' : 'w-5/12'}`}
					>
						<Outlet />
					</IonRow>
				</IonRow>
			</IonGrid>
		</IonPage>
	);
};

export default SignInLayout;
