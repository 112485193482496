import {
	IonCardHeader,
	IonContent,
	IonIcon,
	IonItem,
	IonList,
	IonPopover,
	IonRow,
	IonTitle,
} from '@ionic/react';
import { chevronBack, helpCircleOutline, homeOutline, personCircleOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { pageTitles } from 'utils/page-title';
import logoimg from '../images/RP_Logo-01.svg';
import { Auth } from 'aws-amplify';
import * as clientStorage from 'utils/client-storage';
import AppContext from 'utils/app-context';
import profileImg from '../images/profileImg.svg';
import { Routes } from 'utils/routes';
import './Header.scss';
import useMobileDetector from 'hooks/use-mobile-detector';
import MenuIcon from '../images/MenuIcon.svg';

const Header = () => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [signOutPage, setSignOutPage] = useState(false);
	const device = useMobileDetector();

	//TODO: for testing only remove later remove account fields
	const [pageConfig, setPageConfig] = useState({
		title: 'RinngitPay',
		backBtn: false,
		profile: false,
		account: false,
		home: false,
	});
	let location = useLocation();
	const navigate = useNavigate();
	const { accountId } = useParams();
	const appContext: any = useContext(AppContext);
	const isAdmin =
		appContext?.userDetails?.signInUserSession?.idToken?.payload['cognito:groups'] || [];

	React.useEffect(() => {
		const pageTitle = pageTitles[location.pathname] || pageTitles[`/accounts/:accountId`];
		setPageConfig(pageTitle);
	}, [location, accountId]);

	const backClickHandler = () => {
		navigate(-1);
	};
	const profileClickHandler = () => {
		navigate(Routes.PROFILE, { replace: true });
	};
	const helpClickHandler = () => {
		//to implement
	};

	//TODO: for testing only remove later
	const homeClickHandler = () => {
		navigate(Routes.ACCOUNT_SELECTION, { replace: true });
	};

	const signOutClickHandler = async () => {
		await Auth.signOut();
		clientStorage.clear();
		appContext.userDetails = null;
		navigate(Routes.SIGNIN, { replace: true });
	};

	const AccountsTableClickHandler = async () => {
		appContext.isAdmin ? navigate(Routes.MANAGE_ACCOUNTS) : navigate(Routes.ACCOUNT_SELECTION);
	};

	const mobileSignOut = async () => {
		setSignOutPage(!signOutPage);
		!signOutPage && console.log('navigates to sign out screen');
		signOutPage && console.log('re-navigates');
		!signOutPage && navigate(Routes.SIGNOUT_MOBILE);
		signOutPage && navigate(-1);
	};

	return (
		<IonCardHeader className="bg-secondary-00 text-secondary-75 py-3">
			<div className="flex items-center justify-center md:justify-start">
				{/* Replace text with SVG logo */}
				<img src={logoimg} alt="Logo" className=" h-8" />
			</div>

			{/* onClick={device === 'mobile' && appContext.isAdmin ? mobileSignOut : toggleDropdown} */}

			{pageConfig.backBtn && (
				<>
					{device === 'mobile' && appContext.isAdmin ? (
						<IonIcon
							id="popover-button"
							className="absolute top-0 right-2 mr-3 w-5 h-full z-10 flex justify-end items-center
					visible hover:cursor-pointer "
							icon={MenuIcon}
							onClick={device === 'mobile' && appContext.isAdmin ? mobileSignOut : undefined}
						></IonIcon>
					) : (
						<IonIcon
							id="popover-button"
							className="absolute top-0 right-2 mr-3 w-5 h-full z-10 flex justify-end items-center
					visible hover:cursor-pointer "
							icon={profileImg}
						></IonIcon>
					)}
					{device === 'mobile' && appContext.isAdmin ? undefined : (
						<IonPopover trigger="popover-button" dismissOnSelect={true}>
							<IonContent>
								<IonList>
									<IonItem
										button={true}
										detail={false}
										onClick={AccountsTableClickHandler}
										lines="none"
									>
										Accounts
									</IonItem>
									<IonItem button={true} detail={false} onClick={signOutClickHandler} lines="none">
										Signout
									</IonItem>
								</IonList>
							</IonContent>
						</IonPopover>
					)}
				</>
			)}

			{/* {pageConfig.profile && (
				<>
					<IonIcon
						icon={personCircleOutline}
						className="absolute h-8 w-8 float-right right-3 z-10"
						onClick={profileClickHandler}
					></IonIcon>
				</>
			)}

			{location.pathname === Routes.PROFILE && (
				<>
					<IonIcon
						icon={helpCircleOutline}
						className="absolute h-8 w-8 float-right right-3 z-10"
						onClick={helpClickHandler}
					></IonIcon>
				</>
			)} */}

			{pageConfig.account && (
				<>
					{/* <nav data-te-navbar-ref className="h-full absolute right-2 top-8">
						<div className="relative ml-3" data-te-dropdown-ref>
							<div
								// onClick={toggleDropdown}
								onClick={device === 'mobile' && appContext.isAdmin ? mobileSignOut : toggleDropdown}
								className="absolute top-0 right-2 w-7 h-full z-10 flex justify-end items-center
					 	        visible hover:cursor-pointer "
								aria-expanded={isDropdownOpen ? 'true' : 'false'}
							>
								{device === 'mobile' && appContext.isAdmin ? (
									<IonIcon className="w-5 h-5 mb-2" icon={MenuIcon}></IonIcon>
								) : (
									<IonIcon className="w-5 h-5 mb-2" icon={profileImg}></IonIcon>
								)}
							</div>

							<ul
								className={`absolute right-0 top-5 left-auto z-[1000] float-left m-0 ${
									isDropdownOpen ? 'block' : 'hidden'
								} min-w-max list-none overflow-hidden rounded-lg border-none bg-secondary-00 bg-clip-padding text-left text-base shadow-none dark:bg-neutral-700`}
								data-te-dropdown-menu-ref
							>
								{!appContext.isAdmin ? (
									<li>
										<a
											className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-secondary-00/30"
											href="#"
											data-te-dropdown-item-ref
											onClick={AccountsTableClickHandler}
										>
											Accounts
										</a>
									</li>
								) : null}

								<li>
									<a
										className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-secondary-00/30"
										href="#"
										data-te-dropdown-item-ref
										onClick={signOutClickHandler}
									>
										Sign Out
									</a>
								</li>
							</ul>
						</div>
					</nav> */}
				</>
			)}

			{pageConfig.home && (
				<>
					{/* <nav data-te-navbar-ref className="h-full absolute right-2 top-8">
						<div className="relative ml-3" data-te-dropdown-ref>
							<div
								onClick={toggleDropdown}
								className="absolute top-0 right-2 w-7 h-full z-10 flex justify-end items-center
					  visible hover:cursor-pointer "
								aria-expanded={isDropdownOpen ? 'true' : 'false'}
							>
								{device === 'mobile' && appContext.isAdmin ? (
									<IonIcon className="w-5 h-5 mb-2" icon={MenuIcon}></IonIcon>
								) : (
									<IonIcon className="w-5 h-5 mb-2" icon={profileImg}></IonIcon>
								)}
							</div>

							<ul
								className={`absolute right-0 top-5 left-auto z-[1000] float-left m-0 ${
									isDropdownOpen ? 'block' : 'hidden'
								} min-w-max list-none overflow-hidden rounded-lg border-none bg-secondary-00 bg-clip-padding text-left text-base shadow-none dark:bg-neutral-700`}
								data-te-dropdown-menu-ref
							>
								{!appContext.isAdmin ? (
									<li>
										<a
											className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-secondary-00/30"
											href="#"
											data-te-dropdown-item-ref
											onClick={AccountsTableClickHandler}
										>
											Accounts
										</a>
									</li>
								) : null}

								<li>
									<a
										className="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-secondary-00/30"
										href="#"
										data-te-dropdown-item-ref
										onClick={signOutClickHandler}
									>
										Sign Out
									</a>
								</li>
							</ul>
						</div>
					</nav> */}
					{device === 'mobile' && appContext.isAdmin ? (
						<IonIcon
							id="popover-button"
							className="absolute top-0 right-2 mr-3 w-5 h-full z-10 flex justify-end items-center
					visible hover:cursor-pointer "
							icon={MenuIcon}
							onClick={device === 'mobile' && appContext.isAdmin ? mobileSignOut : undefined}
						></IonIcon>
					) : (
						<IonIcon
							id="popover-button"
							className="absolute top-0 right-2 mr-3 w-5 h-full z-10 flex justify-end items-center
					visible hover:cursor-pointer "
							icon={profileImg}
						></IonIcon>
					)}
					{device === 'mobile' && appContext.isAdmin ? undefined : (
						<IonPopover trigger="popover-button" dismissOnSelect={true}>
							<IonContent>
								<IonList>
									<IonItem
										button={true}
										detail={false}
										onClick={AccountsTableClickHandler}
										lines="none"
									>
										Accounts
									</IonItem>
									<IonItem button={true} detail={false} onClick={signOutClickHandler} lines="none">
										Signout
									</IonItem>
								</IonList>
							</IonContent>
						</IonPopover>
					)}
				</>
			)}
		</IonCardHeader>
	);
};
export default React.memo(Header);
