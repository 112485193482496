import { Amplify, Storage } from 'aws-amplify';
import awsExports from 'aws-exports';
import * as clientStorage from 'utils/client-storage'

let config;

export function configureAmplify() {

  if (config) {
    return config;
  }

  config = Amplify.configure({ ...awsExports, storage: clientStorage.preferredStorage() })

  // no need to access level prefix as all 
  // the contents are handled by custom access patterns
  Storage.configure({
    customPrefix: {
      public: ''
    }
  })

  return config;
}

export function getAwsConfig() {

  if (!config) {
    config = configureAmplify();
  }

  return {
    region: config.aws_project_region,
    S3: {
      bucket: config.aws_user_files_s3_bucket,
      region: config.aws_user_files_s3_bucket_region
    }
  }

}
