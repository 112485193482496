// @ts-ignore
import {
	IonButton,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonLabel,
	IonRow,
	IonSpinner,
	useIonToast,
	IonCard,
	IonCardContent,
	IonContent,
	IonCardHeader,
	IonCardTitle,
	IonInput,
	IonItem,
} from '@ionic/react';
import {
	alert,
	chevronBack,
	chevronForward,
	checkmarkCircleOutline,
	alertCircle,
	alertCircleOutline,
	checkmarkCircle,
	timeOutline,
} from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountType, DocType, DocCategory, VerificationStatus } from 'API';
import DocumentApi from 'api/document';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';
import { filter } from 'rxjs';
import DocumentCapture from 'components/ImageCapture';
import IdCapture from 'components/IdCapture';
import useMobileDetector from 'hooks/use-mobile-detector';
import RinngitPayLoader from 'components/RinngitPayLoader';
import { removeTypenameFromS3 } from 'utils/object-helper';

import AccountApi from 'api/account';

export default function IdSubmissionForDirector() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const [showToast] = useIonToast();
	const fileTypes = ['JPEG', 'PNG', 'JPG'];
	const document0 = useRef<HTMLInputElement>(null);
	const document1 = useRef<HTMLInputElement>(null);
	let [directorIdIndex, setDirectorIdIndex] = useState<number>(0);
	const [directorName, setDirectorName] = useState<string>('');
	const [directorId, setDirectorId] = useState<string>('');
	const [newId, setNewId] = useState<boolean>(false);
	const [showUploadStatus, setShowUploadStatus] = useState<boolean>(true);
	const [showUploadPage, setShowUploadPage] = useState<boolean>(false);
	const [currentDocumentId, setCurrentDocumentId] = useState<any>(undefined);
	const [fileChange, setFileChange] = useState<boolean>(false);
	const [expandedMatchingItem, setExpandedMatchingItem] = useState(null);
	const [expandedNonMatchingItem, setExpandedNonMatchingItem] = useState(null);
	const [captureImage, setCapturImage] = useState<boolean>(false);
	const [capturedImage0, setCapturedImage0] = useState<File | null>(null);
	const [capturedImage1, setCapturedImage1] = useState<File | null>(null);
	const [documentNumber, setDcoumentNumber] = useState<number>(0);
	const [expandedStatus, setExpandedStatus] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);

	const [ssmRawDocument, setSsmRawDocument] = useState<undefined | any>(undefined);
	const [extractedSsmData, setExtractedSsmData] = useState<undefined | any>(undefined);

	const [matchingIdProofs, setMatchingIdProofs] = useState<undefined | any>(undefined);
	const [nonMatchingIdProofs, setNonMatchingIdProofs] = useState<undefined | any>(undefined);

	const [matchingIdUpload, setMatchingIdUpload] = useState<boolean>(false);
	const [nonMatchingIdUpload, setNonMatchingIdUpload] = useState<boolean>(false);
	const [currentNonDocumentId, setCurrentNonDocumentId] = useState<any>(undefined);

	const accountDetails = appContext.selectedAccount();

	const isMobile = useMobileDetector() === 'mobile';

	const isAdmin = appContext.userDetails.signInUserSession.idToken.payload['cognito:groups'] || [];

	let continueButtonValue = false;

	const validationSchema = Yup.object({
		documentType: Yup.string().required(),
		docCategory: Yup.string().required(),
		document0: Yup.string(),
		document1: Yup.string(),
		contactEmailId: Yup.string().required(),
		contactNumber: Yup.number().required(),
		contactName: Yup.string().required(),
	});

	let formik = useFormik({
		initialValues: {
			documentType: '',
			docCategory: DocCategory.INDIVIDUAL,
			document0: undefined as any,
			document1: undefined as any,
			contactNumber: '',
			contactEmailId: '',
			contactName: '',
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			fileChange ? onNext(values, setSubmitting) : setCurrentDocumentId(null);
			setNewId(false);
			setShowUploadStatus(true);
			setShowUploadPage(false);
			formik.resetForm();
		},
	});

	useEffect(() => {
		console.log('ID Submission Page for Business');
		console.log('Selected Account: ', accountDetails);
		loadSsmData();
	}, []);

	const loadSsmData = async () => {
		setIsLoading(true);
		const ssmRawData = await accountDetails.referenceDocs?.items?.filter(
			(obj) => obj.docType === DocType.SSM,
		);
		console.log(ssmRawData);
		setSsmRawDocument(ssmRawData);

		const ssmData = await JSON.parse(JSON.parse(ssmRawData[0]?.extractedData));
		console.log(ssmData);
		setExtractedSsmData(ssmData);

		const documentType = ssmData.directorsDetails[0]?.idType;

		formik.setFieldValue('documentType', documentType);

		const matchingIdProofs = filterUploadedDirectorData(
			ssmData.directorsDetails,
			uploadedIds(),
		).matchingIds;
		setMatchingIdProofs(matchingIdProofs);

		const nonMatchingIdProofs = filterUploadedDirectorData(
			ssmData.directorsDetails,
			uploadedIds(),
		).nonMatchingIds;
		setNonMatchingIdProofs(nonMatchingIdProofs);

		console.log('matchingIdProofs', matchingIdProofs);
		console.log('nonMatchingIdProofs', nonMatchingIdProofs);
		setIsLoading(false);
	};

	function filterUploadedDirectorData(ssmData, uploadedDirectorData) {
		const matchingIds: any[] = [];
		const nonMatchingIds: any[] = [];

		uploadedDirectorData.forEach((data) => {
			const extractedJson = JSON.parse(JSON.parse(data.extractedData));
			// if (extractedJson.hasOwnProperty('name')) {
			const matchingDirector = ssmData.find((director) => {
				if (data.docType === 'MYKAD') {
					return director.name === extractedJson.name && director.id === extractedJson.idNumber;
				} else if (data.docType === 'PASSPORT') {
					return (
						director?.name === extractedJson?.name && director?.id === extractedJson?.documentNumber
					);
				}
				return false;
			});
			if (matchingDirector) {
				matchingIds.push(data);
			} else {
				nonMatchingIds.push(data);
			}
			// }
		});

		return {
			matchingIds,
			nonMatchingIds,
		};
	}

	function isFormValid() {
		if (
			formik.values.documentType === DocType.MYKAD ||
			formik.values.documentType === DocType.PASSPORT
		) {
			return (
				(formik.values.document0?.type && formik.values.document1?.type) ||
				(formik.values.document0 && formik.values.document1)
			);
		}

		return false;
	}

	const isFormValidForContinue = () => {
		if (continueButtonValue === true) {
			return true;
		}
		return false;
	};

	const handleImageCapture = (image: File, documentNumber: number) => {
		documentNumber === 0 ? setCapturedImage0(image) : setCapturedImage1(image);
		formik.setFieldValue('document' + documentNumber, image);
		setCapturImage(false);
	};

	function documentDetailss() {
		return {
			documentType: formik.values.documentType === DocType.MYKAD ? 'MyKad' : 'Passport',
			documentNumber,
		};
	}

	const onNext = async (formState, setSubmitting) => {
		console.log('Form Values:ooooooooooooooooooooo');
		console.log('Form Values:', formik.values);
		console.log('Selected Document Type: ', formik.values.documentType);
		console.log('Uploading Matching ID Proof: ', matchingIdUpload);
		console.log('Current Matching Document ID: ', currentDocumentId);
		console.log('Uploading Non-Matching ID Proof: ', nonMatchingIdUpload);
		console.log('Current Non-Matching Document ID: ', currentNonDocumentId);
		try {
			setIsLoading(true);
			const pages = setUplaodDocument();
			const idDocument =
				newId && !currentDocumentId && matchingIdUpload
					? {}
					: !newId && !currentDocumentId && nonMatchingIdUpload
					? currentNonDocumentId
					: null;
			idDocument.docType = formik.values.documentType;
			idDocument.docCategory = DocCategory.BUSINESS;
			idDocument.accountId = accountDetails.id;
			idDocument.updatedBy = appContext.userDetails.username;
			idDocument.owner = accountDetails.owner;
			idDocument.status = VerificationStatus.PENDING;
			const updatedPages = (idDocument.pages || []).map((page) => removeTypenameFromS3(page));
			idDocument.pages = updatedPages;
			console.log('ID Document: ', idDocument);
			const createDocument = await DocumentApi.save(idDocument);
			console.log('Create Document: ', createDocument);
			await DocumentApi.upload(idDocument, pages, createDocument.id, false);
			idDocument.id = createDocument.id;
			const updateDocument = await DocumentApi.save(idDocument);
			const extractedDataFromId = await DocumentApi.extractID(createDocument.id);
			console.log('Extracted ID Data: ', extractedDataFromId);
			appContext.setSelectedAccount(createDocument.account);
			await getAccount();
			loadSsmData();
			setCurrentDocumentId(null);
			setMatchingIdUpload(false);
			setNonMatchingIdUpload(false);
			setNewId(false);
			setShowUploadStatus(true);
			setShowUploadPage(false);
			setFileChange(false);
			formik.resetForm();
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			console.error('Failed uploading document', error);
		}
	};

	async function getAccount() {
		// console.log('--------', appContext.selectedAccount().id);

		const getAccountDetails = await AccountApi.getByUser(
			appContext.isAdmin ? accountDetails?.owner : appContext.userDetails?.username,
		).then((accounts) => {
			return accounts;
		});
		// console.log('--------', getAccountDetails);
		const selectedAccount = getAccountDetails.find((account) => {
			return account.id === appContext.selectedAccount().id;
		});
		console.log('Selected Account: ', selectedAccount);
		appContext.setSelectedAccount(selectedAccount);
	}

	function uploadedIds() {
		return appContext
			.selectedAccount()
			?.referenceDocs.items.filter(
				(doc) =>
					(doc.docType == DocType.MYKAD || doc.docType == DocType.PASSPORT) &&
					doc.docCategory === DocCategory.BUSINESS &&
					doc.account.type === AccountType.BUSINESS,
			);
	}

	function selectedDirectorInfo(ssmData, currentIdIndex) {
		console.log(
			'Current Director ID Full Data From SSM: ',
			ssmData.directorsDetails[currentIdIndex],
		);
		console.log('Current Director Name From SSM: ', ssmData.directorsDetails[currentIdIndex].name);
		console.log('Current Director ID From SSM: ', ssmData.directorsDetails[currentIdIndex].id);
		console.log(
			'Current Director ID Type From SSM: ',
			ssmData.directorsDetails[currentIdIndex].idType,
		);
		setDirectorName(ssmData.directorsDetails[currentIdIndex].name);
		formik.setFieldValue('documentType', ssmData.directorsDetails[currentIdIndex].idType);
		const currentDirectorData =
			matchingIdProofs &&
			selectedDirectorId(ssmData.directorsDetails[currentIdIndex], matchingIdProofs);
		console.log('Current Director ID Not Uploaded: ', !currentDirectorData);
		console.log('Current Director Raw Data: ', currentDirectorData);
		setCurrentDocumentId(currentDirectorData);
		loadImagefromS3(currentDirectorData);
		const currentDirectorUploadedId = currentDirectorData
			? JSON.parse(JSON.parse(currentDirectorData.extractedData))
			: undefined;
		console.log('Current Director Data: ', currentDirectorUploadedId);
		!currentDirectorUploadedId && newIdUplaod(ssmData.directorsDetails[currentIdIndex].idType);
	}

	function selectedDirectorId(selectedDirector, uploadedDirectorData) {
		console.log('Finding Current Director Data');
		const filteredData = uploadedDirectorData.find((item) => {
			const extractedData = JSON.parse(JSON.parse(item.extractedData));
			if (
				extractedData.name === selectedDirector.name ||
				(selectedDirector.idType === 'PASSPORT' &&
					extractedData.documentNumber === selectedDirector.id) ||
				(selectedDirector.idType === 'MYKAD' && extractedData.idNumber === selectedDirector.id)
			) {
				return true;
			}
			return false;
		});
		return filteredData;
	}

	function newIdUplaod(idType) {
		setExpandedStatus(expandedStatus === !expandedStatus);
		formik.resetForm();
		formik.setFieldValue('documentType', idType);
		setNewId(true);
	}

	function currentNonMatchingId(currentNonDocumentId) {
		setCurrentNonDocumentId(currentNonDocumentId);
	}

	function continueClick() {
		console.log('after all upload comleted');
		if (appContext.isAdmin) {
			navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BANK_VERIFICATION, {
				state: { id: accountDetails?.id, page: 3 },
			});
		} else {
			navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BANK_VERIFICATION, {
				state: { id: accountDetails?.id, page: 5 },
			});
		}
	}

	function setUplaodDocument() {
		return [
			formik.values?.document0 as unknown as File,
			formik.values.document1 as unknown as File,
		];
	}

	function loadImagefromS3(imageDetails) {
		// console.log('get image url', imageDetails);

		imageDetails?.pages?.forEach((element, index) => {
			// console.log(element);
			DocumentApi.getImage(element.key).then((res) => {
				// console.log(res);
				formik.setFieldValue('document' + index, res);
			});
		});
	}

	const matchingToggleAccordion = (currentIdIndex) => {
		setExpandedNonMatchingItem(null);
		setExpandedMatchingItem(expandedMatchingItem === currentIdIndex ? null : currentIdIndex);
		expandedMatchingItem !== currentIdIndex &&
			selectedDirectorInfo(extractedSsmData, currentIdIndex);
		fileChange && onNext(formik.values, formik.isSubmitting);
	};

	const nonMatchingToggleAccordion = (currentIdIndex) => {
		setExpandedMatchingItem(null);
		setExpandedNonMatchingItem(expandedNonMatchingItem === currentIdIndex ? null : currentIdIndex);
		const nonMatchingData =
			expandedNonMatchingItem !== currentIdIndex &&
			nonMatchingIdProofs &&
			nonMatchingIdProofs[currentIdIndex];
		console.log(nonMatchingData ? nonMatchingData : 'Closed');
		loadImagefromS3(nonMatchingData);
	};

	return (
		<form className=" mt-5 md:ml-2" onSubmit={formik.handleSubmit}>
			{!isLoading ? (
				<IonRow>
					{captureImage && (
						<div className="ml-1 md:ml-4">
							<IdCapture onImageCapture={handleImageCapture} documentDetails={documentDetailss()} />
						</div>
					)}

					{!captureImage && (
						<>
							<IonRow className=" w-screen">
								<IonRow className="ml-5 w-11/12 mb-7">
									<h2 className="mt-1 font-bold text-secondary-75 text-22px text-center md:ml-2.5">
										Director's Proof Submission
									</h2>
								</IonRow>

								<IonRow className="w-11/12 ml-5 mb-4">
									<IonLabel className="w-full md:ml-2.5 mb-4 font-normal text-18px text-primary-75">
										Director's List
									</IonLabel>
									<IonLabel className="w-full md:ml-2.5 mb-4 font-normal text-16px text-primary-75">
										Please click the director's name to upload the document
									</IonLabel>
									{extractedSsmData &&
										extractedSsmData.directorsDetails.map((directorsName, currentIdIndex) => {
											const isIdUploaded =
												matchingIdProofs &&
												matchingIdProofs.some(
													(obj) =>
														JSON.parse(JSON.parse(obj.extractedData)).name === directorsName?.name,
												);

											return (
												<div>
													<IonRow key={currentIdIndex} className="m-0 p-0 w-screen ">
														<IonRow className="w-screen">
															<IonCard className="bg-secondary-00 w-full md:w-652px mr-10 md:mr-6 md:ml-3 mx-0 my-1">
																<div className="w-full md:w-652px shadow-none">
																	<IonCardHeader
																		className="ml-0 "
																		onClick={() => matchingToggleAccordion(currentIdIndex)}
																		style={{
																			height: '50px',
																			display: 'flex',
																			alignItems: 'center',
																			width: '100%',
																			cursor: 'pointer',
																		}}
																	>
																		<div className="flex items-center justify-between md:ml-1 w-full">
																			<div className="flex flex-wrap">
																				<div className="">
																					<div className="w-full flex mx-auto text-secondary-100 font-normal text-16px">
																						{directorsName.name}
																					</div>
																				</div>
																			</div>
																			<div className="flex">
																				<IonIcon
																					icon={checkmarkCircle}
																					className={`ml-2 h-8 w-8 ${
																						isIdUploaded ? 'text-success' : 'text-secondary-50'
																					}`}
																				></IonIcon>
																			</div>
																		</div>
																	</IonCardHeader>
																</div>

																{expandedMatchingItem === currentIdIndex && (
																	<>
																		<div
																			className="bg-secondary-00 md:ml-2 md:bg-transparent"
																			style={{
																				display: 'flex',
																				flexDirection: isMobile ? 'column' : 'row',
																				// alignItems: 'center',
																				// marginBottom: '22px',
																			}}
																		>
																			{!formik.values?.document0 && (
																				<>
																					<IonRow className="w-72 md:ml-0.5">
																						<FileUploader
																							handleChange={(e) => {
																								formik.setFieldValue('document0', e);
																								setFileChange(true);
																								handleImageCapture(e, 0);
																								documentDetailss();
																							}}
																							name="file"
																							types={fileTypes}
																						>
																							<IonCard className=" w-64 h-40 border-2 border-dashed  border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00">
																								<IonCol>
																									<IonRow className="mt-5 mb-1 justify-center">
																										<IonIcon
																											className={`icon h-7 w-9  uploadIcon  bg-primary-100`}
																										/>
																									</IonRow>
																									<IonRow className="mt-3 justify-center">
																										<IonLabel className="text-16px text-secondary-75 font-normal leading-5">
																											{directorsName.idType === 'PASSPORT'
																												? 'Front Page of Director Passport'
																												: 'Front Side of MyKad'}
																										</IonLabel>
																									</IonRow>
																									<IonRow className="mt-1 justify-center">
																										<IonLabel className="text-secondary-75 font-normal text-14px">
																											<div>Upload or take a photo</div>
																										</IonLabel>
																									</IonRow>
																									{/* <IonRow className="flex justify-center">
																									<div
																										className="text-xs cursor-pointer mt-2 m-auto h-auto py-1 bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
																										onClick={() => {
																											setCapturImage(true);
																											setDcoumentNumber(0);
																											setFileChange(true);
																											setDirectorIdType(
																												directorsName.idType === DocType.MYKAD
																													? DocType.MYKAD
																													: DocType.PASSPORT,
																											);
																										}}
																									>
																										Capture
																									</div>
																								</IonRow> */}
																								</IonCol>
																							</IonCard>
																						</FileUploader>
																						<IonRow className="ml-2 mr-2 w-64 justify-center mt-2">
																							<div
																								className="text-xs cursor-pointer mb-2 m-auto h-auto py-1 bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
																								onClick={() => {
																									setMatchingIdUpload(true);
																									setCapturImage(true);
																									setDcoumentNumber(0);
																									setFileChange(true);
																								}}
																							>
																								Capture
																							</div>
																						</IonRow>
																					</IonRow>
																				</>
																			)}

																			{formik.values?.document0 && (
																				<>
																					<IonRow className="w-72 mt-1">
																						<IonCol className="p-0">
																							<IonRow>
																								<>
																									<IonCard className="mt-1.5 ml-3 w-64 h-40 border-2  border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																										<IonImg
																											src={
																												formik.values?.document0.type
																													? URL.createObjectURL(
																															formik.values?.document0,
																													  )
																													: formik.values?.document0
																											}
																										></IonImg>
																									</IonCard>
																								</>
																							</IonRow>

																							<IonRow className="mt-1 justify-center">
																								<IonLabel className="text-16px text-secondary-75 font-normal">
																									<div>
																										<IonLabel className="text-16px text-secondary-75 font-normal">
																											{directorsName.idType === 'PASSPORT'
																												? 'Front Page of Director Passport'
																												: 'Front Side of MyKad'}
																										</IonLabel>
																									</div>
																								</IonLabel>
																							</IonRow>

																							{/* <IonRow className="mb-1 w-72 justify-center mt-2">
																								<div
																									className="text-xs cursor-pointer h-auto py-1 border  bg-primary-100 text-secondary-00 rounded-md text-center w-24 "
																									onClick={() => {
																										setMatchingIdUpload(true);
																										setCapturImage(true);
																										setDcoumentNumber(0);
																										setFileChange(true);
																										setDirectorIdType(
																											directorsName.idType === DocType.MYKAD
																												? DocType.MYKAD
																												: DocType.PASSPORT,
																										);
																									}}
																								>
																									Re-Capture
																								</div>
																							</IonRow> */}
																						</IonCol>
																					</IonRow>
																				</>
																			)}

																			{!formik.values?.document1 && (
																				<>
																					<IonRow className="w-72 ">
																						<FileUploader
																							handleChange={(e) => {
																								formik.setFieldValue('document1', e);
																								setFileChange(true);
																								handleImageCapture(e, 1);
																								documentDetailss();
																							}}
																							name="file"
																							types={fileTypes}
																						>
																							<IonCard className="w-64 h-40 border-2 border-dashed  border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00">
																								<IonCol>
																									<IonRow className="mt-5 mb-1 justify-center">
																										<IonIcon
																											className={`icon  h-7 w-9  uploadIcon   bg-primary-100`}
																										/>
																									</IonRow>
																									<IonRow className="mt-3 justify-center">
																										<IonLabel className="text-secondary-75 font-normal text-16px">
																											<IonLabel className="text-16px ml-0.5 text-secondary-75 font-normal leading-5">
																												{directorsName.idType === 'PASSPORT'
																													? 'Back Page of Director Passport'
																													: 'Back Side of MyKad'}
																											</IonLabel>
																										</IonLabel>
																									</IonRow>
																									<IonRow className="mt-1 justify-center">
																										<IonLabel className="text-secondary-75 font-normal text-14px">
																											<div>Upload or take a photo</div>
																										</IonLabel>
																									</IonRow>

																									{/* <IonRow className="flex justify-center">
																									<div
																										className="text-xs cursor-pointer mt-2 m-auto h-auto py-1 bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
																										onClick={() => {
																											setCapturImage(true);
																											setDcoumentNumber(1);
																											setFileChange(true);
																											setDirectorIdType(
																												directorsName.idType === DocType.MYKAD
																													? DocType.MYKAD
																													: DocType.PASSPORT,
																											);
																										}}
																									>
																										Capture
																									</div>
																								</IonRow> */}
																								</IonCol>
																							</IonCard>
																						</FileUploader>
																						<IonRow className="ml-2 mr-2  w-64 justify-center mt-2">
																							<div
																								className="text-xs cursor-pointer mb-2 m-auto h-auto py-1 bg-primary-100 rounded-md text-center text-secondary-00 w-24 align-center"
																								onClick={() => {
																									setMatchingIdUpload(true);
																									setCapturImage(true);
																									setDcoumentNumber(1);
																									setFileChange(true);
																								}}
																							>
																								Capture
																							</div>
																						</IonRow>
																					</IonRow>
																				</>
																			)}

																			{formik.values?.document1 && (
																				<>
																					<IonRow className="w-72 mt-0 md:mt-1 mb-3">
																						<IonCol className="p-0">
																							<IonRow>
																								<>
																									<IonCard className="mt-1.5 ml-3 w-64 h-40 border-2 border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																										<IonImg
																											src={
																												formik.values?.document1.type
																													? URL.createObjectURL(
																															formik.values?.document1,
																													  )
																													: formik.values?.document1
																											}
																										></IonImg>
																									</IonCard>
																								</>
																							</IonRow>

																							<IonRow className="mt-1 justify-center">
																								<IonLabel className="text-16px text-secondary-75 font-normal">
																									<IonLabel className="text-16px text-secondary-75 font-normal">
																										{directorsName.idType === 'PASSPORT'
																											? 'Back Page of Director Passport'
																											: 'Back Side of MyKad'}
																									</IonLabel>
																								</IonLabel>
																							</IonRow>

																							{/* <IonRow className=" w-72 justify-center mt-2">
																								<div
																									className=" text-xs cursor-pointer m-auto h-auto py-1 bg-primary-100 text-secondary-00 rounded-md text-center w-24 align-center"
																									onClick={() => {
																										setMatchingIdUpload(true);
																										setCapturImage(true);
																										setDcoumentNumber(1);
																										setFileChange(true);
																										setDirectorIdType(
																											directorsName.idType === DocType.MYKAD
																												? DocType.MYKAD
																												: DocType.PASSPORT,
																										);
																									}}
																								>
																									Re-Capture
																								</div>
																							</IonRow> */}
																						</IonCol>
																					</IonRow>
																				</>
																			)}
																		</div>

																		<IonRow className="my-1 mb-10 ml-3 md:ml-5 w-80">
																			<IonCol className="p-0">
																				<div className="pb-0.5 ">
																					<IonLabel
																						position="stacked"
																						className="font-normal text-secondary-75 text-16px"
																					>
																						Contact Name
																						<span className="text-failure text-xl">*</span>
																					</IonLabel>
																				</div>

																				<IonItem
																					className={`app-input-filed bg-secondary-00 mt-2.5 ${
																						formik.touched.contactName && formik.errors.contactName
																							? 'app-input-has-error'
																							: ''
																					}`}
																					lines="none"
																				>
																					<IonInput
																						placeholder="Customer Number"
																						className={`text-16px ${
																							appContext.selectedAccount()
																								? 'text-secondary-100'
																								: 'text-secondary-75'
																						} font-medium bg-secondary-00`}
																						value={formik.values.contactName}
																						name="contactName"
																						onBlur={formik.handleBlur}
																						onIonChange={(e) => {
																							formik.handleChange(e);
																							// handleShortNameChange(e);
																						}}
																						maxlength={23}
																					></IonInput>
																				</IonItem>
																			</IonCol>
																		</IonRow>

																		<IonRow className="my-1 mb-10 ml-3 md:ml-5 w-80">
																			<IonCol className="p-0">
																				<div className="pb-0.5">
																					<IonLabel
																						position="stacked"
																						className="font-normal text-secondary-75 text-16px"
																					>
																						Contact Number
																						<span className="text-failure text-xl">*</span>
																					</IonLabel>
																				</div>

																				<IonItem
																					className={`app-input-filed bg-secondary-00 mt-2.5 ${
																						formik.touched.contactNumber &&
																						formik.errors.contactNumber
																							? 'app-input-has-error'
																							: ''
																					}`}
																					lines="none"
																				>
																					<IonInput
																						placeholder="Customer Number"
																						className={`text-16px ${
																							appContext.selectedAccount()
																								? 'text-secondary-100'
																								: 'text-secondary-75'
																						} font-medium bg-secondary-00`}
																						value={formik.values.contactNumber}
																						name="contactNumber"
																						onBlur={formik.handleBlur}
																						onIonChange={(e) => {
																							formik.handleChange(e);
																							// handleShortNameChange(e);
																						}}
																						maxlength={23}
																					></IonInput>
																				</IonItem>
																			</IonCol>
																		</IonRow>

																		<IonRow className="my-1 mb-10 ml-3 md:ml-5 w-80">
																			<IonCol className="p-0">
																				<div className="pb-0.5">
																					<IonLabel
																						position="stacked"
																						className="font-normal text-secondary-75 text-16px"
																					>
																						Contact Email Id
																						<span className="text-failure text-xl">*</span>
																					</IonLabel>
																				</div>

																				<IonItem
																					className={`app-input-filed bg-secondary-00 mt-2.5 ${
																						formik.touched.contactEmailId &&
																						formik.errors.contactEmailId
																							? 'app-input-has-error'
																							: ''
																					}`}
																					lines="none"
																				>
																					<IonInput
																						placeholder="Customer Email Id"
																						className={`text-16px ${
																							appContext.selectedAccount()
																								? 'text-secondary-100'
																								: 'text-secondary-75'
																						} font-medium bg-secondary-00`}
																						value={formik.values.contactEmailId}
																						name="contactEmailId"
																						onBlur={formik.handleBlur}
																						onIonChange={(e) => {
																							formik.handleChange(e);
																							// handleShortNameChange(e);
																						}}
																						maxlength={23}
																					></IonInput>
																				</IonItem>
																			</IonCol>
																		</IonRow>

																		{!isIdUploaded && (
																			<IonRow className="md:w-full ml-2 md:ml-2.5 md:pl-1.5 mt-7 md:mt-0">
																				<IonButton
																					type="submit"
																					expand="block"
																					className="h-36px capitalize rounded-md font-normal mb-5"
																					disabled={
																						!isFormValid() ||
																						formik.isSubmitting ||
																						(!capturedImage0 && !capturedImage1)
																					}
																				>
																					<div
																						className="text-16px"
																						onClick={() => matchingToggleAccordion(currentIdIndex)}
																					>
																						{formik.isSubmitting ? <IonSpinner /> : 'Upload'}
																					</div>
																				</IonButton>
																			</IonRow>
																		)}
																	</>
																)}
															</IonCard>
														</IonRow>
													</IonRow>
												</div>
											);
										})}
								</IonRow>

								<IonRow className="w-11/12 ml-5">
									{nonMatchingIdProofs && nonMatchingIdProofs.length > 0 && (
										<>
											<IonLabel className="w-full md:ml-2.5 mb-1 text-18px text-secondary-75 font-normal">
												Non-Matching ID Proof's
											</IonLabel>
										</>
									)}

									{nonMatchingIdProofs &&
										nonMatchingIdProofs.map((nonMatchingIdProof, currentIdIndex) => {
											const nonMatchData = nonMatchingIdProof
												? JSON.parse(JSON.parse(nonMatchingIdProof.extractedData))
												: undefined;
											continueButtonValue = true;
											return (
												<>
													<div>
														<IonRow key={currentIdIndex} className="m-0 p-0 w-screen ">
															<IonRow className="w-screen">
																<IonCard className="bg-secondary-00 w-full md:w-652px mx-0 ml-0 md:ml-3 my-1 mr-10 md:mr-6 mb-1">
																	<div className="w-full md:w-652px shadow-none">
																		<IonCardHeader
																			onClick={() => nonMatchingToggleAccordion(currentIdIndex)}
																			style={{
																				height: '50px',
																				display: 'flex',
																				justifyContent: 'center',
																				alignItems: 'center',
																				width: '100%',
																				cursor: 'pointer',
																			}}
																		>
																			<div className="flex items-center justify-between w-full">
																				<div className="flex items-center flex-wrap">
																					<div className="font-normal text-secondary-100 text-center">
																						<div className="w-full mx-auto font-normal text-16px">
																							{nonMatchData ? nonMatchData.name : 'Not-available'}
																						</div>
																					</div>
																				</div>

																				<div className="flex">
																					<IonIcon
																						icon={alertCircle}
																						className="h-8 w-8 text-pending"
																					></IonIcon>
																				</div>
																			</div>
																		</IonCardHeader>
																	</div>

																	{expandedNonMatchingItem === currentIdIndex && (
																		<>
																			<div
																				className="bg-secondary-00 md:ml-2 md:bg-transparent"
																				style={{
																					display: 'flex',
																					flexDirection: isMobile ? 'column' : 'row',
																					// alignItems: 'center',
																					// marginBottom: '22px',
																				}}
																			>
																				{formik.values?.document0 && (
																					<>
																						<IonRow className="w-72">
																							<>
																								<IonCard className="md:ml-0.5 w-64 h-40 border-2 border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																									<IonImg
																										src={
																											formik.values?.document0.type
																												? URL.createObjectURL(
																														formik.values?.document0,
																												  )
																												: formik.values?.document0
																										}
																									></IonImg>
																								</IonCard>
																								{/* {!isMobile && ( */}
																								<IonRow className="w-64 flex justify-center">
																									<div className="ml-5 md:ml-0"></div>
																									<FileUploader
																										handleChange={(e) => {
																											formik.setFieldValue('document0', e);
																											setFileChange(true);
																											handleImageCapture(e, 0);
																											documentDetailss();
																										}}
																										name="file"
																										types={fileTypes}
																									>
																										<div className=" mt-2 cursor-pointer mb-5 text-xs m-0 h-auto py-1 px-2  bg-primary-100 text-secondary-00  rounded-md text-center align-center">
																											Re-Upload
																										</div>
																									</FileUploader>
																									<div className="ml-2 mt-2 mr-2">or</div>
																									<div
																										className=" mt-2 cursor-pointer mb-5 text-xs m-0 h-auto py-1 px-2  bg-primary-100 text-secondary-00  rounded-md text-center align-center"
																										onClick={() => {
																											setNonMatchingIdUpload(true);
																											setCapturImage(true);
																											setDcoumentNumber(0);
																											setFileChange(true);
																											setCurrentNonDocumentId(
																												nonMatchingIdProofs[currentIdIndex],
																											);
																											formik.setFieldValue(
																												'documentType',
																												nonMatchingIdProofs[currentIdIndex]
																													.docType === DocType.MYKAD
																													? DocType.MYKAD
																													: DocType.PASSPORT,
																											);
																										}}
																									>
																										Re-Capture
																									</div>
																								</IonRow>
																								{/* )} */}
																							</>
																						</IonRow>
																					</>
																				)}

																				{formik.values?.document1 && (
																					<>
																						<IonRow className="w-72">
																							<>
																								<IonCard className=" w-64 h-40 border-2 border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-transparent">
																									<IonImg
																										src={
																											formik.values?.document1.type
																												? URL.createObjectURL(
																														formik.values?.document1,
																												  )
																												: formik.values?.document1
																										}
																									></IonImg>
																								</IonCard>
																								{/* {!isMobile && ( */}
																								<IonRow className="w-64 flex justify-center">
																									<div className="ml-5 md:ml-3.5"></div>
																									<FileUploader
																										handleChange={(e) => {
																											formik.setFieldValue('document1', e);
																											setFileChange(true);
																											handleImageCapture(e, 1);
																											documentDetailss();
																										}}
																										name="file"
																										types={fileTypes}
																									>
																										<div className=" mt-2 cursor-pointer mb-5 text-xs m-0 h-auto py-1 px-2  bg-primary-100 text-secondary-00  rounded-md text-center align-center">
																											Re-Upload
																										</div>
																									</FileUploader>
																									<div className="ml-2 mt-2 mr-2">or</div>
																									<div
																										className="mt-2 mb-5 cursor-pointer text-xs m-0 h-auto py-1 px-2 bg-primary-100 text-secondary-00  rounded-md text-center w-25 align-center"
																										onClick={() => {
																											setNonMatchingIdUpload(true);
																											setCapturImage(true);
																											setDcoumentNumber(1);
																											setFileChange(true);
																											setCurrentNonDocumentId(
																												nonMatchingIdProofs[currentIdIndex],
																											);
																											formik.setFieldValue(
																												'documentType',
																												nonMatchingIdProofs[currentIdIndex]
																													.docType === DocType.MYKAD
																													? DocType.MYKAD
																													: DocType.PASSPORT,
																											);
																										}}
																									>
																										Re-Capture
																									</div>
																								</IonRow>
																								{/* )} */}
																							</>
																						</IonRow>
																					</>
																				)}
																			</div>
																			<IonRow className="md:w-full ml-2 md:ml-2.5 md:pl-1.5 mt-7 md:mt-0">
																				<IonButton
																					type="submit"
																					expand="block"
																					className="h-36px capitalize rounded-md font-normal mb-5"
																					disabled={
																						!isFormValid() ||
																						formik.isSubmitting ||
																						(!capturedImage0 && !capturedImage1)
																					}
																				>
																					<div
																						className="text-16px"
																						onClick={() =>
																							nonMatchingToggleAccordion(currentIdIndex)
																						}
																					>
																						{formik.isSubmitting ? <IonSpinner /> : 'Upload'}
																					</div>
																				</IonButton>
																			</IonRow>
																		</>
																	)}
																</IonCard>
															</IonRow>
														</IonRow>
													</div>
												</>
											);
										})}
								</IonRow>
							</IonRow>

							<IonRow className="w-full">
								<IonButton
									className="ml-5 md:ml-8 h-36px pt-0.5 mb-11 mt-5 capitalize font-normal text-16px rounded-md"
									disabled={!isFormValidForContinue() || formik.isSubmitting}
									onClick={() => {
										continueClick();
									}}
								>
									Continue
								</IonButton>
							</IonRow>
						</>
					)}
				</IonRow>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</form>
	);
}
