import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { IonButton, IonCard, IonLabel, IonCol, IonRow, IonGrid } from '@ionic/react';
import {
	CameraPreview,
	CameraPreviewPictureOptions,
	CameraPreviewOptions,
} from '@capacitor-community/camera-preview';
import { base64toBlob } from 'utils/image-converter';
import { DocType } from 'API';

const IdCapture = ({ onImageCapture, documentDetails }) => {
	const [imageCaptured, setImageCaptured] = useState(false);
	const [cameraStarted, setCameraStarted] = useState(false);

	useEffect(() => {
		if (!imageCaptured) {
			startCameraPreview();
		}

		return () => {
			stopCamera();
		};
	}, []);

	const startCameraPreview = async () => {
		console.log('Camera Started.....');
		try {
			const cameraPreviewOptions = {
				parent: 'cameraPreview',
				position: documentDetails.documentType === DocType.SELFIE ? 'front' : 'rear',
			};
			await CameraPreview.start(cameraPreviewOptions);
			setCameraStarted(true);
		} catch (error) {
			console.error('Error starting camera preview', error);
		}
	};

	const stopCamera = async () => {
		try {
			CameraPreview.stop();
			console.log('Camera Stoped.....');
		} catch (error) {
			console.error('Error stopping camera preview', error);
		}
	};

	const handleCapture = async () => {
		try {
			const pictureOptions: CameraPreviewPictureOptions = {
				// width:  documentDetails.documentType === DocType.SELFIE ? 768 : 1024,
				// height:  documentDetails.documentType === DocType.SELFIE ? 1024 : 768,
				width: 1024,
				height: 768,
				quality: 90,
			};

			const result = await CameraPreview.capture(pictureOptions);
			// console.log(result);
			if (result && result.value) {
				// const imageBlob = dataURItoBlob(result.value);
				const image = base64toBlob(result.value, 'image/png');
				// console.log(image);
				const file = new File([image], 'captured-image.png', { type: 'image/png' });
				await stopCamera();
				onImageCapture(file, documentDetails.documentNumber);
				// console.log(file);
				setImageCaptured(true);
			}
		} catch (error) {
			console.error('Error in camera', error);
		}
	};

	return (
		<section className="flex justify-start items-start h-full flex-col ml-0 ">
			<IonRow className="">
				<IonCol size="auto">
					<div className="flex flex-col justify-start items-start">
						<IonLabel className="text-secondary-75 text-16px flex justify-start rounded-md items-center font-normal mb-2 ml-2.5">
							Capture a photo of
							{documentDetails.documentType === DocType.SELFIE
								? ' yourself'
								: documentDetails.documentNumber === 0
								? ' the Front'
								: ' the Back'}{' '}
							{documentDetails.documentType === DocType.MYKAD
								? 'side'
								: documentDetails.documentType === DocType.SELFIE
								? ''
								: 'page'}
							{documentDetails.documentType === DocType.SELFIE ? '' : ' of your'}{' '}
							{documentDetails.documentType === DocType.MYKAD
								? ' MyKad'
								: documentDetails.documentType === DocType.SELFIE
								? ''
								: documentDetails.documentType === DocType.PASSPORT
								? ' Passport'
								: 'Document'}
						</IonLabel>
						<IonCard
							className={` ${
								documentDetails.documentType === DocType.SELFIE ? 'h-40 w-64' : 'w-64 h-40'
							}  flex justify-start border-2 border-secondary-25 bg-secondary-25 shadow-none rounded-14 relative overflow-hidden`}
						>
							<div id="cameraPreview" style={{ width: '256px', height: '167px' }}></div>
						</IonCard>
						<div className="ml-2.5 flex justify-center mt-4">
							<IonButton
								className="text-16px font-normal capitalize"
								onClick={handleCapture}
								disabled={!cameraStarted}
							>
								Capture Image
							</IonButton>
						</div>
					</div>
				</IonCol>
			</IonRow>
		</section>
	);
};

export default IdCapture;
