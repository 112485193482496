export const pageTitles = {

    //! basic pages
    "/home": {
        title: "Home",
        backBtn: true,
        profile: false,
        account: false
    },
    "/profile": {
        title: "Profile",
        backBtn: true,
        profile: false,
        account: false
    },

    //! admin pages
    "/admin": {
        title: "Admin Dashboard",
        backBtn: false,
        profile: false,
        account: false,
        home: true,
    },
    "/users": {
        title: "All Accounts",
        backBtn: false,
        profile: false,
        account: false,
        home: true,
    },
    "/accounts": {
        title: "All Accounts",
        backBtn: false,
        profile: false,
        account: false,
        home: true,
    },
    "/accounts/:accountId": {
        title: "Account Details", // Set the title for /accounts/:accountId route
        backBtn: true,
        profile: false,
        account: true,
        home: false,
    },


    //!user pages
    "/account-selection": {
        title: "Account Selection",
        backBtn: false,
        profile: false,
        account: false,
        home: true,
    },
    "/pre-requisite": {
        title: "Pre - Requisite",
        backBtn: true,
        profile: false,
        account: false
    },
    "/kyc": {
        title: "Customer Verification",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/basic-details": {
        title: "Basic Details",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/account-questionnaire": {
        title: "Account Questionnaire",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/id-submission-individual": {
        title: "ID Proof Submission",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/id-submission-business-auth": {
        title: "ID Proof Submission",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/id-submission-business": {
        title: "ID Proof Submission",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/ssm-submission-business": {
        title: "SSM Submission",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/authorization-letter-business": {
        title: "Authorozation Letter Submission",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/selfie-verification": {
        title: "Live Verification",
        backBtn: true,
        profile: false,
        account: true
    },
    "/kyc/link-bank-account": {
        title: "Link Bank Acc",
        backBtn: true,
        profile: false,
        account: true
    },
}
