import {
	IonButton,
	IonCard,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonLabel,
	IonRow,
	IonSpinner,
	useIonToast,
} from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AccountType, DocType, DocCategory, AccountStatus, Account, VerificationStatus } from 'API';
import DocumentApi from 'api/document';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { pdfSplitter } from 'utils/pdf-helper';
import { Routes } from 'utils/routes';
import * as clientStorage from 'utils/client-storage';
import AccountApi from 'api/account';
import RinngitPayLoader from 'components/RinngitPayLoader';
import PDFViewer from 'components/PDFViwer';
import { removeTypenameFromS3 } from 'utils/object-helper';
import { Toaster, toast } from 'react-hot-toast';
import { uploadedBusinessDocument, uploadedDocuments } from 'utils/uploaded-documents';

export default function AuthorizationLetter() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const fileTypes = ['PDF'];
	const document0 = useRef<HTMLInputElement>(null);
	let [imageIndex, setImageIndex] = useState<number>(0);
	const [fileChange, setFileChange] = useState<boolean>(false);
	let isAdmin = appContext.userDetails.signInUserSession.idToken.payload['cognito:groups'] || [];
	const [updateAccount, setUpdateAccount] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(false);
	const accountDetails = appContext.selectedAccount();
	const userDetails = appContext.userDetails;
	const [showToast, setShowToast] = useState(false);

	const docType = DocType.AUTHLETTER;
	const validationSchema = Yup.object({
		documentType: Yup.string().required(),
		docCategory: Yup.string().required(),
		document0: Yup.string(),
	});

	let formik = useFormik({
		initialValues: {
			documentType: docType,
			docCategory: DocCategory.BUSINESS,
			document0: undefined as any,
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			onNext(values, setSubmitting);
		},
	});

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
		setShowToast(false);
	};

	useEffect(() => {
		console.log('Authorization Letter Submission Page for Business');
		console.log('Selected Account: ', accountDetails);
		formik.setFieldValue('documentType', docType);
		let document = accountDetails.referenceDocs?.items?.filter((obj) => obj.docType === docType);
		console.log(document && document[0]);
		loadImagefromS3(document && document[0]);
	}, []);

	async function getAccount() {
		const getAccountDetails = await AccountApi.getByUser(
			appContext.isAdmin ? accountDetails?.owner : appContext.userDetails?.username,
		).then((accounts) => {
			return accounts;
		});
		const selectedAccount = getAccountDetails.find((account) => {
			return account.id === appContext.selectedAccount().id;
		});
		console.log('--------', selectedAccount);

		appContext.setSelectedAccount(selectedAccount);
	}

	const onNext = async (formState, setSubmitting) => {
		try {
			setIsLoading(true);
			if (fileChange) {
				const pages = setUplaodDocument();
				const idDocument =
					appContext.selectedAccount()?.referenceDocs.items.find((doc) => doc.docType == docType) ||
					{};
				idDocument.docType = docType;
				idDocument.docCategory = DocCategory.BUSINESS;
				idDocument.accountId = accountDetails.id;
				idDocument.updatedBy = appContext.userDetails.username;
				idDocument.owner = accountDetails.owner;
				idDocument.status = VerificationStatus.PENDING;
				const updatedPages = (idDocument.pages || []).map((page) => removeTypenameFromS3(page));
				idDocument.pages = updatedPages;
				console.log('idDocument', idDocument);
				const createDocument = await DocumentApi.save(idDocument);
				await DocumentApi.upload(idDocument, pages, createDocument.id, false);
				idDocument.id = createDocument.id;
				const updateDocument = await DocumentApi.save(idDocument);
				// const extractResult = await DocumentApi.extractID(createDocument.id);
				// console.log('extractResult', extractResult);
				appContext.setSelectedAccount(createDocument.account);

				const { allStepsCompleted, incompleteSteps } = await isAllStepsCompleted();
				console.log('Is All Steps Completed: ', allStepsCompleted);
				console.log('InComplete Steps: ', incompleteSteps);
				if (!allStepsCompleted) {
					console.log('Please complete all the steps');
					incompleteSteps.map((incompleteSteps) => {
						if (incompleteSteps.accountType !== undefined && !incompleteSteps.authDocument) {
							console.log('No Valid Account Type');
							notify({ toastMessage: 'No Valid Account Type', toastType: 'error' });
						}

						if (incompleteSteps.individualId !== undefined && !incompleteSteps.individualId) {
							console.log('Individual id not uploaded');
							notify({ toastMessage: 'Complete Proof Submission', toastType: 'error' });
						}

						if (incompleteSteps.selfieDocument !== undefined && !incompleteSteps.selfieDocument) {
							console.log('selfieDocument id not uploaded');
							notify({ toastMessage: 'Complete Face Verification', toastType: 'error' });
						}

						if (incompleteSteps.ssmDocument !== undefined && !incompleteSteps.ssmDocument) {
							console.log('ssmDocument id not uploaded');
							notify({ toastMessage: 'Complete SSM Submission', toastType: 'error' });
						}

						if (incompleteSteps.directorProof !== undefined && !incompleteSteps.directorProof) {
							console.log('directorProof id not uploaded');
							notify({ toastMessage: 'Complete Director Proof Submission', toastType: 'error' });
						}

						if (incompleteSteps.bankaccount !== undefined && !incompleteSteps.bankaccount) {
							console.log('bankaccount id not uploaded');
							notify({ toastMessage: 'Complete Bank Account', toastType: 'error' });
						}

						if (incompleteSteps.authDocument !== undefined && !incompleteSteps.authDocument) {
							console.log('authDocument id not uploaded');
							notify({
								toastMessage: 'Complete Authorization Letter Submission',
								toastType: 'error',
							});
						}
					});
				}

				await updateAccountStatus(allStepsCompleted);

				if (appContext.isAdmin) {
					setTimeout(() => {
						navigate(Routes.MANAGE_ACCOUNTS, { state: true });
					}, 3000);
					clientStorage.clearSelectedAccount();
				} else {
					if (allStepsCompleted) {
						navigate(Routes.PENDING);
						clientStorage.clearSelectedAccount();
					} else {
						navigate(Routes.ACCOUNT_SELECTION);
						clientStorage.clearSelectedAccount();
					}
				}
				setIsLoading(false);
			} else {
				const { allStepsCompleted, incompleteSteps } = await isAllStepsCompleted();
				console.log('Is All Steps Completed: ', allStepsCompleted);
				console.log('InComplete Steps: ', incompleteSteps);
				if (!allStepsCompleted) {
					console.log('Please complete all the steps');
					incompleteSteps.map((incompleteSteps) => {
						if (incompleteSteps.accountType !== undefined && !incompleteSteps.authDocument) {
							console.log('No Valid Account Type');
							notify({ toastMessage: 'No Valid Account Type', toastType: 'error' });
						}

						if (incompleteSteps.individualId !== undefined && !incompleteSteps.individualId) {
							console.log('Individual id not uploaded');
							notify({ toastMessage: 'Complete Proof Submission', toastType: 'error' });
						}

						if (incompleteSteps.selfieDocument !== undefined && !incompleteSteps.selfieDocument) {
							console.log('selfieDocument id not uploaded');
							notify({ toastMessage: 'Complete Face Verification', toastType: 'error' });
						}

						if (incompleteSteps.ssmDocument !== undefined && !incompleteSteps.ssmDocument) {
							console.log('ssmDocument id not uploaded');
							notify({ toastMessage: 'Complete SSM Submission', toastType: 'error' });
						}

						if (incompleteSteps.directorProof !== undefined && !incompleteSteps.directorProof) {
							console.log('directorProof id not uploaded');
							notify({ toastMessage: 'Complete Director Proof Submission', toastType: 'error' });
						}

						if (incompleteSteps.bankaccount !== undefined && !incompleteSteps.bankaccount) {
							console.log('bankaccount id not uploaded');
							notify({ toastMessage: 'Complete Bank Account', toastType: 'error' });
						}

						if (incompleteSteps.authDocument !== undefined && !incompleteSteps.authDocument) {
							console.log('authDocument id not uploaded');
							notify({
								toastMessage: 'Complete Authorization Letter Submission',
								toastType: 'error',
							});
						}
					});
				}
				await updateAccountStatus(allStepsCompleted);

				if (appContext.isAdmin) {
					setTimeout(() => {
						navigate(Routes.MANAGE_ACCOUNTS, { state: true });
					}, 3000);
					clientStorage.clearSelectedAccount();
				} else {
					if (allStepsCompleted) {
						navigate(Routes.PENDING);
						clientStorage.clearSelectedAccount();
					} else {
						navigate(Routes.ACCOUNT_SELECTION);
						clientStorage.clearSelectedAccount();
					}
				}
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
			console.log(error);
			console.error('Failed uploading document', error);
		}
	};

	const isAllStepsCompleted = async () => {
		let stepsBussinessCompleted = {
			accountType: false,
			individualId: false,
			selfieDocument: false,
			ssmDocument: false,
			directorProof: false,
			bankaccount: false,
			authDocument: false,
		};

		const accList = appContext.selectedAccount()?.linkedBankAccounts
			? appContext.selectedAccount()?.linkedBankAccounts.items
			: appContext.selectedAccount()?.account.linkedBankAccounts.items;

		if (
			appContext.selectedAccount() &&
			appContext.selectedAccount().type === AccountType.BUSINESS
		) {
			const hasAccountType = accountDetails?.type === AccountType.BUSINESS ? true : false;
			stepsBussinessCompleted.accountType = hasAccountType;

			if (!appContext.isAdmin) {
				const isIndividualIdUploaded = await uploadedBusinessDocument(
					appContext.selectedAccount(),
					[DocType.MYKAD, DocType.PASSPORT],
					DocCategory.INDIVIDUAL,
					AccountType.BUSINESS,
				);
				stepsBussinessCompleted.individualId = isIndividualIdUploaded ? true : false;

				const isSelfieDocumentUploaded = await uploadedDocuments(
					appContext.selectedAccount(),
					DocType.SELFIE,
					DocCategory.INDIVIDUAL,
					AccountType.BUSINESS,
				);
				stepsBussinessCompleted.selfieDocument = isSelfieDocumentUploaded ? true : false;
			} else {
				stepsBussinessCompleted.individualId = true;
				stepsBussinessCompleted.selfieDocument = true;
			}

			const isSsmDocumentUploaded = await uploadedDocuments(
				appContext.selectedAccount(),
				DocType.SSM,
				DocCategory.BUSINESS,
				AccountType.BUSINESS,
			);
			stepsBussinessCompleted.ssmDocument = isSsmDocumentUploaded ? true : false;

			const bankaccount = accList.length > 0;
			stepsBussinessCompleted.bankaccount = bankaccount;

			const isDirectorsDocumentUploaded = await uploadedBusinessDocument(
				appContext.selectedAccount(),
				[DocType.MYKAD, DocType.PASSPORT],
				DocCategory.BUSINESS,
				AccountType.BUSINESS,
			);
			stepsBussinessCompleted.directorProof = isDirectorsDocumentUploaded ? true : false;

			const isAuthDocumentUploaded = await uploadedDocuments(
				appContext.selectedAccount(),
				DocType.AUTHLETTER,
				DocCategory.BUSINESS,
				AccountType.BUSINESS,
			);
			stepsBussinessCompleted.authDocument = isAuthDocumentUploaded ? true : false;
		}

		const allStepsCompleted = Object.values(stepsBussinessCompleted).every((step) => step);

		const incompleteSteps = Object.keys(stepsBussinessCompleted)
			.filter((step) => !stepsBussinessCompleted[step])
			.map((step) => ({ [step]: false }));

		console.log(stepsBussinessCompleted);
		return {
			allStepsCompleted,
			incompleteSteps,
		};
	};

	const updateAccountStatus = async (allStepsCompleted) => {
		const updateAccountStatus: Account = await AccountApi.save({
			id: accountDetails?.id,
			shortName: accountDetails?.shortName,
			owner: accountDetails?.owner,
			updatedBy: userDetails?.username,
			createdBy: userDetails.username,
			type: accountDetails?.type,
			status: allStepsCompleted ? AccountStatus.PENDING : AccountStatus.DRAFT,
		});
	};

	function setUplaodDocument() {
		return [formik.values?.document0 as unknown as File];
	}

	function loadImagefromS3(imageDetails) {
		setIsLoading(true);
		console.log('get image url', imageDetails);

		imageDetails?.pages?.forEach((element, index) => {
			console.log(element);
			DocumentApi.getImage(element.key).then((res) => {
				formik.setFieldValue('document' + index, res);
			});
		});
		setIsLoading(false);
	}

	const handleFileChange = async (file, key) => {
		setFileChange(true);
		console.log(file, key);
		formik.setFieldValue(key, file);
	};

	const isFormValid = () => {
		if (docType === DocType.AUTHLETTER) {
			return formik.values.document0?.type || formik.values.document0;
		}
		return false;
	};

	const dummyClick = async () => {
		const { allStepsCompleted, incompleteSteps } = await isAllStepsCompleted();
		console.log('Is All Steps Completed: ', allStepsCompleted);
		console.log('InComplete Steps: ', incompleteSteps);
		if (!allStepsCompleted) {
			console.log('Please complete all the steps');
			incompleteSteps.map((incompleteSteps) => {
				if (incompleteSteps.accountType !== undefined && !incompleteSteps.authDocument) {
					console.log('No Valid Account Type');
					notify({ toastMessage: 'No Valid Account Type', toastType: 'error' });
				}

				if (incompleteSteps.individualId !== undefined && !incompleteSteps.individualId) {
					console.log('Individual id not uploaded');
					notify({ toastMessage: 'Complete Proof Submission', toastType: 'error' });
				}

				if (incompleteSteps.selfieDocument !== undefined && !incompleteSteps.selfieDocument) {
					console.log('selfieDocument id not uploaded');
					notify({ toastMessage: 'Complete Face Verification', toastType: 'error' });
				}

				if (incompleteSteps.ssmDocument !== undefined && !incompleteSteps.ssmDocument) {
					console.log('ssmDocument id not uploaded');
					notify({ toastMessage: 'Complete SSM Submission', toastType: 'error' });
				}

				if (incompleteSteps.directorProof !== undefined && !incompleteSteps.directorProof) {
					console.log('directorProof id not uploaded');
					notify({ toastMessage: 'Complete Director Proof Submission', toastType: 'error' });
				}

				if (incompleteSteps.bankaccount !== undefined && !incompleteSteps.bankaccount) {
					console.log('bankaccount id not uploaded');
					notify({ toastMessage: 'Complete Bank Account', toastType: 'error' });
				}

				if (incompleteSteps.authDocument !== undefined && !incompleteSteps.authDocument) {
					console.log('authDocument id not uploaded');
					notify({
						toastMessage: 'Complete Authorization Letter Submission',
						toastType: 'error',
					});
				}
			});
		} else {
			console.log('pending');
		}
	};

	return (
		<form className="mt-5 md:ml-4 md:pl-0.5 " onSubmit={formik.handleSubmit}>
			{!isLoading ? (
				<section className="">
					<IonRow className="ml-5">
						<IonRow className="md:w-3/4">
							<h2 className="mt-1 mb-1.5 font-bold text-22px text-secondary-75">
								Authorization Letter Submission
							</h2>
						</IonRow>
						<IonRow className="md:w-11/12">
							<div className="text-16px font-normal mt-5 mb-2.5 text-secondary-75">
								Once you upload a copy of Authorization Letter, the system will automatically
								capture the information in the document and auto fill your details.
							</div>
						</IonRow>
					</IonRow>
					<IonRow className="">
						<IonRow class="ml-3  md:w-11/12">
							<IonRow class=" ">
								{docType === DocType.AUTHLETTER && (
									<IonRow>
										<input
											type="file"
											ref={document0}
											className="hidden"
											accept="application/pdf"
											onChange={(e) => {
												// @ts-ignore
												handleFileChange(Array.from(e.target.files)[0], 'document0');
											}}
										/>
										{docType === DocType.AUTHLETTER && (
											<>
												{!formik.values.document0 && (
													<>
														<FileUploader
															handleChange={(e) => {
																formik.setFieldValue('document0', e);
																setFileChange(true);
															}}
															name="file"
															types={fileTypes}
														>
															<IonCard className="w-60 h-96 border-2 border-dashed  border-secondary-100 rounded-lg  overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00">
																<IonRow>
																	<i
																		className={`icon h-7 w-9 uploadIcon  m-auto mb-1 ${
																			docType === DocType.AUTHLETTER
																				? 'bg-primary-100'
																				: 'bg-secondary-00'
																		}`}
																	></i>
																</IonRow>
																<IonRow>
																	<IonLabel className="py-2 px-16 font-normal text-secondary-75 text-16px text-center">
																		{' '}
																		Browse Authorization Document
																	</IonLabel>
																</IonRow>
															</IonCard>
														</FileUploader>
														<IonRow className="ml-2 mt-5 w-screen">
															{/* {formik.values.document0 && (
													<IonButton
															type="submit"
															expand="block"
															className="h-36px capitalize text-16px rounded-md mr-3"
															onClick={() => {
																handleFileChange('', 'document0');
															}}
														>
															Change
														</IonButton>
														)} */}
															{/* <IonButton
																type="submit"
																expand="block"
																className="h-36px capitalize text-16px rounded-md"
																disabled={!isFormValid() || formik.isSubmitting}
															>
																{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
															</IonButton> */}
														</IonRow>
													</>
												)}

												{formik.values.document0 && (
													<>
														<IonCard className="ml-2.5 w-80 pb-5 p-2 h-104 border-2 border-secondary-25 rounded-lg overflow-hidden flex flex-col justify-center items-center text-center bg-secondary-00">
															<PDFViewer pdfFile={formik.values.document0} popup={false} />
														</IonCard>
														{/* <IonRow className="w-11/12">
														<IonLabel className="py-2 px-3 font-normal text-16px text-secondary-75 text-center">
															Authorization Letter
														</IonLabel>
													</IonRow> */}

														{/* <IonRow className="w-11/12 mt-3 mb-5">
															<IonRow>
																<IonButton
																	className=" capitalize ml-2.5 mt-2 mb-5 text-xs m-auto  bg-primary-100 text-secondary-00  rounded-md text-center  align-center"
																	onClick={() => {
																		handleFileChange('', 'document0');
																	}}
																>
																	Change
																</IonButton>
																<IonButton
																	type="submit"
																	expand="block"
																	className="h-36px capitalize mt-2 text-16px rounded-md ml-3"
																	disabled={!isFormValid() || formik.isSubmitting}
																>
																	{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
																</IonButton>
															</IonRow>
														</IonRow> */}
													</>
												)}

												<IonRow className="w-11/12 mt-3 mb-5">
													<IonRow>
														<IonButton
															className=" capitalize ml-2.5 mt-2 mb-5 text-xs m-auto  bg-primary-100 text-secondary-00  rounded-md text-center  align-center"
															onClick={() => {
																handleFileChange('', 'document0');
															}}
														>
															Change
														</IonButton>
														<IonButton
															type="submit"
															expand="block"
															className="h-36px capitalize mt-2 text-16px rounded-md ml-3"
															disabled={!isFormValid() || formik.isSubmitting}
														>
															{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
														</IonButton>
														{/* <IonButton
															onClick={dummyClick}
															className="h-36px capitalize mt-2 text-16px rounded-md ml-3"
														>
															Check
														</IonButton> */}
													</IonRow>
												</IonRow>
											</>
										)}
									</IonRow>
								)}
							</IonRow>
						</IonRow>
					</IonRow>
				</section>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</form>
	);
}
