import { IonCol, IonGrid, IonIcon, IonList, IonPage, IonRow, IonText } from '@ionic/react';
import useMobileDetector from 'hooks/use-mobile-detector';
import React, { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './Layout.scss';
import Promotion from './Promotion';
import AppContext from 'utils/app-context';
import Header from './Header';
import NavBar from './NavBar';
import { Routes } from 'utils/routes';
import adminUserIcon from '../images/adminMerchantIcon.svg';
import adminMerchantIcon from '../images/adminUserIcon.svg';

const Layout = () => {
	const device = useMobileDetector();
	const appContext = useContext(AppContext);
	const isAdmin =
		appContext?.userDetails?.signInUserSession?.idToken?.payload['cognito:groups'] || [];
	const navigate = useNavigate();

	function manageUserClick() {
		navigate(Routes.MANAGE_USERS);
	}

	function manageAccountClick() {
		navigate(Routes.MANAGE_ACCOUNTS);
	}

	return (
		<IonPage className="bg-secondary-25 overflow-hidden h-screen">
			<IonGrid className="p-0 m-0 h-full overflow-x-hidden bg-transparent">
				<div className="fixed top-0 w-full z-50">
					<Header />
				</div>

				{device !== 'mobile' && appContext.isAdmin && (
					<div
						className="mt-14 grid h-screen"
						style={{ gridTemplateColumns: 'repeat(24, minmax(0, 1fr))' }}
					>
						<div className="w-88px h-full bg-secondary-90 text-secondary-00 fixed ">
							<div>
								<IonIcon
									className="w-8 h-16 ml-6 mt-6 mb-0 cursor-pointer"
									icon={adminMerchantIcon}
									onClick={manageAccountClick}
								></IonIcon>
								<div className="text-14px pl-2 mt-0 cursor-pointer" onClick={manageAccountClick}>
									Merchant Accounts
								</div>
							</div>
							<div>
								<IonIcon
									className="w-8 h-16 ml-7 mt-5 mb-0 cursor-pointer"
									icon={adminUserIcon}
									onClick={manageUserClick}
								></IonIcon>
								<div
									className="text-14px text-center mt-0 mr-1 cursor-pointer"
									onClick={manageUserClick}
								>
									BO User
								</div>
							</div>
						</div>

						<div></div>

						<div className="ml-12 lg:ml-10 2xl:ml-6 mb-8 col-span-23">
							<Outlet />
						</div>
					</div>
				)}

				{device !== 'mobile' && !appContext.isAdmin && (
					<div className="mt-14">
						<Outlet />
					</div>
				)}
				{device === 'mobile' && (
					<div className="mt-14">
						<Outlet />
					</div>
				)}
			</IonGrid>
		</IonPage>
	);
};

export default Layout;
