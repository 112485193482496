
import { Account } from 'API';
import { API } from "aws-amplify";
import { CommentInput, VerificationStatus } from 'API';
import { createBankAccount, updateBankAccount, verifyBankAccount } from "graphql/mutations";
import get from 'lodash.get';
import { convert } from 'utils/object-helper';


export class BankAccountModel {
    id?: string | null;
    bankName?: string;
    accountNumber?: string;
    accountHolderName?: string | null;
    accountCurrency?: string;
    default?: boolean | null;
    disabled?: boolean | null;
    status?: VerificationStatus | null;
    comments?: Array<CommentInput | null> | null;
    verifiedBy?: string | null;
    accountId?: string | null;
    updatedBy?: string | null;
    owner?: string | null;
};

const BankAccountApi = {
    save: async function (bankAccount: BankAccountModel) {
        let response = await API.graphql({
            query: bankAccount.id ? updateBankAccount : createBankAccount,
            variables: {
                input: convert<BankAccountModel>(bankAccount, new BankAccountModel())
            }
        });

        return get(response, bankAccount.id ? 'data.updateBankAccount' : 'data.createBankAccount', []) as Account;

    },
    verifyBankAccount: async function (bankAccountId: string) {
        return API.graphql({
            query: verifyBankAccount,
            variables: {
                bankAccountId: bankAccountId
            }
        });
    },


};



export default BankAccountApi
