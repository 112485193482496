// src/components/PDFViewer.js
import React, { useState, useEffect, useContext } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { IonIcon, IonRow, IonText } from '@ionic/react';
import { caretBackCircleSharp, caretForwardCircleSharp } from 'ionicons/icons';
import { useLocation } from 'react-router-dom';
import AppContext from 'utils/app-context';
import useMobileDetector from 'hooks/use-mobile-detector';

//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

function PDFViewer({ pdfFile, popup }) {
	let location = useLocation();
	const [numPages, setNumPages] = useState<number | null>(null);
	const [pageNumber, setPageNumber] = useState(1);
	const appContext = useContext(AppContext);
	const isMobile = useMobileDetector() === 'mobile';
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [isMobileWidth, setIsMobileWidth] = useState(false);

	useEffect(() => {
		console.log(appContext.isAdmin);
		console.log(popup);
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
			setIsMobileWidth(window.innerWidth < 768);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	const adjustedWidth = windowWidth < 301 ? 200 : isMobile ? 250 : 300;

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}

	const goToPreviousPage = () => {
		if (pageNumber > 1) {
			setPageNumber(pageNumber - 1);
		}
	};

	const goToNextPage = () => {
		if (numPages !== null && pageNumber < numPages) {
			setPageNumber(pageNumber + 1);
		}
	};

	const mergedPdfFiles = pdfFile && pdfFile.buffer ? pdfFile.buffer : pdfFile;

	return (
		<div>
			<Document
				file={mergedPdfFiles}
				onLoadSuccess={onDocumentLoadSuccess}
				// onLoadError={(error) => alert(`Error isLoading PDF: ${error.message}`)}
				onLoadError={(error) => console.log(error)}
			>
				<Page
					pageNumber={pageNumber}
					width={appContext.isAdmin && popup ? adjustedWidth : 256}
					// height={appContext.isAdmin && popup ? 500 : 384}
					className="items-center justify-center"
				/>
			</Document>

			<IonText className="items-center justify-center">
				{numPages !== null && `Page ${pageNumber} of ${numPages}`}
			</IonText>

			<IonRow className="w-full flex">
				<div className="flex items-start justify-start">
					{pageNumber > 1 && (
						<IonIcon
							className="ml-5 w-7 h-7 mb-2 cursor-pointer"
							onClick={goToPreviousPage}
							icon={caretBackCircleSharp}
						></IonIcon>
					)}
				</div>

				<div className="flex items-end justify-end flex-grow">
					{numPages !== null && pageNumber < numPages && (
						<IonIcon
							className="mr-5 w-7 h-7 mb-2 cursor-pointer"
							onClick={goToNextPage}
							icon={caretForwardCircleSharp}
						></IonIcon>
					)}
				</div>
			</IonRow>
		</div>
	);
}

export default PDFViewer;
