import { createBrowserRouter, Navigate } from 'react-router-dom';
import Layout from 'components/Layout';
import SignInLayout from 'components/SignInLayout';
import { AuthGuard } from 'components/AuthGuard';
import { AccountGuard } from 'components/AccountGuard';
import { AdminGuard } from 'components/AdminGuard';
import SignIn from 'pages/SignIn/SignIn';
import Home from 'pages/Home';
import ForgotPassword from 'pages/SignIn/ForgotPassword';
import CustomerVerification from 'pages/Account/CustomerVerification';
import AccountQuestionnaire from 'pages/Account/Questionnaire';
import IdSubmissionForIndividual from 'pages/DocumentSubmission/IDSubmissionForIndividual';
import IdSubmissionForBusinessAuth from 'pages/DocumentSubmission/IDSubmissionForBusinessAuth';
import IdSubmissionForBusiness from 'pages/DocumentSubmission/IDSubmissionForBusiness';
import SsmDocumentSubmission from 'pages/DocumentSubmission/SsmDocumentSubmission';
import AuthorizationLetter from 'pages/DocumentSubmission/AuthorizationLetter';
import SelfieVerification from 'pages/Account/SelfieVerification';
import LinkBankAccount from 'pages/Account/LinkBankAccount';
import React from 'react';
import BasicDetails from 'pages/Account/BasicDetails';
import AccountSelection from 'pages/Account/AccountSelection';
import Profile from 'pages/Profile';
import PreRequisite from 'pages/Account/PreRequisite';
import Admin from 'pages/Admin/Admin';
import { ManageUsers } from 'pages/Admin/ManageUsers';
import { AccountUsers } from 'pages/Admin/AccountUser';
import { AddUser } from 'pages/Admin/AddUser';
import ManageAccounts from 'pages/Admin/ManageAccounts';
import IndividualAccounts from 'pages/Admin/IndividualAccounts';
import Pending from 'pages/Account/PendingStatus';
import SignOutMobile from 'pages/Account/SignOutMobile';

/**
 * Route definition of easy-to-identify keywords to url paths
 */
export const Routes = {
	HOME: '/home',
	PROFILE: '/profile',
	ACCOUNT_SELECTION: '/account-selection',
	PRE_REQUISITE: '/pre-requisite',
	SIGNOUT_MOBILE: '/SignOut-Mobile',
	CUSTOMER_VERIFICATION: '/kyc',
	ACCOUNT_BASIC_DETAILS: 'basic-details',
	ACCOUNT_QUESTIONNAIRE: 'account-questionnaire',
	ACCOUNT_ID_SUBMISSION_FOR_INDIVIDUAL: 'id-submission-individual',
	ACCOUNT_ID_SUBMISSION_FOR_BUSINESS_AUTH: 'id-submission-business-auth',
	ACCOUNT_ID_SUBMISSION_FOR_BUSINESS: 'id-submission-business',
	ACCOUNT_SSM_SUBMISSION_FOR_BUSINESS: 'ssm-submission-business',
	ACCOUNT_AUTHORIZATION_LETTER: 'authorization-letter-business',
	ACCOUNT_SELFIE_VERIFICATION: 'selfie-verification',
	ACCOUNT_BANK_VERIFICATION: 'link-bank-account',
	PENDING: '/pending',
	ADMIN: '/admin',
	MANAGE_USERS: '/admin/users',
	ACCOUNT_USERS: '/admin/account-users',
	ADD_USER: '/admin/add-user',
	MANAGE_ACCOUNTS: '/admin/accounts',
	INDIVIDUAL_ACCOUNT: '/admin/accounts/:accountId',
	SIGNIN: '/',
	FORGOT_PASSWORD: '/forgot-password',
};
//TODO: for admin need to change admin related page as a children of admin
export const Router = createBrowserRouter([
	{
		element: <Layout />,
		children: [
			{
				path: Routes.HOME,
				element: (
					<AuthGuard>
						<Home />
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.PROFILE,
				element: (
					<AuthGuard>
						<Profile />
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.ACCOUNT_SELECTION,
				element: (
					<AuthGuard>
						<AccountGuard>
							<AccountSelection />
						</AccountGuard>
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.PRE_REQUISITE,
				element: (
					<AuthGuard>
						<PreRequisite />
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.SIGNOUT_MOBILE,
				element: (
					<AuthGuard>
						<SignOutMobile />
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.CUSTOMER_VERIFICATION,
				element: (
					<AuthGuard>
						<CustomerVerification />
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
				children: [
					{
						path: Routes.ACCOUNT_BASIC_DETAILS,
						element: <BasicDetails />,
						errorElement: Routes.SIGNIN,
					},
					{
						path: Routes.ACCOUNT_QUESTIONNAIRE,
						element: <AccountQuestionnaire />,
						errorElement: Routes.SIGNIN,
					},
					{
						path: Routes.ACCOUNT_ID_SUBMISSION_FOR_INDIVIDUAL,
						element: <IdSubmissionForIndividual />,
						errorElement: Routes.SIGNIN,
					},
					{
						path: Routes.ACCOUNT_ID_SUBMISSION_FOR_BUSINESS_AUTH,
						element: <IdSubmissionForBusinessAuth />,
						errorElement: Routes.SIGNIN,
					},
					{
						path: Routes.ACCOUNT_ID_SUBMISSION_FOR_BUSINESS,
						element: <IdSubmissionForBusiness />,
						errorElement: Routes.SIGNIN,
					},
					{
						path: Routes.ACCOUNT_SSM_SUBMISSION_FOR_BUSINESS,
						element: <SsmDocumentSubmission />,
						errorElement: Routes.SIGNIN,
					},
					{
						path: Routes.ACCOUNT_AUTHORIZATION_LETTER,
						element: <AuthorizationLetter />,
						errorElement: Routes.SIGNIN,
					},
					{
						path: Routes.ACCOUNT_SELFIE_VERIFICATION,
						element: <SelfieVerification />,
						errorElement: Routes.SIGNIN,
					},
					{
						path: Routes.ACCOUNT_BANK_VERIFICATION,
						element: <LinkBankAccount />,
						errorElement: Routes.SIGNIN,
					},
				],
			},
			{
				path: Routes.PENDING,
				element: <Pending />,
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.ADMIN,
				element: (
					<AuthGuard>
						<AdminGuard>
							<Admin />
						</AdminGuard>
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.MANAGE_USERS,
				element: (
					<AuthGuard>
						<AdminGuard>
							<ManageUsers />
						</AdminGuard>
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.ACCOUNT_USERS,
				element: (
					<AuthGuard>
						<AdminGuard>
							<AccountUsers />
						</AdminGuard>
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.ADD_USER,
				element: (
					<AuthGuard>
						<AdminGuard>
							<AddUser />
						</AdminGuard>
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.MANAGE_ACCOUNTS,
				element: (
					<AuthGuard>
						<AdminGuard>
							<ManageAccounts />
						</AdminGuard>
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
			{
				path: Routes.INDIVIDUAL_ACCOUNT,
				element: (
					<AuthGuard>
						<AdminGuard>
							<IndividualAccounts />
						</AdminGuard>
					</AuthGuard>
				),
				errorElement: Routes.SIGNIN,
			},
		],
	},
	{
		element: <SignInLayout />,
		children: [
			{
				path: '/',
				element: <SignIn />,
				errorElement: Routes.SIGNIN,
			},

			{
				path: Routes.FORGOT_PASSWORD,
				element: <ForgotPassword />,
				errorElement: Routes.SIGNIN,
			},
		],
	},
	{
		path: '*',
		element: <Navigate to="/" replace />,
	},
]);
