/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const extractDocument = /* GraphQL */ `mutation ExtractDocument($docId: ID!) {
  extractDocument(docId: $docId)
}
` as GeneratedMutation<
  APITypes.ExtractDocumentMutationVariables,
  APITypes.ExtractDocumentMutation
>;
export const verifyFace = /* GraphQL */ `mutation VerifyFace($identityDocId: ID!, $selfieDocId: ID!) {
  verifyFace(identityDocId: $identityDocId, selfieDocId: $selfieDocId)
}
` as GeneratedMutation<
  APITypes.VerifyFaceMutationVariables,
  APITypes.VerifyFaceMutation
>;
export const verifyBankAccount = /* GraphQL */ `mutation VerifyBankAccount($bankAccountId: ID!) {
  verifyBankAccount(bankAccountId: $bankAccountId)
}
` as GeneratedMutation<
  APITypes.VerifyBankAccountMutationVariables,
  APITypes.VerifyBankAccountMutation
>;
export const sendMessage = /* GraphQL */ `mutation SendMessage($input: MessageInput!) {
  sendMessage(input: $input)
}
` as GeneratedMutation<
  APITypes.SendMessageMutationVariables,
  APITypes.SendMessageMutation
>;
export const generateCreditReport = /* GraphQL */ `mutation GenerateCreditReport(
  $reportType: CTOSReportType!
  $identityDocId: String!
) {
  generateCreditReport(reportType: $reportType, identityDocId: $identityDocId)
}
` as GeneratedMutation<
  APITypes.GenerateCreditReportMutationVariables,
  APITypes.GenerateCreditReportMutation
>;
export const createAccount = /* GraphQL */ `mutation CreateAccount(
  $input: CreateAccountInput!
  $condition: ModelAccountConditionInput
) {
  createAccount(input: $input, condition: $condition) {
    id
    shortName
    type
    linkedBankAccounts {
      items {
        id
        bankName
        accountNumber
        accountHolderName
        accountCurrency
        default
        disabled
        status
        owner
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    referenceDocs {
      items {
        id
        docType
        docCategory
        pages {
          bucket
          key
          region
          __typename
        }
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        extractedData
        owner
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    attributes
    questionnaire
    owner
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    merchantProfile
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountMutationVariables,
  APITypes.CreateAccountMutation
>;
export const updateAccount = /* GraphQL */ `mutation UpdateAccount(
  $input: UpdateAccountInput!
  $condition: ModelAccountConditionInput
) {
  updateAccount(input: $input, condition: $condition) {
    id
    shortName
    type
    linkedBankAccounts {
      items {
        id
        bankName
        accountNumber
        accountHolderName
        accountCurrency
        default
        disabled
        status
        owner
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    referenceDocs {
      items {
        id
        docType
        docCategory
        pages {
          bucket
          key
          region
          __typename
        }
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        extractedData
        owner
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    attributes
    questionnaire
    owner
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    merchantProfile
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountMutationVariables,
  APITypes.UpdateAccountMutation
>;
export const deleteAccount = /* GraphQL */ `mutation DeleteAccount(
  $input: DeleteAccountInput!
  $condition: ModelAccountConditionInput
) {
  deleteAccount(input: $input, condition: $condition) {
    id
    shortName
    type
    linkedBankAccounts {
      items {
        id
        bankName
        accountNumber
        accountHolderName
        accountCurrency
        default
        disabled
        status
        owner
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    referenceDocs {
      items {
        id
        docType
        docCategory
        pages {
          bucket
          key
          region
          __typename
        }
        status
        comments {
          message
          commentedBy
          commentedAt
          __typename
        }
        verifiedBy
        extractedData
        owner
        accountId
        account {
          id
          shortName
          type
          linkedBankAccounts {
            nextToken
            __typename
          }
          referenceDocs {
            nextToken
            __typename
          }
          attributes
          questionnaire
          owner
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          merchantProfile
          updatedBy
          createdBy
          createdAt
          updatedAt
          __typename
        }
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    attributes
    questionnaire
    owner
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    merchantProfile
    updatedBy
    createdBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountMutationVariables,
  APITypes.DeleteAccountMutation
>;
export const createBankAccount = /* GraphQL */ `mutation CreateBankAccount(
  $input: CreateBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  createBankAccount(input: $input, condition: $condition) {
    id
    bankName
    accountNumber
    accountHolderName
    accountCurrency
    default
    disabled
    status
    owner
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBankAccountMutationVariables,
  APITypes.CreateBankAccountMutation
>;
export const updateBankAccount = /* GraphQL */ `mutation UpdateBankAccount(
  $input: UpdateBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  updateBankAccount(input: $input, condition: $condition) {
    id
    bankName
    accountNumber
    accountHolderName
    accountCurrency
    default
    disabled
    status
    owner
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBankAccountMutationVariables,
  APITypes.UpdateBankAccountMutation
>;
export const deleteBankAccount = /* GraphQL */ `mutation DeleteBankAccount(
  $input: DeleteBankAccountInput!
  $condition: ModelBankAccountConditionInput
) {
  deleteBankAccount(input: $input, condition: $condition) {
    id
    bankName
    accountNumber
    accountHolderName
    accountCurrency
    default
    disabled
    status
    owner
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBankAccountMutationVariables,
  APITypes.DeleteBankAccountMutation
>;
export const createAccountUser = /* GraphQL */ `mutation CreateAccountUser(
  $input: CreateAccountUserInput!
  $condition: ModelAccountUserConditionInput
) {
  createAccountUser(input: $input, condition: $condition) {
    accountId
    userId
    role
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAccountUserMutationVariables,
  APITypes.CreateAccountUserMutation
>;
export const updateAccountUser = /* GraphQL */ `mutation UpdateAccountUser(
  $input: UpdateAccountUserInput!
  $condition: ModelAccountUserConditionInput
) {
  updateAccountUser(input: $input, condition: $condition) {
    accountId
    userId
    role
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAccountUserMutationVariables,
  APITypes.UpdateAccountUserMutation
>;
export const deleteAccountUser = /* GraphQL */ `mutation DeleteAccountUser(
  $input: DeleteAccountUserInput!
  $condition: ModelAccountUserConditionInput
) {
  deleteAccountUser(input: $input, condition: $condition) {
    accountId
    userId
    role
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAccountUserMutationVariables,
  APITypes.DeleteAccountUserMutation
>;
export const createDocument = /* GraphQL */ `mutation CreateDocument(
  $input: CreateDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  createDocument(input: $input, condition: $condition) {
    id
    docType
    docCategory
    pages {
      bucket
      key
      region
      __typename
    }
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    extractedData
    owner
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentMutationVariables,
  APITypes.CreateDocumentMutation
>;
export const updateDocument = /* GraphQL */ `mutation UpdateDocument(
  $input: UpdateDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  updateDocument(input: $input, condition: $condition) {
    id
    docType
    docCategory
    pages {
      bucket
      key
      region
      __typename
    }
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    extractedData
    owner
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentMutationVariables,
  APITypes.UpdateDocumentMutation
>;
export const deleteDocument = /* GraphQL */ `mutation DeleteDocument(
  $input: DeleteDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  deleteDocument(input: $input, condition: $condition) {
    id
    docType
    docCategory
    pages {
      bucket
      key
      region
      __typename
    }
    status
    comments {
      message
      commentedBy
      commentedAt
      __typename
    }
    verifiedBy
    extractedData
    owner
    accountId
    account {
      id
      shortName
      type
      linkedBankAccounts {
        items {
          id
          bankName
          accountNumber
          accountHolderName
          accountCurrency
          default
          disabled
          status
          owner
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      referenceDocs {
        items {
          id
          docType
          docCategory
          pages {
            bucket
            key
            region
            __typename
          }
          status
          comments {
            message
            commentedBy
            commentedAt
            __typename
          }
          verifiedBy
          extractedData
          owner
          accountId
          account {
            id
            shortName
            type
            attributes
            questionnaire
            owner
            status
            merchantProfile
            updatedBy
            createdBy
            createdAt
            updatedAt
            __typename
          }
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      attributes
      questionnaire
      owner
      status
      comments {
        message
        commentedBy
        commentedAt
        __typename
      }
      merchantProfile
      updatedBy
      createdBy
      createdAt
      updatedAt
      __typename
    }
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentMutationVariables,
  APITypes.DeleteDocumentMutation
>;
export const createUserPreference = /* GraphQL */ `mutation CreateUserPreference(
  $input: CreateUserPreferenceInput!
  $condition: ModelUserPreferenceConditionInput
) {
  createUserPreference(input: $input, condition: $condition) {
    userId
    preferences
    updatedBy
    createdAt
    updatedAt
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPreferenceMutationVariables,
  APITypes.CreateUserPreferenceMutation
>;
export const updateUserPreference = /* GraphQL */ `mutation UpdateUserPreference(
  $input: UpdateUserPreferenceInput!
  $condition: ModelUserPreferenceConditionInput
) {
  updateUserPreference(input: $input, condition: $condition) {
    userId
    preferences
    updatedBy
    createdAt
    updatedAt
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPreferenceMutationVariables,
  APITypes.UpdateUserPreferenceMutation
>;
export const deleteUserPreference = /* GraphQL */ `mutation DeleteUserPreference(
  $input: DeleteUserPreferenceInput!
  $condition: ModelUserPreferenceConditionInput
) {
  deleteUserPreference(input: $input, condition: $condition) {
    userId
    preferences
    updatedBy
    createdAt
    updatedAt
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPreferenceMutationVariables,
  APITypes.DeleteUserPreferenceMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    Username
    Attributes {
      Name
      Value
      __typename
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
    MFAOptions {
      DeliveryMedium
      AttributeName
      __typename
    }
    PreferredMfaSetting
    UserMFASettingList
    Groups
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($input: UserStatusInput!) {
  updateUser(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const toAdministratorGroup = /* GraphQL */ `mutation ToAdministratorGroup($input: Administrator!) {
  toAdministratorGroup(input: $input)
}
` as GeneratedMutation<
  APITypes.ToAdministratorGroupMutationVariables,
  APITypes.ToAdministratorGroupMutation
>;
