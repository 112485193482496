import React, { useContext, useState, useEffect, useRef } from 'react';
import {
	IonButton,
	IonCard,
	IonCol,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonMenu,
	IonMenuButton,
	IonMenuToggle,
	IonPage,
	IonPopover,
	IonRow,
	IonSearchbar,
	IonSelect,
	IonSelectOption,
	IonSpinner,
	IonText,
	IonTitle,
	IonToolbar,
	useIonToast,
} from '@ionic/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AccountApi from 'api/account';
import useMobileDetector from 'hooks/use-mobile-detector';
import { Account, User } from 'API';
import {
	arrowBack,
	arrowForward,
	searchCircle,
	playSkipForward,
	playSkipBack,
	arrowBackSharp,
	arrowBackCircle,
	caretDownSharp,
	arrowForwardSharp,
	arrowForwardCircle,
	arrowUp,
	close,
} from 'ionicons/icons';
import { Routes } from 'utils/routes';
import { useNavigate, useLocation } from 'react-router-dom';
import filter from '../../images/filter.svg';
import { Toaster, toast } from 'react-hot-toast';
import RinngitPayLoader from 'components/RinngitPayLoader';

export const ManageUsers = () => {
	const [newAccount, setNewAccount] = useState<boolean>(false);
	const isMobile = useMobileDetector() === 'mobile';
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [usersList, setUsersList] = useState<User[]>([]);
	const [checked, setChecked] = useState(false);
	const [checkBoxValue, setCheckBoxValue] = useState(false);
	const navigate = useNavigate();
	const [searchText, setSearchText] = useState('');
	const popover = useRef<HTMLIonPopoverElement>(null);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [present] = useIonToast();
	let location = useLocation();

	const [selectedStatus, setSelectedStatus] = useState(null);
	const [selectedUserName, setSelectedUserName] = useState(null);
	const [selectedEmail, setSelectedEmail] = useState(null);

	const statusSelectRef = useRef<HTMLIonSelectElement>(null);
	const userNameInputRef = useRef<HTMLIonInputElement>(null);
	const emailInputRef = useRef<HTMLIonInputElement>(null);

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	useEffect(() => {
		console.log('Admin Manage Users Page');
		console.log(location);
		getListUserGroup();
		if (location.state !== null) {
			notify({ toastMessage: location.state.toastMessage, toastType: location.state.toastType });
		}
	}, []);

	async function getListUserGroup() {
		setIsLoading(true);
		try {
			return AccountApi.listUsersInGroup('SystemAdmin')
				.then((usersList) => {
					console.log('User list: ', usersList);
					const sortedAccounts = usersList.data.listUsersInGroup.sort(
						(a, b) =>
							new Date(b.UserLastModifiedDate).getTime() -
							new Date(a.UserLastModifiedDate).getTime(),
					);
					setUsersList(sortedAccounts);
					setIsLoading(false);
					return sortedAccounts;
				})
				.catch((error) => {
					console.log(error);
					setIsLoading(false);
					notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
				});
		} catch (error) {
			console.error('Error fetching accounts:', error);
		}
	}

	async function updateUserStatus(userName, status) {
		console.log(userName, status);
		const userUpdated = await AccountApi.updateUser(userName, status);
		console.log('userUpdated', userUpdated);
	}

	function AccountCard({ account, isLastIndex }) {
		const labelContent = !account.Attributes[2]
			? account.Attributes[1].Value
			: account.Attributes[2].Value;

		return (
			<>
				<IonRow className="ml-4 mr-5 bg-transparent">
					<>
						{!isMobile && (
							<>
								<div
									className={` grid grid-cols-4 border bg-secondary-00 h-14 w-screen  hover:bg-mousehover-50 ${
										isLastIndex && 'rounded-b-2xl'
									}`}
								>
									<IonRow className="col-span-1 flex justify-start items-start ml-6 my-4 text-16px">
										{!account.Attributes[2]
											? account.Attributes[1].Value.split('@')[0]
											: account.Attributes[2].Value.split('@')[0]}
									</IonRow>
									<IonRow
										className={`col-span-1 flex justify-start items-center ml-2 pb-1 text-16px ${
											window.innerWidth < 1000 ? 'truncate' : ''
										}`}
									>
										{!account.Attributes[2]
											? account.Attributes[1].Value
											: account.Attributes[2].Value}
									</IonRow>
									<IonRow
										className={`col-span-1 flex justify-start items-start ml-2 my-4 pt-0.5 text-16px ${
											window.innerWidth < 940 ? 'truncate' : ''
										}`}
									>
										{account.UserStatus && account.UserStatus}
									</IonRow>
									<IonRow className="col-span-1 flex justify-start items-start ml-4 my-4 text-16px">
										<div>
											<label className="relative inline-flex items-center cursor-pointer">
												<input
													type="checkbox"
													value=""
													className="sr-only peer"
													onChange={(e) => updateUserStatus(account.Username, e.target.checked)}
													defaultChecked={account.Enabled ? true : false}
												/>

												<div
													className="w-11 h-6 bg-failure  peer-checked:bg-success peer-focus:outline-none 
    rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-secondary-00 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-secondary-00 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"
												></div>
											</label>
										</div>
									</IonRow>
								</div>
							</>
						)}

						{isMobile && (
							<>
								<IonCard className="ml-0 mb-0 mt-3 border border-shao bg-secondary-00 h-32 w-full mr-0 shadow-none rounded-lg">
									{/* <IonRow className="flex justify-between">
										<IonLabel
											className={`flex w-5/12 ml-3 mt-3 text-16px ${
												window.innerWidth < 415 ? 'truncate' : ''
											}`}
										>
											{/* {account.Username.slice(0, account.Username.length / 3)}... */}
									{/*{!account.Attributes[2]
												? account.Attributes[1].Value.split('@')[0]
												: account.Attributes[2].Value.split('@')[0]}
										</IonLabel>
										<IonRow className="flex w-4/12 ml-3 mt-1 justify-end text-16px">
											<IonText className="mr-3 justify-end mt-2 capitalize text-14px">
												{/* {account.UserStatus.length > 11 ? 'CHANGE PASSWORD' : account.UserStatus} */}
									{/*	{account.UserStatus && account.UserStatus.length > 11
													? 'FORCE CHANGE PASSWORD'
													: account.UserStatus}
												{!account.UserStatus && 'DATA NOT AVAILABLE'}
											</IonText>
										</IonRow>
										<IonLabel className="flex w-8/12 ml-3 mt-6 text-16px truncate">
											{/* {account.Attributes[2].Value} */}
									{/*{!account.Attributes[2]
												? account.Attributes[1].Value
												: account.Attributes[2].Value}
										</IonLabel>
										<div className="flex items-end mr-3">
											<label className="relative inline-flex items-center cursor-pointer">
												<input
													type="checkbox"
													value=""
													className="sr-only peer"
													onChange={(e) => updateUserStatus(account.Username, e.target.checked)}
													defaultChecked={account.Enabled ? true : false}
												/>

												<div
													className="w-11 h-6 bg-failure  peer-checked:bg-success peer-focus:outline-none 
    rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-secondary-00 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-secondary-00 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"
												></div>
											</label>
										</div>
									</IonRow> */}
									<>
										<IonRow>
											<IonCol size="9">
												<div>
													<IonLabel
														className={`flex  font-bold ml-3 mt-3 text-16px ${
															window.innerWidth < 415 ? 'truncate' : ''
														}`}
													>
														{/* {account.Username.slice(0, account.Username.length / 3)}... */}
														{!account.Attributes[2]
															? account.Attributes[1].Value.split('@')[0]
															: account.Attributes[2].Value.split('@')[0]}
													</IonLabel>

													<IonLabel className="flex font-bold ml-3 mt-3 mb-4 text-16px ">
														{/* {account.Attributes[2].Value} */}
														{/* {!account.Attributes[2]
															? account.Attributes[1].Value
															: account.Attributes[2].Value} */}
														{labelContent.length > 30
															? `${labelContent.slice(0, 30)}...`
															: labelContent}
													</IonLabel>

													<IonText className="ml-3 font-bold w-64 flex mt-2 text-14px">
														{/* {account.UserStatus.length > 11 ? 'CHANGE PASSWORD' : account.UserStatus} */}
														{account.UserStatus && account.UserStatus.length > 11
															? 'FORCE CHANGE PASSWORD'
															: account.UserStatus}
														{!account.UserStatus && 'DATA NOT AVAILABLE'}
													</IonText>
												</div>
											</IonCol>
											<IonCol size="3">
												<label className="relative mt-3 ml-4 inline-flex items-center cursor-pointer">
													<input
														type="checkbox"
														value=""
														className="sr-only peer"
														onChange={(e) => updateUserStatus(account.Username, e.target.checked)}
														defaultChecked={account.Enabled ? true : false}
													/>

													<div
														className="w-11 h-6 bg-failure  peer-checked:bg-success peer-focus:outline-none 
    rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-secondary-00 after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-secondary-00 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"
													></div>
												</label>
											</IonCol>
										</IonRow>
									</>
								</IonCard>
							</>
						)}
					</>
				</IonRow>
			</>
		);
	}

	const totalPages = Math.ceil(usersList.length / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = Math.min(startIndex + itemsPerPage, usersList.length);
	const paginatedAccounts = usersList.slice(startIndex, endIndex);
	const totalCount = usersList.length;
	const startCount = startIndex + 1;
	const endCount = Math.min(startIndex + itemsPerPage, totalCount);

	function navigateAddAdminUser() {
		navigate(Routes.ADD_USER, { state: { userType: 'BO_USER' } });
	}

	const handleInputChange = (e) => {
		console.log('Performing search:', e.target.value);
		console.log(e.type);
		if (e.key === 'Enter') {
			console.log(' search:', e.target.value);
			setSearchText(e.target.value);
			AccountApi.getAllUsers(e.target.value, undefined, undefined)
				.then((usersList) => {
					console.log('User list: ', usersList);
					console.log(usersList.Attributes.find((attr) => attr.Name === 'sub')?.Value);
					setUsersList(usersList.data.listUsers.Users);
				})
				.catch((error) => {
					console.log(error);
					// presentToast(error?.errors[0].message);
				});
		}
	};

	const handleSearchInput = async (e) => {
		const searchInput = e.target.value;
		if (!searchInput) {
			console.log('search cleared');
			getListUserGroup();
			setSearchText('');
		}
		if (searchInput) {
			console.log('Searching for:', searchInput);
			setSearchText(searchInput);
		}
	};

	const handleSearchEnterKey = async (e) => {
		if (e.key === 'Enter') {
			const usersList = await getListUserGroup();
			const filteredUsers = usersList.filter((userList) => {
				const emailAttribute = userList.Attributes.find((attribute) => attribute.Name === 'email');
				console.log('------', emailAttribute.Value);
				const userEmail = emailAttribute ? emailAttribute.Value : '';
				const filteredByEmail = !searchText || userEmail.split('@')[0].includes(searchText);
				return filteredByEmail;
			});
			console.log(filteredUsers);
			const sortedAccounts = filteredUsers.sort(
				(a, b) =>
					new Date(b.UserLastModifiedDate).getTime() - new Date(a.UserLastModifiedDate).getTime(),
			);
			console.log(sortedAccounts);
			setUsersList(sortedAccounts);
		}
	};

	const openPopover = (e: any) => {
		popover.current!.event = e;
		setPopoverOpen(true);
	};

	const handleChange = (e, filterType) => {
		console.log('Filter Type: ', filterType, 'Filter Value: ', e.detail.value);

		if (filterType === 'STATUS') {
			console.log('setting status');
			setSelectedStatus(e.detail.value);
		} else if (filterType === 'USER_NAME') {
			console.log('setting user name');
			setSelectedUserName(e.detail.value);
		} else if (filterType === 'EMAIL') {
			console.log('setting email');
			setSelectedEmail(e.detail.value);
		}
		console.log('done');
	};

	const filterAccounts = async () => {
		console.log('Filtering.....');
		setIsLoading(true);
		const usersList = await getListUserGroup();

		const filteredUsers = usersList.filter((userList) => {
			console.log(selectedStatus, selectedEmail, selectedUserName);
			const filteredByStatus = !selectedStatus || userList.UserStatus === selectedStatus;
			const emailAttribute = userList.Attributes.find((attribute) => attribute.Name === 'email');
			const userEmail = emailAttribute ? emailAttribute.Value : '';
			const filteredByEmail = !selectedEmail || userEmail.includes(selectedEmail);
			return filteredByStatus && filteredByEmail;
		});
		const sortedAccounts = filteredUsers.sort(
			(a, b) =>
				new Date(b.UserLastModifiedDate).getTime() - new Date(a.UserLastModifiedDate).getTime(),
		);
		console.log(sortedAccounts);
		setUsersList(sortedAccounts);
		setIsLoading(false);
	};

	const clearFilter = async () => {
		if (statusSelectRef.current) {
			statusSelectRef.current.value = undefined;
		}
		if (userNameInputRef.current) {
			userNameInputRef.current.value = '';
		}
		if (emailInputRef.current) {
			emailInputRef.current.value = '';
		}
		getListUserGroup();
	};

	const handleItemsPerPageClick = (newItemsPerPage) => {
		handlePageChange(1);
		setItemsPerPage(newItemsPerPage);
		setPopoverOpen(false);
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	return (
		<>
			<Toaster />
			{isLoading ? (
				<RinngitPayLoader props={{ overlay: true }} />
			) : (
				<>
					<Toaster />
					<IonMenu side="end" contentId="filter-menu">
						<IonHeader>
							<IonToolbar>
								<IonRow>
									<IonTitle>Filter</IonTitle>
									<IonMenuToggle>
										<IonIcon icon={close} className="h-6 w-6 mr-3 pt-2 cursor-pointer"></IonIcon>
									</IonMenuToggle>
								</IonRow>
							</IonToolbar>
						</IonHeader>
						<IonContent className="ion-padding">
							{/* <IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">User Name</IonLabel>
							</IonRow>

							<IonItem className="app-input-filed bg-secondary-00 mt-3 ml-0.5 w-60" lines="none">
								<IonInput
									ref={userNameInputRef}
									placeholder="Filter by User Name"
									className=" text-16px text-secondary-75 font-medium bg-secondary-00"
									onIonChange={(e) => handleChange(e, 'USER_NAME')}
								></IonInput>
							</IonItem> */}

							<IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">Email</IonLabel>
							</IonRow>

							<IonItem className="app-input-filed bg-secondary-00 mt-3 ml-0.5 w-60" lines="none">
								<IonInput
									ref={emailInputRef}
									placeholder="Filter by Email"
									className=" text-16px text-secondary-75 font-medium bg-secondary-00"
									onIonChange={(e) => handleChange(e, 'EMAIL')}
								></IonInput>
							</IonItem>

							<IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">User Status</IonLabel>
							</IonRow>

							<IonSelect
								ref={statusSelectRef}
								interface="popover"
								className="app-input-filed text-16px text-secondary-75 font-medium w-60 bg-secondary-00 pr-2 mt-3 ml-0.5"
								onIonChange={(e) => handleChange(e, 'STATUS')}
								placeholder="Filter by Status"
							>
								<IonSelectOption className="w-65 " value="CONFIRMED">
									CONFIRMED
								</IonSelectOption>
								<IonSelectOption className="w-65" value="FORCE_CHANGE_PASSWORD">
									FORCE CHANGE PASSWORD
								</IonSelectOption>
							</IonSelect>

							<IonMenuToggle>
								<IonButton className="mt-7 mr-5" onClick={filterAccounts}>
									Apply
								</IonButton>
								<IonButton className="mt-7 mr-5" onClick={clearFilter}>
									Clear Filter
								</IonButton>
							</IonMenuToggle>
						</IonContent>
					</IonMenu>

					<IonRow>
						<IonRow className="w-screen md:w-1/2 pt-5 pl-4 pb-0 md:pb-0">
							<div className="text-22px text-secondary-75 font-bold">Back Office Users</div>
						</IonRow>
						{/* <IonRow className="w-1/2 md:pt-4 pl-4 md:pl-10 pr-5 pb-1 md:pb-0 justify-start md:justify-end shadow-none ">
					<IonButton
						className="h-36px capitalize text-16px rounded-md shadow-none"
						fill="solid"
						onClick={navigateAddAdminUser}
					>
						Add User
					</IonButton>

				</IonRow> */}
					</IonRow>

					<IonRow>
						<IonRow className="min-width-600  max-width-600 pl-2.5 md:pl-2 mb-0 md:mb- pb-5 md:pb-5">
							<IonSearchbar
								className=" mr-3 max-width-600 md:w-80 shadow-none rounded-full p-2"
								value={searchText}
								onIonChange={(e) => handleSearchInput(e)}
								placeholder="Search by username..."
								onKeyDown={(e) => handleSearchEnterKey(e)}
								// onIonClear={getListUserGroup()}
							></IonSearchbar>
						</IonRow>

						<IonRow className="min-width-600  max-width-600 pt-1.5  pl-4 md:pl-10 pr-5 justify-end shadow-none pb-5">
							<IonButton
								className="h-36px capitalize text-16px rounded-md shadow-none mr-5"
								fill="solid"
								onClick={navigateAddAdminUser}
							>
								Add User
							</IonButton>
							<IonMenuToggle id="filter-menu">
								<IonIcon
									icon={filter}
									className="pt-1.5 pr-0.5 ml-1 h-7 w-7 cursor-pointer"
									// onClick={handleNext}
								></IonIcon>
							</IonMenuToggle>
						</IonRow>
					</IonRow>

					<IonRow>
						{!isMobile && (
							<>
								<div className="ml-4 grid grid-cols-4 border border-shao bg-secondary-00 h-14 w-screen mr-5 shadow-none rounded-t-2xl">
									<div className="col-span-1 flex justify-start items-start ml-6 my-5 text-16px text-secondary-100 font-bold">
										User name
									</div>
									<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
										Email
									</div>
									<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
										Account Status
									</div>
									<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
										Account Enabled
									</div>
								</div>
							</>
						)}

						<div className="w-full">
							{paginatedAccounts.map((account, index) => {
								const isLastIndex = index === paginatedAccounts.length - 1;
								// @ts-ignore
								return <AccountCard key={index} account={account} isLastIndex={isLastIndex} />;
							})}
						</div>
					</IonRow>

					{usersList.length === 0 && (
						<IonRow className=" w-full flex items-center justify-center mt-5 mb-5 ml-4 pr-10">
							<IonLabel className=" text-22px font-bold">No Users to Display</IonLabel>
						</IonRow>
					)}

					{usersList.length !== 0 && (
						<IonRow className="w-full flex items-end justify-end">
							<IonRow className="mt-5 mr-5  flex items-end justify-end">
								{isMobile && (
									<>
										<IonText className="pr-10">{`${startCount} - ${endCount} of ${totalCount}`}</IonText>

										{[playSkipBack, arrowBackSharp, arrowForwardSharp, playSkipForward].map(
											(icon, index) => (
												<IonIcon
													key={index}
													icon={icon}
													className={
														index === 0
															? `pl-3 pb-0.5 h-6 w-6 ${
																	currentPage === 1 ? 'text-secondary-50' : 'text-secondary-75'
															  }`
															: index === 1
															? `pr-3 pl-6 pb-0.5 h-6 w-6 ${
																	currentPage === 1 ? 'text-secondary-50' : 'text-secondary-75'
															  }`
															: index === 2
															? `pl-6 pr-3 pb-0.5 h-6 w-6 ${
																	currentPage === totalPages
																		? 'text-secondary-50'
																		: 'text-secondary-75'
															  }`
															: `pl-3 pb-0.5 h-6 w-6 ${
																	currentPage === totalPages
																		? 'text-secondary-50'
																		: 'text-secondary-75'
															  }`
													}
													onClick={() => {
														if (index === 0) {
															handlePageChange(1);
														} else if (index === 1) {
															currentPage !== 1 && handlePageChange(currentPage - 1);
														} else if (index === 2) {
															currentPage !== totalPages && handlePageChange(currentPage + 1);
														} else if (index === 3) {
															handlePageChange(totalPages);
														}
													}}
												></IonIcon>
											),
										)}
									</>
								)}

								<IonText className="">Items per page:</IonText>

								<div
									className="flex items-end md:items-center justify-end md:justify-center cursor-pointer"
									onClick={openPopover}
								>
									<IonText className="pl-5 pr-5">{itemsPerPage}</IonText>
									<IonIcon
										icon={caretDownSharp}
										className="mt-5 md:mt-0 md:pr-10 pb-1.5 md:pb-0.5 h-4 w-5"
									></IonIcon>
								</div>
								<IonPopover
									size="cover"
									ref={popover}
									isOpen={popoverOpen}
									onDidDismiss={() => setPopoverOpen(false)}
								>
									<IonContent className="ion-padding cursor-pointer">
										{[5, 10, 25, 50].map((value) => (
											<div key={value} onClick={() => handleItemsPerPageClick(value)}>
												{value}
											</div>
										))}
									</IonContent>
								</IonPopover>

								{!isMobile && (
									<>
										<IonText className="pr-10">{`${startCount} - ${endCount} of ${totalCount}`}</IonText>

										{[playSkipBack, arrowBackSharp, arrowForwardSharp, playSkipForward].map(
											(icon, index) => (
												<IonIcon
													key={index}
													icon={icon}
													className={
														index === 0
															? `pl-3 pb-0.5 h-5 w-5 ${
																	currentPage === 1
																		? 'text-secondary-50'
																		: 'text-secondary-75 cursor-pointer'
															  }`
															: index === 1
															? `pr-3 pl-6 pb-0.5 h-5 w-5 ${
																	currentPage === 1
																		? 'text-secondary-50'
																		: 'text-secondary-75 cursor-pointer'
															  }`
															: index === 2
															? `pl-6 pr-3 pb-0.5 h-5 w-5 ${
																	currentPage === totalPages
																		? 'text-secondary-50'
																		: 'text-secondary-75 cursor-pointer'
															  }`
															: `pl-3 pb-0.5 h-5 w-5 ${
																	currentPage === totalPages
																		? 'text-secondary-50'
																		: 'text-secondary-75 cursor-pointer'
															  }`
													}
													onClick={() => {
														if (index === 0) {
															handlePageChange(1);
														} else if (index === 1) {
															currentPage !== 1 && handlePageChange(currentPage - 1);
														} else if (index === 2) {
															currentPage !== totalPages && handlePageChange(currentPage + 1);
														} else if (index === 3) {
															handlePageChange(totalPages);
														}
													}}
												></IonIcon>
											),
										)}
									</>
								)}
							</IonRow>
						</IonRow>
					)}
				</>
			)}
		</>
	);
};
