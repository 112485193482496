import { IonButton, IonCard, IonCol, IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import { useFormik } from 'formik';
import useMobileDetector from 'hooks/use-mobile-detector';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';
import * as Yup from 'yup';
import IndividualWhite from '../../images/IndividualWhite.svg';
import IndividualGrey from '../../images/IndividualGrey.svg';
import Vector from '../../images/Vector.svg';
import BusinessGrey from '../../images/BusinessGrey.svg';
import BusinessWhite from '../../images/BusinessWhite.svg';

export default React.memo(function PreRequisite() {
	const navigate = useNavigate();
	const appContext = useContext(AppContext);
	const isDesktop = useMobileDetector() === 'desktop';

	useEffect(() => {
		console.log('Pre-requisite Page.....');
	}, []);

	const validationSchema = Yup.object({
		accountType: Yup.string().required(),
	});
	let formik = useFormik({
		initialValues: {
			accountType: appContext.registrationDetails.accountType,
		},
		validationSchema: validationSchema,
		onSubmit: (values, { setSubmitting }) => {
			onNext(values, setSubmitting);
		},
	});

	function onNext(formState, setSubmitting) {
		appContext.registrationDetails = {
			accountType: formState.accountType,
			nationality: appContext.registrationDetails.nationality,
		};
		// console.log(appContext.registrationDetails);
		// const route = isDesktop
		// 	? Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BASIC_DETAILS
		// 	: Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BASIC_DETAILS;
		navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BASIC_DETAILS, {
			state: { id: null, page: 0 },
		});
	}

	return (
		<form className="m-0 mt-2 md:mt-0 ml-0 md:ml-2 lg:w-11/12" onSubmit={formik.handleSubmit}>
			<IonRow>
				<IonCol className="lg:w-1/3">
					<IonGrid className="justify-start">
						<IonRow className="h-5 "></IonRow>

						<IonRow className="flex justify-start ml-2 md:ml-4 mb-5">
							<IonRow className="w-32 mr-5">
								<IonCol>
									<div
										className={`flex flex-col shadow-md rounded-lg py-1 px-3 cursor-pointer ${
											formik.values.accountType === 'INDIVIDUAL' ? 'bg-primary-100' : ' bg-secondary-00'
										}`}
										onClick={() => formik.setFieldValue('accountType', 'INDIVIDUAL')}
									>
										{formik.values.accountType === 'INDIVIDUAL' && (
											<div className="justify-center items-center mt-3 ml-5 mb-2">
												<img src={IndividualWhite} alt="Logo" style={{ width: '70%' }} />
											</div>
										)}
										{formik.values.accountType === 'BUSINESS' && (
											<div className="justify-center items-center mt-3 ml-5 mb-2">
												<img src={IndividualGrey} alt="Logo" style={{ width: '70%' }} />
											</div>
										)}
										<IonLabel
											className={`text-center ${
												formik.values.accountType === 'INDIVIDUAL'
													? 'text-secondary-00'
													: 'text-secondary-75'
											}`}
										>
											Individual
										</IonLabel>
									</div>
								</IonCol>
							</IonRow>

							<IonRow className="w-32 ml-0">
								<IonCol>
									<div
										className={`flex flex-col shadow-md rounded-lg py-1 px-3 cursor-pointer ${
											formik.values.accountType === 'BUSINESS' ? 'bg-primary-100' : 'bg-secondary-00'
										}`}
										onClick={() => formik.setFieldValue('accountType', 'BUSINESS')}
									>
										{formik.values.accountType === 'BUSINESS' && (
											<div className="justify-center items-center mt-3 ml-1 mb-2">
												<img src={BusinessWhite} alt="Logo" style={{ width: '100%' }} />
											</div>
										)}
										{formik.values.accountType === 'INDIVIDUAL' && (
											<div className="justify-center items-center mt-3 ml-1 mb-2">
												<img src={BusinessGrey} alt="Logo" style={{ width: '100%' }} />
											</div>
										)}
										<IonLabel
											className={`text-center ${
												formik.values.accountType === 'BUSINESS'
													? 'text-secondary-00'
													: 'text-secondary-75'
											}`}
										>
											Business
										</IonLabel>
									</div>
								</IonCol>
							</IonRow>
						</IonRow>
						<IonRow className="ml-3 md:ml-5 ">
							<IonLabel className="text-22px mt-5 font-bold">Pre-Requisites</IonLabel>
						</IonRow>

						<IonCol size="1">
							<div className="ml-0.5">
								{formik.values.accountType === 'INDIVIDUAL' ? (
									<>
										<IonCard className="bg-secondary-00 pl-10 ml-3 md:ml-5 mt-0 pt-4 h-20 w-72 shadow-md rounded-lg">
											<ul className="list-disc">
												<li>MyKad/Passport</li>
												<li>Selfie Verification</li>
											</ul>
										</IonCard>
									</>
								) : (
									<>
										<IonCard className="bg-secondary-00 pl-10 ml-3 md:ml-5 mt-0 pt-4 h-32 w-72 shadow-md rounded-lg">
											<ul className="list-disc">
												<li>MyKad/Passport</li>
												<li>SSM Document</li>
												<li>All Director/Owner ID Proof</li>
												<li>Authorization letter</li>
											</ul>
										</IonCard>
									</>
								)}
							</div>
						</IonCol>
					</IonGrid>

					<IonRow className="ml-4 md:ml-5 md:pl-1  w-3/4 mb-5">
						<IonButton
							type="submit"
							expand="block"
							className="h-36px capitalize text-16px font-normal rounded-md"
						>
							Create Account
						</IonButton>
					</IonRow>
				</IonCol>
				{isDesktop && (
					<IonCol className="w-1/3 ml-80">
						<div className="justify-center items-center mt-24 mb-2">
							<img src={Vector} alt="Logo" style={{ width: '70%' }} />
						</div>
					</IonCol>
				)}
			</IonRow>
		</form>
	);
});
