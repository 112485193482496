import { DocCategory, DocType, Document, VerificationStatus } from "API";
import { API, Storage } from 'aws-amplify';
import { getAwsConfig } from "utils/aws-config";
import { createDocument, extractDocument, updateDocument, deleteDocument, verifyFace } from "graphql/mutations";
import get from 'lodash.get';
import { convert } from "utils/object-helper";


export class DocumentModel {
  id?: string | null;
  docType?: DocType;
  docCategory?: DocCategory;
  pages?: Array<S3ObjectModel | null> | null;
  status?: VerificationStatus | null;
  comments?: Array<Comment | null> | null;
  verifiedBy?: string | null;
  extractedData?: string | null;
  accountId?: string | null;
  updatedBy?: string | null;
  owner?: string | null;
};


export class S3ObjectModel {
  bucket?: string;
  key?: string;
  region?: string;
};

const DocumentApi = {
  extractID: async function (docId: string) {
    const response = await API.graphql({
      query: extractDocument,
      variables: {
        docId: docId
      }
    });
    const parsedResponse = JSON.parse(response.data.extractDocument);
    return parsedResponse.result;
  },

  verifyFace: async function (identityDocId: string, selfieDocId: string) {
    return API.graphql({
      query: verifyFace,
      variables: {
        identityDocId: identityDocId,
        selfieDocId: selfieDocId
      }
    });
  },

  save: async function (document: DocumentModel) {
    const response = await API.graphql({
      query: document.id ? updateDocument : createDocument,
      variables: {
        input: convert<DocumentModel>(document, new DocumentModel())
      }
    });
    return get(response, document.id ? 'data.updateDocument' : 'data.createDocument') as Document;
  },

  deleteDocument: async function (documentId) {
    const response = await API.graphql({
      query: deleteDocument,
      variables: {
        input: documentId
      }
    });
    return response;
  },

  upload: async function (document: DocumentModel, pages: File[], id, isOrginalPdf) {
    console.log(document, pages, id)
    // await this.listDocuments(`${document.accountId}/${id}/`)
    for (const [index, page] of pages.entries()) {
      const path = isOrginalPdf ? `${document.accountId}/${id}/originalPdf` : `${document.accountId}/${id}/${index}`;
      if (typeof page === "string" && String(page).includes(path)) {
        console.log("page already persisted", page)
      } else {
        // if (document.docType === DocType.SSM) {
        //   const blob = new Blob(pages, { type: 'application/pdf' });
        //   console.log(blob)
        // }
        //! change content type condition to file type instead of doctype
        const result = await Storage.put(path, page, {
          contentType: document.docType === DocType.SSM ? 'application/pdf' : page.type,
        });
        console.log("page persisted", result)
      }
      if (!document.pages) {
        document.pages = []
      }
      document.pages[index] = {
        bucket: getAwsConfig().S3.bucket,
        region: getAwsConfig().S3.region,
        key: path
      }
    }
  },

  getImage: async function (key: string) {
    let imageUrl = await Storage.get(key);
    // console.log(imageUrl)
    return imageUrl;
  },

  deleteS3Object: async function (key) {
    console.log("delete key", key)
    try {

      const documentList = await Storage.list(key);
      console.log(documentList)
      for (let i = 0; i < documentList.results.length; i++) {
        console.log('-----Deleting ', i + 1, ' of ', documentList.results.length, '-----')
        //   await Storage.remove(documentList.results[i].key || '')
        await Storage.remove(documentList.results[i].key || '')
      }
    } catch (error) {
      console.log(error)
      return error;
    }
  }
}

export default DocumentApi;