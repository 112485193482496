import { AccountType, DocType, DocCategory } from 'API';
import AccountApi from 'api/account';


export const uploadedDocuments = async (accountDetails, docType, docCategory, accountType) => {
    const uploadedData = accountDetails?.referenceDocs?.items.filter(
        (doc) =>
            doc.docType == docType &&
            doc.docCategory === docCategory &&
            doc.account.type === accountType,
    );


    if (uploadedData.length === 0) {
        return null;
    }

    return uploadedData;
}


export const uploadedBusinessDocument = async (accountDetails, docType, docCategory, accountType) => {
    const uploadedData = accountDetails?.referenceDocs.items.filter(
        (doc) =>
            (doc.docType == docType[0] || doc.docType == docType[1]) &&
            doc.docCategory === docCategory &&
            doc.account.type === accountType,
    );



    if (uploadedData.length === 0) {
        return null;
    }

    return uploadedData;
}



