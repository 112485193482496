import { IonButton, IonCardContent, IonText } from '@ionic/react';
import { Auth } from 'aws-amplify';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import * as clientStorage from 'utils/client-storage';
import { Routes } from 'utils/routes';

const Home = () => {
	const appContext: any = useContext(AppContext);
	const navigate = useNavigate();
	const signOutHandler = async () => {
		await Auth.signOut();
		clientStorage.clear();
		appContext.userDetails = null;
		// appContext.getUserDetails();
		navigate(Routes.SIGNIN, { replace: true });
	};
	return (
		<IonCardContent className="mt-10">
			<IonText>
				You have logged in as a admin {appContext.isSignedIn() ? 'logged in' : 'logged out'}
			</IonText>
			<IonButton className="mt-5" onClick={signOutHandler}>
				Sign out
			</IonButton>
		</IonCardContent>
	);
};
export default Home;
