import {
	IonButton,
	IonCol,
	IonGrid,
	IonIcon,
	IonImg,
	IonLabel,
	IonRow,
	IonSpinner,
	useIonToast,
	IonCard,
	IonText,
	IonCardHeader,
	IonCardContent,
	IonChip,
	IonSkeletonText,
	IonRouterLink,
	IonPopover,
	IonContent,
	IonSearchbar,
	IonHeader,
	IonMenu,
	IonMenuToggle,
	IonPage,
	IonTitle,
	IonToolbar,
	IonList,
	IonItem,
	IonSelect,
	IonSelectOption,
	IonInput,
} from '@ionic/react';
import React, { useContext, useState, useEffect, useRef } from 'react';
import {
	arrowBackCircle,
	arrowBackSharp,
	arrowForwardCircle,
	arrowForwardSharp,
	arrowUp,
	caretDownSharp,
	ellipse,
	playSkipBack,
	playSkipForward,
	close,
} from 'ionicons/icons';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import AppContext from 'utils/app-context';
import { Routes } from 'utils/routes';

import { Account, AccountStatus, AccountType } from 'API';
import AccountApi from 'api/account';
import useMobileDetector from 'hooks/use-mobile-detector';
import * as clientStorage from 'utils/client-storage';
import FilterOptions from 'components/FilterOptions';
import filter from '../../images/filter.svg';
import Table from 'components/Table';
import RinngitPayLoader from 'components/RinngitPayLoader';
import { Toaster, toast } from 'react-hot-toast';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export default function Accounts() {
	const appContext = useContext(AppContext);
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [fullAccounts, setFullAccounts] = useState<Account[]>([]);
	const navigate = useNavigate();

	const isMobile = useMobileDetector() === 'mobile';
	const popover = useRef<HTMLIonPopoverElement>(null);
	const [popoverOpen, setPopoverOpen] = useState(false);
	const [searchText, setSearchText] = useState(null);
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [selectedShortName, setSelectedShortName] = useState(null);
	const [selectedOwner, setSelectedOwner] = useState(null);
	const [selectedAccountType, setSelectedAccountType] = useState(null);
	const statusSelectRef = useRef<HTMLIonSelectElement>(null);
	const shortNameInputRef = useRef<HTMLIonInputElement>(null);
	const ownerInputRef = useRef<HTMLIonInputElement>(null);
	const accountTypeSelectRef = useRef<HTMLIonSelectElement>(null);
	const [search, setSearch] = useState(false);
	const [filtering, setFiltering] = useState(false);
	// const [loadAccount, setIsLoading] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	let location = useLocation();

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
	};

	useEffect(() => {
		if (location.state !== null) {
			notify({ toastMessage: location.state.toastMessage, toastType: location.state.toastType });
		}
		console.log('Admin List All Accounts');
		// clientStorage.clearSelectedAccount();
		listAccounts();
		console.log(appContext.userDetails.signInUserSession);
	}, []);

	const totalPages = Math.ceil(accounts.length / itemsPerPage);
	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = Math.min(startIndex + itemsPerPage, accounts.length);
	const paginatedAccounts = accounts.slice(startIndex, endIndex);
	const totalCount = accounts.length;
	const startCount = startIndex + 1;
	const endCount = Math.min(startIndex + itemsPerPage, totalCount);

	async function listAccounts() {
		try {
			setIsLoading(true);
			const listAccounts = await AccountApi.listAccounts(
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
			);
			console.log('Before replacing owner names: ', listAccounts.data.listAccounts.items);
			setFullAccounts(listAccounts.data.listAccounts.items);

			const noDraftAccounts = listAccounts.data.listAccounts.items.filter(
				(account) => account.status !== AccountStatus.DRAFT,
			);

			console.log(noDraftAccounts);

			console.log(filtering);

			const finalAccountList = await getFinalAccountList(
				!filtering ? noDraftAccounts : listAccounts.data.listAccounts.items,
			);

			console.log('After replacing owner names: ', finalAccountList);
			if (finalAccountList) {
				const sortedAccounts = finalAccountList.sort(
					(a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
				);
				console.log(sortedAccounts);
				setAccounts(sortedAccounts);
				setIsLoading(false);
				return finalAccountList;
			}
		} catch (error) {
			setIsLoading(false);
			console.error('Error fetching accounts:', error);
		}
	}

	async function getFinalAccountList(accounts) {
		try {
			const finalAccountList = await Promise.all(
				accounts.map(async (account) => {
					const ownerName = await getAccountOwnerName(account.owner);
					account.owner = ownerName;
					return account;
				}),
			);
			return finalAccountList;
		} catch (error) {
			console.error('Error getting final account list:', error);
		}
	}

	async function getAccountOwnerName(accountOwner) {
		try {
			const ownerName = await AccountApi.getUserByUserId(accountOwner);
			return !ownerName.data.getUserByUserId.Attributes[2]
				? ownerName.data.getUserByUserId.Attributes[1].Value.split('@')[0]
				: ownerName.data.getUserByUserId.Attributes[2].Value.split('@')[0];
		} catch (error) {
			console.error('Error getting final account list:', error);
		}
	}

	function AccountCard({ account, isLastIndex, onClick }) {
		function toSentenceCase(inputString) {
			if (typeof inputString !== 'string' || inputString.length === 0) {
				return '';
			}
			return inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase();
		}

		const handleCardClick = (accountId) => {
			console.log(`Clicked on account with ID: ${accountId}`);
		};

		return (
			<IonRow className="ml-4 mr-5">
				<>
					{!isMobile && (
						<>
							<div
								className={` grid grid-cols-5 border bg-secondary-00 h-14 w-screen hover:bg-mousehover-50 ${
									isLastIndex && 'rounded-b-2xl'
								}`}
							>
								<IonRow className="col-span-1 flex justify-start items-start ml-6 my-4 text-14px">
									<div className={`whitespace-nowrap ${window.innerWidth < 980 ? 'truncate' : ''}`}>
										{account.owner}
									</div>{' '}
								</IonRow>
								<IonRow className="col-span-1 flex justify-start items-center text-14px">
									<IonRouterLink
										href={`/admin/accounts/${account.id}`}
										target="_blank"
										className="clickable-link"
										data-tooltip-id={`accountId-${account.id}`}
									>
										{account.id.slice(0, account.id.length / 2)}...
										<ReactTooltip
											id={`accountId-${account.id}`}
											place="bottom"
											content={account.id}
											style={{ backgroundColor: '#1b77f7', color: '#ffffff' }}
										/>
									</IonRouterLink>
								</IonRow>
								<IonRow className="col-span-1 flex justify-start items-start ml-2 my-4 text-16px">
									{account.shortName}
								</IonRow>
								<IonRow
									className={`col-span-1 flex justify-start items-start ml-2 my-4 text-16px ${
										account.status === AccountStatus.ACTIVE
											? 'text-success'
											: account.status === AccountStatus.DRAFT ||
											  account.status === AccountStatus.PENDING
											? 'text-primary-100'
											: 'text-failure'
									}`}
								>
									<IonIcon
										icon={ellipse}
										className={`col-span-1 flex justify-start items-start mr-1 my-1 pb-0.5 text-16px ${
											account.status === AccountStatus.ACTIVE
												? 'text-success'
												: account.status === AccountStatus.DRAFT ||
												  account.status === AccountStatus.PENDING
												? 'text-primary-100'
												: 'text-failure'
										}`}
									/>
									{account.status}
								</IonRow>
								<IonRow className="col-span-1 flex justify-start items-start ml-2 my-4 text-16px ">
									{toSentenceCase(account?.type)}
								</IonRow>
							</div>
						</>
					)}

					{isMobile && (
						<>
							<IonCard
								className="ml-0 mb-0 mt-3 border border-shao bg-secondary-00 h-24 w-full mr-0 shadow-none rounded-lg"
								// onClick={() => onClick(account)}
							>
								<IonRouterLink
									href={`/admin/accounts/${account.id}`}
									target="_blank"
									className="clickable-link"
								>
									<IonRow className="flex justify-between">
										<IonLabel className="flex text-secondary-75 w-5/12 ml-3 mt-3 text-16px">
											{account.shortName}
										</IonLabel>
										<IonRow className="flex w-5/12 ml-3 mt-1 justify-end text-16px">
											<IonText className="mr-3 text-secondary-75 justify-end mt-2 text-16px">
												{toSentenceCase(account?.type)}
											</IonText>
										</IonRow>
										<IonLabel className="flex text-secondary-75 w-5/12 ml-3 mt-6 text-16px">
											{account.owner}
										</IonLabel>
										<IonLabel
											className={`flex w-5/12 mr-3 justify-end mt-6  text-16px ${
												account.status === AccountStatus.ACTIVE
													? 'text-success'
													: account.status === AccountStatus.DRAFT ||
													  account.status === AccountStatus.PENDING
													? 'text-primary-100'
													: 'text-failure'
											}`}
										>
											<IonIcon
												icon={ellipse}
												className={`h-3 w-3 mt-1.5 mr-1 justify-end  ${
													account.status === AccountStatus.ACTIVE
														? 'text-success'
														: account.status === AccountStatus.DRAFT ||
														  account.status === AccountStatus.PENDING
														? 'text-primary-100'
														: 'text-failure'
												}`}
											/>
											{account.status}
										</IonLabel>
									</IonRow>
								</IonRouterLink>
							</IonCard>
						</>
					)}
				</>
			</IonRow>
		);
	}

	const onAccountSelect = (account) => {
		const route = Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BASIC_DETAILS;
		appContext.setSelectedAccount(account);
		navigate(route, {
			state: { id: account?.id },
		});
	};

	const onCreateAccount = () => {
		clientStorage.clearSelectedAccount();
		navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_BASIC_DETAILS, {
			state: { id: '', page: 0 },
		});
	};

	const openPopover = (e: any) => {
		popover.current!.event = e;
		setPopoverOpen(true);
	};

	const handleSearchInput = async (e) => {
		const searchInput = e.target.value;
		if (!searchInput) {
			setSearch(false);
			console.log('search cleared');
			setSearchText(null);
			listAccounts();
		}
		if (searchInput) {
			console.log('Searching for:', searchInput);
			setSearch(true);
			setSearchText(searchInput);
		}
	};

	const handleSearchEnterKey = async (e) => {
		if (e.key === 'Enter') {
			getUsersByEmailFilter();
		}
	};

	const handleFilterEnterKey = async (e) => {
		if (e.key === 'Enter') {
			filterAccounts();
		}
	};

	const getUsersByEmailFilter = async () => {
		setIsLoading(true);
		console.log('Filtering accounts by email');
		try {
			const filteredEamils = await AccountApi.getUserByEmailFilter(searchText || '');
			console.log(filteredEamils);
			var filteredUserName = filteredEamils.data.getUserByEmailFilter.map((item) => item.Username);
			console.log(filteredUserName);
			const loadAccounts = await searchingAccount();
			var filteredAccounts = loadAccounts.filter((item1) => filteredUserName.includes(item1.owner));
			console.log(filteredAccounts);
			const finalAccountList = await getFinalAccountList(filteredAccounts);
			if (finalAccountList) {
				setAccounts(finalAccountList);
			}
			setIsLoading(false);
		} catch (error: any) {
			console.log('error', error);
			setIsLoading(false);
			notify({ toastMessage: error?.errors[0].message, toastType: 'error' });
			// if (error.errors[0].code === 'Failed to get user by email filter') {
			//
			// 	presentToast(error.errors[0].message);
			// }
		}
	};

	async function searchingAccount() {
		try {
			const listAccounts = await AccountApi.listAccounts(
				undefined,
				undefined,
				undefined,
				undefined,
				undefined,
			);
			console.log(listAccounts.data.listAccounts.items);
			const sortedAccounts = listAccounts.data.listAccounts.items.sort(
				(a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
			);
			setAccounts(sortedAccounts);
			return sortedAccounts;
		} catch (error) {
			setIsLoading(false);
			console.error('Error fetching accounts:', error);
		}
	}

	const handleChange = (e, filterType) => {
		setFiltering(true);
		console.log('Filter Type: ', filterType, 'Filter Value: ', e.detail.value);

		if (filterType === 'STATUS') {
			// console.log('setting status');
			setSelectedStatus(e.detail.value);
		} else if (filterType === 'SHORT_NAME') {
			// console.log('setting shortname');
			setSelectedShortName(e.detail.value);
		} else if (filterType === 'OWNER') {
			// console.log('setting owner');
			setSelectedOwner(e.detail.value);
		} else if (filterType === 'ACCOUNT_TYPE') {
			// console.log('setting account type');
			setSelectedAccountType(e.detail.value);
		}
		// console.log('done');
	};

	const filterAccountss = async () => {
		console.log('Filtering.....');

		const accounts = await listAccounts();

		if (accounts) {
			const filteredAccounts = accounts.filter((account) => {
				// console.log(selectedStatus, selectedShortName, selectedOwner, selectedAccountType);
				// const filteredByStatus = !selectedStatus || account.status === selectedStatus;
				const filteredByStatus = selectedStatus === 'ALL' || account.status === selectedStatus;
				const filteredByType = !selectedAccountType || account.type === selectedAccountType;
				const filteredByShortName =
					!selectedShortName || account.shortName.includes(selectedShortName);
				const filteredByOwnerName = !selectedOwner || account.owner.includes(selectedOwner);

				return filteredByStatus && filteredByType && filteredByShortName && filteredByOwnerName;
			});
			console.log(filteredAccounts);
			setAccounts(filteredAccounts);

			setFiltering(false);
		}
	};

	const filterAccounts = async () => {
		console.log('Filtering.....');
		setIsLoading(true);
		const accounts = await listAccounts();

		if (accounts) {
			let filteredAccounts;

			if (selectedStatus === 'ALL') {
				filteredAccounts = accounts.filter((account) => {
					const filteredByType = !selectedAccountType || account.type === selectedAccountType;
					const filteredByShortName =
						!selectedShortName || account.shortName.includes(selectedShortName);
					const filteredByOwnerName = !selectedOwner || account.owner.includes(selectedOwner);

					return filteredByType && filteredByShortName && filteredByOwnerName;
				});
			} else {
				filteredAccounts = accounts.filter((account) => {
					const filteredByStatus = !selectedStatus || account.status === selectedStatus;
					const filteredByType = !selectedAccountType || account.type === selectedAccountType;
					const filteredByShortName =
						!selectedShortName || account.shortName.includes(selectedShortName);
					const filteredByOwnerName = !selectedOwner || account.owner.includes(selectedOwner);

					return filteredByStatus && filteredByType && filteredByShortName && filteredByOwnerName;
				});
			}

			const sortedAccounts = filteredAccounts.sort(
				(a, b) =>
					new Date(b.UserLastModifiedDate).getTime() - new Date(a.UserLastModifiedDate).getTime(),
			);

			console.log(sortedAccounts);
			setAccounts(sortedAccounts);

			setFiltering(false);
			setIsLoading(false);
		}
	};

	const clearFilter = async () => {
		if (statusSelectRef.current) {
			statusSelectRef.current.value = undefined;
		}

		if (shortNameInputRef.current) {
			shortNameInputRef.current.value = '';
		}

		if (ownerInputRef.current) {
			ownerInputRef.current.value = '';
		}

		if (accountTypeSelectRef.current) {
			accountTypeSelectRef.current.value = undefined;
		}

		listAccounts();
	};

	const handleItemsPerPageClick = (newItemsPerPage) => {
		handlePageChange(1);
		setItemsPerPage(newItemsPerPage);
		setPopoverOpen(false);
	};

	const handlePageChange = (newPage) => {
		setCurrentPage(newPage);
	};

	return (
		<>
			<Toaster />
			{!isLoading ? (
				<>
					<IonMenu side="end" contentId="filter-menu">
						<IonHeader>
							<IonToolbar>
								<IonRow>
									<IonTitle>Filter</IonTitle>
									<IonMenuToggle>
										<IonIcon icon={close} className="h-6 w-6 mr-3 pt-2 cursor-pointer"></IonIcon>
									</IonMenuToggle>
								</IonRow>
							</IonToolbar>
						</IonHeader>
						<IonContent className="ion-padding">
							<IonRow className="flex mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">
									Account Status
								</IonLabel>
							</IonRow>

							<IonSelect
								ref={statusSelectRef}
								interface="popover"
								className="app-input-filed text-16px text-secondary-75 font-medium w-60 bg-secondary-00 pr-2 mt-3 ml-0.5"
								onIonChange={(e) => handleChange(e, 'STATUS')}
								placeholder="Filter by status"
								value={selectedStatus}
							>
								<IonSelectOption className="w-60" value="ALL">
									ALL
								</IonSelectOption>
								{Object.values(AccountStatus).map(
									(status) =>
										status !== AccountStatus.DELETED &&
										status !== AccountStatus.BLOCKED && (
											<IonSelectOption key={status} className="w-60" value={status}>
												{status}
											</IonSelectOption>
										),
								)}
								{/* <IonSelectOption value={AccountStatus.DELETED}>{AccountStatus.DELETED}</IonSelectOption>
						<IonSelectOption value={AccountStatus.BLOCKED}>{AccountStatus.BLOCKED}</IonSelectOption> */}
							</IonSelect>

							<IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">Short Name</IonLabel>
							</IonRow>

							<IonItem className="app-input-filed bg-secondary-00 mt-3 ml-0.5 w-60" lines="none">
								<IonInput
									ref={shortNameInputRef}
									placeholder="Filter by Short Name"
									className=" text-16px text-secondary-75 font-medium bg-secondary-00"
									// value="shortName"
									onIonChange={(e) => handleChange(e, 'SHORT_NAME')}
								></IonInput>
							</IonItem>

							<IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">Owner</IonLabel>
							</IonRow>

							<IonItem className="app-input-filed bg-secondary-00 mt-3 ml-0.5 w-60" lines="none">
								<IonInput
									ref={ownerInputRef}
									placeholder="Filter by Owner"
									className=" text-16px text-secondary-75 font-medium bg-secondary-00"
									onIonChange={(e) => handleChange(e, 'OWNER')}
									// onKeyDown={(e) => handleFilterEnterKey(e)}
								></IonInput>
							</IonItem>

							<IonRow className="flex  mt-5 mb-3.5 pt-1.5 w-screen">
								<IonLabel className="font-normal text-secondary-75 text-16px">
									Account Type
								</IonLabel>
							</IonRow>

							<IonSelect
								ref={accountTypeSelectRef}
								interface="popover"
								className="app-input-filed text-16px text-secondary-75 font-medium w-60 bg-secondary-00 pr-2 mt-3 ml-0.5"
								onIonChange={(e) => handleChange(e, 'ACCOUNT_TYPE')}
								placeholder="Filter by Category"
							>
								{Object.values(AccountType).map((type) => (
									<IonSelectOption key={type} className="w-60" value={type}>
										{type}
									</IonSelectOption>
								))}
							</IonSelect>

							<IonMenuToggle>
								<IonButton className="mt-7 mr-5" onClick={filterAccounts}>
									Apply
								</IonButton>
								<IonButton
									className="mt-7 mr-5"
									onClick={() => {
										setFiltering(false);
										clearFilter();
									}}
								>
									Clear Filter
								</IonButton>
							</IonMenuToggle>
						</IonContent>
					</IonMenu>

					<IonRow>
						<IonRow className="w-screen md:w-1/2 pt-5 pl-4 pb-0 md:pb-0">
							<div className="text-22px text-secondary-75 font-bold">Merchant Accounts</div>
						</IonRow>
					</IonRow>

					<IonRow>
						<IonRow className="min-width-600 max-width-600 pl-2.5 md:pl-2 mb-0 md:mb- pb-5 md:pb-5">
							<IonSearchbar
								className="rounded-2xl mr-3 max-width-600 md:w-80 shadow-none"
								placeholder="Search by email..."
								onIonInput={(e) => handleSearchInput(e)}
								value={searchText}
								onKeyDown={(e) => handleSearchEnterKey(e)}
							></IonSearchbar>
						</IonRow>

						<IonRow className="min-width-600  max-width-600 pt-1.5 pl-4 md:pl-10 pr-5 justify-end shadow-none pb-5">
							<IonButton
								className="h-36px capitalize text-16px rounded-md shadow-none mr-5"
								fill="solid"
								onClick={onCreateAccount}
							>
								Add Account
							</IonButton>
							<IonMenuToggle id="filter-menu">
								<IonIcon
									icon={filter}
									className="pt-1.5 pr-0.5 ml-1 h-7 w-7 cursor-pointer"
								></IonIcon>
							</IonMenuToggle>
						</IonRow>
					</IonRow>

					{/* <Table isLoading={isLoading} tableData={accounts} /> */}

					<IonRow>
						{isLoading ? (
							<>
								{!isMobile && (
									<div className=" w-full h-screen">
										<IonRow>
											<div className="ml-4 grid grid-cols-5 border border-shao bg-secondary-00 h-14 w-screen mr-5 shadow-none rounded-t-2xl">
												<div className="col-span-1 flex justify-start items-start ml-6 my-5 text-16px text-secondary-100 font-bold">
													Owner
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
													Identifiers
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
													Short Name
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
													Status
												</div>
												<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
													Category
												</div>
											</div>
										</IonRow>

										{[...Array(10)].map((_, index) => (
											<div key={index}>
												<div
													className={` grid grid-cols-5 border bg-secondary-00 h-14 ml-4 mr-5 hover:bg-mousehover-50 `}
												>
													<IonRow className="col-span-1 flex justify-start items-start ml-6 my-4 text-14px">
														<IonSkeletonText
															animated
															className="bg-primary-500 w-2/4"
														></IonSkeletonText>
													</IonRow>
													<IonRow className="col-span-1  flex justify-start items-center text-14px">
														<IonSkeletonText
															animated
															className="bg-primary-500 w-2/4"
														></IonSkeletonText>
													</IonRow>
													<IonRow className="col-span-1 flex justify-start items-start ml-2 my-4 text-16px">
														<IonSkeletonText
															animated
															className="bg-primary-500 w-2/4"
														></IonSkeletonText>
													</IonRow>
													<IonRow
														className={`col-span-1 flex justify-start items-start ml-2 my-4 text-16px `}
													>
														<IonSkeletonText
															animated
															className="bg-primary-500 w-2/4"
														></IonSkeletonText>
													</IonRow>
													<IonRow className="col-span-1 flex justify-start items-start ml-2 my-4 text-16px ">
														<IonSkeletonText
															animated
															className="bg-primary-500 w-2/4"
														></IonSkeletonText>
													</IonRow>
												</div>
											</div>
										))}
									</div>
								)}

								{isMobile && (
									<div className="w-screen ">
										{[...Array(10)].map((_, index) => (
											<div key={index}>
												<IonCard className="m-4 bg-primary-200">
													<IonCardContent className="p-4">
														<IonSkeletonText
															animated
															className="bg-primary-500 w-2/4"
														></IonSkeletonText>
														<IonSkeletonText
															animated
															className="bg-primary-500 w-3/4"
														></IonSkeletonText>
														<IonSkeletonText
															animated
															className="bg-primary-500 w-2/4 mt-3"
														></IonSkeletonText>
													</IonCardContent>
												</IonCard>
											</div>
										))}
									</div>
								)}
								<RinngitPayLoader props={{ overlay: true }} />
							</>
						) : (
							<>
								{!isMobile && (
									<div className="ml-4 grid grid-cols-5 border border-shao bg-secondary-00 h-14 w-screen mr-5 shadow-none rounded-t-2xl">
										<div className="col-span-1 flex justify-start items-start ml-6 my-5 text-16px text-secondary-100 font-bold">
											Owner
										</div>
										<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
											Identifiers
										</div>
										<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
											Short Name
										</div>
										<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
											Status
										</div>
										<div className="col-span-1 flex justify-start items-start ml-2 my-5 text-secondary-100 font-bold">
											Category
										</div>
									</div>
								)}

								{accounts.length === 0 && (
									<IonRow className="ml-4 w-screen mr-5 flex items-center justify-center mt-5 mb-5 ">
										<IonLabel className=" text-22px font-bold">No Accounts to Display</IonLabel>
									</IonRow>
								)}

								<div className="w-full">
									{paginatedAccounts.map((account, index) => {
										const isLastIndex = index === paginatedAccounts.length - 1;
										// @ts-ignore
										return (
											<AccountCard
												key={index}
												account={account}
												isLastIndex={isLastIndex}
												onClick={onAccountSelect}
											/>
										);
									})}
								</div>

								{accounts.length !== 0 && (
									<IonRow className="w-full flex items-end justify-end">
										<IonRow className="mt-5 mr-5  flex items-end justify-end">
											{isMobile && (
												<>
													<IonText className="pr-10">{`${startCount} - ${endCount} of ${totalCount}`}</IonText>

													{[playSkipBack, arrowBackSharp, arrowForwardSharp, playSkipForward].map(
														(icon, index) => (
															<IonIcon
																key={index}
																icon={icon}
																className={
																	index === 0
																		? `pl-3 pb-0.5 h-6 w-6 ${
																				currentPage === 1
																					? 'text-secondary-50'
																					: 'text-secondary-75'
																		  }`
																		: index === 1
																		? `pr-3 pl-6 pb-0.5 h-6 w-6 ${
																				currentPage === 1
																					? 'text-secondary-50'
																					: 'text-secondary-75'
																		  }`
																		: index === 2
																		? `pl-6 pr-3 pb-0.5 h-6 w-6 ${
																				currentPage === totalPages
																					? 'text-secondary-50'
																					: 'text-secondary-75'
																		  }`
																		: `pl-3 pb-0.5 h-6 w-6 ${
																				currentPage === totalPages
																					? 'text-secondary-50'
																					: 'text-secondary-75'
																		  }`
																}
																onClick={() => {
																	if (index === 0) {
																		handlePageChange(1);
																	} else if (index === 1) {
																		currentPage !== 1 && handlePageChange(currentPage - 1);
																	} else if (index === 2) {
																		currentPage !== totalPages && handlePageChange(currentPage + 1);
																	} else if (index === 3) {
																		handlePageChange(totalPages);
																	}
																}}
															></IonIcon>
														),
													)}
												</>
											)}

											<IonText className=" ">Items per page:</IonText>

											<div
												className="flex items-end md:items-center justify-end md:justify-center cursor-pointer"
												onClick={openPopover}
											>
												<IonText className="pl-5 pr-5">{itemsPerPage}</IonText>
												<IonIcon
													icon={caretDownSharp}
													className="mt-5 md:mt-0 md:pr-10 pb-1.5 md:pb-0.5 h-4 w-5"
												></IonIcon>
											</div>
											<IonPopover
												size="cover"
												ref={popover}
												isOpen={popoverOpen}
												onDidDismiss={() => setPopoverOpen(false)}
											>
												<IonContent className="ion-padding cursor-pointer">
													{[5, 10, 25, 50].map((value) => (
														<div key={value} onClick={() => handleItemsPerPageClick(value)}>
															{value}
														</div>
													))}
												</IonContent>
											</IonPopover>

											{!isMobile && (
												<>
													<IonText className="pr-10">{`${startCount} - ${endCount} of ${totalCount}`}</IonText>

													{[playSkipBack, arrowBackSharp, arrowForwardSharp, playSkipForward].map(
														(icon, index) => (
															<IonIcon
																key={index}
																icon={icon}
																className={
																	index === 0
																		? `pl-3 pb-0.5 h-5 w-5 ${
																				currentPage === 1
																					? 'text-secondary-50'
																					: 'text-secondary-75 cursor-pointer'
																		  }`
																		: index === 1
																		? `pr-3 pl-6 pb-0.5 h-5 w-5 ${
																				currentPage === 1
																					? 'text-secondary-50'
																					: 'text-secondary-75 cursor-pointer'
																		  }`
																		: index === 2
																		? `pl-6 pr-3 pb-0.5 h-5 w-5 ${
																				currentPage === totalPages
																					? 'text-secondary-50'
																					: 'text-secondary-75 cursor-pointer'
																		  }`
																		: `pl-3 pb-0.5 h-5 w-5 ${
																				currentPage === totalPages
																					? 'text-secondary-50'
																					: 'text-secondary-75 cursor-pointer'
																		  }`
																}
																onClick={() => {
																	if (index === 0) {
																		handlePageChange(1);
																	} else if (index === 1) {
																		currentPage !== 1 && handlePageChange(currentPage - 1);
																	} else if (index === 2) {
																		currentPage !== totalPages && handlePageChange(currentPage + 1);
																	} else if (index === 3) {
																		handlePageChange(totalPages);
																	}
																}}
															></IonIcon>
														),
													)}
												</>
											)}
										</IonRow>
									</IonRow>
								)}
							</>
						)}
					</IonRow>
				</>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</>
	);
}
