import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCol,
	IonGrid,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonRow,
	IonSelect,
	IonSelectOption,
	IonSpinner,
	IonText,
} from '@ionic/react';
import BankAccountApi from 'api/bankaccount';
import LoadImage from 'components/LoadImage';
import { useFormik } from 'formik';
import { chevronForward } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from 'utils/app-context';
import * as clientStorage from 'utils/client-storage';
import * as Yup from 'yup';
import { Routes } from 'utils/routes';
import {
	Account,
	AccountStatus,
	AccountType,
	CTOSReportType,
	DocType,
	DocCategory,
	VerificationStatus,
} from 'API';
import CtosApi from 'api/ctos';
import RinngitPayLoader from 'components/RinngitPayLoader';
import createOutline from '../../images/createOutline.svg';
import AccountApi from 'api/account';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Toaster, toast } from 'react-hot-toast';

const banks = [
	{
		key: 'MAYBANK',
		value: 'MAY BANK',
	},
	{
		key: 'HSBC',
		value: 'HSBC',
	},
	{
		key: 'CIMB',
		value: 'CIMB',
	},
	{
		key: 'AMBANK',
		value: 'AM BANK',
	},
	{
		key: 'RHB',
		value: 'RHB',
	},
	{
		key: 'HONGLEONG',
		value: 'HONG LEONG BANK',
	},
	{
		key: 'OCBC',
		value: 'OCBC BANK',
	},
	{
		key: 'PUBLIC',
		value: 'PUBLIC BANK',
	},
	{
		key: 'SCB',
		value: 'STANDARD CHARTERED BANK',
	},
];

export default function LinkBankAccount() {
	const appContext = useContext(AppContext);
	const [showForm, setShowForm] = useState(false);
	const [updateAccount, setUpdateAccount] = useState<any>(null);
	const userDetails = appContext.userDetails;
	const navigate = useNavigate();
	const accList = appContext.selectedAccount()?.linkedBankAccounts
		? appContext.selectedAccount()?.linkedBankAccounts.items
		: appContext.selectedAccount()?.account.linkedBankAccounts.items;
	const imageToLoad = { imageName: 'bank', className: 'h-24 mt-6' };
	let isAdmin = appContext.userDetails.signInUserSession.idToken.payload['cognito:groups'] || [];

	const accountDetails = appContext.selectedAccount();
	const [isLoading, setIsLoading] = useState(false);
	const [showToast, setShowToast] = useState(false);
	const [fileChange, setFileChange] = useState<boolean>(false);

	const notify = (toastMessageDetails) => {
		if (toastMessageDetails.toastType === 'error') {
			toast.error(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}

		if (toastMessageDetails.toastType === 'success') {
			toast.success(toastMessageDetails.toastMessage, {
				duration: 3200,
				position: 'top-center',
			});
		}
		setShowToast(false);
	};

	useEffect(() => {
		console.log('Link Bank Account Page');
		console.log(accList.length);
		console.log(accList.length !== 0);
		console.log(accList.length === 0);
	}, []);

	const validationSchema = Yup.object({
		accountNumber:
			accList.length === 0
				? Yup.string().matches(/^[0-9]+$/, 'Account Number must contain only numbers')
				: Yup.string(),
		accountHolderName:
			accList.length === 0
				? Yup.string()
						.required('Account Holder is required')
						.matches(/^\S.*\S$/, 'Empty spaces at the beginning or end are not allowed')
				: Yup.string(),

		bankName: accList.length === 0 ? Yup.string().required('Bank Name is required') : Yup.string(),
	});

	let formik = useFormik({
		initialValues: {
			accountNumber: '',
			accountHolderName: '',
			bankName: '',
		},
		validationSchema: validationSchema,
		isInitialValid: false, // Set isInitialValid to false
		onSubmit: (values, { setSubmitting }) => {
			onNext(values, setSubmitting);
		},
	});

	const isFormValid = () => {
		if (
			(formik.values.accountNumber && formik.values.accountHolderName && formik.values.bankName) ||
			accList.length !== 0
		) {
			return true;
		}

		return false;
	};

	const onNext = async (formState, setSubmitting) => {
		setIsLoading(true);
		if (fileChange) {
			console.log('*****');
			console.log(formState);
			console.log(updateAccount);
			console.log(appContext.selectedAccount()?.id);
			try {
				const account = await BankAccountApi.save({
					id: updateAccount?.id,
					updatedBy: userDetails.username,
					owner: accountDetails?.owner,
					status: VerificationStatus.PENDING,
					accountHolderName: formState.accountHolderName,
					accountNumber: formState.accountNumber,
					accountCurrency: 'MYR',
					bankName: formState.bankName,
					default: accList.length > 0 ? false : true,
					accountId: appContext.selectedAccount()?.id,
				});

				setSubmitting(false);
				setShowForm(false);

				if (accountDetails.type === AccountType.INDIVIDUAL) {
					updateAccountStatus();
					const identityDocId = await uploadedDocument();
					console.log(identityDocId);
					getCreditReport(CTOSReportType.REPORT_FULL, identityDocId.id);
					clientStorage.clearSelectedAccount();
					navigate(Routes.PENDING);
				}

				if (accountDetails.type === AccountType.BUSINESS) {
					await getAccount();
					const ssmDocumentId = await uploadedSsmDocument();
					console.log(ssmDocumentId);
					getCreditReport(CTOSReportType.REPORT_FULL, ssmDocumentId.id);
					navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_AUTHORIZATION_LETTER, {
						state: { id: accountDetails?.id, page: 6 },
					});
				}

				if (appContext.isAdmin) {
					await getAccount();
					const ssmDocumentId = await uploadedSsmDocument();
					console.log(ssmDocumentId);
					getCreditReport(CTOSReportType.REPORT_FULL, ssmDocumentId.id);
					navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_AUTHORIZATION_LETTER, {
						state: { id: accountDetails?.id, page: 4 },
					});
				}
			} catch (error) {
				setSubmitting(false);
				setIsLoading(false);
				console.error('Failed Saving Account', error);
			}
		} else {
			if (accountDetails.type === AccountType.INDIVIDUAL) {
				updateAccountStatus();
				navigate(Routes.PENDING);
			}

			if (accountDetails.type === AccountType.BUSINESS) {
				updateAccountStatus();
				navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_AUTHORIZATION_LETTER, {
					state: { id: accountDetails?.id, page: 6 },
				});
			}

			if (appContext.isAdmin) {
				navigate(Routes.CUSTOMER_VERIFICATION + '/' + Routes.ACCOUNT_AUTHORIZATION_LETTER, {
					state: { id: accountDetails?.id, page: 4 },
				});
			}
		}
	};

	async function getAccount() {
		const getAccountDetails = await AccountApi.getByUser(
			!appContext.isAdmin ? appContext.userDetails.username : appContext.selectedAccount().owner,
		).then((accounts) => {
			return accounts;
		});
		const selectedAccount = getAccountDetails.find((account) => {
			return account.id === appContext.selectedAccount().id;
		});
		console.log('--------', selectedAccount);
		appContext.setSelectedAccount(selectedAccount);
	}

	function uploadedDocument() {
		const docType =
			JSON.parse(appContext.selectedAccount().attributes).nationality ==
			appContext.nationality.MALAYSIAN
				? DocType.MYKAD
				: DocType.PASSPORT;
		return appContext
			.selectedAccount()
			?.referenceDocs?.items.find(
				(doc) =>
					doc.docType == docType &&
					doc.docCategory === DocCategory.INDIVIDUAL &&
					doc.account.type === AccountType.INDIVIDUAL,
			);
	}

	function uploadedSsmDocument() {
		return accountDetails?.referenceDocs.items.find(
			(doc) =>
				doc.docType == DocType.SSM &&
				doc.docCategory === DocCategory.BUSINESS &&
				doc.account.type === AccountType.BUSINESS,
		);
	}

	const updateAccountStatus = async () => {
		const updateAccountStatus: Account = await AccountApi.save({
			id: accountDetails?.id,
			shortName: accountDetails?.shortName,
			owner: accountDetails?.owner,
			updatedBy: userDetails?.username,
			createdBy: userDetails?.username,
			type: accountDetails?.type,
			status: AccountStatus.PENDING,
		});
	};

	const updateBankAccount = (account) => {
		setFileChange(true);
		formik
			.setValues({
				accountNumber: account.accountNumber,
				accountHolderName: account.accountHolderName,
				bankName: account.bankName,
			})
			.then((res) => {
				setUpdateAccount(account);
				setShowForm(true);
			});
	};

	const handleChange = (e) => {
		formik.setFieldValue('bankName', e.target.value);
	};

	async function getCreditReport(reportType, identityDocId) {
		console.log(reportType, identityDocId);
		try {
			//
			// await CtosApi.getCreditReport(reportType, identityDocId);
			//

			CtosApi.getCreditReport(reportType, identityDocId);
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<section className="ml-5 md:ml-4 md:pl-0.5 selfie-verification">
			{showToast && <Toaster />}
			{!isLoading ? (
				<IonRow>
					<IonRow className="w-11/12 mt-5">
						<div className="md:ml-5  mb-2 font-bold text-secondary-75 text-22px text-center">
							Link Bank Account
						</div>
					</IonRow>

					<form onSubmit={formik.handleSubmit}>
						<IonRow className="md:w-3/4">
							<IonRow className="w-80 md:w-96">
								<IonRow className="my-4 md:ml-5 w-80">
									<IonCol className="p-0">
										<IonLabel
											position="stacked"
											className="font-normal text-secondary-75 text-16px"
										>
											Account Number
											<span className="text-failure text-xl">*</span>
										</IonLabel>
										<IonItem
											className={`app-input-filed bg-secondary-00 mt-3 ml-0.5 ${
												formik.touched.accountNumber && formik.errors.accountNumber
													? 'app-input-has-error'
													: ''
											}`}
											lines="none"
										>
											<IonInput
												placeholder="Account Number"
												className=" text-16px text-secondary-75 font-medium bg-secondary-00"
												value={formik.values.accountNumber}
												name="accountNumber"
												onIonChange={(e) => {
													formik.handleChange(e);
													setFileChange(true);
												}}
												onIonBlur={formik.handleBlur}
											></IonInput>
										</IonItem>
										<IonText className="text-failure">
											{formik.touched.accountNumber && formik.errors.accountNumber}
										</IonText>
									</IonCol>
								</IonRow>

								<IonRow className="my-4 md:ml-5 w-80">
									<IonCol className="p-0">
										<IonLabel
											position="stacked"
											className="font-normal text-secondary-75 text-16px"
										>
											Account Holder Name
											<span className="text-failure text-xl">*</span>
										</IonLabel>
										<IonItem
											className={`app-input-filed bg-secondary-00 mt-3 ml-0.5 ${
												formik.touched.accountHolderName && formik.errors.accountHolderName
													? 'app-input-has-error'
													: ''
											}`}
											lines="none"
										>
											<IonInput
												placeholder="Account Holder Name"
												className=" text-16px text-secondary-75 font-medium bg-secondary-00"
												value={formik.values.accountHolderName}
												name="accountHolderName"
												onIonChange={(e) => {
													formik.handleChange(e);
													setFileChange(true);
												}}
												onIonBlur={formik.handleBlur}
											></IonInput>
										</IonItem>
										<IonText className="text-failure">
											{formik.touched.accountHolderName && formik.errors.accountHolderName}
										</IonText>
									</IonCol>
								</IonRow>

								<IonRow className="my-4 md:ml-5 w-80">
									<IonCol className="p-0">
										<IonLabel
											position="stacked"
											className="font-normal text-secondary-75 text-16px"
										>
											Bank Name
											<span className="text-failure text-xl">*</span>
										</IonLabel>

										<IonSelect
											placeholder="Bank Name"
											className={`app-input-filed text-16px text-secondary-75 font-medium w-80 bg-secondary-00 pr-2 mt-3 ml-0.5 ${
												formik.touched.bankName && formik.errors.bankName
													? 'app-input-has-error'
													: ''
											}`}
											name="bankName"
											id="bankName"
											onIonChange={(e) => {
												formik.handleChange(e);
												setFileChange(true);
											}}
											selectedText={
												banks.filter((obj) => obj.key === formik.values.bankName)[0]?.value
											}
											interface="popover"
										>
											{banks.map((bank, i) => (
												<IonSelectOption className="w-80" key={bank.key} value={bank.key}>
													{bank.value}
												</IonSelectOption>
											))}
										</IonSelect>

										<IonText className="text-failure">
											{formik.touched.bankName && formik.errors.bankName}
										</IonText>
									</IonCol>
								</IonRow>
							</IonRow>
						</IonRow>

						<IonRow className="md:ml-4 md:pl-1 w-3/4 mb-5 mt-2">
							<IonButton
								type="submit"
								expand="block"
								className="h-36px capitalize text-16px font-normal rounded-md mt-1.5"
								disabled={!isFormValid() || formik.isSubmitting}
							>
								{formik.isSubmitting ? <IonSpinner /> : 'Continue'}
							</IonButton>
						</IonRow>
					</form>

					<>
						<IonRow className="md:ml-5 md:pl-0.5 md:w-5/6 mb-10">
							{accList.map((account, i) => {
								return (
									<>
										<IonCard key={account.id} className="bg-primary-200 mx-0 w-80 mr-5">
											<IonCardContent className="flex  p-4">
												<IonRow className={`w-12 h-12  flex items-center justify-content`}>
													<i
														className={`icon h-8 w-8  linkBank ${
															appContext.appTheme === 'light'
																? 'bg-primary-100'
																: 'bg-secondary-100'
														}`}
													></i>
												</IonRow>

												<IonCol>
													<IonRow className="text-secondary-75 text-16px font-bold ">
														Account Holder Name:
													</IonRow>
													<IonRow className="text-secondary-75 text-16px font-normal w-60 truncate">
														<div
															className="truncate"
															data-tooltip-id={`accountHolderName-${account.accountHolderName}`}
														>
															{account.accountHolderName}
														</div>
													</IonRow>
													<IonRow className="text-secondary-75 text-16px font-bold">
														Account Number:
													</IonRow>
													<IonRow className="text-secondary-75 text-16px font-normal w-60 truncate">
														<div
															className="truncate"
															data-tooltip-id={`accountNumber-${account.accountNumber}`}
														>
															{account.accountNumber}
														</div>
													</IonRow>
													<IonRow className="text-secondary-75 text-16px font-bold">
														Bank Name:
													</IonRow>
													<IonRow className="text-secondary-75 text-16px font-normal">
														{account.bankName}
													</IonRow>
												</IonCol>

												<div className="w-10 h-10 flex pl-2 justify-content absolute right-0">
													<IonIcon
														icon={createOutline}
														className={`h-6 w-6 cursor-pointer`}
														onClick={() => updateBankAccount(account)}
													></IonIcon>
												</div>
											</IonCardContent>
										</IonCard>
										<ReactTooltip
											id={`accountHolderName-${account.accountHolderName}`}
											place="bottom"
											content={account.accountHolderName}
											style={{ backgroundColor: '#1b77f7', color: '#ffffff' }}
										/>
										<ReactTooltip
											id={`accountNumber-${account.accountNumber}`}
											place="bottom"
											content={account.accountNumber}
											style={{ backgroundColor: '#1b77f7', color: '#ffffff' }}
										/>
									</>
								);
							})}
						</IonRow>
					</>
				</IonRow>
			) : (
				<RinngitPayLoader props={{ overlay: true }} />
			)}
		</section>
	);
}
